import React, { useState, useEffect, useCallback } from 'react';
import {
  CityTypeIdEnum,
  NationalityEnum,
} from '../../../../../../../../../Enums';
import { lookupItemsGetId } from '../../../../../../../../../Services/LookupsServices';
import { AutocompleteComponent } from '../../../../../../../../../Components';

export const CountryComponent = ({
  parentTranslationPath,
  translationPath,
  addressLoadings ,
  setAddressLoadings ,
  labelClasses , 
  isSubmitted,
   helperText,
   error,
   onUpdateValue , 
   setAddressList , 
   addressList , 
   propertyValues  , 
   isDisabled,
}) => {

  const GetCountries = useCallback(async () => {
    setAddressLoadings((item) => ({ ...item, country:true }));
    const res = await lookupItemsGetId({
      lookupTypeId: NationalityEnum.lookupTypeId,
    });

    if (!(res && res.status && res.status !== 200)) {
      setAddressList((item) => ({ ...item,  countries: res }));
      
    } else{
      setAddressList((item) => ({ ...item,  countries: res }));
    }
      setAddressLoadings((item) => ({ ...item, country:false }));
  }, []);

  const GetCities = useCallback(async (countryId) => {
    setAddressLoadings((item) => ({ ...item, city:true }));
    const res = await lookupItemsGetId({
      lookupTypeId: CityTypeIdEnum.lookupTypeId,
      lookupParentId: countryId,
    });

    if (!(res && res.status && res.status !== 200)) {
      setAddressList((item) => ({ ...item, cities: res }));

    } else
    setAddressList((item) => ({ ...item, cities: [] }));
    setAddressLoadings((item) => ({ ...item, city:false }));
  }, []);

  useEffect(() => {
    GetCountries();
  }, [GetCountries]);

  return (
    <div>
         <AutocompleteComponent
          idRef='CountryRef'
          selectedValues={propertyValues && propertyValues.country}
          labelValue='country'
          isLoading={addressLoadings.country}
          labelClasses={labelClasses}
          data={addressList ? addressList.countries : []}
          multiple={false}
          displayLabel={(option) => (option && option.lookupItemName) || ''}
          withoutSearchButton
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onChange={(event, newValue) => {
           setAddressList((item) => ({ ...item,  cities: [] , districts : []  , communities : [] , subCommunities:[] }));
          if(newValue)
          GetCities(newValue.lookupItemId) ; 
           onUpdateValue(newValue);
          }}
         isSubmitted={isSubmitted}
         helperText={helperText}
         error={error}
         isWithError
         isDisabled={isDisabled}
      />
    </div>
  );
};
