import React from "react";

function UserOneIcon({ fill, ...restProps }) {
  return (
      <svg
        viewBox="0 0 20 20"
        fill={fill ?? ""}
        xmlns="http://www.w3.org/2000/svg"
        {...restProps}
      >
        <path
          d="M9.617 1.702c-1.801.154-3.368 1.377-3.93 3.065-.195.587-.237.847-.237 1.483 0 .636.042.896.237 1.483.494 1.486 1.819 2.661 3.363 2.983a4.777 4.777 0 0 0 2.439-.15c1.316-.455 2.392-1.535 2.824-2.833.195-.587.237-.847.237-1.483 0-.636-.042-.896-.237-1.483-.65-1.955-2.623-3.243-4.696-3.065m.913 1.666c.889.145 1.715.783 2.121 1.641.199.418.259.71.259 1.241 0 .531-.06.823-.259 1.241-.406.858-1.232 1.496-2.121 1.641a4.273 4.273 0 0 1-1.06 0c-.889-.145-1.715-.783-2.121-1.641-.199-.418-.259-.71-.259-1.241 0-.375.014-.519.072-.733a2.988 2.988 0 0 1 1.071-1.6 2.879 2.879 0 0 1 1.209-.547 4.886 4.886 0 0 1 1.088-.002m-4.308 8.769a4.106 4.106 0 0 0-3.488 2.789c-.151.454-.2.89-.209 1.863-.009.964.001 1.029.204 1.254a.823.823 0 0 0 1.359-.226c.048-.111.061-.293.081-1.084.026-1.024.046-1.161.229-1.555.158-.34.53-.784.823-.983a3.77 3.77 0 0 1 .696-.34c.319-.104.907-.121 4.083-.121s3.764.017 4.083.121c.181.059.542.235.696.34.293.199.665.643.823.983.183.394.203.53.228 1.555.026 1.059.042 1.137.274 1.357.23.218.608.282.9.152a.922.922 0 0 0 .434-.463c.065-.183.049-1.719-.023-2.207-.248-1.686-1.565-3.046-3.284-3.393-.253-.051-.648-.058-3.948-.064-2.016-.004-3.799.006-3.961.022"
          fill-rule="evenodd"
          fill={fill ?? ""}
        />
      </svg>
  );
}

export default UserOneIcon;
