import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { Inputs } from "../Inputs/Inputs";
import { useTranslation } from "react-i18next";

export const OptionsFilterInput = ({
  initialOptions,
  onFilterChange,
  textInput,
}) => {
  const { t } = useTranslation(["Shared"]);

  const [filteredOptions, setFilteredOptions] = useState(initialOptions);

  const onTypedInputChange = (searchValue) => {
    const localInitialOptions = [...(initialOptions || [])];
    const searchValueText = textInput? searchValue.toLowerCase() : "";

    const localFilteredOptions = localInitialOptions.filter((option) => {
      const optionText = textInput? option[textInput].toLowerCase() : "";

      const isFound = optionText.includes(searchValueText)
      if (isFound) return option;
    });

    setFilteredOptions(localFilteredOptions);
  };

  useEffect(() => {
    onFilterChange(filteredOptions);
  }, [filteredOptions]);

  useEffect(() => {
    setFilteredOptions(initialOptions);
  }, []);

  return (
    <Inputs
      idRef="OptionsFilterInputRef"
      wrapperClasses="mt-2"
      inputPlaceholder={`${t("Search-for-filter-columns")}`}
      onInputBlur={(event) => {
        event.stopPropagation();
        event.target.focus();
      }}
      onInputChanged={(event) => {
        const inputValue = event.target.value;
        onTypedInputChange(inputValue);
      }}
    />
  );
};

OptionsFilterInput.propTypes = {
  initialOptions : PropTypes.array,
  onFilterChange : PropTypes.func,
  textInput : PropTypes.string,
}

OptionsFilterInput.defaultProps = {
  initialOptions : [],
  onFilterChange : ()=>{},
  textInput : '',
}