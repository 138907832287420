import React, { useEffect, useRef, useState } from 'react';
import { Spinner } from '../../../../../../Components/SpinnerComponent/Spinner';


export default function GoogleDocIframe({ url, className }) {
  const maxDocLoadLimit = 5;
  const iframeLoadTimeoutId = useRef(null);
  const iframeRef = useRef(null);
  const [iframeLoadCounter, setIframeLoadCounter] = useState(0);
  const [loaded, setLoaded] = useState(false);

  const iframeStyles = (!loaded? { minHeight: 500, border: 'none', visibility: 'hidden' } : {
    minHeight: 500,
    border: 'none',
  });

  const getGoogleDocURL = () => {
    return `https://docs.google.com/gview?url=${url}&embedded=true`;
  };

  const updateIframeSrc = () => {
    const isExceededLoadLimit = iframeLoadCounter > maxDocLoadLimit;

    if (isExceededLoadLimit) return;

    if (iframeRef.current) {
      setIframeLoadCounter(iframeLoadCounter + 1);
      iframeRef.current.src = getGoogleDocURL();
    }
  };
  const clearLoadTimeout = () => {
    clearTimeout(iframeLoadTimeoutId.current);
  };

  const onIframeLoaded = () => {
    clearLoadTimeout();
    setLoaded(true);
  };

  useEffect(() => {
    iframeLoadTimeoutId.current = setTimeout(updateIframeSrc, 2000);

    return clearLoadTimeout;
  }, [iframeLoadCounter]);

  return (
    <>
      <Spinner isActive={!loaded} />
      {url && (
        <iframe
          className={className || ""}
          onLoad={onIframeLoaded}
          ref={iframeRef}
          src={url && getGoogleDocURL()}
          width="100%"
          height="100%"
          style={iframeStyles}
        />
      )}
    </>
  );
}
