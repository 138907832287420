import React from "react";

const SearchHomeIcon = () => {
  return (
    <svg
      width="84"
      height="85"
      viewBox="0 0 84 85"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M71.6773 71.1197L57.1139 56.5564C61.3695 51.9273 63.9718 45.7555 63.9718 38.987C63.9718 24.6575 52.315 13 37.9868 13C23.6578 13 12 24.6575 12 38.9868C12 53.315 23.6575 64.9721 37.9865 64.9721C44.7553 64.9721 50.927 62.3697 55.5559 58.1142L70.1192 72.6773C70.3343 72.8923 70.6161 73 70.8981 73C71.1799 73 71.462 72.8923 71.677 72.6773C72.1077 72.2471 72.1077 71.5499 71.6773 71.1197ZM14.203 38.9868C14.203 25.8724 24.8724 15.203 37.9868 15.203C51.1002 15.203 61.769 25.8724 61.769 38.9868C61.769 52.1002 51.1002 62.769 37.9868 62.769C24.8721 62.769 14.203 52.1002 14.203 38.9868Z"
        fill="#B2A186"
      />
      <path
        d="M38.7653 26.9944C38.3349 26.564 37.6377 26.564 37.2073 26.9944L25.7901 38.4109C25.36 38.841 25.36 39.5385 25.7901 39.9687C26.0055 40.1837 26.287 40.2914 26.569 40.2914C26.8508 40.2914 27.1329 40.1837 27.3479 39.9689L28.8109 38.506V47.2637C28.8109 47.8721 29.3039 48.3653 29.9123 48.3653H46.0582C46.6666 48.3653 47.1596 47.8721 47.1596 47.2637V38.5045L48.6237 39.9687C49.0541 40.3988 49.7514 40.3988 50.1818 39.9687C50.6119 39.5385 50.6119 38.841 50.1818 38.4109L38.7653 26.9944ZM44.9571 46.1621H31.0139V36.303L37.9862 29.3309L44.9568 36.3015V46.1621H44.9571Z"
        fill="#4D3F29"
      />
    </svg>
  );
};
export default SearchHomeIcon;
