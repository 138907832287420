import React, { useEffect, useState } from "react";
import moment from "moment";
import { Box, Typography } from "@material-ui/core";
import { CustomCard, CustomMenu, DeleteConfirmationDialog } from "..";
import { useIsAr, useSelectedTheme, useTranslate } from "../../../Hooks";
import { getDownloadableLink } from "../../../Helper";
import { DeleteScopeDocument } from "../../../Services";
import { useVerticalNav } from "../../../Contexts/VerticalNavContext";
import clsx from "clsx";
import { GetFileIconbyDocumentName } from "./GetFileIconbyDocumentName";

// Styles
import useStyles from "./styles";

//Icons
import {
  ListIcon,
  MoreVertIcon,
  ShareIcon,
  Image01Icon,
  DeleteIcon,
  AttatchmentIcon,
  FileIcon,
  ErrorImageIcon,
} from "../../../assets/icons";

function formatDocumentName(documentName) {
  if (!documentName) return "Untitled";
  const namePart = documentName.split(".")[0].slice(0, 13);
  const extension = documentName.split(".")[1];
  return `${namePart}${extension ? "." + extension : ""}`;
}

function formatCreatorName(creator, isAr) {
  return creator
    ? isAr
      ? `${creator.slice(-9)}...`
      : `${creator.slice(0, 9)}...`
    : "Unknown";
}

function DocumentsContactCard({
  document,
  onShareClickHandler,
  setUpdatedGetAllScopeDocuments,
}) {
  const styles = useStyles();
  const {
    theme: { palette },
  } = useSelectedTheme();
  const { translate } = useTranslate("NewContactsView");
  const { isAr } = useIsAr();
  const { setAlertBoxContent } = useVerticalNav();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [imageError, setImageError] = useState(false);

  const formattedDate = document?.createdOn
    ? moment(document?.createdOn).locale("en").format("D MMM YYYY")
    : "Date Unknown";

  const documentName = formatDocumentName(document?.documentName);
  const creatorName = formatCreatorName(document?.createdBy, isAr);

  useEffect(() => {
    // only for imagetype
    if (
      document.documentId &&
      ["png", "jpg", "jpeg", "gif", "bmp", "tiff", "webp", "svg"].includes(
        documentName.split(".").pop().toLowerCase()
      )
    ) {
      setImageUrl(getDownloadableLink(document.documentId));
    }
  }, [document.documentId]);

  const onDeleteHandler = async (scopeDocumentId) => {
    try {
      const res = await DeleteScopeDocument(scopeDocumentId);
      if (!(res && res.status && res.status !== 200)) {
        setAlertBoxContent({
          display: true,
          variant: "success",
          title: translate("document-deleted-successfully"),
          onClose: () => {
            setAlertBoxContent(null);
          },
        });
        setUpdatedGetAllScopeDocuments(true);
      } else {
        setAlertBoxContent({
          display: true,
          variant: "error",
          title: translate("Something-went-wrong!"),
          onClose: () => {
            setAlertBoxContent(null);
          },
        });
      }
    } catch (error) {
      console.error("sorry-something-wrong-please-try-again!", error);
    } finally {
    }
  };
  return (
    <>
      <CustomCard
        borderRadius="xl"
        borderColor="secondary"
        classes={styles.cardWrapper}
      >
        <Box className={styles.cardContainer}>
          <Box
            className={clsx(styles.headingContainer, {
              [styles.withUrl]: document?.documentLink,
              [styles.withImage]:
                document.documentId &&
                [
                  "png",
                  "jpg",
                  "jpeg",
                  "gif",
                  "bmp",
                  "tiff",
                  "webp",
                  "svg",
                ].includes(documentName.split(".").pop().toLowerCase()),
              [styles.withDocument]:
                documentName &&
                ![
                  "png",
                  "jpg",
                  "jpeg",
                  "gif",
                  "bmp",
                  "tiff",
                  "webp",
                  "svg",
                ].includes(documentName.split(".").pop().toLowerCase()),
            })}
          >
            <Box className={styles.imageWrapper}>
              {imageUrl ? (
                <img
                  src={imageUrl}
                  alt={document.documentName}
                  className={styles.imagePreview}
                  onError={(e) => {
                    e.target.onerror = null;
                    setImageError(true);
                  }}
                />
              ) : imageError ? (
                <ErrorImageIcon
                  width="28"
                  height="28"
                  fill={palette.border.tertiary}
                  className={styles.errorIcon}
                />
              ) : document?.documentLink ? (
                <Box className={styles.containerAttatchmentIcon}>
                  <AttatchmentIcon
                    width="28"
                    height="28"
                    fill={palette.border.tertiary}
                  />
                </Box>
              ) : documentName ? (
                <>
                  {GetFileIconbyDocumentName(
                    documentName.split(".").pop().toLowerCase()
                  )}
                </>
              ) : (
                <span className={styles.noImageText}>
                  There is no preview available
                </span>
              )}
            </Box>
            <CustomMenu
              menuItems={[
                {
                  text: "Add to list",
                  icon: (
                    <ListIcon
                      width="16"
                      height="16"
                      fill={palette.foreground.quarterary}
                    />
                  ),
                },
                {
                  text: "Share",
                  icon: (
                    <ShareIcon
                      width="16"
                      height="16"
                      fill={palette.button.secondaryGray_fg}
                    />
                  ),
                  handler: () => {
                    onShareClickHandler(document);
                  },
                },
                {
                  text: "Delete",
                  icon: (
                    <DeleteIcon
                      width="16"
                      height="16"
                      fill={palette.button.secondaryGray_fg}
                    />
                  ),
                  handler: () => {
                    setIsDeleteDialogOpen(true);
                  },
                },
              ]}
              activeIconClass={styles.menuActiveIconClass}
              defaultIconClass={styles.menuDefaultIconClass}
            >
              <MoreVertIcon width="20" height="20" />
            </CustomMenu>
          </Box>

          <Box className={styles.contentContainer}>
            <Box className={styles.titleWrapper}>
              <Typography
                className={styles.categoryName}
                variant="h6"
                align="center"
              >
                {document?.categoryName || "No Category"}
              </Typography>
              <Box className={styles.documentNameWrapper}>
                {document.documentName ? (
                  [
                    "png",
                    "jpg",
                    "jpeg",
                    "gif",
                    "bmp",
                    "tiff",
                    "webp",
                    "svg",
                  ].includes(documentName.split(".").pop().toLowerCase()) ? (
                    <>
                      <Image01Icon
                        width="20"
                        height="20"
                        fill={palette.utility.utility_gray_400}
                      />
                      <span className={styles.documentName}>
                        {documentName}
                      </span>
                    </>
                  ) : (
                    <>
                      <FileIcon
                        width="20"
                        height="20"
                        fill={palette.utility.utility_gray_400}
                      />
                      <span className={styles.documentLink}>
                        {document.documentName}
                      </span>
                    </>
                  )
                ) : document?.documentLink ? (
                  <>
                    <AttatchmentIcon
                      width="20"
                      height="20"
                      fill={palette.utility.utility_gray_400}
                    />
                    <span className={styles.documentLink}>
                      {document.documentLink}
                    </span>
                  </>
                ) : null}
              </Box>
            </Box>
            <Box className={styles.footerContainer}>
              <Typography
                className={styles.createdOn}
                variant="body1"
                align="center"
              >
                Created On: <b className={styles.date}>{formattedDate}</b>{" "}
                <span>{creatorName}</span>
              </Typography>
            </Box>
          </Box>
        </Box>
      </CustomCard>
      {isDeleteDialogOpen && (
        <DeleteConfirmationDialog
          onClose={() => {
            setIsDeleteDialogOpen(false);
          }}
          title={translate("Your-document-will-be-deleted")}
          subTitle={translate(
            "Are-you-sure-you-want-to-delete-this-document-This-action-cannot-be-undone"
          )}
          onConfirm={() => {
            onDeleteHandler(document.scopeDocumentId);
            setIsDeleteDialogOpen(false);
          }}
          confirmText={translate("Delete")}
        />
      )}
    </>
  );
}

export default DocumentsContactCard;
