import { ButtonBase } from '@material-ui/core';
import React, { useState, useRef, useEffect } from 'react';
import { Inputs, Spinner, ViewTypes } from '../../../Components';
import { ViewTypesEnum } from '../../../Enums';
import { useTranslation } from 'react-i18next';
import { GlobalHistory } from '../../../Helper';
import WorkFlowIcon from "../../../assets/images/icons/WorkFlowIcon.png";
import { GetTriggers } from '../../../Services';

export const WorkFlowCardView = () => {
  const initialNodes = [];
  const parentTranslationPath = 'WorkFlow';
  const translationPath = '';
  const { t } = useTranslation(parentTranslationPath);
  const searchTimer = useRef(null);
  const [Triggers, SetTriggers] = useState([]);


  const triggerImages = [
    { id: 1, imageUrl: "https://img.freepik.com/premium-photo/business-process-workflow-automation-with-flowchart-businessman-pointing-illustration-processing-management_20693-1014.jpg" },
    { id: 2, imageUrl: "https://blogit.create.pt/wp-content/uploads/2018/08/BPM-vs-Workflow-BP-Logix.jpg" },
    { id: 3, imageUrl: "https://kissflow.com/hs-fs/hubfs/Workflow_Mapping.png?width=750&height=450&name=Workflow_Mapping.png" }
  ];

  const defaultImages = [
    "https://img.freepik.com/free-vector/businessmen-work-with-improvement-diagrams-charts-business-process-management-business-process-visualization-it-business-analysis-concept-illustration_335657-2085.jpg",
    "https://learn.g2.com/hubfs/iStock-1172252198.jpg",
    "https://t3.ftcdn.net/jpg/08/54/45/82/360_F_854458200_AF8culqIdaz1TMKRw1JbR0ZaGx6pNbp6.jpg",
    "https://view.subpage.app/app/company/C532b8873cc5442e2b1f2265b77a7d7dc/domain/MTiT0jFlGh/page/Ma9aQb2DGh/article/M52e59e269bfbc6d871027a280eb17cb21721814253357/hero/M4cd31c583ffb66e17295695c64b440bd1722590762244.webp"
  ];


  const [isLoading, setIsLoading] = useState(false);
  const [viewTypes, setViewTypes] = useState(ViewTypesEnum.cards.key);
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 25,
    search: '',
    createdBy: null,
    roleId: null,
    fromDate: null,
    ownerId: null,
    toDate: null,
    orderBy: 1
  });

  const searchHandler = (search) => {
    if (searchTimer.current) clearTimeout(searchTimer.current);
    searchTimer.current = setTimeout(() => {
      setFilter((item) => ({ ...item, pageIndex: 0, search }));
    }, 700);
  };

  const [nodes, setNodes] = useState(initialNodes);
  const onTypeChanged = (activeType) => {
    localStorage.setItem('ReportsBuilderView', JSON.stringify(activeType));
    setViewTypes(activeType);
    setFilter((item) => ({ ...item, pageIndex: 0 }));
  };



  const GetTriggersAPI = async () => {
    setIsLoading(true);
    const response = await GetTriggers({
      pageIndex: 1,
      pageSize: 1000,
      isExist: true,
    });
    if (!(response && response.status && response.status !== 200)) {
      SetTriggers(response);
      setIsLoading(false);
    } else {
      SetTriggers([]);
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  // Function to get a random image
  const getRandomImage = () => {
    const randomIndex = Math.floor(Math.random() * defaultImages.length);
    return defaultImages[randomIndex];
  };

  useEffect(() => {
    GetTriggersAPI();
  }, []);
  const renderTriggers = () => {
    return Triggers && Triggers.result.map((trigger) => {
      // Find the corresponding image for the trigger
      const triggerImage = triggerImages.find(image => image.id === trigger.trigerId);

      return (
        <div className="card" key={trigger.trigerId}
          onClick={() => {
            GlobalHistory.push(`/home/ExistingWorkFlow?trigerId=${trigger.trigerId}`);
          }}
        >
          <div className="card-inner" style={{ "--clr": "#fff" }}>
            <div className="box">
              <div className="imgBox">
                <img
                  src={triggerImage ? triggerImage.imageUrl : getRandomImage()} // If not found, fallback to a random image
                  alt={trigger.trigerName || "Workflow"}
                />
              </div>
              <div className="icon">
                <a href="#" className="iconBox">
                  <span className="material-symbols-outlined">
                    <span className="mdi mdi-vector-point" />
                  </span>
                </a>
              </div>
            </div>
          </div>
          <div className="content flex-content">
            <h3>{trigger.trigerName}</h3>
            <p>Fill out the form and the Flow will offer the right team of experts</p>
            <ul>
              <li className="branding" style={{ "--clr-tag": "#d3b19a" }}>*********</li>
              <li className="packaging" style={{ "--clr-tag": "#70b3b1" }}>*********</li>
              <li className="marketing" style={{ "--clr-tag": "#d05fa2" }}>*********</li>
            </ul>
          </div>
        </div>
      );
    });
  };


  return (
    <div className='WorkFlow-View-wrapper card-item--work-flow  body-card-item--work-flow   '>
      <Spinner isActive={isLoading} />
      {/* <div className=' view-wrapper d-flex-column'>
        <div className='header-section'>
          <div className='filter-section'>
            <div className='filter-ButtonBase'>
              <div className='section px-2'>
                <ButtonBase
                  id='add-new-Reports-ref'
                  onClick={() => {
                    localStorage.removeItem('nodes');
                    localStorage.removeItem('edges');
                    GlobalHistory.push(`/home/QA`)
                  }
                  }
                  className='btns theme-solid bg-primary'
                >
                  <span className='mdi mdi-file-tree mx-1 ' />
                  {t(`${translationPath}Add New Flows`)}
                </ButtonBase>
              </div>
            </div>
            <div />
            <div className='section autocomplete-section'>
              <div className='d-flex-column px-2 w-100 p-relative'>
                <div className='d-flex-column w-100'>
                  <Inputs
                    idRef='SearcReportshRef'
                    label={t(`${translationPath}Search in Flows`)}
                    // value={searchInput}
                    beforeIconClasses='mdi mdi-magnify mdi-24px c-gray-primary'
                    inputPlaceholder={t(`${translationPath}type-Search-in-Reports`)}
                    translationPath={translationPath}
                    parentTranslationPath={parentTranslationPath}
                    onInputChanged={(e) => {
                      searchHandler(e.target.value);
                    }}
                  />
                </div>
              </div>
              <ViewTypes
                onTypeChanged={onTypeChanged}
                initialActiveType={viewTypes}
                activeTypes={[ViewTypesEnum.tableView.key, ViewTypesEnum.cards.key]}
                className='mb-3'
              />
            </div>
          </div>
        </div>
        {' '}
      </div> */}

      <section className="   body-card-item--work-flow">
        <ButtonBase
          onClick={() => {
            GlobalHistory.push(`/home/MainWorkFlowView`);
          }}
        >        <span className='mdi mdi-arrow-left-thick' /> Back to Main Work Flow Page</ButtonBase>
        <h2>Existing<br />Work Flows</h2>
        <div className="container">
          {/* Card 1 */}
          {/* <div className="card">
      <div className="card-inner" style={{ "--clr": "#fff" }}>
        <div className="box">
          <div className="imgBox">
            <img
              src="https://images.unsplash.com/photo-1601049676869-702ea24cfd58?q=80&w=2073&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              alt="Trust & Co."
            />
          </div>
          <div className="icon">
            <a href="#" className="iconBox">
              <span className="material-symbols-outlined">arrow_outward</span>
            </a>
          </div>
        </div>
      </div>
      <div className="content flex-content">
        <h3>Trust &amp; Co.</h3>
        <p>Fill out the form and the algorithm will offer the right team of experts</p>
        <ul>
          <li
            className="branding"
            style={{ "--clr-tag": "#d3b19a" }}
          >
            Branding
          </li>
          <li
            className="packaging"
            style={{ "--clr-tag": "#70b3b1" }}
          >
            Packaging
          </li>
        </ul>
      </div>
    </div> */}
          {Triggers && Triggers.result && Triggers.result.length > 0 ? renderTriggers() : (
            <div style={{ textAlign: 'center', opacity: 0.21 }}>
              <img alt="folder" className="no-work-flow-found" src={WorkFlowIcon} />
              <p className="no-work-flow-found-text">No Workflows Found</p>
            </div>
          )}

        </div>
      </section>




      {/* {JSON.parse(localStorage.getItem('nodes')).length > 0 &&
        <div class="cardsWork">
          <div>
            <div onClick={() => {
              GlobalHistory.push(`/home/QA`)
            }} class="card-WORKFLOW">
              <img src="https://www.jotform.com/blog/wp-content/uploads/2020/02/Well-known-workflow-types-1536x1195.png" class="card__image" alt="" />
              <div class="card__overlay">
                <div class="card__header">
                  <svg class="card__arc" xmlns="http://www.w3.org/2000/svg"><path /></svg>
                  <img class="card__thumb" src="https://static.vecteezy.com/system/resources/previews/018/667/648/original/roadmap-line-gradient-icon-vector.jpg" alt="" />
                  <div class="card__header-text">
                    <h3 class="card__title">Insert Lead</h3>
                    <span class="card__status">1 hour ago</span>
                  </div>
                </div>
                <p class="card__description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores, blanditiis?</p>
              </div>
            </div>
          </div>
        </div>
        ||
        <div style={{ textAlign: 'center', opacity: 0.21 }}>
          <img alt="folder" className="no-work-flow-found" src={WorkFlowIcon} />
          <p className='no-work-flow-found-text'>No Flows Found</p>
        </div>
      } */}
    </div>
  );
};