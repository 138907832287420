import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { PhonesComponent } from "../../../../../Components";
import {
  duplicatePhoneRole,
  PhoneValidationRole,
} from "../../../../../Rule/PhoneRule";

export const PhoneItemComponent = ({
  props,
  item,
  index,
  loopValue,
  state,
  setState,
  listItem,
  setListItem,
  setSaveIsDisabled,
  id,
  setIsOpenPhone,
  isOpenPhone,
}) => {
  // const translationPath = '';
  // const { t } = useTranslation('FormBuilder');
  const [helperText, setHelperText] = useState("");
  const { t } = useTranslation("Shared");
  const [currentvalue, setcurrentvalue] = useState("");
  const searchTimer = useRef(null);

  useEffect(() => {
    const itemRegex = new RegExp(item.data.regExp);
    if (
      currentvalue !== "" &&
      (!itemRegex.test(currentvalue) ||
        currentvalue?.length < 9 ||
        currentvalue.length > 14)
    ) {
      setHelperText(
        item.data.errorMsg || t("please-fill-phone-in-correct-way")
      );
      setSaveIsDisabled(true);
    } else if (isPhoneItemDuplicating()) {
      setHelperText(t("duplicate-phone-number"));
      setSaveIsDisabled(true);
    } else {
      setHelperText("");
      setSaveIsDisabled(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentvalue, id]);

  console.log("OTHER", currentvalue, state);

  const isPhoneItemDuplicating = () => {
    if (!props.initialState || !props.initialState.phone) return false;
    let isDublocate = false;
    let list = [];
    if (state && state.others)
      list = [...state.others, props.initialState.phone];
    else list = [props.initialState.phone];

    list.map((item) => {
      if (list.filter((w) => w === currentvalue).length > 1) isDublocate = true;
    });
    return isDublocate;
  };
  useEffect(() => {
    console.log(state?.others?.some((p) => p === ""));

    if (state?.others?.some((p) => p === "")) {
      const ff = [...state?.others.filter((o) => o !== "")];
      setState({ id: "others", value: ff });
    }
  }, [isOpenPhone]);

  return (
    <PhonesComponent
      idRef={`phonesRef${index + 1}`}
      isValid={() =>
        !duplicatePhoneRole(
          props.type,
          item,
          props.itemList,
          props.selectedValues
        ) ||
        PhoneValidationRole(
          state && state.others && state.others[index]
            ? state.others[index]
            : ""
        )
      }
      labelValue={`${index + 2}- ${props.label}`}
      isDisabled={item.data.isReadonly}
      value={
        state === null ||
        state === undefined ||
        !state.others ||
        !state.others[index]
          ? item.data.defaultCountryCode
          : state.others[index] || ""
      }
      helperText={helperText}
      error={helperText !== ""}
      onInputChanged={(value) => {
        value = value.replaceAll(" ", "");
        setcurrentvalue(value);
        console.log("--- Value Phone Number  ---", value);
        if (searchTimer.current) clearTimeout(searchTimer.current);
        searchTimer.current = setTimeout(() => {
          if (
            state &&
            state.others &&
            state.others.length > 0 &&
            index === state.others.length
          ) {
            let newUpdate = [...state.others, value === "+" ? "" : value];
            console.log("1", value);
            setState({ id: "others", value: newUpdate });
          } else if (
            state &&
            state.others &&
            state.others.length > 0 &&
            index !== state.others.length
          ) {
            console.log("2", value);
            let newUpdate = [...state.others];
            newUpdate[index] = value === "+" ? "" : value;
            setState({ id: "others", value: newUpdate });
          } else if (
            state &&
            state.others &&
            state.others.length === 0 &&
            index === 0
          ) {
            setState({ id: "others", value: [value === "+" ? "" : value] });
            console.log("3", value);
          } else {
            console.log("4", value);
            setState({
              id: "others",
              value: value === "" || value === "+" ? [""] : [value],
            });
          }
        }, 50);
      }}
      buttonOptions={
        (!item.data.isReadonly && {
          className: `btns-icon theme-solid ${
            index === listItem.length - 1 ? "bg-blue-lighter" : "bg-danger"
          }`,
          iconClasses:
            index === listItem.length - 1 ? "mdi mdi-plus" : "mdi mdi-minus",
          isDisabled: !(
            state &&
            state.others &&
            state.others[index] &&
            state.others[index].length >= 9 &&
            !isPhoneItemDuplicating()
          ),
          isRequired: false,
          onActionClicked:
            index === listItem.length - 1
              ? () => {
                  if (
                    state &&
                    state.others &&
                    state.others[index] &&
                    state.others[index].length >= 9
                  )
                    setListItem([
                      ...listItem,
                      listItem[listItem.length - 1] + 1,
                    ]);
                }
              : () => {
                  const listItemIndex = listItem.findIndex(
                    (f) => f === loopValue
                  );
                  listItem.splice(listItemIndex, 1);
                  state.others.splice(listItemIndex, 1);
                  setState({ id: "others", value: [...state.others] });
                  const newlist = [];
                  listItem.map((v, loopIndex) => newlist.push(loopIndex + 2));
                  setListItem([...newlist]);
                },
        }) ||
        undefined
      }
    />
  );
};

PhoneItemComponent.propTypes = {
  props: PropTypes.instanceOf(Object).isRequired,
  state: PropTypes.instanceOf(Object).isRequired,
  item: PropTypes.instanceOf(Object).isRequired,
  index: PropTypes.number.isRequired,
  setState: PropTypes.func.isRequired,
  listItem: PropTypes.array.isRequired,
  setListItem: PropTypes.func.isRequired,
  setSaveIsDisabled: PropTypes.func.isRequired,
};
