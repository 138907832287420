import React, { useCallback, useEffect, useState, useReducer } from 'react';
import {useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";
import { ButtonBase } from '@material-ui/core';
import { ContactsMergeActions } from '../../../../../../store/ContactsMerge/Actions';
import { Spinner, ViewTypes, CheckboxesComponent } from '../../../../../../Components';
import { GetDuplicateCriteria, GetCustomDuplicateContact, contactsDetailsGet} from '../../../../../../Services';
import { ViewTypesEnum, TableFilterOperatorsEnum, IndividualContactDuplicatesCriteriaEnum, CorporateContactDuplicatesCriteriaEnum} from '../../../../../../Enums';
import { GetParams, GlobalHistory } from '../../../../../../Helper';
import { GetAllContactUnitTransactions } from '../../../../../../Services';
import { DuplicatesContactsComponenets } from './DuplicatesContactsUtilities/DuplicatesContactsComponenets';
import { ContactsMapper } from '../../../ContactsUtilities';

export const DuplicatesContactsComponent = ({
  parentTranslationPath,
  translationPath,
  setActiveTab,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const dispatch = useDispatch();
  const location = useLocation();
  const [checkedCards, setCheckedCards] = useState([]);
  const [checkedCardsIds, setCheckedCardsIds] = useState([]);
  const [activeCard, setActiveCard] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [activeActionType, setActiveActionType] = useState(
    ViewTypesEnum.tableView.key
  );
  const [duplicatesContacts, setDuplicatesContacts] = useState({
    result: [],
    totalCount: 0,
  });
  const [contactDetails,setContactDetails]= useState(null)
  const [urlParams,setUrlParams]= useState({
    contactId: +GetParams('id'),
    formTypeId: +GetParams('formType')
  });
  const [filter, setFilter] = useState({
    pageSize: 25,
    pageIndex: 0,
  });

  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);

  const initialState = {
    Corporate: {
      companyName: false, 
      email: false, 
      mobile: false, 
    },
    Individual: {
      firstName: false, 
      lastName: false, 
      email: false, 
      mobile: false, 
      nationality: false, 
      idNumber: false, 
      passportNumber: false, 
    }
  }
  const [isWithDiscard, setIsWithDiscard] = useState(false);
  const [criteriaParameters, setCriteriaParameters] = useReducer(reducer, initialState)
  const [iscCriteriaParametersLoaded, setIscCriteriaParametersLoaded] =useState(false)
  const [isDiscardChanged, setIsDiscardChanged] = useState(false);

  const MatchContactMapper = (contact) =>({
    Corporate:{
      companyName: contact?.company_name || '',
      email: contact?.general_email?.email || '', 
      mobile: contact?.landline_number?.phone || '', 
    },
    Individual:{
      firstName: contact?.first_name || '', 
      lastName: contact?.last_name || '', 
      email: contact?.email_address?.email || '', 
      mobile: contact?.mobile?.phone || '', 
      nationality: contact?.nationality?.lookupItemName || '', 
      idNumber: contact?.id_card_no || '', 
      passportNumber: contact?.passport_no || '',
    }
    })

    const getIsNoCriteriaSelected = ()=>{
      let isNoCriteriaSelected = false;
      if(criteriaParameters && urlParams.formTypeId === 1){
        isNoCriteriaSelected = !(Object.values(criteriaParameters.Individual).
        find(isSelectedFlag => isSelectedFlag === true))
      }
      if(criteriaParameters && urlParams.formTypeId === 2){
        isNoCriteriaSelected = !(Object.values(criteriaParameters.Corporate).
        find(isSelectedFlag => isSelectedFlag === true))
      }
      return isNoCriteriaSelected;
    }

  const getContactDetails = useCallback(async () => {
    setIsLoading(true);

    const res = await contactsDetailsGet({ id: urlParams.contactId });
    if (!(res && res.status && res.status !== 200)) {
      if(res){
        setContactDetails(MatchContactMapper(res.contact));
        const mappedContact = ContactsMapper(res, res.contact)
        setCheckedCards([mappedContact]);
      }
    } else {
      setContactDetails({});
    }
    setIsLoading(false);
  }, [urlParams]);

  const getCustomDuplicateContact = useCallback(async () => {
    setIsLoading(true);

    let localCriteria = {}

    if (urlParams.formTypeId === 1) {
      Object.keys(criteriaParameters.Individual)
        .map(item => {
          const isCriteriaSelected = criteriaParameters.Individual[item] === true;
          const criteriaDisplayPath = IndividualContactDuplicatesCriteriaEnum[item]?.displayPath;
          if (isCriteriaSelected && criteriaDisplayPath) {
            localCriteria = {
              ...localCriteria, [criteriaDisplayPath]: [
                {
                  searchType: TableFilterOperatorsEnum.equal.key,
                  value: contactDetails.Individual[item]
                }
              ]
            };
          }
        })
    } else if (urlParams.formTypeId === 2) {
      Object.keys(criteriaParameters.Corporate)
      .map(item => {
        const isCriteriaSelected = criteriaParameters.Corporate[item] === true;
        const criteriaDisplayPath = CorporateContactDuplicatesCriteriaEnum[item]?.displayPath;
        if (isCriteriaSelected && criteriaDisplayPath) {
          localCriteria = {
            ...localCriteria, [criteriaDisplayPath]: [
              {
                searchType: TableFilterOperatorsEnum.equal.key,
                value: contactDetails.Corporate[item]
              }
            ]
          };
        }
      })
    }

    const body = {
      pageIndex: filter.pageIndex + 1,
      pageSize: filter.pageSize,
      matchContactId: urlParams.contactId,
      withDiscard: isWithDiscard,
      criteria: localCriteria,
    }

    const res = await GetCustomDuplicateContact(body);
    if (!(res && res.status && res.status !== 200)) {
      setDuplicatesContacts({
        result: ((res && res.result) || []).map((item) => ContactsMapper(item, JSON.parse(item.contactJson).contact)),
        totalCount: (res && res.totalCount) || 0,
      });
      setIsDiscardChanged(false);
    } else { 
      setDuplicatesContacts({
        result: [],
        totalCount: 0,
      });
      setIsDiscardChanged(false);
    }
    setIsLoading(false);
  },[filter, isWithDiscard, criteriaParameters, duplicatesContacts, contactDetails, urlParams]);
  
  const getDuplicateCriteria = useCallback(async () => {
    setIsLoading(true);
    const res = await GetDuplicateCriteria();
    if (!(res && res.status && res.status !== 200)) {

      const contactDuplicateCriteria = res.find(item=> (item.contactDuplicateCriteriaId === urlParams.formTypeId ));

      if(contactDuplicateCriteria){
        setCriteriaParameters({
          id: 'edit',
          value: {
            Corporate:{
              companyName: contactDuplicateCriteria.companyName,
              email: contactDuplicateCriteria.email,
              mobile: contactDuplicateCriteria.mobile,
            },
            Individual: {
              firstName: contactDuplicateCriteria.firstName,
              lastName: contactDuplicateCriteria.lastName,
              email: contactDuplicateCriteria.email,
              mobile: contactDuplicateCriteria.mobile,
              nationality: contactDuplicateCriteria.nationality,
              idNumber: contactDuplicateCriteria.idNumber,
              passportNumber: contactDuplicateCriteria.passportNumber,
            }
          }
        })
      }
      setIscCriteriaParametersLoaded(true);
    }
    setIsLoading(false);
  },[urlParams]);
  
  const onTypeChanged = useCallback((activeType) => {
    setActiveActionType(activeType);
  }, []);

  const getAllContactUnitTransactions = useCallback(async () => {
    setIsLoading(true);
    const res = await GetAllContactUnitTransactions(filter, +GetParams('id'));
    if (!(res && res.status && res.status !== 200)) {
      const isContactHasTransactions = res&&(res.result&& res.result.length > 0);
      return isContactHasTransactions;
    }
    setIsLoading(false);
  }, [filter]);

  const mergeContactHandler = async () =>{
    const isOriginalWithTransactions = await getAllContactUnitTransactions()
    const mergeContactsInfo = {
      ContactsMergeIds: checkedCardsIds,
      isOriginalWithTransactions,
    }
    const userTypeId = checkedCards[0] ? checkedCards[0].userTypeId : null;
    dispatch(ContactsMergeActions.contactsMergeRequest(mergeContactsInfo));
    GlobalHistory.push(`/home/Contacts-CRM/merge?userTypeId=${userTypeId}`);
  }

  useEffect(() => {
    if (iscCriteriaParametersLoaded && contactDetails) getCustomDuplicateContact();
  }, [iscCriteriaParametersLoaded, contactDetails, urlParams, filter]);
  
  useEffect(()=>{
    getContactDetails();
    getDuplicateCriteria();
    setCheckedCardsIds([urlParams.contactId])
  },[urlParams])

  useEffect(()=>{
    setUrlParams({
      contactId: +GetParams('id'),
      formTypeId: +GetParams('formType')
    })
  },[location])

  return (
    <div className='associated-contacts-wrapper childs-wrapper p-relative'>
      <Spinner isActive={isLoading} isAbsolute />
      <div className='header-section'>
        <div className='filter-section px-2'>
          <div className='section mt-1'>
            <ButtonBase
              className='btns theme-solid px-3'
              disabled={checkedCardsIds.length === 1}
              onClick={mergeContactHandler}
            >
              {t(`${translationPath}merge`)}
            </ButtonBase>
          </div>
          <div className='parameter-wrapper'>
          <fieldset className='parameter-fieldset'>
          <legend>{t(`${translationPath}duplicates-parameters`)}</legend>
              {urlParams.formTypeId === 1 &&
                <div>
                  <CheckboxesComponent idRef="firstNameRef"
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    label="first-name"
                    themeClass="theme-secondary"
                    singleChecked={criteriaParameters.Individual.firstName}
                    onSelectedCheckboxChanged={() => {
                      setCriteriaParameters({
                        id: 'Individual',
                        value: {
                          ...criteriaParameters.Individual,
                          firstName: !criteriaParameters.Individual.firstName
                        }
                      })
                    }}
                  />
                  <CheckboxesComponent idRef="lastNameRef"
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    label="last-name"
                    themeClass="theme-secondary"
                    singleChecked={criteriaParameters.Individual.lastName}
                    onSelectedCheckboxChanged={() => {
                      setCriteriaParameters({
                        id: 'Individual',
                        value: {
                          ...criteriaParameters.Individual,
                          lastName: !criteriaParameters.Individual.lastName
                        }
                      })
                    }}
                  />
                  <CheckboxesComponent idRef="mobileRef"
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    label="mobile"
                    themeClass="theme-secondary"
                    singleChecked={criteriaParameters.Individual.mobile}
                    onSelectedCheckboxChanged={() => {
                      setCriteriaParameters({
                        id: 'Individual',
                        value: {
                          ...criteriaParameters.Individual,
                          mobile: !criteriaParameters.Individual.mobile
                        }
                      })
                    }}
                  />
                  <CheckboxesComponent idRef="emailRef"
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    label="email"
                    themeClass="theme-secondary"
                    singleChecked={criteriaParameters.Individual.email}
                    onSelectedCheckboxChanged={() => {
                      setCriteriaParameters({
                        id: 'Individual',
                        value: {
                          ...criteriaParameters.Individual,
                          email: !criteriaParameters.Individual.email
                        }
                      })
                    }}
                  />
                  <CheckboxesComponent idRef="nationalityRef"
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    label="nationality"
                    themeClass="theme-secondary"
                    singleChecked={criteriaParameters.Individual.nationality}
                    onSelectedCheckboxChanged={() => {
                      setCriteriaParameters({
                        id: 'Individual',
                        value: {
                          ...criteriaParameters.Individual,
                          nationality: !criteriaParameters.Individual.nationality
                        }
                      })
                    }}
                  />
                  <CheckboxesComponent idRef="idNumberRef"
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    label="id-number"
                    themeClass="theme-secondary"
                    singleChecked={criteriaParameters.Individual.idNumber}
                    onSelectedCheckboxChanged={() => {
                      setCriteriaParameters({
                        id: 'Individual',
                        value: {
                          ...criteriaParameters.Individual,
                          idNumber: !criteriaParameters.Individual.idNumber
                        }
                      })
                    }}
                  />
                  <CheckboxesComponent idRef="passportNumberRef"
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    label="passport-number"
                    themeClass="theme-secondary"
                    singleChecked={criteriaParameters.Individual.passportNumber}
                    onSelectedCheckboxChanged={() => {
                      setCriteriaParameters({
                        id: 'Individual',
                        value: {
                          ...criteriaParameters.Individual,
                          passportNumber: !criteriaParameters.Individual.passportNumber
                        }
                      })
                    }}
                  />
                </div>
              }
              {urlParams.formTypeId === 2 &&
                <div>
                  <CheckboxesComponent idRef="companyNameRef"
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    label="company-name"
                    themeClass="theme-secondary"
                    singleChecked={criteriaParameters.Corporate.companyName}
                    onSelectedCheckboxChanged={() => {
                      setCriteriaParameters({
                        id: 'Corporate',
                        value: {
                          ...criteriaParameters.Corporate,
                          companyName: !criteriaParameters.Corporate.companyName
                        }
                      })
                    }}
                  />
                  <CheckboxesComponent idRef="landline-numberRef"
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    label="landline-number"
                    themeClass="theme-secondary"
                    singleChecked={criteriaParameters.Corporate.mobile}
                    onSelectedCheckboxChanged={() => {
                      setCriteriaParameters({
                        id: 'Corporate',
                        value: {
                          ...criteriaParameters.Corporate,
                          mobile: !criteriaParameters.Corporate.mobile
                        }
                      })
                    }}
                  />
                  <CheckboxesComponent idRef="emailRef"
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    label="email"
                    themeClass="theme-secondary"
                    singleChecked={criteriaParameters.Corporate.email}
                    onSelectedCheckboxChanged={() => {
                      setCriteriaParameters({
                        id: 'Corporate',
                        value: {
                          ...criteriaParameters.Corporate,
                          email: !criteriaParameters.Corporate.email
                        }
                      })
                    }}
                  />
                </div>
              }
      <div className='parameters-button-wrapper'>
      <CheckboxesComponent idRef="withDiscardRef"
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        label="with-discarded"
        themeClass="theme-secondary"
        singleChecked={isWithDiscard}
        onSelectedCheckboxChanged={()=>{
          setIsWithDiscard(!isWithDiscard);
        }}
      />
      <ButtonBase
      onClick={getCustomDuplicateContact}
      disabled={getIsNoCriteriaSelected()}
      className='btns theme-solid px-3'>
        {t(`${translationPath}show-duplicates`)}
      </ButtonBase>
        </div>
          </fieldset>
          </div>
          <ViewTypes
            onTypeChanged={onTypeChanged}
            initialActiveType={ViewTypesEnum.tableView.key}
            activeTypes={[ViewTypesEnum.tableView.key, ViewTypesEnum.cards.key]}
          />
        </div>
      </div>
      <DuplicatesContactsComponenets
        filter={filter}
        setFilter={setFilter}
        activeCard={activeCard}
        setActiveTab={setActiveTab}
        checkedCards={checkedCards}
        setActiveCard={setActiveCard}
        setCheckedCards={setCheckedCards}
        checkedCardsIds={checkedCardsIds}
        translationPath={translationPath}
        reloadData={getCustomDuplicateContact}
        activeActionType={activeActionType}
        duplicatesContacts={duplicatesContacts}
        isDiscardChanged={isDiscardChanged}
        setIsDiscardChanged={setIsDiscardChanged}
        setCheckedCardsIds={setCheckedCardsIds}
        parentTranslationPath={parentTranslationPath}
      />
    </div>
  );
};
 
DuplicatesContactsComponent.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  setActiveTab: PropTypes.func.isRequired,
};
