import moment from 'moment';
import { ContactTypeEnum, UserAccountTypeEnum, ContactPreferenceEnum } from '../../../../../Enums';

export const ContactsMapper = (item, contact) => {
  const getAccountType = (type) => {
    if (type === 'Normal') return UserAccountTypeEnum.normal.value;
    if (type === 'VIP') return UserAccountTypeEnum.platinum.value;
    if (type === 'VVIP') return UserAccountTypeEnum.gold.value;
    return null;
  };
  let userTypeId = contact && contact.contact_type_id;
  const { leadTypes, isDiscardContact } = item;
  if (contact) {
    return {
      ...contact,
      opportunityContact: item.opportunityContact,
      id: item.contactId || item.contactsId,
      createdBy: item.createdBy,
      isBulkUpload: item.isBulkUpload,
      isHubSpot: item.isHubSpot,
      isMarketingContact: item.isMarketingContact,
      updatedBy: item.updatedBy,
      isWithTransaction: +item.isWithTransaction,
      map: contact && contact.map && `${contact.map.latitude} , ${contact.map.longitude}` || '',
      contactClassifications: (contact && contact.contact_classifications) || [],
      contact_classifications:
        contact && contact.contact_classifications && contact.contact_classifications.length > 0 ?
          contact.contact_classifications.map((el) => `${el.lookupItemName}, `) :
          'N/A',
      contact_preference:
        contact && contact.contact_preference && contact.contact_preference.length > 0 ?
          contact.contact_preference.map((el) => `${el.lookupItemName}, `) :
          'N/A',
      contactPreference: (userTypeId === 1 && contact?.contact_preference) ||
        (userTypeId === 2 && contact?.contact_preference &&
          contact.contact_preference.filter(item => item.lookupItemName !== ContactPreferenceEnum.whatsapp.value)) || [],
      imagePath: contact &&
        contact['contact_type_id'] && contact['contact_type_id'] === 1 ?
        (contact && contact.contact_image &&
          contact.contact_image['Image Upload'] &&
          contact.contact_image['Image Upload'][0] &&
          contact.contact_image['Image Upload'][0].uuid) ||
        '' :
        (contact && contact.company_logoimage &&
          contact.company_logoimage['Company Logo'] &&
          contact.company_logoimage['Company Logo'][0] &&
          contact.company_logoimage['Company Logo'][0].uuid) ||
        '',
      leadTypes,
      isDiscardContact,
      name:
        contact && contact['contact_type_id'] && contact['contact_type_id'] === 2 ?
          (contact && contact.company_name) || '' :
          `${(contact && contact.first_name) || ''} ${(contact && contact.last_name) || ''
          }` || 'N/A',
      // updateDate: item && item.updateOn,
      contactIds: contact && contact.contactIds,
      isMerge:  (item && item.mergedWithContactIds) || (item && item.mergedWithContactId) ,
      type:
        contact && contact.contact_type_id && contact.contact_type_id === 2 ?
          ContactTypeEnum.corporate.value :
          (contact && contact.gender &&
            contact.gender.lookupItemName === 'Male' &&
            ContactTypeEnum.man.value) ||
          ContactTypeEnum.woman.value,
      userType: userTypeId === 2 ? 'Corporate' : 'Individual',
      userTypeId,
      accountType:
        contact && contact.contact_type_id === 2 ?
          contact && contact.company_class && contact.company_class.lookupItemName && getAccountType(contact.company_class.lookupItemName) :
          contact && contact.contact_class && contact.contact_class.lookupItemName && getAccountType(contact.contact_class.lookupItemName),
      progress:
        contact.data_completed && typeof contact && contact.data_completed === 'string' && contact.data_completed.includes('%') ?
          contact.data_completed.substr(0, contact.data_completed.length - 1) :
          contact && contact.data_completed,
      progressWithPercentage:
        contact.data_completed && typeof contact && contact.data_completed && contact.data_completed !== 'string' ?
          `${contact && contact.data_completed && contact.data_completed}%` :
          contact && contact.data_completed,
      createdOn: moment(item.createdOn).isValid() && item.createdOn || null,
      updateOn: moment(item.updateOn).isValid() && item.updateOn || null,
      sourceFileName: item.sourceFileName || null,
      date_of_birth: moment(contact.date_of_birth).isValid() && contact.date_of_birth || null,
      id_card_expiry_date: moment(contact.id_card_expiry_date).isValid() && contact.id_card_expiry_date || null,
      passport_issue_date: moment(contact.passport_issue_date).isValid() && contact.passport_issue_date || null,
      passport_expiry_date: moment(contact.passport_expiry_date).isValid() && contact.passport_expiry_date || null,
      visa_issue_date: moment(contact.visa_issue_date).isValid() && contact.visa_issue_date || null,
      visa_expiry_date: moment(contact.visa_expiry_date).isValid() && contact.visa_expiry_date || null,
      source_file_date: moment(contact.source_file_date).isValid() && contact.source_file_date || null,
      established_date: moment(contact.established_date).isValid() && contact.established_date || null,
      landline_number: contact.landline_number,
      details: [
        {
          title: 'name',
          value:
            contact && contact.contact_type_id && contact.contact_type_id === 2 ?
              (contact && contact.company_name) || 'N/A' :
              `${(contact && contact.first_name) || ''} ${(contact && contact.last_name) || ''
              }` || 'N/A',
        },
        {
          title: 'location',
          value:
            ((contact && contact.country &&
              contact.country.lookupItemName &&
              `${contact.country.lookupItemName}`) ||
              '') +
            ((contact && contact.city && contact.city.lookupItemName && `, ${contact.city.lookupItemName}`) ||
              '') +
            ((contact && contact.street && contact.street.value && `, ${contact.street.value} `) || '') ||
            'N/A',
        },
        {
          title: 'email',
          value:
            contact && contact.contact_type_id === 2 ?
              contact && contact.general_email && contact.general_email.email :
              (contact && contact.email_address && contact.email_address.email) || 'N/A',
        },
        {
          title: contact && contact.contact_type_id === 2 ? 'landline-number' : 'mobile',
          value:
            contact && contact.contact_type_id === 2 ?
              contact && contact.landline_number && contact.landline_number.phone :
              (contact && contact.mobile && contact.mobile.phone) || 'N/A',
        },
        {
          iconClasses: 'mdi mdi-earth',
          title: 'nationality',
          value:
            contact && contact.contact_type_id === 2 ?
              contact && contact.company_nationality && contact.company_nationality.lookupItemName :
              (contact && contact.nationality && contact.nationality.lookupItemName) || 'N/A',
        },
        (contact && contact.contact_type_id && contact.contact_type_id === 2 && {
          title: 'industry',
          value: (contact && contact.industry && contact.industry.lookupItemName) || 'N/A',
        }) || {
          iconClasses: 'mdi mdi-translate',
          title: 'language',
          value: (contact && contact.language && contact.language.lookupItemName) || 'N/A',
        },
        {
          title: contact && contact.contact_type_id === 2 ? 'website' : 'home-phone',
          value:
            contact && contact.contact_type_id === 2 ?
              contact && contact.company_website :
              (contact && contact.home_phone && contact.home_phone.phone) || 'N/A',
        },
        {
          title: contact && contact.contact_type_id && contact.contact_type_id === 2 ? 'company-type' : 'work-company-name',
          value:
            contact && contact.contact_type_id && contact.contact_type_id === 2 ?
              contact && contact.company_type && contact.company_type.lookupItemName :
              (contact && contact.work_company_name && contact.work_company_name.name) || 'N/A',
        },
        {
          title: contact && contact.contact_type_id === 2 ? 'contact-person-mobile' : 'investor',
          value: (contact && contact.investor && contact.investor) || 'N/A',
        },
        {
          title: contact && contact.contact_type_id === 2 ? 'contact-person-email' : 'media-name',
          value: (contact && contact.media_name && contact.media_name.lookupItemName) || 'N/A',
        },
        {
          title: contact && contact.contact_type_id === 2 ? 'contact-person-name' : 'profession',
          value:
            contact && contact.contact_type_id === 2 ?
              contact && contact.contacts_person && contact.contacts_person.map((el) => `${el.name}, `) :
              (contact && contact.profession) || 'N/A',
        },
        {
          ContactAllDetails: [
            {
              title: 'contact_class',
              value: (contact && contact.contact_class && contact.contact_class.lookupItemName) || '',
            },
            {
              title: 'title',
              value: (contact && contact.title && contact.title.lookupItemName) || '',
            },
            {
              title: 'gender',
              value: (contact && contact.gender && contact.gender.lookupItemName) || '',
            },
            {
              title: 'investor',
              value: (contact && contact.investor) || '',
            },
            {
              title: 'owner_for_one_unit',
              value: (contact && contact.owner_for_one_unit) || '',
            },
            {
              title: 'owner_in_multiple_areas',
              value: (contact && contact.owner_in_multiple_areas) || '',
            },
            {
              title: 'passport_expiry_date',
              value: (contact && contact.passport_expiry_date) || '',
            },
            {
              title: 'createdOn',
              value: (contact && contact.createdOn) || '',
            },
            {
              title: 'multi_property_owner',
              value: (contact && contact.multi_property_owner) || '',
            },
            {
              title: 'high_net_worth',
              value: (contact && contact.high_net_worth) || '',
            },
            {
              title: 'floor_no',
              value: (contact && contact.floor_no && contact.floor_no.type) || '',
            },
            {
              title: 'building_name',
              value: (contact && contact.building_name && contact.building_name.value) || '',
            },
            {
              title: 'building_no',
              value: (contact && contact.building_no && contact.building_no.value) || '',
            },
            {
              title: 'near_by',
              value: (contact && contact.near_by && contact.near_by.value) || '',
            },
            {
              title: 'postalzip_code',
              value: (contact && contact.postalzip_code && contact.postalzip_code.value) || '',
            },
            {
              title: 'po_box',
              value: (contact && contact.po_box && contact.po_box.value) || '',
            },
            {
              title: 'address_line_1',
              value: (contact && contact.address_line_1 && contact.address_line_1.value) || '',
            },
            {
              title: 'workoffice_no',
              value: (contact && contact.workoffice_no && contact.workoffice_no.value) || '',
            },
            {
              title: 'fax',
              value: (contact && contact.fax && contact.fax.phone) || '',
            },
            {
              title: 'nationality_no',
              value: (contact && contact.nationality_no && contact.nationality_no) || '',
            },
            {
              title: 'nationality_category',
              value:
                (contact && contact.nationality_category && contact.nationality_category.lookupItemName) || '',
            },
            {
              title: 'workcountry',
              value: (contact && contact.workcountry) || '',
            },
            {
              title: 'marital_status',
              value: (contact && contact.marital_status && contact.marital_status.lookupItemName) || '',
            },
            {
              title: 'number_of_kids',
              value: (contact && contact.number_of_kids) || '',
            },
            {
              title: 'tax_registration_no',
              value: (contact && contact.tax_registration_no && contact.tax_registration_no) || '',
            },
            {
              title: 'sub_community',
              value: (contact && contact.sub_community && contact.sub_community.lookupItemName) || '',
            },
            {
              title: 'community_source_file',
              value: contact && contact.community_source_file || '',
            },
            {
              title: 'community',
              value: (contact && contact.community && contact.community.lookupItemName) || '',
            },
            {
              title: 'country',
              value: (contact && contact.country && contact.country.lookupItemName) || '',
            },
            {
              title: 'data_completed',
              value: contact && contact.data_completed || '',
            },
            {
              title: 'date_of_birth',
              value: contact && contact.date_of_birth || '',
            },
            {
              title: 'district',
              value: (contact && contact.district && contact.district.lookupItemName) || '',
            },
            {
              title: 'facebook_link',
              value: (contact && contact.facebook_link && contact.facebook_link.link) || '',
            },
            {
              title: 'id_card_expiry_date',
              value: contact && contact.id_card_expiry_date || '',
            },
            {
              title: 'id_card_no',
              value: contact && contact.id_card_no || '',
            },
            {
              title: 'instagram_link',
              value: (contact && contact.instagram_link && contact.instagram_link.link) || '',
            },
            {
              title: 'linkedin_link',
              value: (contact && contact.linkedin_link && contact.linkedin_link.link) || '',
            },
            {
              title: 'main_information',
              value: contact && contact.main_information || '',
            },
            {
              title: 'other_contact_mobile_no',
              value: (contact && contact.other_contact_mobile_no && contact.other_contact_mobile_no.phone) || '',
            },
            {
              title: 'passport_issue_date',
              value: contact && contact.passport_issue_date && '',
            },
            {
              title: 'passport_no',
              value: contact && contact.passport_no || '',
            },
            {
              title: 'place_of_work',
              value: contact && contact.place_of_work && '',
            },
            {
              title: 'promo_sms_email',
              value: contact && contact.promo_sms_email && '',
            },
            {
              title: 'property_source_file',
              value: contact && contact.property_source_file || '',
            },
            {
              title: 'qualification',
              value:
                contact && contact.contact_type_id === 2 ?
                  contact && contact.qualification && contact.qualification :
                  contact && contact.qualification && contact.qualification,
            },
            {
              title: 'representative_contact',
              value:
                contact && contact.contact_type_id === 2 ?
                  contact && contact.representative_contact && contact.representative_contact :
                  contact && contact.representative_contact && contact.representative_contact,
            },
            {
              title: 'social_media_tap',
              value:
                contact && contact.contact_type_id === 2 ?
                  contact && contact.social_media_tap && contact.social_media_tap :
                  contact && contact.social_media_tap && contact.social_media_tap,
            },
            {
              title: 'source_by',
              value:
                contact && contact.contact_type_id === 2 ?
                  contact && contact.source_by && contact.source_by :
                  contact && contact.source_by && contact.source_by,
            },
            {
              title: 'source_file_date',
              value:
                contact && contact.contact_type_id === 2 ?
                  contact && contact.source_file_date && contact.source_file_date :
                  contact && contact.source_file_date && contact.source_file_date,
            },
            {
              title: 'source_file_name',
              value:
                contact && contact.contact_type_id === 2 ?
                  contact && contact.source_file_name && contact.source_file_name :
                  contact && contact.source_file_name && contact.source_file_name,
            },
            {
              title: 'source_file_tap',
              value:
                contact && contact.contact_type_id === 2 ?
                  contact && contact.source_file_tap && contact.source_file_tap :
                  contact && contact.source_file_tap && contact.source_file_tap,
            },
            {
              title: 'source_file_year',
              value:
                contact && contact.contact_type_id === 2 ?
                  contact && contact.source_file_year && contact.source_file_year :
                  contact && contact.source_file_year && contact.source_file_year,
            },
            {
              title: 'spouse_contact',
              value:
                contact && contact.contact_type_id === 2 ?
                  contact && contact.spouse_contact && contact.spouse_contact :
                  contact && contact.spouse_contact && contact.spouse_contact,
            },
            {
              title: 'twitter_link',
              value: contact && contact.contact_type_id &&
                contact.contact_type_id === 2 ?
                contact && contact.twitter_link :
                contact && contact.twitter_link,
            },
            {
              title: 'visa_expiry_date',
              value:
                contact && contact.contact_type_id && contact.contact_type_id === 2 ?
                  contact && contact.visa_expiry_date :
                  contact && contact.visa_expiry_date,
            },
            {
              title: 'visa_issue_date',
              value:
                contact && contact.contact_type_id === 2 ?
                  contact && contact.visa_issue_date :
                  contact && contact.visa_issue_date,
            },
            {
              title: 'visa_issuer_country',
              value:
                contact && contact.contact_type_id === 2 ?
                  contact && contact.visa_issuer_country :
                  contact && contact.visa_issuer_country,
            },
            {
              title: 'visa_no',
              value:
                contact && contact.contact_type_id && contact.contact_type_id === 2 ?
                  contact && contact.visa_no :
                  contact && contact.visa_no,
            },
            {
              title: 'visa_type',
              value:
                contact && contact.contact_type_id === 2 ?
                  contact && contact.visa_type :
                  contact && contact.visa_type,
            },
            {
              title: 'whatsapp_mobile_number',
              value:
                contact && contact.contact_type_id && contact.contact_type_id === 2 ?
                  contact && contact.whatsapp_mobile_number :
                  contact && contact.whatsapp_mobile_number,
            },
            {
              title: 'work_phone',
              value:
                contact && contact.contact_type_id === 2 ?
                  contact && contact.work_phone :
                  contact && contact.work_phone,
            },
            {
              title: 'workcity',
              value:
                contact && contact.contact_type_id === 2 ?
                  contact && contact.workcity :
                  contact && contact.workcity,
            },
            {
              title: 'workcommunity',
              value:
                contact.contact_type_id === 2 ?
                  contact && contact.workcommunity :
                  contact && contact.workcommunity,
            },
            {
              title: 'workdistrict',
              value:
                contact && contact.contact_type_id === 2 ?
                  contact && contact.workdistrict :
                  contact && contact.workdistrict,
            },
            {
              title: 'workmap',
              value:
                contact && contact.contact_type_id === 2 ?
                  contact && contact.workmap :
                  contact && contact.workmap,
            },
            {
              title: 'workpostalzip_code',
              value:
                contact && contact.contact_type_id === 2 ?
                  contact && contact.workpostalzip_code :
                  contact && contact.workpostalzip_code,
            },
            {
              title: 'worksubcommunity',
              value:
                contact && contact.contact_type_id === 2 ?
                  contact && contact.worksubcommunity :
                  contact && contact.worksubcommunity,
            },
            {
              title: 'contactsId',
              value:
                contact && contact.contact_type_id === 2 ?
                  contact && contact.contactsId :
                  contact.contactsId,
            },
          ],
        },
      ],
      allDetails: {
        'Main Information': [
          {
            title: 'title',
            value: (contact && contact.title && contact.title.lookupItemName) || 'N/A',
          },
          {
            title: 'first_name',
            value: (contact && contact.first_name) || 'N/A',
          },
          {
            title: 'last_name',
            value: (contact && contact.last_name) || 'N/A',
          },
          {
            title: 'gender',
            value: (contact && contact.gender && contact.gender.lookupItemName) || 'N/A',
          },
          {
            title: 'mobile',
            value: (contact && contact.mobile && contact.mobile.phone) || 'N/A',
          },
          {
            title: 'other_contact_mobile_no',
            value:
              (contact && contact.other_contact_mobile_no && contact.other_contact_mobile_no.phone) || 'N/A',
          },
          {
            title: 'home_phone',
            value: (contact && contact.home_phone && contact.home_phone.phone) || 'N/A',
          },
          {
            title: 'fax',
            value: (contact && contact.fax && contact.fax.phone) || 'N/A',
          },
          {
            title: 'email',
            value: (contact && contact.email_address && contact.email_address.email) || 'N/A',
          },
          {
            title: 'nationality',
            value: (contact && contact.nationality && contact.nationality.lookupItemName) || 'N/A',
          },
          {
            title: 'language',
            value: (contact && contact.language && contact.language.lookupItemName) || 'N/A',
          },
          {
            title: 'contact_class',
            value: (contact && contact.contact_class && contact.contact_class.lookupItemName) || 'N/A',
          },
          {
            title: 'promo_sms_email',
            value: (contact && contact.promo_sms_email) || 'N/A',
          },
          {
            title: 'multi_property_owner',
            value: (contact && contact.multi_property_owner) || 'N/A',
          },
          {
            title: 'contact_preference',
            value:
              (contact && contact.contact_preference && contact.contact_preference.length > 0 &&
                contact.contact_preference.map((el) => `${el.lookupItemName}, `)) ||
              'N/A',
          },
          {
            title: 'main_information',
            value: (contact && contact.main_information && contact.main_information) || 'N/A',
          },
        ],
        'Residence Address': [
          {
            title: 'country',
            value: (contact && contact.country && contact.country.lookupItemName) || 'N/A',
          },
          {
            title: 'city',
            value: (contact && contact.city && contact.city.lookupItemName) || 'N/A',
          },
          {
            title: 'district',
            value: (contact && contact.district && contact.district.lookupItemName) || 'N/A',
          },
          {
            title: 'community',
            value: (contact && contact.community && contact.community.lookupItemName) || 'N/A',
          },
          {
            title: 'sub_community',
            value: (contact && contact.sub_community && contact.sub_community.lookupItemName) || 'N/A',
          },
          {
            title: 'street',
            value: (contact && contact.street && contact.street.value) || 'N/A',
          },
          {
            title: 'building_name',
            value: (contact && contact.building_name && contact.building_name.value) || 'N/A',
          },
          {
            title: 'building_no',
            value: (contact && contact.building_no && contact.building_no.value) || 'N/A',
          },
          {
            title: 'floor_no',
            value: (contact && contact.floor_no && contact.floor_no.value) || 'N/A',
          },
          {
            title: 'postalzip_code',
            value: (contact && contact.postalzip_code && contact.postalzip_code.value) || 'N/A',
          },
          {
            title: 'po_box',
            value: (contact && contact.po_box && contact.po_box.value) || 'N/A',
          },
          {
            title: 'map',
            value: (contact && contact.map && `${contact.map.latitude}, ${contact.map.longitude}`) || 'N/A',
          },
        ],
        'Company Info': [
          {
            title: 'company_name',
            value: (contact && contact.company_name) || 'N/A',
          },
          {
            title: 'landline_number',
            value: (contact && contact.landline_number && contact.landline_number.phone) || 'N/A',
          },
          {
            title: 'established_date',
            value:
              (contact && contact.established_date && moment(contact.established_date).format('DD/MM/YYYY')) ||
              'N/A',
          },
          {
            title: 'retail_category',
            value: (contact && contact.retail_category && contact.retail_category.lookupItemName) || 'N/A',
          },
          {
            title: 'company_nationality',
            value:
              (contact && contact.company_nationality && contact.company_nationality.lookupItemName) || 'N/A',
          },
          {
            title: 'no_of_local_branches',
            value: (contact && contact.no_of_local_branches) || 'N/A',
          },
          {
            title: 'no_of_remote_branches',
            value: (contact && contact.no_of_remote_branches) || 'N/A',
          },
          {
            title: 'no_of_employees',
            value: (contact && contact.no_of_employees) || 'N/A',
          },
          {
            title: 'business_region',
            value: (contact && contact.business_region) || 'N/A',
          },
          {
            title: 'company_website',
            value: (contact && contact.company_website && contact.company_website.link) || 'N/A',
          },
          {
            title: 'general_email',
            value: (contact && contact.general_email && contact.general_email.email) || 'N/A',
          },
          {
            title: 'language',
            value: (contact && contact.language && contact.language.lookupItemName) || 'N/A',
          },
          {
            title: 'company_type',
            value: (contact && contact.company_type && contact.company_type.lookupItemName) || 'N/A',
          },
          {
            title: 'promo_smsemail',
            value: (contact && contact.promo_smsemail && contact.promo_smsemail) || 'N/A',
          },
          {
            title: 'company_class',
            value: (contact && contact.company_class && contact.company_class.lookupItemName) || 'N/A',
          },
          {
            title: 'multi_property_owner',
            value: (contact && contact.multi_property_owner) || 'N/A',
          },
          {
            title: 'exclusive',
            value: (contact && contact.exclusive) || 'N/A',
          },
          {
            title: 'contacts_person',
            value:
              (contact && contact.contacts_person && contact.contacts_person.length > 0 && contact.contacts_person.map((el) => `${el.name}, `)) ||
              'N/A',
          },
          {
            title: 'contact_preference',
            value:
              (contact && contact.contact_preference && contact.contact_preference.length > 0
                && contact.contact_preference.map((el) => `${el.lookupItemName}, `)) ||
              'N/A',
          },
          {
            title: 'contact_classifications',
            value:
              (contact && contact.contact_classifications && contact.contact_classifications.length > 0
                && contact.contact_classifications.map((el) => `${el.lookupItemName}, `)) ||
              'N/A',
          },
          {
            title: 'special_requirements',
            value: (contact && contact.special_requirements) || 'N/A',
          },
        ],
        Address: [
          {
            title: 'country',
            value: (contact && contact.country && contact.country.lookupItemName) || 'N/A',
          },
          {
            title: 'city',
            value: (contact && contact.city && contact.city.lookupItemName) || 'N/A',
          },
          {
            title: 'district',
            value: (contact && contact.district && contact.district.lookupItemName) || 'N/A',
          },
          {
            title: 'community',
            value: (contact && contact.community && contact.community.lookupItemName) || 'N/A',
          },
          {
            title: 'street',
            value: (contact && contact.street && contact.street.value) || 'N/A',
          },
          {
            title: 'building_name',
            value: (contact && contact.building_name && contact.building_name.value) || 'N/A',
          },
          {
            title: 'floor_no',
            value: (contact && contact.floor_no && contact.floor_no.value) || 'N/A',
          },
          {
            title: 'office_no',
            value: (contact && contact.office_no && contact.office_no.value) || 'N/A',
          },
          {
            title: 'map',
            value:
              (contact && contact.map &&
                `Longitude: ${contact && contact.map.longitude}, Latitude: ${contact.map.latitude}`) ||
              'N/A',
          },
          {
            title: 'postalzip_code',
            value: (contact && contact.postalzip_code && contact.postalzip_code.value) || 'N/A',
          },
          {
            title: 'po_box',
            value: (contact && contact.po_box && contact.po_box.value) || 'N/A',
          },
          {
            title: 'address_line_1',
            value: (contact && contact.address_line_1 && contact.address_line_1.value) || 'N/A',
          },
          {
            title: 'near_by',
            value: (contact && contact.near_by && contact.near_by.value) || 'N/A',
          },
        ],
        'Social Media': [
          {
            title: 'facebook_link',
            value: (contact && contact.facebook_link && contact.facebook_link.link) || 'N/A',
          },
          {
            title: 'twitter_link',
            value: (contact && contact.twitter_link && contact.twitter_link.link) || 'N/A',
          },
          {
            title: 'instagram_link',
            value: (contact && contact.instagram_link && contact.instagram_link.link) || 'N/A',
          },
          {
            title: 'linkedin_link',
            value: (contact && contact.linkedin_link && contact.linkedin_link.link) || 'N/A',
          },
        ],
        'Company Documents': [
          {
            title: 'licence_no',
            value: (contact && contact.licence_no) || 'N/A',
          },
          {
            title: 'license_issuer',
            value: (contact && contact.license_issuer) || 'N/A',
          },
          {
            title: 'license_expiry',
            value: (contact && contact.license_expiry && contact.license_expiry) || 'N/A',
          },
        ],
        'Upload Image & Documents': [
          {
            title: 'upload_documents',
            value:
              (contact && contact.upload_documents &&
                contact.upload_documents.ID &&
                contact.upload_documents.selected &&
                `ID: ${contact.upload_documents.ID.map(
                  (el) => el.fileName
                )}, Selected: ${contact.upload_documents.selected.map((el) => el.fileName)}`) ||
              'N/A',
          },
          {
            title: 'company_logoimage',
            value: (contact && contact.company_logoimage && contact.company_logoimage.fileName) || 'N/A',
          },
        ],
        'Work Address': [
          {
            title: 'work_company_name',
            value: (contact && contact.work_company_name) || 'N/A',
          },
          {
            title: 'workcountry',
            value: (contact && contact.workcountry && contact.workcountry.lookupItemName) || 'N/A',
          },
          {
            title: 'workcity',
            value: (contact && contact.workcity && contact.workcity.lookupItemName) || 'N/A',
          },
          {
            title: 'workdistrict',
            value: (contact && contact.workdistrict && contact.workdistrict.lookupItemName) || 'N/A',
          },
          {
            title: 'workcommunity',
            value: (contact && contact.workcommunity && contact.workcommunity.lookupItemName) || 'N/A',
          },
          {
            title: 'worksubcommunity',
            value: (contact && contact.worksubcommunity && contact.worksubcommunity.lookupItemName) || 'N/A',
          },
          {
            title: 'workstreet',
            value: (contact && contact.workstreet && contact.workstreet.value) || 'N/A',
          },
          {
            title: 'workbuilding_name',
            value: (contact && contact.workbuilding_name && contact.workbuilding_name.value) || 'N/A',
          },
          {
            title: 'workfloor_no',
            value: (contact && contact.workfloor_no && contact.workfloor_no.value) || 'N/A',
          },
          {
            title: 'workbuilding_no',
            value: (contact && contact.workbuilding_no && contact.workbuilding_no.value) || 'N/A',
          },
          {
            title: 'workoffice_no',
            value: (contact && contact.workoffice_no && contact.workoffice_no.value) || 'N/A',
          },
          {
            title: 'work_phone',
            value: (contact && contact.work_phone && contact.work_phone.phone) || 'N/A',
          },
          {
            title: 'workpostalzip_code',
            value: (contact && contact.workpostalzip_code && contact.workpostalzip_code.value) || 'N/A',
          },
          {
            title: 'workmap',
            value:
              (contact && contact.workmap &&
                contact.workmap.value &&
                contact.workmap.value.latitude &&
                `Latitude: ${contact.workmap.value.latitude}, Longitude: ${contact.workmap.value.longitude}`) ||
              'N/A',
          },
          {
            title: 'workaddress_line_1',
            value: (contact && contact.workaddress_line_1 && contact.workaddress_line_1.value) || 'N/A',
          },
        ],
      },
    };
  }
};
