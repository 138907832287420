import React from "react";

const FileIcon = ({ fill, ...restProps }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={fill ?? ""}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M6.92 1.042c-.765.049-1.246.162-1.74.409A3.776 3.776 0 0 0 3.124 4.16C3.012 4.738 3 5.47 3 12c0 7.433.002 7.515.245 8.32.332 1.102 1.333 2.103 2.435 2.435.78.235 1.036.245 6.32.245 5.284 0 5.54-.01 6.32-.245 1.102-.332 2.105-1.334 2.435-2.435.243-.811.252-1.048.236-6.7-.014-4.964-.014-5.002-.1-5.32a4.1 4.1 0 0 0-.932-1.728c-.535-.608-4.603-4.628-4.879-4.821a4.022 4.022 0 0 0-1.851-.708c-.437-.049-5.56-.05-6.309-.001m5.955 1.983L13 3.05l.001 2.045c0 2.225.013 2.387.226 2.824.139.286.568.715.854.854.437.213.598.226 2.827.226L18.955 9l.025.17c.014.094.018 2.393.01 5.11-.017 5.368-.01 5.205-.238 5.672-.126.259-.562.686-.834.816-.447.215-.371.212-5.918.212-5.538 0-5.467.003-5.918-.209-.27-.128-.705-.554-.834-.819a2.86 2.86 0 0 1-.16-.48c-.063-.27-.068-.842-.068-7.472 0-6.63.005-7.202.068-7.472.087-.373.206-.61.432-.861.364-.406.763-.577 1.46-.627.502-.035 5.725-.049 5.895-.015M16.3 5.72 17.58 7H15V5.72c0-.704.005-1.28.01-1.28.006 0 .586.576 1.29 1.28m-8.605 6.337a1.04 1.04 0 0 0-.567.459c-.091.156-.108.23-.108.484 0 .256.016.328.111.489.125.213.318.375.539.454.222.079 8.438.079 8.66 0 .221-.079.414-.241.539-.454.095-.161.111-.233.111-.489 0-.254-.017-.328-.108-.484a1.02 1.02 0 0 0-.582-.461c-.261-.079-8.344-.076-8.595.002m0 4a1.04 1.04 0 0 0-.567.459c-.091.156-.108.23-.108.484 0 .256.016.328.111.489.125.213.318.375.539.454.221.079 6.439.079 6.66 0 .221-.079.414-.241.539-.454.095-.161.111-.233.111-.489 0-.254-.017-.328-.108-.484a1.02 1.02 0 0 0-.582-.461c-.26-.078-6.345-.076-6.595.002"
        fill-rule="evenodd"
        fill={fill ?? ""}
        
      />
    </svg>
  );
};
export default FileIcon;