import React, {
  Children,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  RadiosGroupComponent,
  AutocompleteComponent,
  Inputs,
} from '../../../../../../Components';
import {
  GetAllBranches,
  GetAllLMSCourses,
  GetAllTenantLMSCourses,
  GetCourses,
  GetTeam,
} from '../../../../../../Services';
import { useDebouncedAction } from '../../../../../../Hooks/DebouncedAction';
import { getErrorByName, showError } from '../../../../../../Helper';
import Joi from 'joi';

export const SchemaSetting = ({
  parentTranslationPath,
  translationPath,
  state,
  onStateChanged,
  schema,
  isSubmitted,
  rotationEdit,
  onScemaChanged,
  currentSelected,
  setCurrentSelected,
  setCoursesSchemaError,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [ResponseTeam, SetResponseTeam] = useState([]);
  const [loadings, setLoading] = useState(false);
  const [loadingsTeam, setLoadingsTeam] = useState(false);
  const filter = {
    pageSize: 25,
    pageIndex: 0,
    branchName: '',
    filterBy: null,
    orderBy: null,
  };
  const searchTimer = useRef(null);
  const [branches, setBranches] = useState({
    result: [],
    totalCount: 0,
  });
  const [courses, setCourses] = useState([]);
  const [schemaErrors, setSchemaErrors] = useState({});
  const courseSchema = Joi.object({
    lmsCourseId: Joi.number(),
    lmsCourseName: Joi.string(),
    scoreFrom: Joi.number()
      .min(1)
      .max(99)
      .required()
      .messages({
        'number.base': 'Score From must be a number',
        'any.required': t('field_is_required', { field: t('score_from') }),
        'number.min': t('min_number', { number: 1 }),
        'number.max': t('max_number', { number: 99 }),
      }),
    scoreTo: Joi.number()
      .required()
      .min(2)
      .max(100)
      .greater(Joi.ref('scoreFrom'))
      .messages({
        'number.base': 'Score To must be a number',
        'any.required': t('field_is_required', { field: t('score_to') }),
        'number.greater': t('score_to_error'),
        'number.min': t('min_number', { number: 2 }),
        'number.max': t('max_number', { number: 100 }),
      }),
  });

  const getAllBranches = useCallback(
    async (value) => {
      setLoading(true);
      const res = await GetAllBranches({
        ...filter,
        pageSize: 25,
        pageIndex: 1,
        branchName: value,
      });
      if (!(res && res.status && res.status !== 200)) {
        setBranches({
          result: res.result,
          totalCount: res.totalCount,
        });
      } else {
        setBranches({
          result: [],
          totalCount: 0,
        });
      }

      setLoading(false);
    },
    [filter]
  );

  const GetMyTeam = async (searchItem) => {
    setLoadingsTeam(true);
    const res = await GetTeam(1, 25, searchItem || '');

    if (!(res && res.status && res.status !== 200)) SetResponseTeam(res);
    else SetResponseTeam([]);

    setLoadingsTeam(false);
  };

  const getCourses = async (search) => {
    try {
      const result = await GetAllLMSCourses({
        pageIndex: 1,
        pageSize: 100,
        search,
      });
      if (result) {
        setCourses(result);
      }
    } catch (error) {
      showError('something_went_wrong');
    }
  };

  const debouncedGetCourses = useDebouncedAction(getCourses);

  const selectedCourseHandler = (selected) => {
    const newSelected = selected.map((course) => {
      if (!course.scoreTo) {
        course.scoreTo = 100;
      }
      if (!course.scoreFrom) {
        course.scoreFrom = 1;
      }
      return course;
    });
    onStateChanged({ id: 'rotationSchemeCourses', value: newSelected });
    setCurrentSelected((prev) => ({
      ...prev,
      rotationSchemeCourses: newSelected,
    }));
    if (rotationEdit) onScemaChanged('edit', 'rotationSchemeBranches');
  };

  const changeScoreHandler = (field, value, id) => {
    const newSelected = [...state.rotationSchemeCourses];
    const index = newSelected.findIndex((course) => course.lmsCourseId === id);
    if (index !== -1) {
      newSelected[index][field] = value;
      const validation = courseSchema.validate(newSelected[index], {
        abortEarly: false,
        allowUnknown: true,
      });
      const errors = { ...schemaErrors };
      if (validation.error) {
        errors[id] = validation;
        setSchemaErrors(errors);
      } else {
        delete errors[id];
        setSchemaErrors(errors);
      }
    }
    onScemaChanged('edit')
    onStateChanged({ id: 'rotationSchemeCourses', value: newSelected });
  };

  useEffect(() => {
    setCoursesSchemaError(!!Object.values(schemaErrors).length);
  }, [schemaErrors]);

  useEffect(() => {
    getAllBranches();
    GetMyTeam();
    getCourses();
  }, []);

  return (
    <>
      <div className='schema-setting-wrapper p-4'>
        <div className='count-base-wrapper'>
          <span>{t(`${translationPath}count-based-on`)}</span>
          <div className='switch-btns'>
            <RadiosGroupComponent
              data={[
                { value: '3', label: 'overAll-syetem' },
                { value: '1', label: 'current-schema-assigned' },
                { value: '2', label: 'current-schema-acitve-leads' },
                { value: '4', label: 'one-by-one' },
              ]}
              idRef='Actions'
              onSelectedRadioChanged={(e) => {
                const localNewValue = {
                  id: 'rotationSchemeLeadDistributionType',
                  value: e.target.value,
                };
                onStateChanged(localNewValue);
              }}
              value={
                state &&
                state.rotationSchemeLeadDistributionType &&
                (state && state.rotationSchemeLeadDistributionType).toString()
              }
              name='Active'
              labelInput='label'
              valueInput='value'
              themeClass='theme-line'
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              translationPathForData={translationPath}
            />
          </div>
        </div>
        <div className='override-wrapper mt-3'>
          <span>{t(`${translationPath}cap-based-on`)}</span>
          <RadiosGroupComponent
            data={[
              { value: false, label: 'OVERALL-SYSTEM' },
              { value: true, label: 'CURENT-SCHEMA' },
            ]}
            idRef='Actions'
            onSelectedRadioChanged={(e) => {
              const localNewValue = {
                id: 'isOverrideLeadCapacitySettingEnabled',
                value: JSON.parse(e.target.value),
              };
              onStateChanged(localNewValue);
            }}
            value={state.isOverrideLeadCapacitySettingEnabled}
            name='Active'
            labelInput='label'
            valueInput='value'
            themeClass='theme-line'
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            translationPathForData={translationPath}
          />
        </div>
        <AutocompleteComponent
          idRef='branchName-ToRef'
          labelValue='branchName'
          selectedValues={currentSelected?.rotationSchemeBranches || []}
          multiple
          isDisabled={
            currentSelected?.rotationSchemeTeams?.length > 0 ||
            currentSelected.rotationSchemeCourses?.length > 0
          }
          data={branches.result || []}
          chipsLabel={(option) => option.branchName || ''}
          displayLabel={(option) => t(`${option.branchName || ''}`)}
          getOptionSelected={(option) =>
            (currentSelected?.rotationSchemeBranches &&
              currentSelected.rotationSchemeBranches.findIndex(
                (item) => item && item.branchId === option.branchId
              ) !== -1) ||
            ''
          }
          withoutSearchButton
          inputPlaceholder={t(`${translationPath}Select-multiple-branchName`)}
          isLoading={loadings}
          onInputKeyUp={(e) => {
            const { value } = e.target;
            if (searchTimer.current) clearTimeout(searchTimer.current);
            searchTimer.current = setTimeout(() => {
              getAllBranches(value);
            }, 700);
          }}
          isWithError
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onChange={(event, newValue) => {
            const localNewValue = {
              id: 'rotationSchemeBranches',
              value:
                newValue && newValue.map((x) => ({ branchId: x.branchId })),
            };
            const localNewSelected =
              newValue &&
              newValue.map((x) => ({
                branchId: x.branchId,
                branchName: x.branchName,
              }));
            onStateChanged(localNewValue);
            setCurrentSelected((prevState) => ({
              ...prevState,
              rotationSchemeBranches: localNewSelected,
            }));

            if (rotationEdit) onScemaChanged('edit', 'rotationSchemeBranches');
          }}
        />
        <AutocompleteComponent
          idRef='teams-ToRef'
          labelValue='teams'
          selectedValues={currentSelected?.rotationSchemeTeams || []}
          multiple
          data={ResponseTeam.result || []}
          chipsLabel={(option) => option.teamsName || option.teamName || ''}
          isDisabled={
            currentSelected.rotationSchemeBranches?.length > 0 ||
            currentSelected.rotationSchemeCourses?.length > 0
          }
          displayLabel={(option) =>
            t(`${option.teamsName || option.teamName || ''}`)
          }
          getOptionSelected={(option) =>
            (currentSelected?.rotationSchemeTeams &&
              currentSelected.rotationSchemeTeams.findIndex(
                (item) =>
                  item &&
                  (item.teamsId === option.teamsId ||
                    item.teamId === option.teamsId)
              ) !== -1) ||
            ''
          }
          withoutSearchButton
          inputPlaceholder={t(`${translationPath}Select-multiple-teams`)}
          isLoading={loadingsTeam}
          onInputKeyUp={(e) => {
            const { value } = e.target;
            if (searchTimer.current) clearTimeout(searchTimer.current);
            searchTimer.current = setTimeout(() => {
              GetMyTeam(value);
            }, 700);
          }}
          isWithError
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onChange={(event, newValue) => {
            const localNewValue = {
              id: 'rotationSchemeTeams',
              value:
                newValue &&
                newValue.map((item) => ({
                  teamId: item.teamsId || item.teamId,
                })),
            };
            const localNewSelected =
              newValue &&
              newValue.map((item) => ({
                teamId: item.teamsId || item.teamId,
                teamName: item.teamsName || item.teamName,
              }));

            onStateChanged(localNewValue);
            if (rotationEdit) onScemaChanged('edit', 'rotationSchemeTeams');
            setCurrentSelected((prevState) => ({
              ...prevState,
              rotationSchemeTeams: localNewSelected,
            }));
          }}
        />
        <div className='mt-1'>
          <Inputs
            idRef='slaRef'
            labelValue={t(`${translationPath}sla`)}
            value={state.sla || ''}
            isSubmitted={isSubmitted}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            onInputChanged={(event) => {
              if (isNaN(event.target.value)) return;
              onStateChanged({ id: 'sla', value: event.target.value });
            }}
          />
        </div>
        <div>
          <AutocompleteComponent
            data={courses}
            withoutSearchButton
            multiple
            chipsLabel={(option) => option?.lmsCourseName}
            displayLabel={(option) => option?.lmsCourseName}
            labelValue={'courses'}
            onChange={(_e, newValues) => selectedCourseHandler(newValues)}
            selectedValues={state.rotationSchemeCourses}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            onInputChange={(e) => debouncedGetCourses(e.target.value)}
            isDisabled={
              currentSelected.rotationSchemeBranches?.length > 0 ||
              currentSelected.rotationSchemeTeams?.length > 0
            }
            inputPlaceholder={'select_multiple_course'}
          />
          <ul className='mt-3'>
            {Children.toArray(
              state.rotationSchemeCourses.map((course) => (
                <li>
                  <div className='flex fa-center gap-1'>
                    <strong>{course.lmsCourseName}</strong>
                    <Inputs
                      wrapperClasses={'w-25'}
                      type={'number'}
                      labelValue={'score_from'}
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                      value={course.scoreFrom}
                      onInputChanged={(e) =>
                        changeScoreHandler(
                          'scoreFrom',
                          e.target.valueAsNumber,
                          course.lmsCourseId
                        )
                      }
                      helperText={
                        getErrorByName(
                          schemaErrors[course.lmsCourseId] ?? {},
                          'scoreFrom'
                        )?.message
                      }
                      error={
                        getErrorByName(
                          schemaErrors[course.lmsCourseId] ?? {},
                          'scoreFrom'
                        )?.error
                      }
                    />
                    <Inputs
                      wrapperClasses={'w-25'}
                      type={'number'}
                      labelValue={'score_to'}
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                      value={course.scoreTo}
                      onInputChanged={(e) =>
                        changeScoreHandler(
                          'scoreTo',
                          e.target.valueAsNumber,
                          course.lmsCourseId
                        )
                      }
                      isWithError
                      helperText={
                        getErrorByName(
                          schemaErrors[course.lmsCourseId] ?? {},
                          'scoreTo'
                        )?.message
                      }
                      error={
                        getErrorByName(
                          schemaErrors[course.lmsCourseId] ?? {},
                          'scoreTo'
                        )?.error
                      }
                    />
                  </div>
                  <hr />
                </li>
              ))
            )}
          </ul>
        </div>
      </div>
    </>
  );
};

SchemaSetting.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  state: PropTypes.PropTypes.instanceOf(Object),
  onStateChanged: PropTypes.func.isRequired,
  schema: PropTypes.instanceOf(Object).isRequired,
  isSubmitted: PropTypes.bool.isRequired,
};
