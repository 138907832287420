import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useReducer,
} from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Tables, Spinner } from "../../../../../Components";
import {
  GetParams,
  returnPropsByPermissions,
  showError,
  showSuccess,
  showWarn,
} from "../../../../../Helper";
import {
  GetAgreementDetailsByIdAPI,
  DeleteAgreementDetailsAPI,
} from "../../../../../Services";
import { TableActions } from "../../../../../Enums";
import { MessageConfirmDialog } from "../../../../../SharedComponents";
import { AgreementDetailsManagementDialog } from "./AgreementDetailsManagement";
import { DevelopersPermissions } from "../../../../../Permissions";

export const AgreementDetailsTable = ({
  translationPath,
  parentTranslationPath,
  data,
  filter,
  setFilter,
  reloadData,
  contactReletedWithDeveloperId 
}) => {
  const { t } = useTranslation(parentTranslationPath, "Shared");
  const [isLoading, setIsLoading] = useState({ info: false });
  const [activeItem, setActiveItem] = useState(null);
  const [isOpenEditDialog, setIsOpenEditDialog] = useState(false);
  const [isMissingInfoMSGOpen, setIsMissingInfoMSGOpen] = useState(false);

  const tableActionClicked = useCallback(
    (actionEnum, item, focusedRow, event) => {
      event.stopPropagation();
      event.preventDefault();
      setActiveItem(item);

      if (actionEnum === TableActions.delete.key) {
        setIsMissingInfoMSGOpen(true);
      } else if (actionEnum === TableActions.editText.key) {
        setIsOpenEditDialog(true);
      }
    },
    []
  );

  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
  };
  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
  };

  const repeated = (list, filed) =>
    list &&
    list.map((item, index) => (
      <span key={index}>
        {" "}
        {item[filed]} {list.length - 1 != index && <span> , </span>}
      </span>
    ));

  const getActionTableWithPermissions = () => {
    const list = [];
    if (
      returnPropsByPermissions(
        DevelopersPermissions.EditAgreementDetails.permissionsId
      )
    )
      list.push({
        enum: TableActions.editText.key,
        title: t(`${translationPath}Shared:edit`),
      });
    list.push({
      enum: TableActions.delete.key,
      title: t(`${translationPath}Shared:delete`),
    });
    return list;
  };

  const GetAgreementDetailsByIdHandler = useCallback(async () => {
    setIsLoading((item) => ({ ...item, info: true }));

    const res = await GetAgreementDetailsByIdAPI(
      activeItem && activeItem.agreementDetailId
    );
    if (res && res.ErrorId) {
      showError(t(`${translationPath}failed-to-get-agreement-detail`));
    } else if (!(res && res.status && res.status !== 200)) {
    }
    setIsLoading((item) => ({ ...item, info: false }));
  }, [activeItem]);

  const DeleteHandler = useCallback(async () => {
    if (activeItem && activeItem.agreementDetailId) {
      setIsLoading((item) => ({ ...item, delete: true }));

      const res = await DeleteAgreementDetailsAPI(
        activeItem && activeItem.agreementDetailId
      );
      if (res && res.ErrorId) {
        showError(t(`${translationPath}failed-to-delete-agreement-detail`));
      } else if (!(res && res.status && res.status !== 200)) {
        showSuccess(
          t(`${translationPath}delete-agreement-detail-successfully`)
        );
        setIsMissingInfoMSGOpen(false);
        setActiveItem(null);
        reloadData();
      }
      setIsLoading((item) => ({ ...item, delete: false }));
    }
  }, [activeItem]);

  useEffect(() => {
    if (activeItem) {
      GetAgreementDetailsByIdHandler(activeItem.agreementDetailId);
    }
  }, [activeItem]);

  return (
    <div className="d-flex-column mt-2">
      <Spinner isActive={isLoading.info || isLoading.delete} isAbsolute />
      <Tables
        data={data.result || []}
        headerData={[
          {
            id: 1,
            label: t(`${translationPath}agreement-type`),
            input: "agreementType",
          },
          {
            id: 2,
            label: t(`${translationPath}agreement-start-date`),
            input: "agreementStartDate",
            isDate: true,
          },
          {
            id: 3,
            label: t(`${translationPath}agreement-duration-months`),
            input: "agreementDuration",
          },
          {
            id: 4,
            label: t(`${translationPath}agreement-end-date`),
            input: "agreementEndDate",
            isDate: true,
          },
          {
            id: 5,
            label: t(`${translationPath}projects-names`),
            input: "project-name",
            component: (item) =>
              repeated(item.agreementDetailsProjects, "propertyName"),
          },
          {
            id: 6,
            label: t(`${translationPath}signing-branches`),
            input: "signing_branch",
            component: (item) =>
              repeated(item.agreementDetailsSigningBranches, "branchName"),
          },
          {
            id: 7,
            label: t(`${translationPath}authorizedes-signatories`),
            input: "authorized_signatory",
            component: (item) =>
              repeated(item.agreementDetailsAuthorizedSignatories, "userName"),
          },
          {
            id: 9,
            label: t(`${translationPath}isMarketingBudgetAllocation`),
            input: "isMarketingBudgetAllocation",
            component: (item, index) =>
              (item.isMarketingBudgetAllocation && (
                <div className="globel-open-style" index={index}>
                  {t(`${translationPath}yes`)}
                </div>
              )) || (
                <div className="globel-closed-style" index={index}>
                  {t(`${translationPath}no`)}
                </div>
              ),
          },
          {
            id: 10,
            label: t(`${translationPath}marketingBudgetAmount`),
            input: "marketingBudgetAmount",
          },
        ]}
        onPageIndexChanged={onPageIndexChanged}
        onPageSizeChanged={onPageSizeChanged}
        defaultActions={getActionTableWithPermissions()}
        actionsOptions={{
          onActionClicked: tableActionClicked,
        }}
        activePage={filter.pageIndex}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        itemsPerPage={filter.pageSize}
        totalItems={(data && data?.totalCount) || 0}
      />
      {isMissingInfoMSGOpen && activeItem && (
        <MessageConfirmDialog
          isDialogOpen={isMissingInfoMSGOpen}
          theme="warning"
          content={{
            heading: "delete-agreement-details",
            body:
              t(
                `${translationPath}are-you-sure-you-want-to-delete-delete-agreement-details-id`
              ) +
              " " +
              ((activeItem && activeItem.agreementDetailId) || ""),
          }}
          confirmButton={{
            label: t(`${translationPath}confirm`),
            handler: () => DeleteHandler(),
          }}
          cancelButton={{
            label: t(`${translationPath}discard`),
            handler: () => {
              setIsMissingInfoMSGOpen(false);
            },
          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      )}
      {isOpenEditDialog && activeItem && (
        <AgreementDetailsManagementDialog
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          isOpen={isOpenEditDialog}
          onClose={() => {
            setIsOpenEditDialog(false);
            setActiveItem(null);
          }}
          onSave={(editItem) => {
            if (editItem) {
              setIsOpenEditDialog(false);
              setActiveItem(null);
              reloadData();
            }
          }}
          agreementDetailsValues={activeItem}
          contactReletedWithDeveloperId={contactReletedWithDeveloperId}
        />
      )}
    </div>
  );
};
