import React from "react";

function AttatchmentIcon({ fill, ...restProps }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={fill ?? ""}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M15.56 1.804c-1.07.119-2.069.526-2.98 1.212-.431.325-2.071 1.959-2.172 2.164a1.008 1.008 0 0 0 .449 1.352c.215.113.659.12.863.012.077-.04.554-.482 1.06-.981.999-.986 1.324-1.236 1.94-1.494.54-.226.89-.29 1.56-.285.487.003.642.02.964.106a4.029 4.029 0 0 1 2.865 2.863c.087.326.104.479.107.967.005.671-.059 1.021-.286 1.56-.264.63-.504.942-1.491 1.94-.5.506-.943.983-.983 1.06-.108.204-.101.648.012.863.266.503.855.698 1.352.447.337-.171 1.995-1.888 2.388-2.473a5.99 5.99 0 0 0 .02-6.685 6.017 6.017 0 0 0-5.668-2.628m-.48 5.799c-.139.062-.981.881-3.733 3.629-1.954 1.951-3.604 3.621-3.667 3.711-.305.436-.177 1.09.272 1.387.295.195.794.206 1.065.022.178-.121 7.168-7.102 7.301-7.292a.985.985 0 0 0-.362-1.435 1.06 1.06 0 0 0-.876-.022m-9.627 2.72a1.389 1.389 0 0 0-.3.098c-.073.036-.538.474-1.034.972-1.061 1.068-1.372 1.46-1.728 2.181a5.996 5.996 0 0 0-.251 4.762 5.998 5.998 0 0 0 8.286 3.273c.723-.357 1.116-.669 2.181-1.727.541-.539.938-.966.985-1.062a.925.925 0 0 0 .07-.72.988.988 0 0 0-1.247-.68c-.221.064-.277.111-1.154.975-1.153 1.137-1.318 1.266-1.96 1.532-.561.233-.863.29-1.541.291-.552 0-.663-.012-1.011-.108-1.424-.395-2.484-1.458-2.859-2.866-.086-.322-.103-.477-.106-.964-.005-.67.059-1.02.285-1.56.258-.616.508-.941 1.494-1.94.499-.506.938-.981.976-1.055.357-.705-.298-1.55-1.086-1.402"
        fill-rule="evenodd"
        fill={fill ?? ""}
      />
    </svg>
  );
}
export default AttatchmentIcon;
