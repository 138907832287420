import React, { useState, useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import ReactFlow, {
  ReactFlowProvider,
  MiniMap,
  Controls,
  Background,
  addEdge,
  Handle,
} from "react-flow-renderer";
import "react-flow-renderer/dist/style.css";
import { GetSubActions, GetTrigerSubActions, GetTriggers } from "../../../Services";
import CustomNode from "./WorkFlowUtilities/CustomNode";
import Sidebar from "./WorkFlowUtilities/Sidebar";
import CustomEdge from "./WorkFlowUtilities/CustomEdge";
import { WorkFlowCardView } from "./WorkFlowCardView";

export const ExistingWorkFlowView = () => {
  const parentTranslationPath = 'WorkFlow';
  const translationPath = '';

  const { t } = useTranslation(parentTranslationPath);
  const [TrigerSubActions, SetTrigerSubActions] = useState([]);
  const [Triggers, SetTriggers] = useState([]);
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const [subActions, setSubActions] = useState([]);



  return (
    <div className="ExistingWorkFlowView-wrapper Card-Selection-CSS">
      <WorkFlowCardView />
    </div>
  );
};
