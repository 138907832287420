
export const SharedViaEnum = {
    Email: {
      id: 1,
      key: "Email",
      title: "Email",
      mdiIconClass: "mdi-email",
    },
    WhatsApp: {
      id: 2,
      key: "WhatsApp",
      title: "WhatsApp",
      mdiIconClass: "mdi-whatsapp",
    },
  };
