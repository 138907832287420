import React, { memo } from "react";
import Styles from "./LeadInquiriesCard.module.scss";
import GeneralStyles from "../../LeadInquiriesView.module.scss";
import { formatCommasForPriceUnit } from "../../../../../../../../Helper/formatCommas.Helper";
import moment from "moment";
import { CopyToClipboardComponents } from "../../../../../../../../ReusableComponents/UtilityComponents";
import BuildingIcon from '../../../../../../../../assets/images/icons/Building.svg';
import bedIcon from '../../../../../../../../assets/images/Propxicon/side-bed.svg';
import bathIcon from '../../../../../../../../assets/images/icons/bath.svg';

const LeadInquiriesCard = memo(({
  data
}) => {


  return (
    <div className={Styles.Card}>
      <div className="">
        <p className={Styles.Card_ID}>
          <span>Inquiry Id :</span>
          <span>
            <CopyToClipboardComponents
              data={data.inquiryId}
              childrenData={data.inquiryId}
            />
          </span>
        </p>
        {data.propertyName ? (<p className="d-flex fa-center gap-4">
          <span className={Styles.Card__title_icon}><img src={BuildingIcon} alt="Building-Icon" /></span>
          <span className={Styles.Card__title}>{data.propertyName || null}</span>
        </p>) : null}
      </div>
      <div className={GeneralStyles.Flex_between}>
        <p className={Styles.Price}>
          <span>{formatCommasForPriceUnit(data.priceFrom || 0)} - {formatCommasForPriceUnit(data.priceTo || 0)}</span>
          <span className={Styles.Price__currency}>AED</span>
        </p>
        <div className={GeneralStyles.Flex_center}>
          <span className={`${Styles.UnitType} mx-2`}>{data.unitType || null}</span>
        </div>
      </div>
      <div className="d-flex-center gap-8">
        {data.numberOfBedRooms ?
          <p className={GeneralStyles.Solid_capsule}>
            <span><img src={bedIcon} alt="bed-Icon" /></span>
            <span>{data.numberOfBedRooms || null}</span>
          </p> : null}
        {data.numberOfBathRooms ?
          <p className={GeneralStyles.Solid_capsule}>
            <span><img src={bathIcon} alt="bath-Icon" /></span>
            <span>{data.numberOfBathRooms || null}</span>
          </p> : null}
      </div>
      <div>
        {data.creationDate ? <p className={Styles.CreatedOn}>
          <span className={Styles.CreatedOn_title}>CreatedOn:</span>
          <span>{moment(data.creationDate).format("DD MMM YYYY")}</span>
        </p> : null}

        <div className="d-flex-center gap-8">
          {data.inquiryStatus ? <div className={Styles.Outlined_capsule__red}>
            <span className={Styles.Outlined_capsule__red_Text}>
              {data.inquiryStatus}
            </span>
          </div> : null}
          {data.inquiryType ? <div className={Styles.Outlined_capsule__blue}>
            <span className={Styles.Outlined_capsule__blue_Text}>
              {data.inquiryType}
            </span>
          </div> : null}
        </div>
      </div>
    </div>
  );
});

export default LeadInquiriesCard;
