import React from "react";
import { CustomDialog } from "../../../../../../Components";
import { Box, ButtonBase, Typography } from "@material-ui/core";
import { SearchHomeIcon, SaleOwnerIcon } from "../../../../../../assets/icons";
import { GlobalHistory } from "../../../../../../Helper";

//styles
import useStyles from "./styles";

function ContactAddNewLeadDialog({
  addNewLeadDialog,
  setAddNewLeadDialog,
  translate,
  contactId,
}) {
  const styles = useStyles();

  const navigateToLeadForm = ({ formType }) => {
    GlobalHistory.push(
      `/home/Contacts-CRM/addLead?formType=${formType}&contactId=${contactId}`
    );
  };

  return (
    <CustomDialog
      open={addNewLeadDialog}
      onClose={() => {
        setAddNewLeadDialog(false);
      }}
      showActions={false}
      title={translate("Add-new-lead")}
      subtitle={translate(
        "Create-a-new-lead-entry-choosing-between-seeker-or-owner-type-to-categorize-leads-based-on-their-status-and-engagement"
      )}
      width={"1000px"}
      content={
        <Box className={styles.containeAddNewLeadsDialog}>
          <ButtonBase
            className={styles.containerBox}
            onClick={() => navigateToLeadForm({ formType: 1 })}
          >
            <Box>
              <SearchHomeIcon />
              <Typography className={styles.titleBox}>
                {translate("Seeker")}
              </Typography>
              <Typography className={styles.descriptionBox}>
                Lorem ipsum dolor sit amet consectetur. Cras viverra id tempus
                faucibus vestibulum
              </Typography>
            </Box>
          </ButtonBase>
          <ButtonBase
            className={styles.containerBox}
            onClick={() => navigateToLeadForm({ formType: 2 })}
          >
            <Box>
              <SaleOwnerIcon />
              <Typography className={styles.titleBox}>
                {translate("Owner")}
              </Typography>
              <Typography className={styles.descriptionBox}>
                Lorem ipsum dolor sit amet consectetur. Cras viverra id tempus
                faucibus vestibulum
              </Typography>
            </Box>
          </ButtonBase>
        </Box>
      }
    />
  );
}
export default ContactAddNewLeadDialog;
