import { makeStyles } from "@material-ui/core/styles";
import { useIsAr } from "../../../Hooks";

export default makeStyles((theme) => {
  const { isAr } = useIsAr(theme.direction);

  return {
    cardWrapper: {
      padding: "20px 16px",
      width: "311px",
    },
    cardContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: "16px",
    },
    headingContainer: {
      position: "relative",
      display: "flex",
      alignItems: "flex-start",
      gap: "25px",
      width: "100%",
      flexDirection: isAr ? "row-reverse" : "row",
      justifyContent: "center",
    },

    imageWrapper: {
      width: "178px",
      height: "117px",
      borderRadius: "4px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      overflow: "hidden",
      marginTop: "7px",
    },
    imagePreview: {
      width: "100%",
      height: "100%",
      objectFit: "contain",
    },
    contentContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: "19px",
    },
    titleWrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: "6px",
      alignSelf: "stretch",
      borderBottom: `1px solid ${theme.palette.background.gray_200}`,
      paddingBottom: "16px",
    },
    categoryName: {
      fontSize: "18px",
      fontWeight: 600,
      lineHeight: "28px",
    },
    documentNameWrapper: {
      display: "flex",
      alignItems: "center",
      gap: "4px",
    },
    documentName: {
      color: theme.palette.text.tertiary,
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "20px",
    },
    footerContainer: {
      display: "flex",
      gap: "12px",
    },
    createdOn: {
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: 400,
      color: theme.palette.text.secondary,
    },
    date: {
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "20px",
      color: theme.palette.text.primary,
    },
    menuActiveIconClass: {
      fill: theme.palette.foreground.quarterary,
      cursor: "pointer",
      position: "absolute",
      top: "0",
      right: "0",
    },
    menuDefaultIconClass: {
      fill: theme.palette.foreground.quinary,
      cursor: "pointer",
      position: "absolute",
      top: "0",
      right: "0",
    },
    deleteTitle: {
      backgroundColor: theme.palette,
    },
    containerAttatchmentIcon: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: `rgba(16, 24, 40, 0.20)`,
      borderRadius: "28px", 
      width: "56px", 
      height: "56px",
      backdropFilter: 'blur(4px)'
    },withUrl:{
      backgroundColor:theme.palette.border.tertiary,
      borderRadius:'6px'
    },withDocument:{
      backgroundColor:theme.palette.border.tertiary,
       borderRadius:'6px'
    },documentLink:{
      color: theme.palette.text.tertiary,
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "20px",
      whiteSpace: "nowrap", 
      overflow: "hidden",   
      textOverflow: "ellipsis", 
      maxWidth: "220px", 
    }
  };
});
