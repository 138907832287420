import React from 'react';
import {  getBezierPath } from 'react-flow-renderer';

const CustomEdge = ({ id, sourceX, sourceY, targetX, targetY, sourcePosition, targetPosition, label }) => {
  const [edgePath] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return (
    <>
      <path id={id} className="react-flow__edge-path" d={edgePath} />
        <div
          style={{
            position: 'absolute',
            transform: 'translate(-50%, -50%)',
            background: '#fff',
            padding: '2px 5px',
            borderRadius: '3px',
            fontSize: '12px',
            border: '1px solid #ccc',
          }}
        >
          {label}
        </div>
    </>
  );
};

export default CustomEdge;
