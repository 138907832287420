import React, { useEffect, useState , useRef  } from 'react';
import {
  AutocompleteComponent,
  DialogComponent,
  Spinner,
} from '../../../../Components';
import LookupAutocomplete from '../../../../Components/Controls/LookupAutocomplete/LookupAutocomplete';
import { StaticLookupsIds } from '../../../../assets/json/StaticLookupsIds';
import {
  CreateOrUpdateCourseDetails,
  GetLMSCourseDetailsById,
  getProperties,
  GetAllDevelopersContactsAPI,
} from '../../../../Services';
import { showError, showSuccess } from '../../../../Helper';
import { useTranslation } from 'react-i18next';
import { useDebouncedAction } from '../../../../Hooks/DebouncedAction';
import { createDtoModel, fetchedDataModel } from '../CourseRelatedToData.Model';

function CourseRelatedToDialog({
  open,
  parentTranslationPath,
  onClose,
  courseId,
}) {
  const { t } = useTranslation(parentTranslationPath);
  const [data, setData] = useState({ properties: [], developers: [] });
  const searchTimer = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadings, setLoadings] = useState({
    developers: false,
    projects: false,
  });
  const [courseDetails, setCourseDetails] = useState({
    courseCommunities: [],
    courseDevelopers: [],
    courseProjects: [],
    lmsOperationTypes: [],
    lmsPropertyCategories: [],
  });
  const getLMSCourseDetailsById = async () => {
    setIsLoading(true);
    try {
      const result = await GetLMSCourseDetailsById(courseId);
      const formattedResult = fetchedDataModel(result);
      setCourseDetails(formattedResult);
    } catch (error) {
      showError(t('something_went_wrong'));
    } finally {
      setIsLoading(false);
    }
  };
  const getAllProperties = async (searchValue) => {
    setLoadings((prev) => ({ ...prev, projects: true }));
    const res = await getProperties({
      pageSize: 10,
      pageIndex: 0,
      search: searchValue || '',
    });
    if (!(res && res.status && res.status !== 200)) {
      setData((prevData) => ({ ...prevData, properties: res.result }));
    }
    setLoadings((prev) => ({ ...prev, projects: false }));
  };

  const getDevelopers = async (searchValue) => {
    const res = await GetAllDevelopersContactsAPI({
      pageIndex: 1,
      pageSize: 50,
      search: searchValue || '',
    });
    if (!(res?.status && res.status !== 200)) {
      return res?.result;
    }
    return [];
  };
  const getAllDevelopers = async (searchValue) => {
    setLoadings((prev) => ({ ...prev, developers: true }));
    const res = await getDevelopers(searchValue);
    if (!(res && res.status && res.status !== 200)) {
      let developersList = [];
      if (Array.isArray(res)) {
        res.forEach((element) => {
          const developerContact =
            element?.contact && JSON.parse(element.contact);
  
          developersList.push({
            contactsId: element.contactsId,
            name: developerContact?.contact?.company_name,
          });
        });
      }
      setData((prevData) => ({ ...prevData, developers: developersList }));
    }
    setLoadings((prev) => ({ ...prev, developers: false }));
  };
  const debouncedGetAllDevelopers = useDebouncedAction(getAllDevelopers, 1000);
  const debouncedGetAllProperties = useDebouncedAction(getAllProperties);

  const onChangeHandler = (field, value) => {
    setCourseDetails((prevDetails) => ({ ...prevDetails, [field]: value }));
  };

  const saveHandler = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    const body = {
      lmsCourseId: +courseId,
      ...createDtoModel(courseDetails),
    };
    const createDto = createDtoModel(body);
    try {
      await CreateOrUpdateCourseDetails(createDto);
      showSuccess(t('updated_successfully'));
      onClose();
    } catch (error) {
      showError(t('something_went_wrong'));
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getLMSCourseDetailsById();
  }, [courseId]);

  return (
    open && (
      <DialogComponent
        isOpen
        titleText={'course_management'}
        dialogContent={
          <div className='flex gap-1 fj-center p-2 flex-wrap'>
            <Spinner isActive={isLoading} />
            <LookupAutocomplete
              lookupTypeId={StaticLookupsIds.Community}
              parentTranslationPath={parentTranslationPath}
              label={'communities'}
              multiple
              selectedValues={courseDetails.courseCommunities}
              onChange={(_e, newValues) =>
                onChangeHandler('courseCommunities', newValues)
              }
              className={'w-75'}
            />
            <LookupAutocomplete
              parentTranslationPath={parentTranslationPath}
              lookupName={'CourseOperationType'}
              label={'course_operation_types'}
              multiple
              selectedValues={courseDetails.lmsOperationTypes}
              onChange={(_e, newValues) =>
                onChangeHandler('lmsOperationTypes', newValues)
              }
              className={'w-75'}
            />
            <LookupAutocomplete
              parentTranslationPath={parentTranslationPath}
              lookupName={'CoursePropertyCategory'}
              label={'categories'}
              multiple
              selectedValues={courseDetails.lmsPropertyCategories}
              onChange={(_e, newValues) =>
                onChangeHandler('lmsPropertyCategories', newValues)
              }
              className={'w-75'}
            />
            <div className='w-75'>
              <AutocompleteComponent
                selectedValues={courseDetails.courseProjects || []}
                isLoading={loadings.projects}
                onOpen={() => {
                  if (data.properties?.length === 0) getAllProperties();
                }}
                onChange={(_e, newValues) =>
                  onChangeHandler('courseProjects', newValues)
                }
                onInputKeyUp={(e) => {
                  const value = e?.target?.value;
                  debouncedGetAllProperties(value);
                }}
                displayLabel={(option) =>
                  option?.property?.property_name || option?.propertyName || ''
                }
                labelValue={t(`projects`)}
                chipsLabel={(option) =>
                  option?.property?.property_name ?? option?.propertyName ?? ''
                }
                data={data.properties}
                withoutSearchButton
                getOptionSelected={(option) =>
                  courseDetails?.courseProjects?.findIndex(
                    (item) => item?.propertyId === option?.propertyId
                  ) !== -1
                }
              />
            </div>
            <div className='w-75'>
              <AutocompleteComponent
                idRef='developer-specialist-forRef'
                wrapperClasses='w-min-unset mb-2'
                selectedValues={courseDetails?.courseDevelopers}
                onChange={(_e, newValues) =>
                  onChangeHandler('courseDevelopers', newValues)
                }
                labelValue={`course_developers`}
                multiple
                isLoading={loadings.developers}
                data={data?.developers}
                displayLabel={(option) => option?.name || ''}
                renderOption={(option) => option?.name || ''}
                chipsLabel={(option) => option?.name || ''}
                onInputKeyUp={(event) => {
                  const { value } = event.target;
                  if (searchTimer.current)
                    clearTimeout(searchTimer.current);
                  searchTimer.current = setTimeout(() => {
                    debouncedGetAllDevelopers(value);
                  }, 700);
                }}
                withoutSearchButton
                parentTranslationPath={parentTranslationPath}
                filterOptions={(options) => {
                  const isFind = (id) =>
                    courseDetails?.courseDevelopers &&  courseDetails.courseDevelopers?.findIndex(
                      (w) => w.contactsId === id
                    ) === -1 || false;
                  return options.filter((w) => isFind(w.contactsId));
                }}
                getOptionSelected={(option) =>
                  (
                    courseDetails.courseDevelopers &&
                    courseDetails.courseDevelopers.findIndex(
                      (item) => item?.contactsId === option?.contactsId
                    ) !== -1) ||
                  false
                }
                filterSelectedOptions
              />
            </div>
          </div>
        }
        onCloseClicked={onClose}
        onCancelClicked={onClose}
        parentTranslationPath={parentTranslationPath}
        onSaveClicked={saveHandler}
      />
    )
  );
}

export default CourseRelatedToDialog;
