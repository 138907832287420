import { DeveloperEmployees ,  BranchesLocation } from '../PeopleAndLocations' ; 
import { DevelopersPermissions } from '../../../../../Permissions'

export const PeopleAndLocationsVerticalTabsData = [
  {
    label: "developer-employees",
    component: DeveloperEmployees,
    permissionsList: Object.values(DevelopersPermissions),
    permissionsId: [DevelopersPermissions.DevelopersEmployees.permissionsId],
  },
  {
    label: "branches-location",
    component: BranchesLocation,
    permissionsList: Object.values(DevelopersPermissions),
    permissionsId: [DevelopersPermissions.NewBranchesLocation.permissionsId],
  },
]; ;



