import React, { useState, useRef  , useEffect  , useCallback  } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  Inputs,
  AutocompleteComponent,
  DatePickerComponent,
} from "../../../../../../Components";
import {
  GetLookupItemsByLookupTypeName,
  GetDeveloperPropartiesByContactIdAPI , 
  GetAllBranches,
  OrganizationUserSearch,
} from "../../../../../../Services";

export const AgreementDetailsManagementComponents = ({
  translationPath,
  parentTranslationPath,
  state,
  setState,
  selected,
  onChangeHandler,
  className,
  editForm,
  contactReletedWithDeveloperId
}) => {
  const { t } = useTranslation(parentTranslationPath, "Shared");
  const searchTimer = useRef(null);
  const [isLoading, setIsLoading] = useState({
    agreementsTypes: false,
    branches: false,
    users: false,
    properties: false,
  });

  const [items, setItems] = useState({
    agreementsTypes: [],
    branches: [],
    users: [],
    properties: [],
  });

  const getLookupItemsByName = async (lookUpName, key) => {
    setIsLoading((item) => ({ ...item, [key]: true }));
    const result = await GetLookupItemsByLookupTypeName({
      lookUpName,
      pageSize: 50,
      pageIndex: 1,
    });
    if (!(result && result.status && result.status !== 200)) {
      setItems((item) => ({ ...item, [key]: (result && result.result) || [] }));
    } else setItems((item) => ({ ...item, [key]: [] }));
    setIsLoading((loading) => ({ ...loading, [key]: false }));
  };

  const GetDeveloperPropartiesByContactId = useCallback(async (searchValue) => {
    let list = [];
    setIsLoading((loading) => ({ ...loading, properties: true }));
    const rs = (contactReletedWithDeveloperId && await GetDeveloperPropartiesByContactIdAPI(contactReletedWithDeveloperId)) || null ; 
    if (!rs) {
      setIsLoading((item) => ({ ...item, properties: false }));
      setItems((item) => ({ ...item, properties: list || [] }));
      return;
    } else {
      rs.map((valueLoop) => {
        list.push({
          propertyId: valueLoop?.propertyId,
          propertyName: valueLoop?.propertyName
        });
      });
      setItems((item) => ({ ...item, properties: list || [] }));
      setIsLoading((item) => ({ ...item, properties: false }));
    }
  });

  const getAllUsersAPI = async (search) => {
    let list = [];
    setIsLoading((item) => ({ ...item, users: true }));
    const rs = await OrganizationUserSearch({
      pageIndex: 1,
      pageSize: 50,
      name: search,
      userStatusId: 2,
    });
    setIsLoading((item) => ({ ...item, users: false }));

    if (!rs || !rs.result) {
      setItems((item) => ({ ...item, users: list || [] }));
      return;
    }
    rs.result.map((element) => {
      list.push({
        usersId: element.id,
        userName: element.fullName,
        branch: element.branch,
      });
    });

    setItems((item) => ({ ...item, users: list || [] }));
  };

  const getAllBranchesAPI = async (search) => {
    let list = [];

    setIsLoading((item) => ({ ...item, branches: true }));
    const rs = await GetAllBranches({
      pageIndex: 1,
      pageSize: 50,
      branchName: search || "",
    });
    setIsLoading((item) => ({ ...item, branches: false }));

    if (!rs || !rs.result) {
      setItems((item) => ({ ...item, branches: list || [] }));
      return;
    }

    setItems((item) => ({ ...item, branches: rs.result || [] }));
  };

  const DateWithMonthsDelay = (newDate, months) => {
    const date = new Date(newDate);
    date.setMonth(date.getMonth() + months);
    return date;
  };

  const calculationAgreementEndDate = (
    agreementStartDate,
    agreementDurationValue
  ) => {
    if (agreementDurationValue && agreementStartDate) {
      const agreementStartDateNew = new Date(agreementStartDate) || null;
      const dateWithMonths = DateWithMonthsDelay(
        agreementStartDateNew,
        agreementDurationValue
      );
      const updateState = {
        ...state,
        agreementStartDate:
          moment(agreementStartDate).format("YYYY-MM-DDTHH:mm:ss") || null,
        agreementDuration: parseInt(agreementDurationValue) || null,
        agreementEndDate:
          (dateWithMonths &&
            moment(dateWithMonths).format("YYYY-MM-DDTHH:mm:ss")) ||
          null,
      };
      setState({ id: "edit", value: updateState });
    } else setState({ id: "agreementDuration", value: agreementDurationValue });
  };

  return (
    <>
      <div className={`${className || "px-2 w-50 "}`}>
        <AutocompleteComponent
          key={`agreementType`}
          selectedValues={selected.agreementType}
          idRef={`agreementTypeIdRef`}
          multiple={false}
          inputPlaceholder={t("select-agreement-type")}
          data={items && items.agreementsTypes}
          inputClasses="inputs theme-form-builder"
          displayLabel={(option) => (option && option.lookupItemName) || ""}
          onChange={(e, newValue) => {
            onChangeHandler(
              "agreementTypeId",
              newValue && newValue.lookupItemId,
              "agreementType",
              newValue
            );
          }}
          isLoading={isLoading.agreementsTypes}
          withLoader
          withoutSearchButton
          labelValue={"agreement-type"}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          onOpen={() => {
            if (items?.agreementsTypes?.length === 0)
              getLookupItemsByName("AgreementType", "agreementsTypes");
          }}
        />
      </div>

      <div className={`${className || "px-2 w-50 "}`}>
        <DatePickerComponent
          idRef={"agreementStartDateRef"}
          labelValue={"agreement-start-date"}
          value={
            state &&
            state.agreementStartDate &&
            moment(state.agreementStartDate).isValid() &&
            moment(state.agreementStartDate).format("YYYY-MM-DDTHH:mm:ss")
          }
          onDateChanged={(newValue) => {
            onChangeHandler(
              "agreementStartDate",
              (newValue && moment(newValue).format("YYYY-MM-DDTHH:mm:ss")) ||
                null
            );
            if (
              newValue &&
              state.agreementDuration &&
              state.agreementDuration !== ""
            )
              calculationAgreementEndDate(
                newValue,
                parseInt(state.agreementDuration)
              );
          }}
          placeholder={"select-agreement-start-date"}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
        />
      </div>
      <div className={`${className || "px-2 w-50 "}`}>
        <Inputs
          isAttachedInput
          idRef={`agreementDurationMonthsIDRef${1}`}
          labelValue={"agreement-duration-months"}
          value={state.agreementDuration || ""}
          type={"number"}
          min={0}
          onInputChanged={(e) => {
            let { value } = e.target;
            if (value !== "0" && value && value !== "")
              calculationAgreementEndDate(
                state.agreementStartDate,
                parseInt(value)
              );
            else onChangeHandler("agreementDuration", null);
          }}
          inputPlaceholder={"enter-agreement-duration-months"}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
        />
      </div>
      <div className={`${className || "px-2 w-50 "}`}>
        <DatePickerComponent
          idRef={"AgreementEndDateRef"}
          value={
            state &&
            state.agreementEndDate &&
            moment(state.agreementEndDate).isValid() &&
            moment(state.agreementEndDate).format("YYYY-MM-DDTHH:mm:ss")
          }
          labelValue={"agreement-end-date"}
          placeholder={"select-agreement-end-date"}
          onDateChanged={(newValue) => {
            onChangeHandler(
              "agreementEndDate",
              (newValue && moment(newValue).format("YYYY-MM-DDTHH:mm:ss")) ||
                null
            );
          }}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          isDisabled={
            state.agreementStartDate &&
            state.agreementDuration &&
            state.agreementDuration !== ""
          }
        />
      </div>
      <div className={`${className || "px-2 w-50 "}`}>
        <AutocompleteComponent
          key={`project-name`}
          idRef={`ProjectNameRef`}
          multiple
          inputPlaceholder={t("select-properties")}
          data={items?.properties || []}
          selectedValues={selected.agreementDetailsProjects}
          inputClasses="inputs theme-form-builder"
          chipsLabel={(option) => (option && option.propertyName) || ""}
          displayLabel={(option) => (option && option.propertyName) || ""}
          onChange={(e, newValues) => {
            const agreementDetailsProjectsListIds =
              (newValues &&
                newValues.length &&
                newValues.map((property) => ({
                  propertyId: property?.propertyId,
                }))) ||
              [];
            onChangeHandler(
              "agreementDetailsProjects",
              agreementDetailsProjectsListIds,
              "agreementDetailsProjects",
              newValues
            );
          }}
          isLoading={isLoading.properties}
          withLoader
          withoutSearchButton
          labelValue={"projects-names"}
          filterOptions={(options) => {
            const isFind = (id) =>
              selected.agreementDetailsProjects?.findIndex(
                (s) => s.propertyId === id
              ) === -1 || false;
            return options.filter((w) => isFind(w.propertyId));
          }}
          getOptionSelected={(option) =>
            (state &&
              state.agreementDetailsProjects &&
              state.agreementDetailsProjects.findIndex(
                (item) => item?.propertyId === option?.propertyId
              ) !== -1) ||
            false
          }
          onOpen={() => {
            if (items?.properties?.length === 0)
              GetDeveloperPropartiesByContactId();
          }}
          filterSelectedOptions
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          
        />
      </div>
      <div className={`${className || "px-2 w-50 "}`}>
        <AutocompleteComponent
          key={`signingBranch`}
          idRef={`signingBranchRef`}
          multiple
          inputPlaceholder={t("select-branches")}
          data={items?.branches || []}
          selectedValues={selected.agreementDetailsSigningBranches}
          inputClasses="inputs theme-form-builder"
          chipsLabel={(option) => (option && option.branchName) || ""}
          displayLabel={(option) => (option && option.branchName) || ""}
          onChange={(e, newValues) => {
            const branchesIds =
              (newValues &&
                newValues.length &&
                newValues.map((branch) => ({
                  branchId: branch.branchId,
                }))) ||
              [];
            onChangeHandler(
              "agreementDetailsSigningBranches",
              branchesIds,
              "agreementDetailsSigningBranches",
              newValues
            );
          }}
          onInputKeyUp={(e) => {
            const { value } = e.target;
            if (searchTimer.current) clearTimeout(searchTimer.current);
            searchTimer.current = setTimeout(() => {
              getAllBranchesAPI(value);
            }, 700);
          }}
          filterOptions={(options) => {
            const isFind = (id) =>
              selected.agreementDetailsSigningBranches?.findIndex(
                (w) => w.branchId === id
              ) === -1 || false;
            return options.filter((w) => isFind(w.branchId));
          }}
          getOptionSelected={(option) =>
            (state &&
              state.agreementDetailsSigningBranches &&
              state.agreementDetailsSigningBranches.findIndex(
                (item) => item?.branchId === option?.branchId
              ) !== -1) ||
            false
          }
          filterSelectedOptions
          isLoading={isLoading.branches}
          withLoader
          withoutSearchButton
          labelValue={"signing-branches"}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          onOpen={() => {
            if (items && items.branches?.length === 0) getAllBranchesAPI();
          }}
        />
      </div>

      <div className={`${className || "px-2 w-50 "}`}>
        <AutocompleteComponent
          key={`users`}
          idRef={`usersRef`}
          multiple
          inputPlaceholder={t("select-users")}
          data={items && items.users}
          selectedValues={selected.agreementDetailsAuthorizedSignatories}
          inputClasses="inputs theme-form-builder"
          displayLabel={(option) => (option && option.userName) || ""}
          chipsLabel={(option) => (option && option.userName) || ""}
          onChange={(e, newValues) => {
            const usersIds =
              (newValues &&
                newValues.length &&
                newValues.map((user) => ({
                  usersId: user?.usersId,
                  userId: 0,
                }))) ||
              [];
            onChangeHandler(
              "agreementDetailsAuthorizedSignatories",
              usersIds,
              "agreementDetailsAuthorizedSignatories",
              newValues
            );
          }}
          filterOptions={(options) => {
            const isFind = (id) =>
              selected.agreementDetailsAuthorizedSignatories?.findIndex(
                (w) => w.usersId === id
              ) === -1 || false;
            return options.filter((w) => isFind(w.usersId));
          }}
          getOptionSelected={(option) =>
            (state &&
              state.agreementDetailsAuthorizedSignatories &&
              state.agreementDetailsAuthorizedSignatories.findIndex(
                (item) => item?.usersId === option?.usersId
              ) !== -1) ||
            false
          }
          filterSelectedOptions
          isLoading={isLoading.users}
          withLoader
          withoutSearchButton
          labelValue={"authorizedes-signatories"}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          onOpen={() => {
            if (items && items.users?.length === 0) getAllUsersAPI();
          }}
          onInputKeyUp={(e) => {
            const { value } = e.target;
            if (searchTimer.current) clearTimeout(searchTimer.current);
            searchTimer.current = setTimeout(() => {
              getAllUsersAPI(value);
            }, 700);
          }}
        />
      </div>
      <div className={`${className || "px-2 w-50 "}`}>
        <AutocompleteComponent
          key={`marketingBudgetAllocation`}
          idRef={`marketingBudgetAllocationRef`}
          multiple={false}
          selectedValues={selected.marketingBudgetAllocation}
          inputPlaceholder={t("marketing-budget-allocation")}
          data={[
            { key: true, name: "yes" },
            { key: false, name: "no" },
          ]}
          inputClasses="inputs theme-form-builder"
          displayLabel={(option) =>
            (option && t(`${translationPath}${option.name}`)) || ""
          }
          withoutSearchButton
          labelValue={"marketing-budget-allocation"}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          onChange={(e, newValue) => {
            onChangeHandler(
              "isMarketingBudgetAllocation",
              newValue === null ? false : newValue.key,
              "marketingBudgetAllocation",
              newValue
            );
            if (newValue === null || newValue?.key === false)
              onChangeHandler("marketingBudgetAmount", null);
          }}
        />
      </div>
      <div className={`${className || "px-2 w-50 "}`}>
        {state && state.isMarketingBudgetAllocation && (
          <Inputs
            idRef={`budget-amount`}
            labelClasses=""
            key={`budget-amount`}
            value={state.marketingBudgetAmount || ""}
            labelValue={"budget-amount"}
            onInputChanged={(e) => {
              const { value } = e.target;
              onChangeHandler("marketingBudgetAmount", value);
            }}
            type={"number"}
            withNumberFormat
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
          />
        )}
      </div>
    </>
  );
};
AgreementDetailsManagementComponents.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  state: PropTypes.objectOf(PropTypes.any).isRequired,
  setState: PropTypes.func.isRequired,
  selected: PropTypes.objectOf(PropTypes.any).isRequired,
  setSelected: PropTypes.func.isRequired,
  className: PropTypes.string,
  editForm: PropTypes.bool,
};

AgreementDetailsManagementComponents.defaultProps = {
  editForm: false,
  className: "",
};
