import React from "react";
import { Box, Avatar } from "@material-ui/core";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { CustomBadge, SocialLinks, ProgressBar, CustomIconButton } from "../../../../../../Components";
import { getDownloadableLink, truncateString } from "../../../../../../Helper";
import { ArrowUpRight } from "../../../../../../assets/icons";

import useStyles from "./styles";

const ContactName = ({ item }) => {
  const { isDarkMode } = useSelector((state) => state.theme);
  const classes = useStyles({ isDarkMode });
  const history = useHistory();

  const handleClick = (item) => {
    if (history) {
      history.push(
        `/home/Contacts-CRM/contact-profile-overview?formType=${
          item?.row?.contact_type_id
        }&id=${item?.row?.contactId || item?.row?.id}`
      );
    }
  };

  const getFullName = () => {
    if (item?.row?.first_name) {
      return `${item?.row?.first_name} ${
        item?.row?.last_name ? item?.row?.last_name : "N/A"
      }`;
    }
    return item?.row?.company_name || "N/A";
  };

  const displayName = getFullName();
  const truncatedName =
    displayName.length >= 18 ? truncateString(displayName, 18) : displayName;

  return (
    <Box className={classes.container}>
      <Box>
        {getDownloadableLink(item?.row?.imagePath) && (
          <Avatar
            className={
              item?.row?.imagePath ? classes.avatar : classes.defaultAvatar
            }
            src={
              item?.row?.imagePath
                ? getDownloadableLink(item?.row?.imagePath)
                : item?.row?.contact_type_id === 2
                ? "/images/contacts/CorporateAvatar.svg"
                : item?.row?.["gender.lookupItemName"] === "Male"
                ? "/images/contacts/MaleAvatar.svg"
                : "/images/contacts/FemaleAvatar.svg"
            }
          />
        )}
      </Box>

      <Box className={classes.detailsColumn}>
        <Box className={classes.nameRow} onClick={() => handleClick(item)}>
          <span className={classes.nameText}>{truncatedName}</span>
          <CustomIconButton
            variant="text"
            size="xs"
            boxShadow="none"
            color="secondary"
          >
            <ArrowUpRight
              width="20"
              height="20"
              fill={!isDarkMode ? "#344054" : "#CECFD2"}
            />
          </CustomIconButton>
        </Box>
        <Box className={classes.socialLinksBox}>
          <SocialLinks activeItem={item?.row} gap={6} />
        </Box>
      </Box>
    </Box>
  );
};

export const ContactDuplicatesTableHeaderData = (isDarkMode = false) => [
  {
    id: 1,
    formFieldId: 1,
    minWidth: 300,
    headerName: "Contact name",
    field: "fullName",
    isHiddenFilter: false,
    sortable: false,
    filterable: false,
    fieldType: "text",
    displayPath: "fullName",
    isDefaultFilterColumn: true,
    fieldKey: "fullName",
    formFieldTitle: "fullName",
    disableColumnMenu: true,
    searchKey: "fullName",
    renderCell: (item) => {
      return <ContactName item={item} />;
    },
  },
  {
    id: 2,
    formFieldId: 2,
    minWidth: 160,
    headerName: "Contact Type",
    field: "contactTypeStr",
    filterable: false,
    sortable: false,
    fieldType: "text",
    isDefaultFilterColumn: true,
    displayPath: "contactTypeStr",
    disableColumnMenu: true,
    fieldKey: "contactTypeStr",
    formFieldTitle: "Contact Type",
    renderCell: (item) => {
      return (
        <span>
          {item?.row?.contact_type_id === 2 ? "Corporate" : "Individual"}
        </span>
      );
    },
  },
  {
    id: 3,
    formFieldId: 3,
    minWidth: 130,
    headerName: "Class",
    field: "contact_class.lookupItemName",
    fieldType: "text",
    sortable: false,
    filterable: false,
    displayPath: "contact_class.lookupItemName",
    isDefaultFilterColumn: true,
    disableColumnMenu: true,
    fieldKey: "contact_class.lookupItemName",
    formFieldTitle: "Class",
    renderCell: (item) => {
      return item?.row?.["contact_class.lookupItemName"] === "VIP" ||
        item?.row?.["company_class.lookupItemName"] === "VIP" ? (
        <CustomBadge
          Style={{
            padding: "0 2px",
            borderRadius: "2px",
            lineHeight: "20px",
          }}
          label={"VIP"}
          BackgroundColor={!isDarkMode ? "#B2A186" : "#635235"}
          BorderColor={!isDarkMode ? "#B2A186" : "#635235"}
          Color={"#FFF"}
        />
      ) : item?.row?.["contact_class.lookupItemName"] === "VVIP" ||
        item?.row?.["company_class.lookupItemName"] === "VVIP" ? (
        <CustomBadge
          Style={{
            padding: "0 2px",
            borderRadius: "2px",
            lineHeight: "20px",
          }}
          label={"VVIP"}
          BackgroundColor={!isDarkMode ? "#7F6944" : "#A38F6F"}
          BorderColor={!isDarkMode ? "#7F6944" : "#A38F6F"}
          Color={"#FFF"}
        />
      ) : (
        <span>Normal</span>
      );
    },
  },
  {
    id: 4,
    formFieldId: 4,
    minWidth: 190,
    headerName: "Creation Date",
    field: "createdOn",
    isHiddenFilter: false,
    filterable: false,
    disableColumnMenu: true,
    fieldType: "datePicker",
    displayPath: "createdOn",
    isDate: true,
    sortable: false,
    isDefaultFilterColumn: true,
    fieldKey: "createdOn",
    formFieldTitle: "Creation Date",
    renderCell: (item) => {
      return (
        <Box style={{ display: "flex", flexDirection: "column" }}>
          {item?.row?.createdOn && (
            <label style={{ lineHeight: "20px", fontWeight: 500 }}>
              {moment(item.row.createdOn).format("DD MMM YYYY")}
            </label>
          )}
          {item?.row?.createdBy && (
            <span
              style={{
                lineHeight: "20px",
                fontWeight: 400,
                color: !isDarkMode ? "#475467" : "#94969C",
              }}
            >
              {item.row.createdBy}
            </span>
          )}
        </Box>
      );
    },
  },
  {
    id: 5,
    formFieldId: 5,
    minWidth: 190,
    headerName: "Progress",
    field: "data_completed",
    displayPath: "data_completed",
    fieldKey: "data_completed",
    filterable: false,
    textInputType: "number",
    disableColumnMenu: true,
    textInputMax: 100,
    textInputMin: 0,
    isHiddenFilter: true,
    fieldType: "number",
    isNumber: true,
    sortable: false,
    isDefaultFilterColumn: true,
    formFieldTitle: "Progress",
    renderCell: (item) => {
      if (item?.row?.progress === "N/A") {
        return 0;
      }
      return (
        <Box style={{ display: "flex", alignItems: "center", gap: "12px" }}>
          <Box style={{ width: "100px", display: "flex" }}>
            <ProgressBar staticProgress={item?.row?.progress || 0} />
          </Box>
          <label style={{ fontSize: "14px", fontWeight: 500 }}>
            {item?.row?.progressWithPercentage}
          </label>
        </Box>
      );
    },
  },
  {
    minWidth: 240,
    id: 6,
    formFieldId: 6,
    headerName: "Email",
    field: "email_address.email",
    isHiddenFilter: false,
    fieldKey: "email_address.email",
    fieldType: "text",
    sortable: false,
    displayPath: "email_address.email",
    disableColumnMenu: true,
    filterable: false,
    isDefaultFilterColumn: true,
    formFieldTitle: "Email",
    renderCell: (item) => {
      const counter =
        item?.row?.["email_address.others"] ||
        item?.row?.["general_email.others"];

      return (
        <span>
          {((item?.row?.["email_address.email"] ||
            item?.row?.["general_email.email"]) &&
            truncateString(
              item?.row?.["email_address.email"] ||
                item?.row?.["general_email.email"]
            )) ||
            "N/A"}
          {(item?.row?.["email_address.others"]?.length > 0 ||
            item?.row?.["general_email?others"]?.length > 0) && (
            <CustomBadge
              Style={{
                padding: "0 4px",
                height: "18px",
                marginLeft: "5px",
              }}
              label={`+${counter?.length}`}
              SizeVariant={"small"}
              BackgroundColor={!isDarkMode ? "#F9FAFB" : "#161B26"}
              BorderColor={!isDarkMode ? "#EAECF0" : "#333741"}
              Color={!isDarkMode ? "#344054" : "#CECFD2"}
            />
          )}
        </span>
      );
    },
  },
  {
    id: 7,
    formFieldId: 7,
    minWidth: 161,
    headerName: "Nationality",
    field: "nationality.lookupItemName",
    fieldType: "text",
    fieldKey: "nationality.lookupItemName",
    sortable: false,
    displayPath: "nationality.lookupItemName",
    filterable: false,
    disableColumnMenu: true,
    isDefaultFilterColumn: true,
    formFieldTitle: "Nationality",
    renderCell: (item) => {
      return (
        <label style={{ fontWeight: 500 }}>
          {item?.row?.["nationality.lookupItemName"] ||
            item?.row?.["company_nationality.lookupItemName"] ||
            "N/A"}
        </label>
      );
    },
  },
  {
    id: 8,
    formFieldId: 8,
    minWidth: 290,
    headerName: "Location",
    fieldType: "text",
    sortable: false,
    field: "city.lookupItemName",
    fieldKey: "city.lookupItemName",
    displayPath: "city.lookupItemName",
    filterable: false,
    disableColumnMenu: true,
    isDefaultFilterColumn: true,
    formFieldTitle: "Location",
    renderCell: (item) => {
      return item?.row?.details?.find(item => item.title === "location")?.value;
    },
  },
];
