import React from "react";

function EXEIcon({ fill, ...restProps }) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.75 4C7.75 2.20508 9.20508 0.75 11 0.75H27C27.1212 0.75 27.2375 0.798159 27.3232 0.883885L38.1161 11.6768C38.2018 11.7625 38.25 11.8788 38.25 12V36C38.25 37.7949 36.7949 39.25 35 39.25H11C9.20507 39.25 7.75 37.7949 7.75 36V4Z"
        stroke="#D0D5DD"
        stroke-width="1.5"
      />
      <path
        d="M27 0.5V8C27 10.2091 28.7909 12 31 12H38.5"
        stroke="#D0D5DD"
        stroke-width="1.5"
      />
      <rect x="1" y="18" width="26" height="16" rx="2" fill="#444CE7" />
      <path
        d="M4.93484 30V22.7273H9.8354V23.995H6.47248V25.728H9.58327V26.9957H6.47248V28.7322H9.84961V30H4.93484ZM12.4996 22.7273L13.9663 25.206H14.0231L15.4968 22.7273H17.2333L15.0138 26.3636L17.283 30H15.5146L14.0231 27.5178H13.9663L12.4748 30H10.7134L12.9897 26.3636L10.756 22.7273H12.4996ZM18.2063 30V22.7273H23.1069V23.995H19.744V25.728H22.8548V26.9957H19.744V28.7322H23.1211V30H18.2063Z"
        fill="white"
      />
    </svg>
  );
}
export default EXEIcon;
