import React from "react";

const SaleOwnerIcon = () => {
  return (
    <svg
      width="84"
      height="85"
      viewBox="0 0 84 85"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M53.5874 53.5C62.5262 53.5 72.5874 46.233 72.5874 36.3046C72.5874 26.3762 66.5262 19.5 57.5874 19.5"
        stroke="#B2A186"
        stroke-width="2"
      />
      <path
        d="M54.1667 11H12V28H54.1667C54.1667 28 58 25.0685 58 19.5C58 13.9315 54.1667 11 54.1667 11ZM52.25 21.625C51.191 21.625 50.3333 20.6741 50.3333 19.5C50.3333 18.3259 51.191 17.375 52.25 17.375C53.309 17.375 54.1667 18.3259 54.1667 19.5C54.1667 20.6741 53.3088 21.625 52.25 21.625Z"
        fill="#CABFAC"
      />
      <path
        d="M52.2325 22.8067C53.8331 22.8067 55.1356 21.5042 55.1356 19.9033C55.1356 18.3025 53.8331 17 52.2325 17C50.6318 17 49.3293 18.3025 49.3293 19.9033C49.3293 21.5042 50.6318 22.8067 52.2325 22.8067ZM52.2325 18.9357C52.7657 18.9357 53.2001 19.3703 53.2001 19.9035C53.2001 20.4368 52.7655 20.8714 52.2325 20.8714C51.6992 20.8714 51.2646 20.4368 51.2646 19.9035C51.2646 19.3703 51.6992 18.9357 52.2325 18.9357Z"
        fill="#4D3F29"
      />
      <path
        d="M18.8455 24.6127H19.8132C21.6809 24.6127 23.2002 23.0934 23.2002 21.2257C23.2002 19.3579 21.6809 17.8386 19.8132 17.8386H18.8455C18.0451 17.8386 17.394 17.1873 17.394 16.387C17.394 15.5868 18.0453 14.9355 18.8455 14.9355H19.8132C20.6136 14.9355 21.2647 15.5868 21.2647 16.387V16.871H23.2002V16.387C23.2002 14.5193 21.6809 13 19.8132 13H18.8455C16.9778 13 15.4585 14.5193 15.4585 16.387C15.4585 18.2548 16.9778 19.7741 18.8455 19.7741H19.8132C20.6136 19.7741 21.2647 20.4254 21.2647 21.2257C21.2647 22.0259 20.6134 22.6772 19.8132 22.6772H18.8455C18.0451 22.6772 17.394 22.0259 17.394 21.2257V20.7417H15.4585V21.2257C15.4585 23.0934 16.9778 24.6127 18.8455 24.6127Z"
        fill="#4D3F29"
      />
      <path
        d="M26.9355 20.7419H30.8065V24.6129H32.7419V16.871C32.7419 14.7361 31.0059 13 28.871 13C26.7361 13 25 14.7361 25 16.871V24.6129H26.9355V20.7419ZM28.871 14.9355C29.9383 14.9355 30.8065 15.8036 30.8065 16.871V18.8065H26.9355V16.871C26.9355 15.8034 27.8036 14.9355 28.871 14.9355Z"
        fill="#4D3F29"
      />
      <path
        d="M39.8065 22.6774H35.9355V13H34V24.6127H39.8065V22.6774Z"
        fill="#4D3F29"
      />
      <path
        d="M46.8065 22.6774H42.9355V19.7741H46.8065V17.8386H42.9355V14.9355H46.8065V13H41V24.6127H46.8065V22.6774Z"
        fill="#4D3F29"
      />
      <path
        d="M31 51.2778H20.3333C17.8442 51.2778 16.5996 51.2778 15.6489 51.7622C14.8126 52.1883 14.1327 52.8682 13.7066 53.7045C13.2222 54.6552 13.2222 55.8997 13.2222 58.3888V73.4999M53.2222 73.4999V40.6111C53.2222 38.122 53.2222 36.8774 52.7377 35.9267C52.3116 35.0904 51.6317 34.4105 50.7955 33.9844C49.8447 33.5 48.6002 33.5 46.1111 33.5H38.1111C35.622 33.5 34.3774 33.5 33.4267 33.9844C32.5904 34.4105 31.9105 35.0904 31.4844 35.9267C31 36.8774 31 38.122 31 40.6111V73.4999M55.4444 73.4999H11M38.7777 42.3889H45.4444M38.7777 51.2778H45.4444M38.7777 60.1666H45.4444"
        stroke="#4D3F29"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export default SaleOwnerIcon;
