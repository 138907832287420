import { config } from '../../config';
import { HttpServices } from '../../Helper';

export const HubSpotIntegration = async (body) => {
    const result = await HttpServices.post(
      `${config.server_address}/CrmDfm/HubSpotIntegration/GetHubSpotContacts`, body
  )
      .then((data) => data)
      .catch((error) => error.response);
    return result;
  };

export const ContactsBatchUpload = async (body) => {
    const result = await HttpServices.post(
      `${config.server_address}/CrmDfm/HubSpotIntegration/ContactsBatchUpload`, body
  )
      .then((data) => data)
      .catch((error) => error.response);
    return result;
  };
