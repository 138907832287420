import { makeStyles } from "@material-ui/core/styles";
import { useIsAr } from "../../../../Hooks";

export default makeStyles((theme) => {
  const { isAr } = useIsAr(theme.direction);

  return {
    customTimelineOppositeContent: {
      flex: 0,
      textAlign: "left",
      padding: "0px 0px",
      display: "flex",
      flexDirection: "column",
      marginInlineEnd: "20px",
      alignItems: "flex-center",
    },
    customTimeline: {
      padding: "0px 0px",
    },
    customTimelineContent: {
      marginBottom: "16px",
      ...(isAr ? { padding: "0px 20px 0px 0px" } : {padding: "0px 0px 0px 20px"}),
    },
    contactActivitesCard: {
      gap: "12px",
      display: "flex",
      flexDirection: "row",
      position: "relative",
      width: "100%",
      ...(isAr ? { padding: "12px 24px 12px 6px" } : { padding: "12px 6px 12px 24px" }),
      marginBottom:'16px'
    },
    container_Lead_Score_Info: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      gap: "96px",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        gap: "10px",
      },
    },
    LeadInfo: {
      display: "flex",
      flexDirection: "row",
      width: "300px",
      gap: "6px",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    InfoItem: {
      display: "flex",
      flexDirection: "row",
      gap: "6px",
    },
    container_LeadInfo: {
      display: "flex",
      flexDirection: "column",
      gap: "2px",
    },
    container_LeadIdValue: {
      display: "flex",
      flexDirection: "row",
      gap: "3px",
    },
    container_Status: {
      display: "flex",
      flexDirection: "row",
      gap: "10px",
    },
    container_ScoreInfo: {
      display: "flex",
      flexDirection: "column",
      gap: "6px",
      width: "100%",
      marginRight: "18px",
    },
    container_Score_CreatedBy_AssignTo: {
      display: "flex",
      flexDirection: "column",
    },
    container_Score: {
      display: "flex",
      flexDirection: "row",
      gap: "6px",
    },
    container_CreatedBy_AssignTo: {
      display: "flex",
      flexDirection: "row",
      gap: "8px",
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
      },
    },
    container_CreatedBy: {
      display: "flex",
      flexDirection: "row",
      gap: "6px",
    },
    container_AssignTo_ArrowIcon: {
      display: "flex",
      flexDirection: "row",
      gap: "10px",
    },
    container_AssignTo: {
      display: "flex",
      flexDirection: "row",
      gap: "6px",
    },
    divider: {
      width: "100%",
    },
    container_MoreIcon: {
      display: "flex",
      position: "absolute",
      justifyContent: "center",
      flexDirection: "column",
      gap: "2px",
      position: "absolute",
      top: "12px",
      ...(isAr ? { left: "18px" } : { right: "18px" }),
    },
    container_Comment: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
      },
    },
    ValueInfo: {
      width: "100px",
      height: "24px",
      [theme.breakpoints.down("lg")]: {
        width: "60px",
      },
      [theme.breakpoints.down("sm")]: {
        width: "100px",
      },
      [theme.breakpoints.down("xs")]: {
        width: "60px",
      },
    },
    TitleInfo: {
      width: "80px",
      height: "24px",
      [theme.breakpoints.down("lg")]: {
        width: "40px",
      },
      [theme.breakpoints.down("sm")]: {
        width: "80px",
      },
      [theme.breakpoints.down("xs")]: {
        width: "40px",
      },
    },TimeDateElement:{
      width:'64px',
      height:'28px',
      [theme.breakpoints.down("xs")]: {
        width: "50px",
      },
    },titleCard:{
      width:'185px',
      height:'24px',
      [theme.breakpoints.down("xs")]: {
        width: "70px",
      },
    },
    timelineSeparator: {
      paddingLeft: "4px",
      paddingRight: "6px",
      position: "relative",
      zIndex: 2,
    },
    timelineDot: {
      margin: 0,
      position: "absolute",
      top: "26px",
      left: "-0.8px",
      background: theme.palette.skeleton.original,
      "&::after": {
        content: '""',
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "20px",
        height: "20px",
        borderRadius: "50%",
        background: theme.palette.skeleton.light,
        zIndex: -1,
      },
    },
    timelineConnector: {
      zIndex: -2,
      background: theme.palette.border.tertiary,
    },
  };
});
