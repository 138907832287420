import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Inputs } from "../../../../../Components";
import {
  matchYoutubeUrl,
  matchFacebookUrl,
  matchInstagramUrl,
  matchLinkedinUrl,
  matchSnapchatUrl,
  matchTiktokLink,
  matchXLink,
  showError,
} from "../../../../../Helper";

export const SocialMediaLinkManagementComponents = ({
  translationPath,
  parentTranslationPath,
  state,
  setState,
  className,
  editForm,
  setIsHaveValues,
  setIsSaveValidValues,
}) => {
  const { t } = useTranslation(parentTranslationPath, "Shared");
  const [isSubmitted, setIsSubmitted] = useState(false);

  const checkIsValidValues = (key) => {
    if (key === "linkedInLink" && state.linkedInLink)
      return !matchLinkedinUrl(state.linkedInLink || "");
    else if (key === "facebookLink" && state.facebookLink)
      return !matchFacebookUrl(state.facebookLink || "");
    else if (key === "instagramLink" && state.instagramLink)
      return !matchInstagramUrl(state.instagramLink || "");
    else if (key === "snapchatLink" && state.snapchatLink)
      return !matchSnapchatUrl(state.snapchatLink || "");
    else if (key === "youtubeLink" && state.youtubeLink)
      return !matchYoutubeUrl(state.youtubeLink || "");
    else if (key === "tikTokLink" && state.tikTokLink)
      return !matchTiktokLink(state.tikTokLink || "");
    else if (key === "xLink" && state.xLink)
      return !matchXLink(state.xLink || "");

    return false;
  };

  const checkItLeaseOneItem = () => {
    if (
      (state.linkedInLink && state.linkedInLink !== "") ||
      (state.facebookLink && state.facebookLink !== "") ||
      (state.instagramLink && state.instagramLink !== "") ||
      (state.snapchatLink && state.snapchatLink !== "") ||
      (state.youtubeLink && state.youtubeLink !== "") ||
      (state.tikTokLink && state.tikTokLink !== "") ||
      (state.xLink && state.xLink !== "")
    )
      return true;
    return false;
  };

  const checkIsSaveValidValues = () => {
    let checkValues = null;
    checkValues = {
      linkedInLink: {
        id: "linkedInLink",
        value:
          state.linkedInLink !== "" && state.linkedInLink !== null
            ? matchLinkedinUrl(state.linkedInLink || "")
            : true,
      },
      facebookLink: {
        id: "facebookLink",
        value:
          state.facebookLink !== "" && state.facebookLink !== null
            ? matchFacebookUrl(state.facebookLink || "")
            : true,
      },
      instagramLink: {
        id: "instagramLink",
        value:
          state.instagramLink !== "" && state.instagramLink !== null
            ? matchInstagramUrl(state.instagramLink || "")
            : true,
      },
      snapchatLink: {
        id: "snapchatLink",
        value:
          state.snapchatLink !== "" && state.snapchatLink !== null
            ? matchSnapchatUrl(state.snapchatLink || "")
            : true,
      },
      youtubeLink: {
        id: "youtubeLink",
        value:
          state.youtubeLink !== "" && state.youtubeLink !== null
            ? matchYoutubeUrl(state.youtubeLink || "")
            : true,
      },
      tikTokLink: {
        id: "tikTokLink",
        value:
          state.tikTokLink !== "" && state.tikTokLink !== null
            ? matchTiktokLink(state.tikTokLink || "")
            : true,
      },
      xLink: {
        id: "xLink",
        value:
          state.xLink !== "" && state.xLink !== null
            ? matchXLink(state.xLink || "")
            : true,
      },
    };
    const saveValues = [...Object.values(checkValues)];
    return saveValues.some((x) => x.value === false);
  };
  useEffect(() => {
    setIsHaveValues(checkItLeaseOneItem);
    setIsSaveValidValues(checkIsSaveValidValues);
  }, [state]);

  return (
    <>
      <div className={`${className || "px-2 w-50 "}`}>
        <Inputs
          isAttachedInput
          idRef={"LinkedInLinkRef"}
          labelValue={"linkedIn-link"}
          type={"text"}
          value={state.linkedInLink || ""}
          onInputChanged={(e) => {
            let { value } = e.target;
            if (value !== "" && !value.includes("https://"))
              value = `https://${value}`;
            setState({
              id: "linkedInLink",
              value: value === "" ? null : value,
            });
          }}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          endAdornment={
            <>
              <Button
                disabled={
                  state.linkedInLink
                    ? state.linkedInLink !== ""
                      ? checkIsValidValues("linkedInLink")
                      : true
                    : true
                }
                onClick={() => {
                  try {
                    window.open(state.linkedInLink || "");
                  } catch (error) {
                    showError(t(`${translationPath}this-link-is-not-valid`));
                  }
                }}
                className="googlemaps-bbt "
              >
                <span className="p-1 youtube-wraper">
                  <span className="mdi mdi-web" />
                </span>
              </Button>
            </>
          }
          helperText={t`${translationPath}please-enter-valid-linkedIn-url`}
          error={
            state?.linkedInLink !== ""
              ? checkIsValidValues("linkedInLink")
              : false
          }
          isWithError
          isSubmitted={isSubmitted}
        />
      </div>

      <div className={`${className || "px-2 w-50 "}`}>
        <Inputs
          isAttachedInput
          idRef={"Facebook LinkRef"}
          labelValue={"facebook-link"}
          type={"text"}
          value={state.facebookLink || ""}
          onInputChanged={(e) => {
            let { value } = e.target;
            setState({
              id: "facebookLink",
              value: value === "" ? null : value,
            });
          }}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          endAdornment={
            <>
              <Button
                disabled={
                  state.facebookLink
                    ? state.facebookLink !== ""
                      ? checkIsValidValues("facebookLink")
                      : true
                    : true
                }
                onClick={() => {
                  try {
                    window.open(state.facebookLink || "");
                  } catch (error) {
                    showError(t(`${translationPath}this-link-is-not-valid`));
                  }
                }}
                className="googlemaps-bbt "
              >
                <span className="p-1 youtube-wraper">
                  <span className="mdi mdi-web" />
                </span>
              </Button>
            </>
          }
          helperText={t`${translationPath}please-enter-valid-facebook-link-url`}
          error={
            state?.facebookLink !== ""
              ? checkIsValidValues("facebookLink")
              : false
          }
          isWithError
          isSubmitted={isSubmitted}
        />
      </div>
      <div className={`${className || "px-2 w-50 "}`}>
        <Inputs
          isAttachedInput
          idRef={"instagramLinkRef"}
          labelValue={"instagram-link"}
          type={"text"}
          value={state.instagramLink || ""}
          onInputChanged={(e) => {
            let { value } = e.target;
            setState({
              id: "instagramLink",
              value: value === "" ? null : value,
            });
          }}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          endAdornment={
            <>
              <Button
                disabled={
                  state.instagramLink
                    ? state.instagramLink !== ""
                      ? checkIsValidValues("instagramLink")
                      : true
                    : true
                }
                onClick={() => {
                  try {
                    window.open(state.instagramLink || "");
                  } catch (error) {
                    showError(t(`${translationPath}this-link-is-not-valid`));
                  }
                }}
                className="googlemaps-bbt "
              >
                <span className="p-1 youtube-wraper">
                  <span className="mdi mdi-web" />
                </span>
              </Button>
            </>
          }
          helperText={t`${translationPath}please-enter-valid-instagram-link-url`}
          error={
            state?.instagramLink !== ""
              ? checkIsValidValues("instagramLink")
              : false
          }
          isWithError
          isSubmitted={isSubmitted}
        />
      </div>
      <div className={`${className || "px-2 w-50 "}`}>
        <Inputs
          isAttachedInput
          idRef={"InstagramAccountNumberRef"}
          labelValue={"instagram-account-number"}
          type={"text"}
          value={state.instagramAccountNumber || ""}
          onInputChanged={(e) => {
            let { value } = e.target;

            setState({
              id: "instagramAccountNumber",
              value: value === "" ? null : value,
            });
          }}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
        />
      </div>
      <div className={`${className || "px-2 w-50 "}`}>
        <Inputs
          isAttachedInput
          idRef={"SnapchatLinkRef"}
          labelValue={"snapchat-link"}
          type={"text"}
          value={state.snapchatLink || ""}
          onInputChanged={(e) => {
            let { value } = e.target;
            setState({
              id: "snapchatLink",
              value: value === "" ? null : value,
            });
          }}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          endAdornment={
            <>
              <Button
                disabled={
                  state.snapchatLink
                    ? state.snapchatLink !== ""
                      ? checkIsValidValues("snapchatLink")
                      : true
                    : true
                }
                onClick={() => {
                  try {
                    window.open(state.snapchatLink || "");
                  } catch (error) {
                    showError(t(`${translationPath}this-link-is-not-valid`));
                  }
                }}
                className="googlemaps-bbt "
              >
                <span className="p-1 youtube-wraper">
                  <span className="mdi mdi-web" />
                </span>
              </Button>
            </>
          }
          helperText={t`${translationPath}please-enter-valid-snapchat-link-url`}
          error={
            state?.snapchatLink !== ""
              ? checkIsValidValues("snapchatLink")
              : false
          }
          isWithError
          isSubmitted={isSubmitted}
        />
      </div>
      <div className={`${className || "px-2 w-50 "}`}>
        <Inputs
          isAttachedInput
          value={state.tikTokLink || ""}
          idRef={"TikTokLinkRef"}
          labelValue={"tiktok-link"}
          type={"text"}
          onInputChanged={(e) => {
            let { value } = e.target;
            setState({ id: "tikTokLink", value: value === "" ? null : value });
          }}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          endAdornment={
            <>
              <Button
                disabled={
                  state.tikTokLink
                    ? state.tikTokLink !== ""
                      ? checkIsValidValues("tikTokLink")
                      : true
                    : true
                }
                onClick={() => {
                  try {
                    window.open(state.tikTokLink || "");
                  } catch (error) {
                    showError(t(`${translationPath}this-link-is-not-valid`));
                  }
                }}
                className="googlemaps-bbt "
              >
                <span className="p-1 youtube-wraper">
                  <span className="mdi mdi-web" />
                </span>
              </Button>
            </>
          }
          helperText={t`${translationPath}please-enter-valid-tikTok-link-url`}
          error={
            state?.tikTokLink !== "" ? checkIsValidValues("tikTokLink") : false
          }
          isWithError
          isSubmitted={isSubmitted}
        />
      </div>
      <div className={`${className || "px-2 w-50 "}`}>
        <Inputs
          isAttachedInput
          value={state.youtubeLink || ""}
          idRef={"YouTubeLinkRef"}
          labelValue={"youTube-link"}
          type={"text"}
          onInputChanged={(e) => {
            let { value } = e.target;
            setState({ id: "youtubeLink", value: value === "" ? null : value });
          }}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          endAdornment={
            <>
              <Button
                disabled={
                  state.tikTokLink
                    ? state.youtubeLink !== ""
                      ? checkIsValidValues("youtubeLink")
                      : true
                    : true
                }
                onClick={() => {
                  try {
                    window.open(state.youtubeLink || "");
                  } catch (error) {
                    showError(t(`${translationPath}this-link-is-not-valid`));
                  }
                }}
                className="googlemaps-bbt "
              >
                <span className="p-1 youtube-wraper">
                  <span className="mdi mdi-web" />
                </span>
              </Button>
            </>
          }
          helperText={t`${translationPath}please-enter-valid-youtube-link-url`}
          error={
            state?.youtubeLink !== ""
              ? checkIsValidValues("youtubeLink")
              : false
          }
          isWithError
          isSubmitted={isSubmitted}
        />
      </div>
      <div className={`${className || "px-2 w-50 "}`}>
        <Inputs
          isAttachedInput
          value={state.xLink || ""}
          idRef={"XRef"}
          labelValue={"x-link"}
          type={"text"}
          onInputChanged={(e) => {
            let { value } = e.target;
            setState({ id: "xLink", value: value === "" ? null : value });
          }}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          endAdornment={
            <>
              <Button
                disabled={
                  state.xLink
                    ? state.xLink !== ""
                      ? checkIsValidValues("xLink")
                      : true
                    : true
                }
                onClick={() => {
                  try {
                    window.open(state.xLink || "");
                  } catch (error) {
                    showError(t(`${translationPath}this-link-is-not-valid`));
                  }
                }}
                className="googlemaps-bbt "
              >
                <span className="p-1 youtube-wraper">
                  <span className="mdi mdi-web" />
                </span>
              </Button>
            </>
          }
          helperText={t`${translationPath}please-enter-valid-x-link-url`}
          error={state?.xLink !== "" ? checkIsValidValues("xLink") : false}
          isWithError
          isSubmitted={isSubmitted}
        />
      </div>
    </>
  );
};

SocialMediaLinkManagementComponents.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  state: PropTypes.objectOf(PropTypes.any).isRequired,
  setState: PropTypes.func.isRequired,
  className: PropTypes.string,
  editForm: PropTypes.bool,
  setIsHaveValues: PropTypes.func.isRequired,
  setIsValid: PropTypes.func.isRequired,
};
SocialMediaLinkManagementComponents.defaultProps = {
  editForm: false,
};
