import { ContactFormTypeEnum } from '../../Enums/ContactFormTypeEnum';

export const contactFields = [
  {
    label: 'first_name',
    name: 'firstName',
    type: 'text',
    required: true,
    for: ContactFormTypeEnum.INDIVIDUAL.value,
  },
  {
    label: 'last_name',
    name: 'lastName',
    type: 'text',
    required: true,
    for: ContactFormTypeEnum.INDIVIDUAL.value,
  },
  {
    label: 'email',
    name: 'email',
    type: 'email',
    required: true,
  },
  {
    label: 'phone',
    name: 'phone',
    type: 'tel',
    required: true,
    for: ContactFormTypeEnum.INDIVIDUAL.value,
  },
  {
    label: 'company_name',
    name: 'companyName',
    type: 'text',
    required: true,
    for: ContactFormTypeEnum.CORPORATE.value,
  },
  {
    label: 'landline',
    name: 'landline',
    type: 'tel',
    required: true,
    for: ContactFormTypeEnum.CORPORATE.value,
  },
];
