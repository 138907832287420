import React, {  useState  , useRef } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { GetAllDevelopersContactsAPI   , } from '../../../../../../../Services';
import { AutocompleteComponent} from '../../../../../../../Components';

export const DeveloperComponent = ({
    parentTranslationPath,
    translationPath,
    onStateChanged,
    state,
}) => {
    const { t } = useTranslation([parentTranslationPath, 'Shared']);
    const searchTimer = useRef(null);
    const [contacts, setContacts] = useState([]);
    const [timer, setTimer] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const GetAllDevelopersContacts = async (search) => {
        setIsLoading(true);
        const res = await GetAllDevelopersContactsAPI({ pageIndex: 1, pageSize: 50  , search :( search || '')});
        if (!(res && res.status && res.status !== 200)) {
            let developersList = [] ; 
            res.result.map((element) => {
                const developerContact  = element?.contact && JSON.parse(element.contact) ; 
                  developersList.push({
                       developerId: element.contactsId,
                       developerName: developerContact?.contact?.company_name,
                    });  
                });
         setContacts(({
         result: developersList ||  [], 
            totalCount: res?.totalCount || 0 ,
          }));

        }
        else setContacts(({
            result: [],
            totalCount: 0,
        }));
        setIsLoading(false);
    };


    return (
        <>
            <div className='dialog-content-item'>
                <AutocompleteComponent
                    idRef='developerIdRef'
                    labelValue={t(`${translationPath}developer`)}
                    selectedValues={state.rotationSchemaDeveloperIds}
                    data={contacts.result || []}
                    multiple
                    displayLabel={(option) => (option && option.developerName) || ''}
                    chipsLabel={(option) => (option && option.developerName) || ''}
                    withoutSearchButton
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    withLoader
                    isLoading={isLoading}
                    onChange={(event, newValue) => {
                        const localNewValue = {
                            id: 'rotationSchemaDeveloperIds',
                            value: [
                                ...newValue,
                            ],
                        };
                        onStateChanged(localNewValue);
                    }}
                    getOptionSelected={(option) =>
                        (state &&
                          state.rotationSchemaDeveloperIds &&
                          state.rotationSchemaDeveloperIds.findIndex(
                            (item) => item?.developerId === option?.developerId
                          ) !== -1) ||
                        false
                      }
                     filterSelectedOptions
                    filterOptions={(options) => {
                        const isFind = (id) => state.rotationSchemaDeveloperIds.findIndex((w) => w.developerId === id) === -1;
                        return options.filter((w) => isFind(w.developerId));
                    }}
                    onOpen={() => {
                        if (contacts.result  && contacts.result?.length === 0) GetAllDevelopersContacts();
                      }}
                    onInputKeyUp={(e) => {
                        const searchValue = e.target.value;
                        if (searchTimer.current) clearTimeout(searchTimer.current);
                        searchTimer.current = setTimeout(() => {
                            GetAllDevelopersContacts(searchValue);
                        }, 700);
                      }}
        
                />
            </div>
        </>
    );
};
const convertJsonValueShape = PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.number,
    PropTypes.number,
    PropTypes.number,
    PropTypes.number,
    PropTypes.number,
    PropTypes.number,
    PropTypes.number,
    PropTypes.number,
    PropTypes.number,
    PropTypes.number,
    PropTypes.number,
    PropTypes.number,
    PropTypes.number,
    PropTypes.number,
    PropTypes.number,
    PropTypes.number,
    PropTypes.array,
    PropTypes.array,
    PropTypes.array,
]);
DeveloperComponent.propTypes = {
    parentTranslationPath: PropTypes.string.isRequired,
    state: PropTypes.objectOf(convertJsonValueShape).isRequired,
    translationPath: PropTypes.string.isRequired,
    onStateChanged: PropTypes.func.isRequired,
};
