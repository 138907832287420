import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { UnitsAdvanceSearchTest } from "../../../../../../../Services";
import {
  TableFilterOperatorsEnum,
  TableActions,
  UnitsOperationTypeEnum,
} from "../../../../../../../Enums";
import { bottomBoxComponentUpdate } from "../../../../../../../Helper";
import { UnitMapper } from "../../../../../../../Views/Home/UnitsSalesView/UnitMapper/UnitMapper";
import { UnitsSalesTableComponent } from "../../../../../../../Views/Home/UnitsSalesView/UnitsSalesUtilities/UnitsSalesTableComponent/UnitsSalesTableComponent";
import Button from "@material-ui/core/Button";
import { ReassignListingAgentDialog } from "../../../Dialogs";
import {
  RadiosGroupComponent,
  PaginationComponent,
  SelectComponet,
  Spinner,
} from "../../../../../../../Components";
import { ButtonBase } from "@material-ui/core";

export const UnitListingView = ({
  parentTranslationPath,
  translationPath,
  deActiveUserId,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const unitFilterKeys = {
    SaleListingAgent: "listing_agent.id",
    RentListingAgent: "rent_listing_agent.id",
  };
  const ListingAgentTypes = {
    SaleListing: { key: 1, label: t(`${translationPath}SaleListing`) },
    RentListing: { key: 2, label: t(`${translationPath}RentListing`) },
  };

  const [data, setData] = useState({
    result: [],
    totalCount: 0,
  });
  const [filter, setFilter] = useState({
    pageSize: 25,
    pageIndex: 0,
  });
  const [filterSearchDto, setFilterSearchDto] = useState(null);
  const [selectedOrderBy, setSelectedOrderBy] = useState({
    filterBy: "createdOn",
    orderBy: 2,
  });
  const [orderBy, setOrderBy] = useState({
    filterBy: "createdOn",
    orderBy: 2,
  });
  const [unitsTableFilter, setUnitsTableFilter] = useState(null);
  const [isClearFiltersClicked, setIsClearFiltersClicked] = useState(false);
  const [checkedRows, setCheckedRows] = useState([]);
  const [listingAgentType, setListingAgentType] = useState(1);
  const [isReassignDialogOpen, setIsReassignDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getUnitsData = async () => {
    setIsLoading(true);

    const localFilterDto = filterSearchDto || {};

    if (unitsTableFilter) {
      Object.values(unitsTableFilter)
        .filter((item) => item.searchableKey || item.displayPath)
        .map((item) => {
          if (localFilterDto[item.searchableKey || item.displayPath]) {
            localFilterDto[item.searchableKey || item.displayPath] = [
              {
                searchType: item.operator,
                value: item.value,
              },
            ];
          } else if (item.value) {
            localFilterDto[item.searchableKey || item.displayPath] = [
              {
                searchType: item.operator,
                value: item.value,
              },
            ];
          } else if (
            !item.value &&
            (item.operator === TableFilterOperatorsEnum.isNotBlank.key ||
              item.operator === TableFilterOperatorsEnum.isBlank.key)
          ) {
            localFilterDto[item.searchableKey || item.displayPath] = [
              {
                searchType: item.operator,
                value: null,
              },
            ];
          }
          return undefined;
        });
    }

    const body = {
      ...filter,
      ...orderBy,
      pageIndex: filter.pageIndex + 1,
      criteria: localFilterDto,
      OperationType:
        listingAgentType == ListingAgentTypes.SaleListing.key
          ? "Sale"
          : "Lease",
    };

    const res = await UnitsAdvanceSearchTest(filter, body);
    if (!(res && res.status && res.status !== 200) && res && res.result) {
      const result = ((res && res.result) || []).map(
        (item) =>
          item.unitJson && UnitMapper(item, JSON.parse(item.unitJson).unit)
      );
      setData({
        result: result,
        totalCount: (res && res.totalCount) || 0,
      });
    } else {
      setData({
        result: [],
        totalCount: 0,
      });
    }
    setIsLoading(false);
  };

  const tableActionClicked = useCallback((actionEnum, item) => {
    if (actionEnum === TableActions.openFile.key ) {
      if(item.operationType == UnitsOperationTypeEnum.sale.key 
        || item.operationType == UnitsOperationTypeEnum.rentAndSale.key) window.open(
        `/home/units-sales/unit-profile-edit?formType=${item.unitTypeId}&id=${item.id}&operationType=${item.operationType}`,
        "_blank"
      );
      else if(item.operationType == UnitsOperationTypeEnum.rent.key) window.open(
        `/home/units-lease/unit-profile-edit?formType=${item.unitTypeId}&id=${item.id}&operationType=${item.operationType}`,
        "_blank"
      );
    }
  }, []);

  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
  };
  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
  };

  const onFilterValuesChanged = (newValue) => {
    setUnitsTableFilter(newValue);
  };

  const onSelectClicked = useCallback(
    (row, rowIndex) => {
      setCheckedRows((items) => {
        const index = items.findIndex((item) => item.id === row.id);
        if (index !== -1) items.splice(index, 1);
        else items.push(row);
        return [...items];
      });
    },
    [checkedRows]
  );

  const orderBySubmitted = (event) => {
    event.preventDefault();
    if (!selectedOrderBy.filterBy || !selectedOrderBy.orderBy) {
      if (orderBy.filterBy || orderBy.orderBy) setOrderBy({});
      return;
    }
    setOrderBy({
      filterBy: selectedOrderBy.filterBy,
      orderBy: selectedOrderBy.orderBy,
    });
  };

  const filterByChanged = (value) => {
    setSelectedOrderBy((item) => ({ ...item, filterBy: value }));
  };

  const orderByChanged = (value) => {
    setSelectedOrderBy((item) => ({ ...item, orderBy: value }));
  };

  const getIsSelected = useCallback(
    (row) =>
      checkedRows && checkedRows.findIndex((item) => item.id === row.id) !== -1,
    [checkedRows]
  );

  const listingFilterChangeHandler = (listingValue, filterParam) => {
    const localFilterSearchDto = { ...(filterParam || filterSearchDto || {}) };

    if (listingValue == ListingAgentTypes.SaleListing.key) {
      delete localFilterSearchDto[unitFilterKeys.RentListingAgent];
      setFilterSearchDto({
        ...localFilterSearchDto,
        [unitFilterKeys.SaleListingAgent]: [
          {
            value: deActiveUserId,
            searchType: TableFilterOperatorsEnum.equal.key,
          },
        ],
      });
    } else if (listingValue == ListingAgentTypes.RentListing.key) {
      delete localFilterSearchDto[unitFilterKeys.SaleListingAgent];
      setFilterSearchDto({
        ...localFilterSearchDto,
        [unitFilterKeys.RentListingAgent]: [
          {
            value: deActiveUserId,
            searchType: TableFilterOperatorsEnum.equal.key,
          },
        ],
      });
    }
  };

  const onClearedAllFiltersClick = useCallback(() => {
    setIsClearFiltersClicked(true);
    setSelectedOrderBy((item) => ({ ...item, filterBy: null, orderBy: null }));
    setOrderBy({ filterBy: 'createdOn', orderBy: 2 });
    setUnitsTableFilter(null);
    listingFilterChangeHandler(listingAgentType, {});
  });

  useEffect(() => {
    if (deActiveUserId) listingFilterChangeHandler(listingAgentType);
  }, [listingAgentType, deActiveUserId]);

  useEffect(() => {
  if (filterSearchDto) getUnitsData();
  }, [filterSearchDto, filter, orderBy, unitsTableFilter]);

  useEffect(() => {
    bottomBoxComponentUpdate(
      <PaginationComponent
        pageIndex={filter.pageIndex}
        pageSize={filter.pageSize}
        totalCount={data.totalCount}
        onPageIndexChanged={onPageIndexChanged}
        onPageSizeChanged={onPageSizeChanged}
      />
    );
  });
  useEffect(
    () => () => {
      bottomBoxComponentUpdate(null);
    },
    []
  );
  return (
    <div className="w-100">
    <Spinner isActive={isLoading} isAbsolute />
      <div className="d-flex-h-between pt-3 users-manage-view">
        <div className="d-flex-default">
          <Button
            className="btns theme-propx outlined px-3 ml-4"
            id="btn-save"
            onClick={() => {
              if (checkedRows && checkedRows.length > 0)
                setIsReassignDialogOpen(true);
            }}
          >
            <span className="icons b-icon i-reverse-right mr-1"></span>
            <span>{t(`${translationPath}Reassign-listing-agent`)}</span>
          </Button>
          <Button
            className="btns theme-propx solid px-3 mx-4"
            id="btn-save"
            onClick={() => {
              if (checkedRows && checkedRows.length == 0)
                setIsReassignDialogOpen(true);
            }}
          >
            <span>{t(`${translationPath}Complete-Reassign`)}</span>
          </Button>
        </div>
        <RadiosGroupComponent
          data={Object.values(ListingAgentTypes)}
          labelClasses="Requierd-Color"
          valueInput="key"
          wrapperClasses="pr-4"
          labelInput="label"
          value={listingAgentType}
          onSelectedRadioChanged={(e, newValue) => {
            setListingAgentType(+newValue);
            setCheckedRows([]);
            setData({
              result: [],
              totalCount: 0,
            });
          }}
          name="radioGroups"
          titleClasses="texts gray-primary-bold"
        />
      </div>
      <div className="propx-view-container sm m-3">
        <div className="d-flex px-2">
          <div className="d-flex px-2">
            <span className="px-2 d-flex">
              <span className="texts-large mt-1">
                {t(`${translationPath}order-by`)}:
              </span>
              <div className="px-2">
                <SelectComponet
                  idRef="filterByRef"
                  data={[
                    { id: "createdOn", filterBy: "created-on" },
                    { id: "updateOn", filterBy: "last-updated" },
                    {
                      id: "LastUpdatedOwner",
                      filterBy: "last-updated-owner",
                    },
                  ]}
                  value={selectedOrderBy.filterBy}
                  onSelectChanged={filterByChanged}
                  wrapperClasses="mb-3"
                  isRequired
                  valueInput="id"
                  textInput="filterBy"
                  emptyItem={{
                    value: null,
                    text: "select-filter-by",
                    isDisabled: false,
                  }}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  translationPathForData={translationPath}
                />
              </div>
              <div className="px-2">
                <SelectComponet
                  idRef="orderByRef"
                  data={[
                    { id: 1, orderBy: "ascending" },
                    { id: 2, orderBy: "descending" },
                  ]}
                  value={selectedOrderBy.orderBy}
                  onSelectChanged={orderByChanged}
                  wrapperClasses="mb-3"
                  isRequired
                  valueInput="id"
                  textInput="orderBy"
                  emptyItem={{
                    value: null,
                    text: "select-sort-by",
                    isDisabled: false,
                  }}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  translationPathForData={translationPath}
                />
              </div>
              <div className="mb-2">
                <ButtonBase
                  className="btns theme-propx outlined-colored"
                  onClick={orderBySubmitted}
                  id="action_apply"
                  disabled={
                    !selectedOrderBy.filterBy || !selectedOrderBy.orderBy
                  }
                >
                  <span>{t(`${translationPath}apply`)}</span>
                </ButtonBase>
              </div>
            </span>
          </div>

          <ButtonBase
            onClick={onClearedAllFiltersClick}
            id="onClearedAllFiltersref"
            className="btns theme-solid bg-danger clear-all-btn max-height"
          >
            <span className="mdi mdi-filter-remove m-1" />
            {t(`${translationPath}clear-filters`)}
          </ButtonBase>
        </div>
        <UnitsSalesTableComponent
          detailsUnitsList={data}
          tableActionClicked={tableActionClicked}
          onPageIndexChanged={onPageIndexChanged}
          onPageSizeChanged={onPageSizeChanged}
          unitsTableFilter={unitsTableFilter}
          setIsClearFiltersClicked={setIsClearFiltersClicked}
          isClearFiltersClicked={isClearFiltersClicked}
          onFilterValuesChanged={onFilterValuesChanged}
          filter={filter}
          parentTranslationPath={parentTranslationPath}
          onSelectClicked={onSelectClicked}
          setCheckedCards={setCheckedRows}
          checkedCardsIds={checkedRows}
          getIsSelected={getIsSelected}
          isDeactivationView
          setOrderBy={setOrderBy}
          displyOpenFileButton
        />
      </div>
      {isReassignDialogOpen && (
        <ReassignListingAgentDialog
          isDialogOpen={isReassignDialogOpen}
          onSave={() => {
            setIsReassignDialogOpen(false);
            getUnitsData();
            setCheckedRows([]);
          }}
          onClose={() => {
            setIsReassignDialogOpen(false);
          }}
          unitReassignProps={{
            unitIds: checkedRows && checkedRows.map((item) => item.id),
            deActiveUserId,
            isSaleListing:
              listingAgentType == ListingAgentTypes.SaleListing.key,
            isCompleteAction: checkedRows && checkedRows.length == 0,
          }}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
        />
      )}
    </div>
  );
};
