import { InquiryAssignmentTrack } from '../../../../../../Layouts';
import {
  ListingShortageInformationComponent,
} from '../Sections';
import { ListingShortagePermissions } from '../../../../../../Permissions' ; 
import { AssignInquiryToListingShortage } from './AssignInquiryToListingShortage';



export const ListingShortageVerticalTabsData = [
  {
    label: 'Inquiries-information',
    component: ListingShortageInformationComponent,
    permissionsList: Object.values(ListingShortagePermissions),
    permissionsId: ListingShortagePermissions.ViewInquiry.permissionsId,
  },
  {
    label: 'Assigned-Units',
    component: AssignInquiryToListingShortage,
    permissionsList: Object.values(ListingShortagePermissions),
    permissionsId: ListingShortagePermissions.ViewInquiry.permissionsId,
  },
  {
    label: 'inquiry_assignment',
    component: InquiryAssignmentTrack,
    permissionsList: Object.values(ListingShortagePermissions),
    permissionsId: ListingShortagePermissions.ViewInquiryAssignmentTracker.permissionsId,
  },
];
