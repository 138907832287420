import React, { useState } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  Inputs,
  AutocompleteComponent,
  DatePickerComponent,
} from "../../../../../../Components";
import { ActivityFilesUploader } from "../../../../../../Components/UploaderActivitieFileComponent/ActivityFilesUploader";
import { Edit } from "@material-ui/icons";

export const AuditAndComplianceManagementComponents = ({
  translationPath,
  parentTranslationPath,
  state,
  setState,
  selected,
  className,
  editForm,
  onChangeHandler,
}) => {
  const { t } = useTranslation(parentTranslationPath, "Shared");

  return (
    <>
      {!editForm && (
        <div className={!editForm ? "part1 mt-2 mb-2" : ""}>
          <div className={`${className || "px-2 w-50 "}`}>
            <AutocompleteComponent
              key={`auditAndCompliance`}
              idRef={`auditAndComplianceRef`}
              multiple={false}
              selectedValues={selected.auditProcessComplete}
              inputPlaceholder={t("select-audit-process-complete")}
              data={[
                { key: true, name: "yes" },
                { key: false, name: "no" },
              ]}
              inputClasses="inputs theme-form-builder"
              displayLabel={(option) =>
                (option && t(`${translationPath}${option.name}`)) || ""
              }
              withoutSearchButton
              labelValue={"audit-process-complete"}
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
              onChange={(e, newValue) => {
                onChangeHandler(
                  "isAuditProcessComplete",
                  newValue === null ? false : newValue.key,
                  "auditProcessComplete",
                  newValue
                );
              }}
            />
          </div>
          <div className={`${className || "px-2 w-50 "}`}>
            <DatePickerComponent
              idRef={"auditDateRef"}
              labelValue={"select-audit-date"}
              value={
                state &&
                state.auditDate &&
                moment(state.auditDate).isValid() &&
                moment(state.auditDate).format("YYYY-MM-DDTHH:mm:ss")
              }
              onDateChanged={(newValue) => {
                onChangeHandler(
                  "auditDate",
                  moment(newValue).format("YYYY-MM-DDTHH:mm:ss") || newValue
                );
              }}
              placeholder={"select-audit-date"}
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
            />
          </div>
          <div className={`${className || "px-2 w-50 "}`}>
            <Inputs
              isAttachedInput
              idRef={`AuditRemarks${1}`}
              labelValue={"audit-remarks"}
              multiline
              rows={3}
              value={state.auditRemarks || ""}
              onInputChanged={(e) => {
                const { value } = e.target;
                onChangeHandler("auditRemarks", value);
              }}
              inputPlaceholder={"enter-audit-remarks"}
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
            />
          </div>
        </div>
      )}
      {editForm && (
        <>
          <div className={`${className || "px-2 w-50 "}`}>
            <AutocompleteComponent
              key={`auditAndCompliance`}
              idRef={`auditAndComplianceRef`}
              multiple={false}
              selectedValues={selected.auditProcessComplete}
              inputPlaceholder={t("select-audit-process-complete")}
              data={[
                { key: true, name: "yes" },
                { key: false, name: "no" },
              ]}
              inputClasses="inputs theme-form-builder"
              displayLabel={(option) =>
                (option && t(`${translationPath}${option.name}`)) || ""
              }
              withoutSearchButton
              labelValue={"audit-process-complete"}
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
              onChange={(e, newValue) => {
                onChangeHandler(
                  "isAuditProcessComplete",
                  newValue === null ? false : newValue.key,
                  "auditProcessComplete",
                  newValue
                );
              }}
            />
          </div>
          <div className={`${className || "px-2 w-50 "}`}>
            <DatePickerComponent
              idRef={"auditDateRef"}
              labelValue={"select-audit-date"}
              value={
                state &&
                state.auditDate &&
                moment(state.auditDate).isValid() &&
                moment(state.auditDate).format("YYYY-MM-DDTHH:mm:ss")
              }
              onDateChanged={(newValue) => {
                onChangeHandler(
                  "auditDate",
                  moment(newValue).format("YYYY-MM-DDTHH:mm:ss") || newValue
                );
              }}
              placeholder={"select-audit-date"}
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
            />
          </div>
          <div className={`${className || "px-2 w-50 "}`}>
            <Inputs
              isAttachedInput
              idRef={`AuditRemarks${1}`}
              labelValue={"audit-remarks"}
              multiline
              rows={3}
              value={state.auditRemarks || ""}
              onInputChanged={(e) => {
                const { value } = e.target;
                onChangeHandler("auditRemarks", value);
              }}
              inputPlaceholder={"enter-audit-remarks"}
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
            />
          </div>
        </>
      )}
      <div className="w-100">
        <ActivityFilesUploader
          state={state}
          setState={setState}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          multiple={true}
          accept={"/*"}
        />
      </div>
    </>
  );
};

AuditAndComplianceManagementComponents.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  state: PropTypes.objectOf(PropTypes.any).isRequired,
  setState: PropTypes.func.isRequired,
  selected: PropTypes.objectOf(PropTypes.any).isRequired,
  className: PropTypes.string,
  editForm: PropTypes.bool,
};
AuditAndComplianceManagementComponents.defaultProps = {
  editForm: false,
};
