import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    container_DocumentsContactCard: {
      padding: "20px 16px",
      width: "311px",
      display: "flex",
      flexDirection: "column",
      gap: "5px",
    },
    ImageSection: {
      display: "flex",
      flexDirection: "row",
      gap: "25px",
      alignItems: "flex-start",
      position: "relative",
    },
    DetailsSection: {
      display: "flex",
      flexDirection: "column",
      gap: "5px",
    },
    conatainer_MoreIcon: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      gap: "2px",
      position: "absolute",
      top: "0px",
      right: "10px",
    },
    ImageWrapper: {
      marginTop: "20px",
    },
    Title_Container: {
      display: "flex",
      flexDirection: "column",
      gap: "0px",
      alignItems: "center",
      justifyContent: "center",
    },
    containerType: {
      display: "flex",
      flexDirection: "row",
      gap: "5px",
    },
    divider: { borderColor: theme.palette.border.secondary },
    DateItem: {
      display: "flex",
      flexDirection: "row",
      gap:'12px',
      marginTop:'10px'
    },
  };
});
