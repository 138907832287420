import React, {
    useState,
    useEffect,
    useCallback,
  } from "react";
  import { useSSR, useTranslation } from "react-i18next";
  import {
    Spinner,
    Tables
  } from "../../../../../../Components";
  import {
    showError,
    showSuccess,
    returnPropsByPermissions 
  } from "../../../../../../Helper";
  import { TableActions } from '../../../../../../Enums'; 
  import { DeleteBranchLocationAPI ,  GetBranchLocationByIdAPI  } from '../../../../../../Services'; 
  import { MessageConfirmDialog } from "../../../../../../SharedComponents";
  import { BranchLocationManagementDialog } from './BranchLocationManagementDialog' ; 
  import { DevelopersPermissions  } from '../../../../../../Permissions' ; 

  
  export const BranchesLocationTable = ({
    translationPath,
    parentTranslationPath,
    data ,
    filter ,
    setFilter ,
    reloadData , 
  }) => {

    const [isLoading, setIsLoading] = useState({ info: false , delete: false  });
    const { t } = useTranslation(parentTranslationPath, "Shared");  
    const [ activeItem ,  setActiveItem ] = useState(null) ; 
    const [isOpeneEditDialog , setIsOpeneEditDialog ] = useState(false);
    const [isMissingInfoMSGOpen , setIsMissingInfoMSGOpen] = useState(false) ; 
    const [ branchesLocationValue ,  setBranchesLocationValue] = useState(null) ; 


    const DeleteHandler = useCallback(async () => {  
      setIsLoading((item) => ({ ...item, delete: true }));

        const res = await DeleteBranchLocationAPI(
          activeItem && activeItem.branchesLocationId 
        );
        if (res && res.ErrorId) {
          showError(t(`${translationPath}failed-to-delete-branches-location`));
        } else if (!(res && res.status && res.status !== 200)) {
          setIsMissingInfoMSGOpen(false) ; 
          showSuccess(t(`${translationPath}branches-location-deleted-sucessfuully`));
          reloadData() ;
       
        } else showError(t(`${translationPath}failed-to-delete-branches-location`));
        setIsLoading((item) => ({ ...item, delete: false }));

      },[activeItem]);


    const tableActionClicked = useCallback(
        (actionEnum, item, focusedRow, event) => {
          event.stopPropagation();
          event.preventDefault();
          setActiveItem(item);
          if (actionEnum === TableActions.deleteText.key) {
            setIsMissingInfoMSGOpen(true);
          } else if (actionEnum === TableActions.editText.key)
            {
              setIsOpeneEditDialog(true) ; 
            
           }
        },
        []
      );

      const onPageIndexChanged = (pageIndex) => {
        setFilter((item) => ({ ...item, pageIndex }));
      };
      const onPageSizeChanged = (pageSize) => {
        setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
      };

      const GetBranchLocationById = useCallback(async () => {
        setIsLoading((item) => ({ ...item, info: true }));
        const res = await GetBranchLocationByIdAPI(
          activeItem && activeItem.branchesLocationId
        );
        if (res && res.ErrorId) {
          showError(t(`${translationPath}failed-to-get`));
        } else if (!(res && res.status && res.status !== 200)) {
          setBranchesLocationValue(res) ; 
        }
        setIsLoading((item) => ({ ...item, info: false }));
      }, [activeItem]);

      const getActionTableWithPermissions = () => {
        const list = [];
        if(returnPropsByPermissions(DevelopersPermissions.EditBranchesLocation.permissionsId))
        list.push({
          enum: TableActions.editText.key,
          title: t(`${translationPath}Shared:edit`),
        });
        list.push({
          enum: TableActions.delete.key,
          title: t(`${translationPath}Shared:delete`),
        });
        return list;
      };


      useEffect(() => {
        if (activeItem) {
          GetBranchLocationById(activeItem.branchesLocationId);
        }
      }, [activeItem]);
      
  
    return (
      <div className="d-flex-column mt-2">
        <Spinner isActive={isLoading.info || isLoading.delete}  isAbsolute/>
        <Tables
            data={data ? data.result : []}
            headerData={[
              {
                id: 1,
                label: t(`${translationPath}branch-name`),
                input: 'branchName',
              },
              {
                id: 2,
                label: t(`${translationPath}country`),
                component: (item) => (
                  <div>
                    {item && item.country  }
                  </div>
                ),
              },
              {
                id: 3,
                label: t(`${translationPath}city`),
                component: (item) => (
                  <div>
                    {item && item.city }
                  </div>
                ),
              },
              {
                id: 4,
                label: t(`${translationPath}District`),
                component: (item) => (
                  <div>
                    {item && item.district}
                  </div>
                ),
              },
              {
                id: 6,
                label: t(`${translationPath}community`),
                input: 'community',
                isDate : false , 
                component: (item) => (
                  <div>
                    {item && item.community}
                  </div>
                ),

              },
              {
                id: 7,
                label: t(`${translationPath}subCommunity`),
                input: 'subCommunity',
                isDate : false , 
                component: (item) => (
                  <div>
                    {item && item.subCommunity }
                  </div>
                ),

              },
              {
                id: 8,
                label: t(`${translationPath}street`),
                isDate : false , 
                component: (item) => (
                  <div>
                    {item && item.street}
                  </div>
                ),

              },
              {
                id: 9,
                label: t(`${translationPath}building-name`),
                isDate : false , 
                component: (item) => (
                  <div>
                    {item && item.buildingName}
                  </div>
                ),

              },
              {
                id: 10 ,
                label: t(`${translationPath}building-number`),
                isDate : false , 
                component: (item) => (
                  <div>
                    {item && item.buildingNumber}
                  </div>
                ),

              },
              {
                id: 11,
                label: t(`${translationPath}floor-number`),
                isDate : false , 
                component: (item) => (
                  <div>
                    {item && item.floorNumber}
                  </div>
                ),

              },
              {
                id: 12 ,
                label: t(`${translationPath}office-number`),
                isDate : false , 
                component: (item) => (
                  <div>
                    {item && item.officeNumber}
                  </div>
                ),

              },{
                id: 13,
                label: t(`${translationPath}postal-zip-code`),
                isDate : false , 
                component: (item) => (
                  <div>
                    {item && item.postalCode}
                  </div>
                ),

              },
              {
                id: 14,
                label: t(`${translationPath}po-box`),
                isDate : false , 
                component: (item) => (
                  <div>
                    {item && item.poBox}
                  </div>
                ),

              },
            ]}
            onPageIndexChanged={onPageIndexChanged}
            onPageSizeChanged={onPageSizeChanged}
            defaultActions={getActionTableWithPermissions()}
            actionsOptions={{
              onActionClicked: tableActionClicked,
            }}
            activePage={filter.pageIndex}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            itemsPerPage={filter.pageSize}
            totalItems={data ? data.totalCount : 0}
          />
        {isMissingInfoMSGOpen  && (
        <MessageConfirmDialog
          isDialogOpen={isMissingInfoMSGOpen}
          theme="warning"
          content={{
            heading: "delete-branch-location",
            body :(t(`${translationPath}are-you-sure-you-want-to-delete-branch-location`) + ' ' + ((activeItem && activeItem.branchName && t(`${translationPath}branch`)) || '') + `  ${(activeItem && activeItem.branchesLocationId )||''}`)

          }}
          confirmButton={{
            label: (t(`${translationPath}confirm`)) , 
            handler: () => DeleteHandler(),
          }}
          cancelButton={{
            label  :(t(`${translationPath}discard`)),
            handler: () => {
              setIsMissingInfoMSGOpen(false);
            },
          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}

        />
      )}
      { 
         isOpeneEditDialog && activeItem && 
         <BranchLocationManagementDialog
         parentTranslationPath ={parentTranslationPath}
         translationPath={translationPath}
         isOpen={isOpeneEditDialog}
         onClose={()=> setIsOpeneEditDialog(false)}
         onSave={(edit)=> {
          if(edit)
          {
            setIsOpeneEditDialog(false) ; 
            setActiveItem(null) ; 
            reloadData() ; 
          }
         }}
         branchesLocationValue={branchesLocationValue}  
         />
         
      }
      </div>
    );
  };
  