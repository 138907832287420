import React, { Children, useEffect, useMemo, useState } from 'react';
import AgentCard from './components/AgentCard';
import {
  bottomBoxComponentUpdate,
  generateUniqueKey,
  showError,
} from '../../../Helper';
import {
  GetAgentsCourseQualifications,
  GetAllBranches,
  GetAllLMSCourses,
  GetTeam,
} from '../../../Services';
import {
  AutocompleteComponent,
  Inputs,
  PaginationComponent,
  SelectComponet,
  Spinner,
} from '../../../Components';
import { useDebouncedAction } from '../../../Hooks/DebouncedAction';
import { AgentCourseQualificationsLocalePath } from './i18n/AgentCourseQualificationsLocale';
import { ButtonBase } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { LearningHubLocalePath } from '../../../Views/Home/LearningHub/i18n/LearningHub';
import { isEqual } from 'lodash';

const initialFilter = {
  lmsCourseId: null,
  agentName: null,
  branchId: null,
  teamId: null,
  staffCode: null,
  badgeFilter: null,
};
function AgentCourseQualifications({ propertyId, unitId }) {
  const { t } = useTranslation(AgentCourseQualificationsLocalePath);
  const [agents, setAgents] = useState({ result: [], totalCount: 0 });
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({ pageIndex: 1, pageSize: 25 });
  const [filter, setFilter] = useState(initialFilter);
  const [filterKey, setFilterKey] = useState(generateUniqueKey);
  const [message, setMessage] = useState(null);
  const noFilters = useMemo(() => isEqual(initialFilter, filter), [filter]);
  const [data, setData] = useState({
    courses: [],
    teams: [],
    branches: [],
  });
  const idObject = useMemo(() => {
    if (unitId) {
      return { unitId };
    } else {
      return { propertyId };
    }
  }, []);

  const getAgentsCourseQualifications = async () => {
    setLoading(true);
    try {
      const response = await GetAgentsCourseQualifications({
        ...idObject,
        ...pagination,
        ...filter,
      });
      if (response.message) {
        setMessage(response.message);
        return;
      }
      setAgents(response);
    } catch (error) {
      showError(t('something_went_wrong', { ns: LearningHubLocalePath }));
    } finally {
      setLoading(false);
    }
  };
  const setDataHandler = (field, value) => {
    setData((prevData) => ({ ...prevData, [field]: value }));
  };
  const getTeams = async (searchItem) => {
    const res = await GetTeam(1, 25, searchItem || '');

    if (!(res && res.status && res.status !== 200))
      setDataHandler('teams', res.result);
  };
  const getTeamsDebounced = useDebouncedAction(getTeams);
  const getCourses = async (search) => {
    try {
      const result = await GetAllLMSCourses({
        pageIndex: 1,
        pageSize: 100,
        search,
      });
      if (result) {
        setDataHandler('courses', result);
      }
    } catch (error) {
      showError(t('something_went_wrong', { ns: LearningHubLocalePath }));
    }
  };
  const getCoursesDebounced = useDebouncedAction(getCourses);

  const getAllBranches = async (value) => {
    const res = await GetAllBranches({
      pageSize: 25,
      pageIndex: 1,
      branchName: value,
    });
    if (!(res && res.status && res.status !== 200)) {
      setDataHandler('branches', res.result);
    }
  };
  const getAllBranchesDebounced = useDebouncedAction(getAllBranches);
  const setFilterDebounced = useDebouncedAction(setFilter);

  useEffect(() => {
    bottomBoxComponentUpdate(
      <PaginationComponent
        pageSize={pagination.pageSize}
        pageIndex={pagination.pageIndex - 1}
        onPageIndexChanged={(index) =>
          setPagination((prev) => ({ ...prev, pageIndex: index + 1 }))
        }
        onPageSizeChanged={(size) =>
          setPagination((prev) => ({ ...prev, pageSize: size }))
        }
        totalCount={agents.totalCount}
      />
    );
    return () => bottomBoxComponentUpdate(null);
  }, [pagination, agents.totalCount]);
  useEffect(() => {
    getAgentsCourseQualifications();
  }, [pagination]);

  useEffect(() => {
    setPagination((prev) => ({ pageIndex: 1, pageSize: prev.pageSize }));
  }, [filter]);

  useEffect(() => {
    getCourses();
    getAllBranches();
    getTeams();
  }, []);
  return (
    <div className='agent-course-qualifications'>
      <Spinner isActive={loading} isAbsolute />
      {message ? (
        <div className='flex fj-center fa-center pt-5'>
          <h1 className='text-center'>{t(message)}</h1>
        </div>
      ) : (
        <>
          <div className='flex'>
            <div
              className='agent-courses-qualifications-filters py-3 px-2 gap-1'
              key={filterKey}
            >
              <AutocompleteComponent
                data={data.courses}
                multiple={false}
                withoutSearchButton
                chipsLabel={(option) => option?.lmsCourseName}
                displayLabel={(option) => option?.lmsCourseName}
                labelValue={'courses'}
                parentTranslationPath={AgentCourseQualificationsLocalePath}
                onInputChange={(e) => getCoursesDebounced(e.target.value)}
                inputPlaceholder={'select_course'}
                onChange={(event, newValue) => {
                  setFilter((prev) => ({
                    ...prev,
                    lmsCourseId: newValue?.lmsCourseId,
                  }));
                }}
                wrapperClasses={'w-25'}
              />
              <AutocompleteComponent
                idRef='branchName-ToRef'
                labelValue='branches'
                multiple={false}
                data={data.branches}
                chipsLabel={(option) => option.branchName || ''}
                displayLabel={(option) => `${option.branchName || ''}`}
                withoutSearchButton
                inputPlaceholder={`select_branch`}
                onInputKeyUp={(e) => {
                  const { value } = e.target;
                  getAllBranchesDebounced(value);
                }}
                parentTranslationPath={AgentCourseQualificationsLocalePath}
                onChange={(event, newValue) => {
                  setFilter((prev) => ({
                    ...prev,
                    branchId: newValue?.branchId,
                  }));
                }}
                wrapperClasses={'w-25'}
              />
              <AutocompleteComponent
                idRef='teams-ToRef'
                labelValue='teams'
                data={data.teams || []}
                chipsLabel={(option) =>
                  option.teamsName || option.teamName || ''
                }
                displayLabel={(option) =>
                  `${option.teamsName || option.teamName || ''}`
                }
                multiple={false}
                withoutSearchButton
                inputPlaceholder={`select_team`}
                onInputKeyUp={(e) => {
                  const { value } = e.target;
                  getTeamsDebounced(value);
                }}
                parentTranslationPath={AgentCourseQualificationsLocalePath}
                onChange={(event, newValue) => {
                  setFilter((prev) => ({
                    ...prev,
                    teamId: newValue?.teamsId,
                  }));
                }}
                wrapperClasses={'w-25'}
              />
              <SelectComponet
                wrapperClasses={'w-25'}
                data={[
                  { id: 1, name: 'Community' },
                  { id: 2, name: 'Developer' },
                  { id: 3, name: 'Project' },
                ]}
                valueInput={'id'}
                textInput={'name'}
                labelValue={'badge'}
                value={filter.badgeFilter}
                onSelectChanged={(value) =>
                  setFilter((prev) => ({ ...prev, badgeFilter: value }))
                }
                emptyItem={{
                  value: null,
                  text: 'select_badge',
                }}
                parentTranslationPath={AgentCourseQualificationsLocalePath}
              />
              <Inputs
                labelValue={'agent_name'}
                inputPlaceholder={'agent_name'}
                wrapperClasses={'w-25 m-0'}
                onInputChanged={(e) => {
                  e.persist();
                  setFilterDebounced((prev) => ({
                    ...prev,
                    agentName: e.target.value,
                  }));
                }}
                parentTranslationPath={AgentCourseQualificationsLocalePath}
              />
              <Inputs
                labelValue={'staff_id'}
                inputPlaceholder={'staff_id'}
                type={'number'}
                wrapperClasses={'w-25 m-0'}
                onInputChanged={(e) => {
                  e.persist();
                  setFilterDebounced((prev) => ({
                    ...prev,
                    staffCode: e.target.value,
                  }));
                }}
                parentTranslationPath={AgentCourseQualificationsLocalePath}
              />
            </div>
            <div className='flex fa-center'>
              <ButtonBase
                className='btns theme-solid bg-danger clear-all-btn px-4'
                onClick={() => {
                  setFilterKey(generateUniqueKey());
                  setFilter(initialFilter);
                }}
                disabled={noFilters}
              >
                <span className='mdi mdi-filter-remove m-1' />
              </ButtonBase>
            </div>
          </div>
          <div className='agent-cards-container'>
            {Children.toArray(
              agents.result.map((agent, index) => (
                <AgentCard key={agent.staffCode + index} {...agent} />
              ))
            )}
            {agents.totalCount === 0 &&
              !loading &&
              (noFilters ? (
                <h1>{t('no_agent_found')}</h1>
              ) : (
                <h1>{t('no_matching_agents_found')}</h1>
              ))}
          </div>
        </>
      )}
    </div>
  );
}

export default AgentCourseQualifications;
