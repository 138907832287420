import React, { useCallback, useEffect, useReducer, useState } from "react";
import {
  DatePickerComponent,
  Inputs,
  PaginationComponent,
  PermissionsComponent,
  Spinner,
  Tables,
} from "../../../../../../Components";
import { useTranslation } from "react-i18next";
import { useTitle } from "../../../../../../Hooks";
import moment from "moment";
import { ButtonBase } from "@material-ui/core";
import {
  LeadsAdvanceSearchTest,
  GetSourceFileByContactId,
  CreateSourceFile,
} from "../../../../../../Services";
import {
  GetParams,
  GlobalHistory,
  bottomBoxComponentUpdate,
  showWarn,
} from "../../../../../../Helper";
import PropTypes from "prop-types";
import { showSuccess, showError } from "../../../../../../Helper";
import { ContactsPermissions } from "../../../../../../Permissions";
import { HistoryContactDetailsTable } from "./ContactProfileSourceFileUtilites/HistoryContactDetailsTable";
import ContactSourceDetailsCard from "./ContactProfileSourceFileUtilites/ContactSourceDetailsCard";

const translationPath = "";

export const ContactProfileSourceFileComponent = ({
  parentTranslationPath,
  isOpen,
}) => {
  const contactId = +GetParams("id");
  const { t } = useTranslation(parentTranslationPath);
  useTitle(t("contact-source"));
  const reducer = useCallback((state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [isFirstTimeFetch, setIsFirstTimeFetch] = useState(true);
  const [contactSourceCardData, setContactSourceCardData] = useState(false);
  const [leadArrayInfo, setLeadArrayInfo] = useState([]);
  const [dataFiled, setdataFiled] = useState([]);
  const initialFilter = {
    contactId: contactId,
    pageIndex: 1,
    pageSize: parseInt(localStorage.getItem("Pagination")) || 25,
    sourceFileName: null,
    sourceFileYear: null,
    communitySourceFile: null,
    propertySourceFile: null,
    mediaName: null,
    sourceBy: null,
    sourceFileDate: null,
    fromDate: null,
    toDate: null,
    createdby: null,
  };
  const [searchFilter, setSearchFilter] = useState(initialFilter);

  const [filter, setFilter] = useReducer(reducer, {
    contactId: +GetParams("id"),
    sourceFileName: "",
    sourceFileYear: "",
    communitySourceFile: "",
    propertySourceFile: "",
    sourceBy: "",
    sourceFileDate: "",
  });

  const [selected, setSelected] = useReducer(reducer, {
    sourceFileName: "",
    sourceFileYear: "",
    communitySourceFile: "",
    propertySourceFile: "",
    sourceBy: "",
    sourceFileDate: "",
  });

  const leadTableData = useCallback(async (leadId) => {
    setIsLoading(true);
    const isForLog = true;

    let body = {
      criteria: {
        contactId: [{ searchType: 1, value: leadId }],
      },
      filterBy: "createdOn",
      orderBy: 2,
    };

    const res = await LeadsAdvanceSearchTest(
      { pageIndex: 0, pageSize: 100, isForLog },
      body
    );
    if (!(res && res.status && res.status !== 200)) {
      if (res && res.totalCount > 0) {
        const leadsData = res.result.map((lead) => {
          const { leadJson, leadId } = lead;
          const { lead: leadDetails } = JSON.parse(leadJson);
          return { leadId, lead: leadDetails };
        });
        setLeadArrayInfo(leadsData);
      } else {
        setLeadArrayInfo(null);
      }
    }
    setIsLoading(false);
  });
  const cancelHandler = () => {
    const parentContact = localStorage.getItem("parentContact");
    if (GetParams("isAssociated") && parentContact) {
      const parsParentContact = JSON.parse(parentContact);
      parsParentContact.id &&
        parsParentContact.userTypeId({
          id: JSON.stringify(parsParentContact.id),
          userTypeId: JSON.stringify(parsParentContact.userTypeId),
        });
    }
    GlobalHistory.goBack();
  };

  const validateInputs = useCallback(() => {
    const selectedArray = Object.values(selected);
    return selectedArray.findIndex((item) => !item);
  }, [selected]);

  const saveHandler = useCallback(async () => {
    if (!validateInputs()) {
      showWarn(
        t(
          `${translationPath}fill-at-least-contact-file-name-to-complete-create-contact-file`
        )
      );
      return;
    }
    const res = await CreateSourceFile({
      body: filter,
    });
    if (!(res && res.status && res.status !== 200)) {
      showSuccess(t(`${translationPath}save-contact-file-successfully`));
      getSourceFileByContactId();
    } else {
      showError(t(`${translationPath}failed-to-save-contact-file`));
    }
  });

  const getSourceFileByContactId = useCallback(async () => {
    setIsLoading(true);
    const res = await GetSourceFileByContactId(searchFilter);
    if (!(res && res.status && res.status !== 200)) {
      if (isFirstTimeFetch) {
        setIsFirstTimeFetch(false);
        setContactSourceCardData(res?.result[0]);
      }

      setdataFiled(res);
    }
    setIsLoading(false);
  });

  useEffect(() => {
    if (contactId && activeTab === 0) getSourceFileByContactId();
  }, [activeTab, searchFilter]);

  useEffect(() => {
    if (contactId && activeTab === 0) leadTableData(contactId);
  }, [activeTab]);

  useEffect(() => {
    if (dataFiled) {
      setSelected({
        id: "edit",
        value: {
          sourceFileName: dataFiled.sourceFileName || "",
          sourceFileYear: dataFiled.sourceFileYear || "",
          communitySourceFile: dataFiled.communitySourceFile || "",
          propertySourceFile: dataFiled.propertySourceFile || "",
          sourceBy: dataFiled.sourceBy || "",
          sourceFileDate: dataFiled.sourceFileDate || null,
          contactId: +GetParams("id"),
        },
      });
      setFilter({
        id: "edit",
        value: {
          sourceFileName: dataFiled.sourceFileName || "",
          sourceFileYear: dataFiled.sourceFileYear || "",
          communitySourceFile: dataFiled.communitySourceFile || "",
          propertySourceFile: dataFiled.propertySourceFile || "",
          sourceBy: dataFiled.sourceBy || "",
          sourceFileDate: dataFiled.sourceFileDate || null,
          contactId: +GetParams("id"),
        },
      });
    }
  }, [dataFiled]);

  const onPageIndexChanged = (pageIndex) => {
    setSearchFilter((item) => ({ ...item, pageIndex }));
  };
  const onPageSizeChanged = (pageSize) => {
    setSearchFilter((item) => ({ ...item, pageIndex: 1, pageSize }));
  };

  useEffect(() => {
    bottomBoxComponentUpdate(
      <PaginationComponent
        pageIndex={searchFilter?.pageIndex - 1}
        pageSize={searchFilter?.pageSize}
        totalCount={dataFiled?.totalCount}
        onPageIndexChanged={onPageIndexChanged}
        onPageSizeChanged={onPageSizeChanged}
      />
    );
  });

  return (
    <div className=" my-3 childs-wrapper">
      <Spinner isActive={isLoading} isAbsolute />
      <div className="title-section">{t("contact-source")}</div>
      <PermissionsComponent
        permissionsList={Object.values(ContactsPermissions)}
        permissionsId={ContactsPermissions.EditContactSourceFile.permissionsId}
      >
        <div className="d-flex w-100">
          <div className="tabs-wrapper d-flex-column">
            <div className="d-flex">
              <div className="w-33 m-2">
                <div className="mt-2 mb-1 pt-1">
                  <Inputs
                    idRef="SourceFilenameRef"
                    labelValue={t(`${translationPath}contact-file-name`)}
                    value={selected.sourceFileName || ""}
                    onInputChanged={(event) => {
                      const SourceFilename = event?.target?.value;
                      setSelected({
                        id: "sourceFileName",
                        value: SourceFilename,
                      });
                      setFilter({
                        id: "sourceFileName",
                        value: SourceFilename,
                      });
                    }}
                    translationPath={translationPath}
                    parentTranslationPath={parentTranslationPath}
                  />
                </div>
              </div>
              <div className="w-33 m-2">
                <div className="mt-2 mb-1 pt-1">
                  <Inputs
                    idRef="SourceFileYearRef"
                    labelValue={t(`${translationPath}contact-file-year`)}
                    value={selected.sourceFileYear || ""}
                    isWithError
                    onInputChanged={(event) => {
                      const SourceFileYear = event?.target?.value;
                      setSelected({
                        id: "sourceFileYear",
                        value: SourceFileYear,
                      });
                      setFilter({
                        id: "sourceFileYear",
                        value: SourceFileYear,
                      });
                    }}
                    translationPath={translationPath}
                    parentTranslationPath={parentTranslationPath}
                  />
                </div>
              </div>
              <div className="w-33 m-2">
                <div className=" mt-2 mb-1 pt-1">
                  <DatePickerComponent
                    idRef="SourceFselectedileDateRef"
                    labelValue={t(`${translationPath}contact-file-date`)}
                    placeholder="DD/MM/YYYY"
                    value={selected.sourceFileDate || null}
                    onDateChanged={(newValue) => {
                      const sourceFileDate =
                        (newValue &&
                          moment(newValue).format("YYYY-MM-DDTHH:mm:ss")) ||
                        null;
                      setSelected({
                        id: "sourceFileDate",
                        value: sourceFileDate,
                      });
                      setFilter({
                        id: "sourceFileDate",
                        value: sourceFileDate,
                      });
                    }}
                    translationPath={translationPath}
                    parentTranslationPath={parentTranslationPath}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex">
              <div className="d-flex ">
                <div className="w-33 m-2">
                  <div className=" mt-2 mb-1 pt-1">
                    <Inputs
                      idRef="SourceByRef"
                      labelValue={t(`${translationPath}contact-by`)}
                      value={selected.sourceBy || ""}
                      isWithError
                      onInputChanged={(event) => {
                        const SourceBy = event?.target?.value;

                        setSelected({ id: "sourceBy", value: SourceBy });
                        setFilter({ id: "sourceBy", value: SourceBy });
                      }}
                      translationPath={translationPath}
                      parentTranslationPath={parentTranslationPath}
                    />
                  </div>
                </div>
                <div className="w-33 m-2">
                  <div className=" mt-2 mb-1 pt-1">
                    <Inputs
                      idRef="CommunitySourceFileRef"
                      labelValue={t(`${translationPath}community-contact-file`)}
                      value={selected.communitySourceFile || ""}
                      isWithError
                      onInputChanged={(event) => {
                        const CommunitySourceFile = event?.target?.value;

                        setSelected({
                          id: "communitySourceFile",
                          value: CommunitySourceFile,
                        });
                        setFilter({
                          id: "communitySourceFile",
                          value: CommunitySourceFile,
                        });
                      }}
                      translationPath={translationPath}
                      parentTranslationPath={parentTranslationPath}
                    />
                  </div>
                </div>
                <div className="w-33 m-2">
                  <div className=" mt-2 mb-1 pt-1">
                    <Inputs
                      labelValue={t(`${translationPath}property-contact-file`)}
                      value={selected.propertySourceFile || ""}
                      idRef="PropertySourceFileRef"
                      isWithError
                      onInputChanged={(event) => {
                        const PropertySourceFile = event?.target?.value;
                        setSelected({
                          id: "propertySourceFile",
                          value: PropertySourceFile,
                        });
                        setFilter({
                          id: "propertySourceFile",
                          value: PropertySourceFile,
                        });
                      }}
                      translationPath={translationPath}
                      parentTranslationPath={parentTranslationPath}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="d-flex-v-center-h-end flex-wrap">
                <ButtonBase
                  className="btns theme-transparent mb-2"
                  onClick={cancelHandler}
                >
                  <span>{t("cancel")}</span>
                </ButtonBase>

                <PermissionsComponent
                  permissionsList={Object.values(ContactsPermissions)}
                  permissionsId={
                    ContactsPermissions.EditContactSourceFile.permissionsId
                  }
                >
                  <ButtonBase
                    className="btns theme-solid mb-2"
                    onClick={() => saveHandler()}
                  >
                    <span>{t("create")}</span>
                  </ButtonBase>
                </PermissionsComponent>
              </div>
            </div>
          </div>

          <div className="w-50 mx-4">
            <ContactSourceDetailsCard
              data={contactSourceCardData || []}
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
            />
          </div>
        </div>
      </PermissionsComponent>
      <div className="dialog-content-item w-100 px-4 py-3 mb-5">
        <div className="title-section">{t("Lead-Table")}</div>
        <Tables
          data={leadArrayInfo || []}
          headerData={[
            {
              id: 1,
              label: "Lead ID",
              key: 0,
              component: (item) =>
                item?.leadId ? <span>{item?.leadId}</span> : "N/A",
            },
            {
              id: 2,
              label: "GCLID",
              key: 1,
              component: (item) =>
                item?.lead?.google_gclid ? (
                  <span>{item?.lead?.google_gclid}</span>
                ) : (
                  "N/A"
                ),
            },
            {
              id: 3,
              label: "Campaign Name",
              key: 2,
              component: (item) =>
                item?.lead?.campaign_name?.name ? (
                  <span>{item?.lead?.campaign_name?.name}</span>
                ) : (
                  "N/A"
                ),
            },
          ]}
          defaultActions={[]}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
        <HistoryContactDetailsTable
          contactId={contactId}
          contactDetailsData={dataFiled}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          searchFilter={searchFilter}
          setSearchFilter={setSearchFilter}
          initialSearchFilter={initialFilter}
          onReload={() => {
            getSourceFileByContactId();
          }}
        />
      </div>
    </div>
  );
};
ContactProfileSourceFileComponent.defaultProps = {
  isOpen: false,
};
ContactProfileSourceFileComponent.propTypes = {
  isOpen: PropTypes.bool,
};
