import React, { useCallback, useEffect, useReducer, useState } from "react";
import { PropTypes } from "prop-types";
import Joi from "joi";
import { useTranslation } from "react-i18next";
import { Spinner, TabsComponent } from "../../../../../../Components";
import { UnitProfileMarketingTabsData } from "./TabsData";
import {
  GlobalHistory,
  showError,
  showSuccess,
} from "../../../../../../Helper";
import {
  UpdateUnitMarketing,
  CreateUnitMarketing,
  GetUnitMarketingByUnitId,
  GetAllRolesByUserId,
  IsNeedApprovalMessageInMarketing,
} from "../../../../../../Services";
import { useSelector } from "react-redux";
import { NeedsApprovalDialog } from "../../../../UnitsSalesView/UnitsSalesProfileManagement/Sections/Marketing/Sections";

export const Marketing = ({
  unitId,
  activeItem,
  parentTranslationPath,
  translationPath,
}) => {
  const { t } = useTranslation([parentTranslationPath, "Shared"]);
  const [isMarketAsADifferentAgent, setIsMarketAsADifferentAgent] =
    useState(false);
  const [id, setId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const loginResponse = useSelector((state) => state.login.loginResponse);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [approvalResponse, setApprovalResponse] = useState(null);
  const [savedRoles, setSavedRoles] = useState({});

  const defaultState = {
    unitId,
    uspId: null,
    agentsId: null,
    titleEn: null,
    titleAr: null,
    descriptionEn: null,
    descriptionAr: null,
    isFeatureUnit: false,
    isHotDealUnit: false,
    marketingWebPortalIds: [],
    isPublishUnitSale: false,
    isPublishUnitLease: false,
    isPublishedForDubizzleSale: false,
    isPublishedForDubizzleLease: false,
    isForLease: true,
    isPublishedForBayoutLease: false,
    isPublishedForPropertyFinderLease: false,
    isPublishedForPrianLease: false,
    isPublishedForHouzaLease: false,
    isDealOfTheDay: false,
    // dealExpirationPeriod: 1,
  };
  const reducer = useCallback((state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const [state, setState] = useReducer(reducer, null);
  const [selected,setSelected]=useReducer(reducer,{
    dealExpirationPeriodValue:0
  })
  const onStateChanged = (newValue) => {
    setState(newValue);
  };
  const onTabChanged = (e, newTap) => {
    setActiveTab(newTap);
  };
  const schema = Joi.object({
    // uspId: Joi.number()
    //   .required()
    //   .messages({
    //     'number.base': t(`${translationPath}usp-is-required`),
    //     'number.empty': t(`${translationPath}usp-is-required`),
    //   }),
    agentsId: Joi.any()
      .custom((value, helpers) => {
        if (!value && isMarketAsADifferentAgent)
          return helpers.error("state.agentRequired");
        return value;
      })
      .messages({
        "state.agentRequired": t(
          `${translationPath}marketing-agent-is-required`
        ),
      }),
    titleEn: Joi.string()
      .required()
      .messages({
        "string.base": t(`${translationPath}marketing-title-is-required`),
        "string.empty": t(`${translationPath}marketing-title-is-required`),
      }),
    descriptionEn: Joi.string()
      .required()
      .messages({
        "string.base": t(`${translationPath}descriptionEn-title-is-required`),
        "string.empty": t(`${translationPath}descriptionEn-title-is-required`),
      }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(state);
  const cancelHandler = () => {
    GlobalHistory.goBack();
  };
  const getUnitMarketingByUnitId = useCallback(async () => {
    setIsLoading(true);
    const res = await GetUnitMarketingByUnitId(unitId, true);
    if (!(res && res.status && res.status !== 200)) {
      setSelected({
        id : 'edit',
        value:{
          dealExpirationPeriodValue :res.dealExpirationPeriod || null
        }
      })
      setState({
        id: "edit",
        value: {
          unitMarketingId: res.unitMarketingId || null,
          unitId,
          uspId: res.uspId || null,
          agentsId: res.agentsId || null,
          titleEn: res.titleEn || null,
          titleAr: res.titleAr || null,
          descriptionEn: res.descriptionEn || null,
          descriptionAr: res.descriptionAr || null,
          isFeatureUnit: res.isFeatureUnit || false,
          isHotDealUnit: res.isHotDealUnit || false,
          isPublishUnitSale: res.isPublishUnitSale || false,
          isPublishedForDubizzleSale: res.isPublishedForDubizzleSale || false,
          isPublishedForDubizzleLease: res.isPublishedForDubizzleLease || false,
          isPublishedForPropertyFinderLease:
            res.isPublishedForPropertyFinderLease || false,
          isPublishedForPrianLease: res.isPublishedForPrianLease || false,
          isPublishedForHouzaLease: res.isPublishedForHouzaLease || false,
          isPublishedForBayoutLease: res.isPublishedForBayoutLease || false,
          isForLease: true,
          isPublishUnitLease: res.isPublishUnitLease || false,
          marketingWebPortalIds:
            (res.unitMarketingWebPortals &&
              res.unitMarketingWebPortals.map(
                (item) => item.marketingWebPortalId
              )) ||
            [],
          isDealOfTheDay: res.isDealOfTheDay || false,
          dealExpirationPeriod: res.dealExpirationPeriod || null,
        },
      });
      if (res.agentsId) setIsMarketAsADifferentAgent(true);
      else setIsMarketAsADifferentAgent(false);
      setId(res.unitMarketingId || null);
    } else {
      setState({
        id: "edit",
        value: {
          ...defaultState,
        },
      });
    }
    setIsLoading(false);
  }, [unitId, activeTab]);

  const getActiveUserRoles = async () => {
    setIsLoading(true);
    const response = await GetAllRolesByUserId(loginResponse.userId, 1, 30);
    setSavedRoles({
      result: (response && response.result) || [],
      totalCount: (response && response.totalCount) || 0,
    });
    setIsLoading(false);
  };
  const isNeedApprovalMessageInMarketing = async () => {
    const response = await IsNeedApprovalMessageInMarketing({
      userBranchId: loginResponse?.branchId,
      isPublishAction: !state?.isPublishUnitLease,
      roleIds: savedRoles?.result?.map((item) => item.rolesId),
      userId: loginResponse?.userId,
    });

    if (!(response && response.status && response.status !== 200)) {
      return response;
    }
    return false;
  };

  const saveHandler = useCallback(async () => {
    setIsSubmitted(true);
    if (schema.error) {
      showError(t("Shared:please-fix-all-errors"));
      return;
    }
    setIsLoading(true);
    const res =
      (id && (await UpdateUnitMarketing(id, {...state ,dealExpirationPeriod: Number(selected?.dealExpirationPeriodValue) || null}))) ||
      (await CreateUnitMarketing(
        {...state ,dealExpirationPeriod: Number(selected?.dealExpirationPeriodValue) ||null}
      ));
    setIsLoading(false);
    if (!(res && res.status && res.status !== 200)) {
      if (id)
        showSuccess(t(`${translationPath}unit-marketing-updated-successfully`));
      else
        showSuccess(t(`${translationPath}unit-marketing-created-successfully`));
    } else if (id) {
      const textOfError =
        res &&
        res.data &&
        res.data.Message &&
        res.data.Message.split(": ") &&
        res.data.Message.split(": ");
      if (textOfError[2])
        showError(
          t(`${translationPath}marketing-description-Error`) + textOfError[2]
        );
      else showError(t(`${translationPath}${textOfError[1]}`));
    } else showError(t(`${translationPath}unit-marketing-create-failed`));
    getUnitMarketingByUnitId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, schema, state, t, translationPath]);
  useEffect(() => {
    if (unitId) getUnitMarketingByUnitId();
  }, [getUnitMarketingByUnitId, unitId]);

  const handleApprovalResponse = (response) => {
    setApprovalResponse(response);
  };

  useEffect(() => {
    getActiveUserRoles();
  }, []);

  return (
    <div className="units-profile-marketing-wrapper childs-wrapper b-0">
      {isLoading || !state ? (
        <Spinner isActive isAbsolute />
      ) : (
        <TabsComponent
          data={UnitProfileMarketingTabsData}
          labelInput="label"
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          themeClasses="theme-curved"
          currentTab={activeTab}
          onTabChanged={onTabChanged}
          dynamicComponentProps={{
            state,
            isMarketAsADifferentAgent,
            onIsMarketAsADifferentAgent: (newValue) =>
              setIsMarketAsADifferentAgent(newValue),
            onStateChanged,
            isSubmitted,
            schema,
            activeItem,
            cancelHandler,
            saveHandler,
            parentTranslationPath,
            translationPath,
            type: "leaseLeadOwner",
            approvalResponse,
            isNeedApprovalMessageInMarketing,
            setIsDialogOpen,
            setIsSubmitted,
            setSelected,
            selected
          }}
        />
      )}

      {isDialogOpen && (
        <NeedsApprovalDialog
          setIsDialogOpen={setIsDialogOpen}
          isDialogOpen={isDialogOpen}
          loginResponse={loginResponse}
          state={state}
          onApprovalResponse={handleApprovalResponse}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      )}
    </div>
  );
};

Marketing.propTypes = {
  activeItem: PropTypes.instanceOf(Object),
  unitId: PropTypes.number,
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
};
Marketing.defaultProps = {
  activeItem: undefined,
  unitId: undefined,
};
