export const returnPropsByPermissions = (permissionsId) => {
    const userPermissionsList = JSON.parse(localStorage.getItem('session')).permissions;
    const checkIsHavePermission = userPermissionsList.some((p) => p.permissionsId === permissionsId);
    
    return checkIsHavePermission;
};

export const returnPropsByPermissions2 = (permissionsId, permissionsId2) => {
    const userPermissionsList = JSON.parse(localStorage.getItem('session')).permissions;
    const checkIsHavePermission1 = userPermissionsList.some((p) => p.permissionsId === permissionsId);
    const checkIsHavePermission2 = userPermissionsList.some((p) => p.permissionsId === permissionsId2);

    return checkIsHavePermission1 && checkIsHavePermission2;
};

export const returnPropsByPermissions3 = (permissionsId, permissionsId2 , permissionsId3  ) => {
    const userPermissionsList = JSON.parse(localStorage.getItem('session')).permissions;
    const checkIsHavePermission1 = userPermissionsList.some((p) => p.permissionsId === permissionsId);
    const checkIsHavePermission2 = userPermissionsList.some((p) => p.permissionsId === permissionsId2);
    const checkIsHavePermission3 = userPermissionsList.some((p) => p.permissionsId === permissionsId3);


    return checkIsHavePermission1 ||  checkIsHavePermission2 || checkIsHavePermission3 ;
};

export const havePermissionToViewDetails = (permissionList) => {
  let userPermissions = localStorage.getItem("session");
  if (userPermissions) {
    userPermissions = JSON.parse(localStorage.getItem("session")).permissions;
    const ishavePermission = permissionList.some((item1) =>
      userPermissions.some(
        (item2) =>
          item2.permissionsId === item1?.permissionsId ||
          (item1.permissionsId &&
            Array.isArray(item1.permissionsId) &&
            item1.permissionsId.some((p) => p === item2.permissionsId))
      )
    );
    return ishavePermission;
  }
  return false;
};

export const havePermissionToEditInformation = (editPermissionsId) => {
    let userPermissions = localStorage.getItem('session') ;
   if(userPermissions)
    {
     userPermissions = JSON.parse(localStorage.getItem('session')).permissions;
     const  haveEditPermission = userPermissions.some(item =>  item.permissionsId === editPermissionsId) ; 
      return haveEditPermission  ;   
    }
    return false ; 
};
