import React from "react";
import PropTypes from "prop-types";
import { Box, IconButton } from "@material-ui/core";
import { useSelectedTheme, useIsDesktop } from "../../../Hooks";

// Styles
import useStyles from "./styles";

// Icons
import { TrashOne, EditOne, EyeIcon } from "../../../assets/icons";

function TableEditDeleteActions({
  anchorPosition,
  onDelete,
  onEdit,
  onPreview,
}) {
  const {
    theme: { palette },
  } = useSelectedTheme();

  const { isDesktop } = useIsDesktop();

  const styles = useStyles({ isDesktop });

  return (
    <Box
      className={styles.actionsContainer}
      style={{
        top: anchorPosition?.y || 0, // Fallback to 0 if no anchorPosition
      }}
    >
      <Box className={styles.flex}>
        {onDelete && (
          <IconButton
            aria-label="Delete"
            className={styles.button}
            onClick={onDelete}
          >
            <TrashOne
              width="20"
              height="20"
              fill={palette.button.tertiaryGray_fg}
            />
          </IconButton>
        )}

        {onEdit && (
          <IconButton
            aria-label="Edit"
            className={styles.button}
            onClick={onEdit}
          >
            <EditOne
              width="20"
              height="20"
              fill={palette.button.tertiaryGray_fg}
            />
          </IconButton>
        )}

        {onPreview && (
          <IconButton
            aria-label="Preview"
            className={styles.button}
            onClick={onPreview}
          >
            <EyeIcon
              width="20"
              height="20"
              fill={palette.button.tertiaryGray_fg}
            />
          </IconButton>
        )}
      </Box>
    </Box>
  );
}

TableEditDeleteActions.propTypes = {
  anchorPosition: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }),
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  onPreview: PropTypes.func,
};

TableEditDeleteActions.defaultProps = {
  anchorPosition: { x: 0, y: 0 },
  onDelete: null,
  onEdit: null,
  onPreview: null,
};

export default TableEditDeleteActions;
