import React, { useState } from "react";
import { PropTypes } from 'prop-types';
import "../../../../LeadsProfileManagement.Style.scss";
import Styles from "./MatchedUnitCard.module.scss";
import GeneralStyles from "../../LeadInquiriesView.module.scss";
import {
  LoadableImageComponant,
  SelectComponet,
} from "../../../../../../../../Components";
import {
  DefaultImagesEnum,
  LoadableImageEnum,
  UnitsOperationTypeEnum,
} from "../../../../../../../../Enums";
import Button from "@material-ui/core/Button";
import { formatCommasForPriceUnit } from "../../../../../../../../Helper/formatCommas.Helper";
import { CopyToClipboardComponents } from "../../../../../../../../ReusableComponents/UtilityComponents";
import { getDownloadableLink, showError, showSuccess } from "../../../../../../../../Helper";
import { GetSuitableTemplateIdForUnit, MarkOrUnmarkUnitAsShortListed } from "../../../../../../../../Services";
import { UnitTemplateDialogPreviewDialog } from "../../../../../../UnitsView/UnitsProfileManagementView/Dialogs/UnitTemplateDialog/UnitTemplateDialogPreviewDialog";
import { useTranslation } from "react-i18next";
import bedIcon from '../../../../../../../../assets/images/Propxicon/side-bed.svg';
import bathIcon from '../../../../../../../../assets/images/icons/bath.svg';
import broomIcon from '../../../../../../../../assets/images/icons/broom.svg';
import areaIcon from '../../../../../../../../assets/images/icons/area.svg';
import bookmarkCheck from '../../../../../../../../assets/images/Propxicon/bookmark-check.svg';
import bookmarkIcon from '../../../../../../../../assets/images/Propxicon/bookmark.svg';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { MessageConfirmDialog } from "../../../../../../../../SharedComponents";

const MatchedUnitCard = ({
  data,
  inquiryId,
  reload,
  parentTranslationPath,
  translationPath,
  actionButtons,
  viewUnitHandler
}) => {
  const { t } = useTranslation([parentTranslationPath]);
  const history = useHistory();
  const [templatesProposal, setTemplatesProposal] = useState([]);
  const [activeTemplateId, setActiveTemplateId] = useState(-1);
  const [isLoading, setIsLoading] = useState({
    template: false,
    markAsShortListed: false,
  });
  const [templates, setTemplates] = useState([]);
  const [activeTemplateName, setActiveTemplateName] = useState(null);
  const [isOpenTemplateDialog, setIsOpenTemplateDialog] = useState(false);
  const [isOpenUnmarkDialog, setIsOpenUnmarkDialog] = useState(false);

  const GetSuitableTemplateIdForUnitApi = async () => {
    setIsLoading(prevState => ({ ...prevState, template: true }));
    let res;
    res = await GetSuitableTemplateIdForUnit(
      data.unitId,
      1,
      10000,
      UnitsOperationTypeEnum.rent.key
    );
    if (!((res && res.data && res.data.ErrorId) || !res))
      setTemplatesProposal((res && res.result) || []);
    else setTemplatesProposal([]);
    setIsLoading(prevState => ({ ...prevState, template: false }));
  };


  const markOrUnmarkUnitAsShortListed = async (isShortListed) => {
    setIsLoading(prevState => ({ ...prevState, markAsShortListed: true }));
    let res;
    res = await MarkOrUnmarkUnitAsShortListed({
      unitId: data?.unitId,
      inquiryId,
      isShortListed,
    });
    if (res == true) {
      showSuccess("Successful Process!");
      reload();
    } else showError("Process Failed ..");
    setIsLoading(prevState => ({ ...prevState, markAsShortListed: false }));
  };

  return (
    <>
      <div className={Styles.Card_wrapper} key={data.unitId}>
        <div className={Styles.Card}>
          <div className={Styles.Card_Content}>
            <div className={`${Styles.Card_section1} d-flex-v-center-h-between`}>
              <div>
                <LoadableImageComponant
                  classes={Styles.Card_Image}
                  type={LoadableImageEnum.div.key}
                  alt={`unit-image`}
                  src={
                    data.unitImages
                      ? getDownloadableLink(data.unitImages.fileId)
                      : DefaultImagesEnum.buildings.defaultImg
                  }
                />
              </div>
              <div className={Styles.Card__Main_info} >
                {(data.rentPerYear || data.sellingPrice) ? (
                  <p>
                    <span
                      className={`${GeneralStyles.Fw600} ${GeneralStyles.Fs18} mr-2`}
                    >
                      {formatCommasForPriceUnit(
                        data.operationType == "Rent"
                          ? data.rentPerYear
                          : data.sellingPrice || 0
                      )}
                    </span>
                    <span className={`${GeneralStyles.Fs16}`}>AED</span>
                  </p>
                ) : null}
                {data.propertyName ? (
                  <p
                    className={`${GeneralStyles.Fs18} ${GeneralStyles.Fw600} my-3`}
                  >
                    {data.propertyName}
                  </p>
                ) : null}
                <p className={`${GeneralStyles.Fs14} ${GeneralStyles.Fw500}`}>{data.country || null}</p>
                <div className={`d-flex fa-center flex-wrap ${Styles.Location_info}`}><p>{data.community || null}</p><span>.</span><p>{data.subCommunity || null}</p></div>
                {data.unitRefNo ? (
                  <p
                    className={`${GeneralStyles.Fs14} ${GeneralStyles.Fw500} c-primary`}
                  >
                    <span>
                      <CopyToClipboardComponents
                        data={data.unitRefNo}
                        childrenData={`#${data.unitRefNo}`}
                      />
                    </span>
                  </p>
                ) : null}
                <div className={Styles.bookmark_icon}>
                  {actionButtons.markAsShortListed ?
                    <span onClick={() => { if (!isLoading.markAsShortListed) markOrUnmarkUnitAsShortListed(true) }}>
                      <img src={bookmarkIcon} alt="bookmar-Icon" /></span>
                    : null}
                  {actionButtons.unmarkAsShortListed ?
                    <span onClick={() => { if (!isLoading.markAsShortListed) setIsOpenUnmarkDialog(true); }}>
                      <img src={bookmarkCheck} alt="bookmarkCheck-Icon" /></span>
                    : null}
                </div>
              </div>
            </div>
            <div className={`${Styles.Card_section2} d-flex-v-center`}>
              {data.saleType ?
                <div className={Styles.Outlined_capsule}>
                  <span>{data.saleType}</span>
                </div> : null}
              <p>{data.unitType || null}</p>
              <p className="c-primary">{(data.operationType === "Rent" ? data.leaseStatusName : data.saleStatusName) || null}</p>
            </div>
            <div className={Styles.Capsules}>
              <div className={Styles.Solid_capsule}>
                <span><img src={bedIcon} alt="bed-Icon" /></span>
                <span>{data.bedroom || "-"}</span>
              </div>

              <div className={Styles.Solid_capsule}>
                <span><img src={bathIcon} alt="bath-Icon" /></span>
                <span>{data.bathroom || "-"}</span>
              </div>

              <div className={Styles.Solid_capsule}>
                <span><img src={areaIcon} alt="area-Icon" /></span>
                <span>{data.sizeArea || "-"}</span>
              </div>

              <div className={Styles.Solid_capsule}>
                <span><img src={broomIcon} alt="broom-Icon" /></span>
                <span>{data.maidRooms || "-"}</span>
              </div>
            </div>
            <div>
              {(data.operationType !== "Rent" && data.listingAgent) && (
                <p>
                  <span className={`${GeneralStyles.Fw500} mr-2`}>
                    Listing Agent:
                  </span>
                  <span>{data.listingAgent}</span>
                </p>
              )}
              {(data.operationType === "Rent" && data.rentListingAgent) && (
                <p>
                  <span className={`${GeneralStyles.Fw500} mr-2`}>
                    Rent Listing Agent:
                  </span>
                  <span>{data.rentListingAgent}</span>
                </p>
              )}
            </div>
          </div>
          <div className={Styles.Card_Buttons}>
            {actionButtons.sendPropsal ?
              <Button
                className="btns theme-propx outlined"
                id="btn-save"
                onClick={() => { }}
              >
                <span>{t(`Send-Propsal`)}</span>
                <div className="Select_Button_Type">
                  <SelectComponet
                    data={templatesProposal}
                    keyLoopBy="systemTemplateId"
                    valueInput="systemTemplateId"
                    textInput="systemTemplateName"
                    value={activeTemplateId}
                    keyValue="actionsbuttons"
                    idRef="contactsActionsRef"
                    onSelectChanged={(value) => {
                      setActiveTemplateId(value);
                      if (value !== -1) {
                        const itemIndex = templates.findIndex(
                          (item) => item.systemTemplateId === value
                        );
                        if (itemIndex !== -1) {
                          setActiveTemplateName(
                            templates[itemIndex].systemTemplateName
                          );
                        }
                        setIsOpenTemplateDialog(true);
                      }
                    }}
                    themeClass="theme-action-buttons"
                    emptyItem={{
                      value: -1,
                      text: "templateProposal",
                      isHiddenOnOpen: true,
                    }}
                    onOpen={() => {
                      setTemplatesProposal([]);
                      GetSuitableTemplateIdForUnitApi(data.id);
                    }}
                    isLoading={isLoading.template}
                  />
                </div>
              </Button> : null}
            {actionButtons.markAsShortListed ? <Button
              className={`btns theme-propx solid ${Styles.blueBtnTheme}`}
              id="btn-save"
              disabled={isLoading.markAsShortListed}
              onClick={() => markOrUnmarkUnitAsShortListed(true)}
            >
              <span>{t(`mark-As-Short-Listed`)}</span>
            </Button> : null}
            {actionButtons.quickTransaction ? <Button
              className={`btns theme-propx solid ${Styles.blueBtnTheme}`}
              id="btn-save"
              onClick={() =>
                history.push(
                  `/home/units-sales/unit-transaction-parameters?unitRefNo=${data?.unitRefNo}`
                )
              }        >
              <span>{`Quick Transaction`}</span>
            </Button> : null}
          </div>
        </div>
        {(viewUnitHandler && actionButtons.viewUnit) ?
          <Button className={Styles.Side_popup_btn}
            onClick={viewUnitHandler}>
            <span className="mdi mdi-folder-open"></span>
          </Button>
          : null}
      </div>
      {isOpenUnmarkDialog && (
      <MessageConfirmDialog
          isDialogOpen={isOpenUnmarkDialog}
          theme="warning"
          content={{
            heading: "Remove unit from shortlist confirmation",
            body: `Are you sure you want to remove this unit ( ${data.unitRefNo} ) from your shortlist? This action cannot be undone.`          }}
          confirmButton={{
            label: "Unmark",
            handler: async () => {
              await markOrUnmarkUnitAsShortListed(false);
              setIsOpenUnmarkDialog(false);
            },
          }}
          cancelButton={{
            label: "Discard",
            handler: () => {
              setIsOpenUnmarkDialog(false);
            },
          }}
          allowContentCopy
        />)}
      {isOpenTemplateDialog && (
        <UnitTemplateDialogPreviewDialog
          activeItem={activeTemplateId}
          isOpen={isOpenTemplateDialog}
          unitID={data.unitId}
          maintitleText={activeTemplateName}
          unitOwner={data?.leadOwnerDetails}
          leaseUnitOwner={data?.leaseLeadOwnerDetails}
          isOpenChanged={() => {
            setIsOpenTemplateDialog(false);
            setActiveTemplateId(-1);
          }}
          templateName={activeTemplateName}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      )}
    </>
  );
};

MatchedUnitCard.propTypes = {
  actionButtons: PropTypes.object,
  viewUnit: PropTypes.func,
  reload: PropTypes.func,
  parentTranslationPath: PropTypes.string,
  translationPath: PropTypes.string,
};
MatchedUnitCard.defaultProps = {
  actionButtons: {
    sendPropsal: false,
    unmarkAsShortListed: false,
    markAsShortListed: false,
    quickTransaction: false,
    viewUnit: false,
  },
  reload: () => { },
  parentTranslationPath: "Inquires",
  translationPath: "",
};
export default MatchedUnitCard;
