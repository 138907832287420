import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useReducer,
  useMemo,
  Children,
} from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { Button, ButtonBase } from "@material-ui/core";
import moment from "moment";
import {
  sideMenuIsOpenUpdate,
  sideMenuComponentUpdate,
  GlobalHistory,
  bottomBoxComponentUpdate,
  havePermissionToViewDetails,
  havePermissionToEditInformation,
  getSideMenuStatus,
  getSideMenuComponent,
  returnPropsByPermissions,
  showSuccess,
  generateUniqueKey,
} from "../../../Helper";
import {
  ActionsButtonsComponent,
  ViewTypes,
  AutocompleteComponent,
  SelectComponet,
  Spinner,
  PermissionsComponent,
  Inputs,
  DialogComponent,
} from "../../../Components";
import {
  ActionsEnum,
  ViewTypesEnum,
  TableActions,
  ActionsButtonsEnum,
  FormsIdsEnum,
  UnitsFilterStatusEnum,
  TableFilterTypesEnum,
  TableFilterOperatorsEnum,
  ArrayOFSearchableFormFields,
  AgentRoleEnum,
  ListingAgentIdsEnum,
  DateFilterTypesEnum,
  SearchCriteriaEnum,
} from "../../../Enums";
import {
  CardDetailsComponent,
  UnitsCardsComponent,
  UnitsSalesTableComponent,
} from "./UnitsSalesUtilities";
import {
  UnitsAdvanceSearchTest,
  GetAllSearchableFormFieldsByFormId,
  GetAllFormFieldsByFormId,
  GetLookupItems,
  GetUnitDetails,
  GetAllRolesByUserId,
  IsNeedApprovalMessageInMarketing,
  PropertiesAdvanceSearchByUnits,
  GetAllSystemTemplateByCategoryId,
  getProperties,
  GetLookupItemsByLookupTypeName,
  lookupItemsGetId,
  GetUnitsRefNumbers,
  GetFieldsNeedingApprovalForChange,
} from "../../../Services";
import { AdvanceSearch } from "../../../SharedComponents/AdvanceSearch/AdvanceSearch";
import { AdvanceSearchSecondVersion } from "../../../SharedComponents/AdvanceSearchSecondVersion";
import { PaginationComponent } from "../../../Components/PaginationComponent/PaginationComponent";
import { UnitsImportDialog } from "./UnitsSalesUtilities/Dialogs/UnitsImportDialog/UnitsImportDialog";
import { UnitMapper } from "./UnitMapper";
import { ActiveItemActions } from "../../../store/ActiveItem/ActiveItemActions";
import { useTitle } from "../../../Hooks";
import { GlobalOrderFilterActions } from "../../../store/GlobalOrderFilter/GlobalOrderFilterActions";
import { GlobalAdvanceSearchActions } from "../../../store/GlobalAdvanceSearch/GlobalAdvanceSearchActions";
import { DateRangePickerComponent } from "../../../Components/Controls/DateRangePickerComponent/DateRangePickerComponent";
import { UnitsSalesPermissions } from "../../../Permissions/Sales/UnitsSalesPermissions";
import { getIsAllowedPermission } from "../../../Helper/Permissions.Helper";
import { UnitsSalesTableHeaderData } from "./UnitsSalesUtilities/UnitsSalesTableComponent/UnitsSalesTableHeaderData";
import { UnitsVerticalTabsData } from "../Common/OpenFileView/OpenFileUtilities/OpenFileData/UnitsVerticalTabsData";
import { ActivitiesManagementDialog } from "../../../SharedComponents/ActivitiesManagementDialog/ActivitiesManagementDialog";
import { useScrollToElement } from "../../../Hooks/useScrollToElement";
import { useSuccessDialog } from "../../../SharedComponents";
import { StaticLookupsIds } from "../../../assets/json/StaticLookupsIds";
import {
  NeedsApprovalDialogBulkUnit,
  OwnerDetailsDialog,
  UnitsBulkStatusUpdateDialog,
} from "./UnitsSalesUtilities/Dialogs";
import UnitsMapView from "../../../SharedComponents/UnitsMapView";
import UnitsByProperty from "./UnitsSalesUtilities/UnitsByProperty";
import UnitCard from "./UnitsSalesUtilities/UnitsByProperty/UnitCard";
import { UnitTemplateDialogPreviewDialog } from "../UnitsView/UnitsProfileManagementView/Dialogs/UnitTemplateDialog/UnitTemplateDialogPreviewDialog";
import { AddressEnum } from "../../../Enums";
import { FieldsNeedsApprovalDialogBulkUnits } from "./UnitsSalesUtilities/Dialogs/UnitsBulkStatusUpdateDialog/FieldsNeedsApprovalDialogBulkUnits";
import { FavoriteDFM } from "../../DFMSharedComponents/FavoriteDFM/FavoriteDFM.View";
import { RemoveLeadsOrUnitsFromFolder } from "../../../Services/FavoriteFoldersServices";


const parentTranslationPath = "UnitsView";
const translationPath = "";
const dateRangeDefault = {
  startDate: null,
  endDate: null,
  key: "selection",
  selectedDateType: 1,
};

export const UnitsSalesView = () => {
  const reducer = useCallback((state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);

  const [ownerDetailsDialog, setOwnerDetailsDialog] = useState({
    isOpen: false,
    titleText: "",
  });

  const [isLoadingAllUnits, setIsLoadingAllUnits] = useState(false);
  const [reloadTrigger, setReloadTrigger] = useState(false);
  const { t } = useTranslation(parentTranslationPath);
  const [leadOwnerDetails, setLeadOwnerDetails] = useState({});
  const [map, setMap] = useState(undefined);
  const [isMapChanged, setIsMapChanged] = useState(false);

  const { isOpen } = useSuccessDialog();
  const [addActivity, setAddActivity] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [displayOpenFileButton, setDisplayOpenFileButton] = useState(false);
  const [displayMatchingButton, setDisplayMatchingButton] = useState(false);
  const [unitsBulkStatusUpdateIsOpen, setUnitsBulkStatusUpdateIsOpen] =
    useState(false);
  const [unitsIdUpdateArray, setUnitsIdUpdateArray] = useState([]);
  const [viewType, setViewType] = useState("");
  const [isPublishedUnits, setIsPublishedUnits] = useState(false);
  const [isStatusFilterDisabled, setIsStatusFilterDisabled] = useState(false);
  const [actionButtonsKey, setActionButtonsKey] = useState(generateUniqueKey());
  const [isProposalDialogOpen, setIsProposalDialogOpen] = useState(false);
  const [isSelectTemplateOpen, setIsSelectTemplateOpen] =  useState(false)

  const dispatch = useDispatch();
  const orderFilter = useSelector((state) => state.GlobalOrderFilterReducer);
  const advanceSearch = useSelector(
    (state) => state.GlobalAdvanceSearchReducer
  );
  const [unitsTableFilter, setUnitsTableFilter] = useState(
    (advanceSearch && advanceSearch.UnitsSalesFilter) || null
  );

  const [isLoading, setIsLoading] = useState(false);
  const [isAllFormFieldsLoading, setIsAllFormFieldsLoading] = useState(false);
  const [isDatePickerChanged, setIsDatePickerChanged] = useState(false);
  const loginResponse = useSelector((state) => state.login.loginResponse);
  const [savedRoles, setSavedRoles] = useState({});
  const [needsApprovalDialog, setNeedsApprovalDialog] = useState(false);
  const [fieldsNeedsApprovalDialog, setFieldsNeedsApprovalDialog] = useState(false)
  const [typeOfRequest, setTypeOfRequest] = useState(false);
  const [activeActionType, setActiveActionType] = useState(
    (localStorage.getItem("ViewType") &&
      JSON.parse(localStorage.getItem("ViewType")).unitsSaleView) ||
    ViewTypesEnum.cards.key
  );

  const [selected, setSelected] = useReducer(reducer, {
    propertyName: null,
    community: null,
    unitType: null,
    saleType: null,
    unitRefNo:null
  });

  const [data, setData] = useReducer(reducer, {
    propertyName: [],
    community: [],
    unitType: [],
    saleType: [],
    unitRef: [],
  });

  const [isExpanded, setIsExpanded] = useState(
    activeActionType === ViewTypesEnum.cardsExpanded.key
  );
  const [mapKey, setMapKey] = useState(generateUniqueKey())
  const [isOpenFavoriteDialog, setIsOpenFavoriteDialog] = useState(false);
  const [, setOrderByToggler] = useState(false);
  const [activeSelectedAction, setActiveSelectedAction] = useState("");
  const [unitStatus, setUnitStatus] = useState(
    orderFilter.UnitsSalesStatus || [0]
  );
  const [unitsPortal, setUnitsPortal] = useState(
    orderFilter.PublishSalePortal || [0]
  );
  const [unitsPropertyName, setUnitsPropertyName] = useState(null);
  const [unitsUnitType, setUnitsUnitType] = useState(null);
  const [unitsSaleType, setUnitsSaleType] = useState(null);
  const [unitsCommunity, setUnitsCommunity] = useState(null);
  const [activeCard, setActiveCard] = useState(null);
  const [isOpenImportDialog, setIsOpenImportDialog] = useState(false);
  const [advanceSearchBtn, setAdvanceSearchBtn] = useState(true);
  const [isClearFiltersClicked, setIsClearFiltersClicked] = useState(false);
  const [isAdvanceSearchCleared, setIsAdvanceSearchCleared] = useState(false);
  const [isAdvanceSearchActive, setIsAdvanceSearchActive] = useState(false);
  const [selectedOrderBy, setSelectedOrderBy] = useState({
    filterBy: orderFilter?.unitsSalesFilter?.filterBy,
    orderBy: orderFilter?.unitsSalesFilter?.orderBy,
  });
  const [selectedPropertyForMap, setSelectedPropertyForMap] = useState(null)
  const [isSelectedUnitDialogOpen, setIsSelectedUnitDialogOpen] = useState(false);
  const [PortalData, setPortalData] = useState(null);
  const [selectedUnitsCards, setSelectedUnitsCards] = useState([])
  const [activeTemplateId, setActiveTemplateId] = useState(null);
  const [templatesProposal, setTemplatesProposal] = useState([]);
  const [fieldsNeedsApproval, setFieldsNeedsApproval] = useState([]);
  const [stopChangeCapturing, setStopChangeCapturing] = useState(false)
  const [orderBy, setOrderBy] = useState(
    selectedOrderBy.filterBy
      ? selectedOrderBy
      : { filterBy: "createdOn", orderBy: 2 }
  );
  const [checkedDetailedCards, setCheckedDetailedCards] = useState([]);
  const [detailsUnitsList, setDetailsUnitsList] = useState(() => ({
    result: [],
    totalCount: 0,
  }));
  const [detailsPropertyByUnitsList, setDetailsPropertyByUnitsList] = useState(() => ({
    result: [],
    totalCount: 0,
  }));

  const { setHash } = useScrollToElement(!!detailsUnitsList.result.length);
  const [searchableFormFields, setSearchableFormFields] = useState([]);
  const [searchedItem, setSearchedItem] = useState("");
  const searchTimer = useRef(null);
  const [allFormFields, setAllFormFields] = useState([]);
  const [dateFilter, setDateFilter] = useState(
    (orderFilter &&
      orderFilter.UnitsSalesDate &&
      orderFilter.UnitsSalesDate.startDate &&
      orderFilter.UnitsSalesDate.endDat !== null && {
      startDate: new Date(
        (orderFilter &&
          orderFilter.UnitsSalesDate &&
          orderFilter.UnitsSalesDate.startDate) ||
        null
      ),
      endDate: new Date(
        (orderFilter &&
          orderFilter.UnitsSalesDate &&
          orderFilter.UnitsSalesDate.endDate) ||
        null
      ),
      key: new Date(
        (orderFilter &&
          orderFilter.UnitsSalesDate &&
          orderFilter.UnitsSalesDate.key) ||
        null
      ),
      selectedDateType:
        (orderFilter &&
          orderFilter.UnitsSalesDate &&
          orderFilter.UnitsSalesDate.selectedDateType) ||
        1,
    }) ||
    dateRangeDefault
  );

  const [searchData, setSearchData] = useState([]);
  const [filterSearchDto, setFilterSearchDto] = useState(null);
  const [unitsCardsByProperty, setUnitCardsByProperty] = useState(false)
  const [checkedCards, setCheckedCards] = useState([]);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [filter, setFilter] = useState({
    pageSize: parseInt(localStorage.getItem("Pagination")) || 25,
    pageIndex:
      JSON.parse(localStorage.getItem("unitsSalesCurrentPageIndex")) || 0,
    search: "",
  });
  const [isFirst, setFirst] = useState(false);
  const [isFirst1, setFirst1] = useState(false);
  const [unitPublishStatus, setUnitPublishStatus] = useState(
    orderFilter.UnitSalesPublishStatusFilter || 0
  );
  const [folderIdValue, setFolderIdValue] = useState(false);

  const [selectFieldsOnAdvanceSearch, setSelectFieldsOnAdvanceSearch] =
    useState([]);
  const [filterValuesFromAdvanceSearch, setFilterValuesFromAdvanceSearch] =
    useState(null);
  useTitle(t(`${translationPath}units`));
  const unitOperationSource = {
    sale: {
      key: 1,
    },
    lease: {
      key: 2,
    },
  };

  const isMapView = useMemo(
    () => activeActionType === ViewTypesEnum.map.key,
    [activeActionType]
  );

  const saleListingAgentRole = () => {
    const saleListingAgentRoleIsExist =
      loginResponse &&
      loginResponse.roles &&
      loginResponse.roles.length &&
      loginResponse.roles.filter(
        (x) => x.rolesName === "Sale Listing Agent" || x.rolesName === "admin"
      );
    if (
      saleListingAgentRoleIsExist.length === 1 &&
      saleListingAgentRoleIsExist[0].rolesName ===
      AgentRoleEnum.SaleListingAgent.name
    )
      return true;
    return false;
  };

  const searchHandler = (data) => {
    const newValue = [
      {
        key: "Address",
        title: "Address",
        value: data,
      },
      // {
      //   key: "PropretyName",
      //   title: "PropretyName",
      //   value: data,
      // },
    ];
    if (searchTimer.current) clearTimeout(searchTimer.current);
    if (data === "") {
      const index = searchData.findIndex((item) => item.key === "Address");
      if (index !== -1) searchData.splice(index, 1);
      setFilterSearchDto(null);
      localStorage.removeItem("UnitSaleFilter");
    } else {
      searchTimer.current = setTimeout(() => {
        setSearchData([...newValue]);
        setFilterSearchDto({
          Address: [
            {
              value: data,
            },
          ],
        });
      }, 1300);
    }
  };


  const [SearchedItemUnitReferenceNumber, setSearchedItemUnitReferenceNumber] = useState(false);

  const SearchHandlerComponent = useCallback((key, title, data, delay) => {
    const newValue = [{ key, title, value: data }];
    if (searchTimer.current) clearTimeout(searchTimer.current);

    if (data === "") {
      const index = searchData.findIndex((item) => item.key === key);
      if (index !== -1) searchData.splice(index, 1);
      setFilterSearchDto(null);
      localStorage.removeItem("UnitSaleFilter");
    } else {
      searchTimer.current = setTimeout(() => {
        setSearchData([...newValue]);
        setFilterSearchDto({ [key]: [{ value: data }] });
      }, delay);
    }
  }, [searchData]);


  const getAllSystemTemplateByCategoryId = useCallback(async () => {
    const res = await GetAllSystemTemplateByCategoryId(8);
    if (!(res && res.status && res.status !== 200)) {
      const mappedTemplatesData =
        res?.map((item) => ({
          systemTemplateName: item.systemTemplateName,
          systemTemplateId: item.systemTemplateId,
        }));
      setTemplatesProposal(mappedTemplatesData);
    } else setTemplatesProposal([]);
  }, [filter]);

  const onTypeChanged = useCallback(
    (activeType) => {
      const ViewTypeData = JSON.parse(localStorage.getItem("ViewType"));
      if (ViewTypeData) {
        ViewTypeData.unitsSaleView = activeType;
        localStorage.setItem("ViewType", JSON.stringify(ViewTypeData));
      } else
        localStorage.setItem(
          "ViewType",
          JSON.stringify({ unitsSaleView: activeType })
        );

      setActiveActionType(activeType);
      setIsExpanded(activeType === ViewTypesEnum.cardsExpanded.key);
      sideMenuIsOpenUpdate(false);
      sideMenuComponentUpdate(null);
    },
    [setActiveActionType]
  );
  const onPageIndexChanged = (pageIndex) => {
    localStorage.setItem(
      "unitsSalesCurrentPageIndex",
      JSON.stringify(pageIndex)
    );
    setFilter((item) => ({ ...item, pageIndex }));
    setActiveCard(null);
    sideMenuComponentUpdate(null);
    sideMenuIsOpenUpdate(false);
  };

  useEffect(() => {
    dispatch(
      GlobalOrderFilterActions.globalOrderFilterRequest({
        ...orderFilter,
        UnitsSalesDate: dateFilter || dateRangeDefault,
      })
    );
  }, [dateFilter]);

 

  const restorePageIndex = useCallback(() => {
    const currentPageIndex = localStorage.getItem("unitsSalesCurrentPageIndex");

    if (currentPageIndex) {
      const pageIndex = JSON.parse(currentPageIndex);
      setFilter((item) => ({ ...item, pageIndex }));
    }
  }, []);

  useEffect(() => {
    restorePageIndex();
  }, []);

  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
    setActiveCard(null);
    sideMenuComponentUpdate(null);
    sideMenuIsOpenUpdate(false);
  };
  const filterOnChange = (event, newValue) => {
    const emptyKeyIndex = newValue.findIndex((item) => !item.value);
    if (!searchInputValue && emptyKeyIndex !== -1) {
      newValue.splice(emptyKeyIndex, 1);
      return;
    }
    if (emptyKeyIndex !== -1) newValue[emptyKeyIndex].value = searchInputValue;
    if (
      filterSearchDto &&
      Object.keys(filterSearchDto).length > 0 &&
      newValue.length === 0
    ) {
      onPageIndexChanged(0);
      setFilterSearchDto(null);
    }
    if (!(newValue && newValue.length))
      localStorage.removeItem("UnitSaleFilter");
    setSearchData([...newValue]);
  };
  const getAllSearchableFormFieldsByFormId = useCallback(async () => {
    const result = await GetAllSearchableFormFieldsByFormId(
      FormsIdsEnum.units.id
    );
    if (
      !(result && result.data && result.data.ErrorId) &&
      result &&
      Array.isArray(result)
    ) {
      const res = result
        .filter(
          (item) =>
            item.isSearchable &&
            item.formFieldId !==
            ListingAgentIdsEnum.RentListingAgent.formFieldId
        )
        .map((item) => ({
          key: item.searchableKey || item.displayPath,
          title: item.formFieldTitle,
        }))
        .concat(ArrayOFSearchableFormFields);
      setSearchableFormFields([{ key: "Address", title: "Address" }, { key: "isFavorite", title: "Is Favorite" }, ...res]);
    } else setSearchableFormFields([]);
  }, []);

  const getAllFormFieldsByFormId = useCallback(async () => {
    setIsAllFormFieldsLoading(true);
    const result = await GetAllFormFieldsByFormId(FormsIdsEnum.units.id);
    if (
      !((result && result.data && result.data.ErrorId) || !result) &&
      Array.isArray(result)
    ) {
      const list = result
        .filter(
          (e) =>
            e.formFieldId !==
            ListingAgentIdsEnum.RentListingAgent.formFieldId &&
            e.formFieldName !== "amenities" &&
            e.formFieldName !== "fitting_and_fixtures" &&
            e.formFieldName !== "unit_model" &&
            e.formFieldName !== "rent_price_fees" &&
            e.formFieldName !== "selling_price_agency_fee" &&
            e.formFieldName !== "primary_view" &&
            e.formFieldName !== "secondary_view" &&
            e.formFieldName !== "owner"
        )
        .map((field) => ({
          id: field.formFieldId || null,
          key: field.formFieldKey || null,
          isDate: field.uiWidgetType === "alt-date" || false,
          label:
            (field.formFieldTitle === "Lead Owner"
              ? (field.formFieldTitle = "Unit Owner")
              : field.formFieldTitle &&
              field.formFieldTitle.replace("*", "")) || "",
          input: field.displayPath || "",
          isNumber:
            (field.propertyJson &&
              JSON.parse(field.propertyJson).schema &&
              field.propertyJson &&
              JSON.parse(field.propertyJson).schema.specialKey ===
              "currency") ||
            (field.propertyJson &&
              JSON.parse(field.propertyJson).schema &&
              field.propertyJson &&
              JSON.parse(field.propertyJson).schema.specialKey === "decimal") ||
            (field.propertyJson &&
              JSON.parse(field.propertyJson).schema &&
              field.propertyJson &&
              JSON.parse(field.propertyJson).schema.specialKey === "size"),
          isSortable: true,
          searchableKey: field.searchableKey,
          data: JSON.parse(field.propertyJson).schema,
          fieldType:
            (field.propertyJson &&
              JSON.parse(field.propertyJson).schema.uiType) ||
            (field.propertyJson &&
              JSON.parse(field.propertyJson).uiSchema["ui:widget"]) ||
            (field.uiWidgetType === null && "text"),
        }))

      setAllFormFields(list);
    } else setAllFormFields([]);
    setIsAllFormFieldsLoading(false);
  }, []);

  const searchAdvanceSearch = (newFilter) => {
    const searchObj = newFilter ? { ...newFilter } : null;
    if (!(newFilter && newFilter.length))
      localStorage.removeItem("UnitsSalesFilter");

    dispatch(
      GlobalAdvanceSearchActions.globalAdvanceSearchRequest({
        ...advanceSearch,
        UnitsSalesFilter: searchObj || null,
      })
    );
    setUnitsTableFilter(searchObj);
  };
  const GetSideMenuStatus = useCallback(() => {
    const isMenuOpen = getSideMenuStatus();
    const activeComponentOpen =
      getSideMenuComponent() && getSideMenuComponent().type;

    if (!isMenuOpen) setAdvanceSearchBtn(true);
    else if (activeComponentOpen === AdvanceSearch) setAdvanceSearchBtn(false);
  });
  const getAllProperties = async (value) => {
    // setIsLoading({ id: 'Property', value: true });
    const res = await getProperties({
      pageIndex: 0,
      pageSize: 25,
      search: value || "",
    });
    if (!(res && res.status && res.status !== 200))
      setData({ id: "propertyName", value: res.result || [] });
    else setData({ id: "propertyName", value: [] });

    // setIsLoading({ id: 'Property', value: false });
  };
  const getLookupItemsByLookupTypeName = useCallback(async (LookupTypeName) => {
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: LookupTypeName,
      pageIndex: 1,
      pageSize: 150,
    });
    if (!(res && res.status && res.status !== 200))
      setData({ id: "unitType", value: res.result || [] });
    else setData({ id: "unitType", value: [] });
    // setIsLoading(false);
  }, []);

  const GetAllUnitsRef = useCallback(async (search) => {
    setSearchedItemUnitReferenceNumber(true);
    const res = await GetUnitsRefNumbers({
      unitRefNo: search,
      pageIndex: 1,
      pageSize: 10,
      operationType: StaticLookupsIds.Sales
    });
    if (!(res && res.status && res.status !== 200))
      setData({ id: "unitRef", value: res.result || [] });
    else setData({ id: "unitRef", value: [] });
    setSearchedItemUnitReferenceNumber(false);
  }, []);

  const getSaleType = useCallback(async () => {
    const res = await lookupItemsGetId({
      lookupTypeId: StaticLookupsIds.UnitSaleType,
    });
    if (!(res && res.status && res.status !== 200))
      setData({ id: "saleType", value: res || [] });
    else setData({ id: "saleType", value: [] });
    // setIsLoading(false);
  }, []);

  const getCommunityType = useCallback(async () => {
    const res = await lookupItemsGetId({
      lookupTypeId: AddressEnum.community.lookupTypeId,
    });
    if (!(res && res.status && res.status !== 200))
      setData({ id: "community", value: res || [] });
    else setData({ id: "community", value: [] });
    // setIsLoading(false);
  }, []);

  const onAdvanceSearchClick = useCallback(
    (event) => {
      event.stopPropagation();
      event.preventDefault();
      if (advanceSearchBtn) {
        sideMenuIsOpenUpdate(true);
        setAdvanceSearchBtn(false);
      } else {
        sideMenuIsOpenUpdate(false);
        setAdvanceSearchBtn(true);
      }

      sideMenuComponentUpdate(
        <AdvanceSearchSecondVersion
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onFilterValuesChanged={onFilterValuesChanged}
          localStorageKey="UnitsSalesFilter"
          isAdvanceSearchCleared={isAdvanceSearchCleared}
          setIsAdvanceSearchCleared={setIsAdvanceSearchCleared}
          setOrderBy={setOrderBy}
          searchCriteriaTypeId={SearchCriteriaEnum.Unit.typeId}
          setIsAdvanceSearchActive={setIsAdvanceSearchActive}
          fields={[...UnitsSalesTableHeaderData, ...allFormFields]}
          selectFieldsOnAdvanceSearch={selectFieldsOnAdvanceSearch}
          setSelectFieldsOnAdvanceSearch={setSelectFieldsOnAdvanceSearch}
          setFilterValuesFromAdvanceSearch={setFilterValuesFromAdvanceSearch}
          filterValuesFromAdvanceSearch={filterValuesFromAdvanceSearch}
          searchAdvanceSearch={searchAdvanceSearch}
          unitType={"sale"}
        />
      );
    },
    [
      isAllFormFieldsLoading,
      allFormFields,
      advanceSearchBtn,
      isAdvanceSearchCleared,
      selectFieldsOnAdvanceSearch,
    ]
  );


  const handleRemoveFavorite = async (Unit) => {
    const type = 'Unit';
    const ids = Unit && Unit.id;
    const index = searchData.findIndex((item) => item.key === 'FavoriteFolderId');
    const folderId = index !== -1 ? searchData[index].value : folderIdValue;

    const result = await RemoveLeadsOrUnitsFromFolder(type, [ids], folderId);
    if (!(result && result.status && result.status !== 200)) {
      showSuccess(t('Shared:item-remove-from-folder'));
      // setIsFavorite(false);
      reloadData();
    } else {
      console.error(`Failed to remove ${type.toLowerCase()}s`);
    }
  };

  const checkDuplicatedUnitsButton = useCallback(() => {
    GlobalHistory.push(`/home/units-sales/duplicated-units`);
  });

  const onClearedAllFiltersClick = useCallback((isActionsClear) => {
    setSelectedOrderBy((item) => ({ ...item, filterBy: null, orderBy: null }));
    setOrderBy({ filterBy: "createdOn", orderBy: 2 });
    setSearchData([]);
    setDateFilter(dateRangeDefault);
    setUnitPublishStatus(0);
    setUnitStatus([]);
    setUnitsPortal([]);
    changePropertyName(null);
    changeUnitType(null);
    changeSaleType(null);
    changeCommunity(null);
    setIsClearFiltersClicked(true);
    setIsAdvanceSearchCleared(true);
    setUnitsTableFilter(null);
    setFilterSearchDto(null);
    sideMenuIsOpenUpdate(false);
    setIsAdvanceSearchActive(false);
    setFilterValuesFromAdvanceSearch();
    setSelectFieldsOnAdvanceSearch([]);
    setIsStatusFilterDisabled(false);
    setMapKey(generateUniqueKey())
    setSelectedPropertyForMap(null);
    onActionButtonChanged(ActionsButtonsEnum[1].id);
    setActionButtonsKey(generateUniqueKey());

    setSelectedUnitsCards([])
    setSelected({
      id: "edit",
      value: {
        propertyName: null,
        community: null,
        unitType: null,
        saleType: null,
        unitRefNo:null
      },
    });
    dispatch(
      GlobalOrderFilterActions.globalOrderFilterRequest({
        ...orderFilter,
        UnitsSalesStatus: [],
        PublishSalePortal: [],
        UnitSalesPublishStatusFilter: 0,
        unitsSalesFilter: {
          filterBy: null,
          orderBy: null,
        },
      })
    );

    dispatch(
      GlobalAdvanceSearchActions.globalAdvanceSearchRequest({
        ...advanceSearch,
        UnitsSalesFilter: null,
      })
    );
  }, []);

  const getUnitsData = useCallback(
    async (f) => {
      if (isLoadingAllUnits && isMapView) {
        return;
      }
      if (isMapView && !map) {
        return;
      }
      if (
        returnPropsByPermissions(
          UnitsSalesPermissions.ViewAndSearchInSaleUnits.permissionsId
        )
      ) {
        setIsLoadingAllUnits(true);
        const index = unitStatus.findIndex((el) => el === 0);
        if (index !== -1) unitStatus.splice(index, 1);
        if (unitStatus.length === 0) unitStatus.push(0);

        const indexPortal = unitsPortal.findIndex((el) => el === 0);
        if (indexPortal !== -1) unitsPortal.splice(indexPortal, 1);
        if (unitsPortal.length === 0) unitsPortal.push(0);

        const localFilterDto = f || filterSearchDto || {};

        const filterCreatedByRole = loginResponse.roles.find(
          (role) => role.rolesName === "Filter Created By"
        );
        const filterListingAgentRole = loginResponse.roles.find(
          (role) => role.rolesName === "Filter Listing Agent"
        );
        const filterDubaiTeamRole = loginResponse.roles.find(
          (role) => role.rolesName === "Filter Dubai Team"
        );
        if (filterCreatedByRole) {
          localFilterDto.createdById = [
            {
              searchType: TableFilterOperatorsEnum.equal.key,
              value: loginResponse.userId,
            },
          ];
        }
        if (filterListingAgentRole) {
          localFilterDto.listing_agent_id = [
            {
              searchType: TableFilterOperatorsEnum.equal.key,
              value: loginResponse.userId,
            },
          ];
        }
        if (filterDubaiTeamRole) {
          localFilterDto.city = [
            {
              searchType: TableFilterOperatorsEnum.equal.key,
              value: "dubai",
            },
          ];
        }

        if (unitStatus && unitStatus.length >= 1 && unitStatus[0] !== 0) {
          localFilterDto.status = unitStatus.map((item) => ({
            searchType: TableFilterOperatorsEnum.equal.key,
            value: (item || "").toLowerCase(),
          }));
        }
        if (unitsPortal && unitsPortal.length >= 1 && unitsPortal[0] !== 0) {
          localFilterDto.PortalId = [
            {
              searchType: TableFilterOperatorsEnum.equal.key,
              value: `${unitsPortal}`,
            },
          ];
        }
        if (unitsPropertyName) {
          localFilterDto.property_name = [
            {
              searchType: TableFilterOperatorsEnum.equal.key,
              value: `${unitsPropertyName}`,
            },
          ];
        }
        if (unitsUnitType) {
          localFilterDto.unit_type = [
            {
              searchType: TableFilterOperatorsEnum.equal.key,
              value: `${unitsUnitType}`,
            },
          ];
        }
        if (unitsSaleType) {
          localFilterDto.sale_type = [
            {
              searchType: TableFilterOperatorsEnum.equal.key,
              value: `${unitsSaleType}`,
            },
          ];
        }
        if (unitsCommunity) {
          localFilterDto.community = [
            {
              searchType: TableFilterOperatorsEnum.equal.key,
              value: `${unitsCommunity}`,
            },
          ];
        }
        if (unitPublishStatus) {
          if (unitPublishStatus === 1) {
            localFilterDto.publish_status = [
              {
                searchType: TableFilterOperatorsEnum.equal.key,
                value: true,
              },
            ];
          } else if (unitPublishStatus === 2) {
            localFilterDto.publish_status = [
              {
                searchType: TableFilterOperatorsEnum.equal.key,
                value: false,
              },
            ];
          }
        }

        if (unitsTableFilter) {
          Object.values(unitsTableFilter)
            .filter((item) => item.searchableKey || item.displayPath)
            .map((item) => {
              if (localFilterDto[item.searchableKey || item.displayPath]) {
                localFilterDto[item.searchableKey || item.displayPath].push({
                  searchType: item.operator,
                  value: item.value,
                });
              } else if (item.value) {
                localFilterDto[item.searchableKey || item.displayPath] = [
                  {
                    searchType: item.operator,
                    value: item.value,
                  },
                ];
              } else if (
                !item.value &&
                (item.operator === TableFilterOperatorsEnum.isNotBlank.key ||
                  item.operator === TableFilterOperatorsEnum.isBlank.key)
              ) {
                localFilterDto[item.searchableKey || item.displayPath] = [
                  {
                    searchType: item.operator,
                    value: null,
                  },
                ];
              }
              return undefined;
            });
        }

        if (Object.values(localFilterDto).length)
          setIsAdvanceSearchActive(true);
        else setIsAdvanceSearchActive(false);
        const body = {
          criteria: localFilterDto,
          ...orderBy,
          OperationType: "Sale",
        };

        setIsDatePickerChanged(false);

        if (dateFilter && dateFilter.startDate && dateFilter.endDate) {
          const fromDate = moment(dateFilter.startDate)
            .startOf("day")
            .format("YYYY-MM-DDTHH:mm:ss");
          const toDate = moment(dateFilter.endDate).format(
            "YYYY-MM-DDTHH:mm:ss"
          );

          if (
            dateFilter.selectedDateType ===
            DateFilterTypesEnum.CreatedOnDate.key
          ) {
            body.fromDate = fromDate;
            body.toDate = toDate;
          } else if (
            dateFilter.selectedDateType ===
            DateFilterTypesEnum.UpdatedOnDate.key
          ) {
            body.updatedFromDate = fromDate;
            body.updatedToDate = toDate;
          } else if (
            dateFilter.selectedDateType === DateFilterTypesEnum.ListingDate.key
          ) {
            body.fromListingDate = fromDate;
            body.toListingDate = toDate;
          } else if (
            dateFilter.selectedDateType === DateFilterTypesEnum.PublishDate.key
          ) {
            body.fromPublishDate = fromDate;
            body.toPublishDate = toDate;
          } else if (
            dateFilter.selectedDateType ===
            DateFilterTypesEnum.ExpiredListingDate.key
          ) {
            body.fromExpiryListingDate = fromDate;
            body.toExpiryListingDate = toDate;
          } else if (
            dateFilter.selectedDateType ===
            DateFilterTypesEnum.SaleTransactedDate.key
          ) {
            body.fromTransactedDate = fromDate;
            body.toTransactedDate = toDate;
          } else if (
            dateFilter.selectedDateType ===
            DateFilterTypesEnum.SaleReservedTransactionDate.key
          ) {
            body.fromReservedDate = fromDate;
            body.toReservedDate = toDate;
          }
        }

        const propertyBody = { ...body, map }
        
        if (isMapView && (!unitsCardsByProperty || isMapChanged || !advanceSearchBtn)) {
          if (!!Object.keys(propertyBody.criteria).length) {
            delete propertyBody.map;
            setStopChangeCapturing(true)
          } else {
            setStopChangeCapturing(false)
          }
        }
        if (!body.criteria.property_name && selectedPropertyForMap && !getSideMenuStatus()) {
          body.criteria.property_name = [{ value: selectedPropertyForMap.value, searchType: 1 }];
        }

        const res = isMapView && (!unitsCardsByProperty || isMapChanged || !advanceSearchBtn)
          ? await PropertiesAdvanceSearchByUnits(filter, propertyBody)
          : await UnitsAdvanceSearchTest(filter, body);


        if (!(res && res.status && res.status !== 200)) {
          if (isMapView && (!unitsCardsByProperty || isMapChanged || !advanceSearchBtn)) {
            setDetailsPropertyByUnitsList({
              result: res.result ?? [],
              totalCount: res?.totalCount ?? 0,
            });
          } else {
            const result = ((res && res.result) || []).map(
              (item) =>
                item.unitJson && UnitMapper(item, JSON.parse(item.unitJson).unit)
            );
            setDetailsUnitsList({
              result: result,
              totalCount: (res && res.totalCount) || 0,
            });
          }
          setIsLoadingAllUnits(false);
        } else {
          setDetailsUnitsList({
            result: [],
            totalCount: 0,
          });
          setIsLoadingAllUnits(false);
        }
        setIsMapChanged(false);
      }
    },
    [
      dateFilter,
      filter,
      filterSearchDto,
      orderBy,
      unitStatus,
      unitsPortal,
      unitPublishStatus,
      unitsTableFilter,
      map,
      unitsCardsByProperty,
      unitsPropertyName,
      unitsUnitType,
      unitsSaleType,
      unitsCommunity,
      isMapChanged,
      selectedPropertyForMap,
    ]
  );
  const searchClicked = async () => {
    localStorage.setItem("UnitSaleFilter", JSON.stringify(searchData));
    setFilterSearchDto(
      searchData.reduce((total, item) => {
        if (total[item.key]) {
          total[item.key].push({
            searchType: TableFilterTypesEnum.textInput.defaultSelectedOperator,
            value: item.value,
          });
        } else {
          total[item.key] = [
            {
              searchType:
                TableFilterTypesEnum.textInput.defaultSelectedOperator,
              value: item.value,
            },
          ];
        }
        return total;
      }, {})
    );
    onPageIndexChanged(0);
  };

  const searchChangedClickedWithoutFilter = async (data) => {
    if (data.length === 0) return;
    const oldFilter = data.reduce((total, item) => {
      if (total[item.key]) {
        total[item.key].push({
          searchType: TableFilterTypesEnum.textInput.defaultSelectedOperator,
          value: item.value,
        });
      } else {
        total[item.key] = [
          {
            searchType: TableFilterTypesEnum.textInput.defaultSelectedOperator,
            value: item.value,
          },
        ];
      }
      return total;
    }, {});
    getUnitsData(oldFilter);
  };
  const tableActionClicked = useCallback(
    (actionEnum, item) => {
      dispatch(ActiveItemActions.activeItemRequest(item));
      if (actionEnum === TableActions.openFile.key) {
        GlobalHistory.push(
          `/home/units-sales/unit-profile-edit?formType=${item.unitTypeId}&id=${item.id}&operationType=${item.operationType}`
        );
      }
      if (actionEnum === TableActions.addActivity.key) {
        setAddActivity(true);
        setActiveItem(item);
      }
    },
    [dispatch]
  );
  const displayedLabel = (option) => `${option.title}: ${searchInputValue}`;
  const disabledOptions = (option) => option.disabledOnSelect;
  const chipsLabel = (option) => `${option.title}: ${option.value}`;
  const inputValueChanged = (event, newInputValue) => {
    setSearchInputValue(newInputValue);
  };

  const clearActionFilters = () => {
    setUnitPublishStatus(0);
    changePublishStatus(0);
    onFilterStatusChange([]);
  }
  
  const onActionButtonChanged = (activeAction) => {
    clearActionFilters();
    setActiveSelectedAction(activeAction);
    setCheckedCards([]);
    setCheckedDetailedCards([]);
    setUnitsIdUpdateArray([]);

    if (activeAction === ActionsButtonsEnum[17].id) {
      changePublishStatus(0);
      setUnitPublishStatus(0);
      onFilterStatusChange(["Sale", "Draft", "ReservedSale"]);
    } else if (activeAction === ActionsButtonsEnum[18].id) {
      changePublishStatus(0);
      setUnitPublishStatus(0);
      onFilterStatusChange(["Available", "Sale", "ReservedSale"]);
    } else if (activeAction === ActionsButtonsEnum[19].id) {
      onFilterStatusChange(["Available"]);
      changePublishStatus(2);
      setUnitPublishStatus(2);
    } else if (activeAction === ActionsButtonsEnum[25].id) {
      onFilterStatusChange(["Available"]);
      changePublishStatus(2);
      setUnitPublishStatus(2);
    } else if (activeAction === ActionsButtonsEnum[20].id) {
      onFilterStatusChange([]);
      changePublishStatus(1);
      setUnitPublishStatus(1);
    }
  };

  const getActiveUserRoles = async () => {
    setIsLoading(true);
    const response = await GetAllRolesByUserId(loginResponse.userId, 1, 30);
    if (!(response && response.status && response.status !== 200)) {
      setSavedRoles(response);
    }
    setIsLoading(false);
  };

  const isNeedApprovalMessageInMarketing = async (isPublishAction) => {
    const body = {
      userBranchId: loginResponse?.branchId,
      isPublishAction,
      roleIds: savedRoles?.result?.map((item) => item.rolesId),
      userId: loginResponse?.userId,
    };
    try {
      const response = await IsNeedApprovalMessageInMarketing(body);

      if (!(response && response.status && response.status !== 200)) {
        return response;
      }
      return false;
    } catch (error) {

      return false;
    }
  };
  const getFieldsNeedingApproval = async () => {
    const userRoleIds = loginResponse?.roles.map((item) => item?.rolesId);

    const res = await GetFieldsNeedingApprovalForChange({ userRoleIds });
    if (!(res && res.status && res.status !== 200)) {
      setFieldsNeedsApproval(res);
    }
  };

  const onActionsButtonClicked = useCallback(
    async (activeAction) => {
      if (activeAction === ActionsButtonsEnum[3].id) {
        setIsOpenImportDialog(true);
      } else if (activeAction === ActionsButtonsEnum[1].id) {
        GlobalHistory.push("/home/units-sales/add?formType=1");
      } else if (activeAction === ActionsButtonsEnum[4].id) {
        GlobalHistory.push("/home/units-sales/unit-bulk-update");
      } else if (activeAction === ActionsButtonsEnum[15].id) {
        GlobalHistory.push("/home/units-sales/unit-transaction-parameters");
      } else if (activeAction === ActionsButtonsEnum[17].id) {
        setViewType("mark-as-available");
        setUnitsBulkStatusUpdateIsOpen(true);
      } else if (activeAction === ActionsButtonsEnum[18].id) {
        setViewType("move-to-draft");
        setUnitsBulkStatusUpdateIsOpen(true);
      } else if (activeAction === ActionsButtonsEnum[19].id) {
        setViewType("publish-to-website");
        setIsPublishedUnits(true);
        setUnitsBulkStatusUpdateIsOpen(true);
      } else if (activeAction === ActionsButtonsEnum[20].id) {
        setViewType("unpublish");
        setIsPublishedUnits(false);
        setUnitsBulkStatusUpdateIsOpen(true);
      } else if (activeAction === ActionsButtonsEnum[25].id) {
        setViewType("share-Unit");
        // setIsAvailableUnits(true)
        setUnitsBulkStatusUpdateIsOpen(true);
      } else if (activeAction === ActionsButtonsEnum[26].id) {
        setViewType("add-to-favourite");
        setIsOpenFavoriteDialog(true);
      }
    },
    [savedRoles]
  );

  const detailedCardSideActionClicked = useCallback(
    (actionEnum, activeData) => async (event) => {
      setHash(activeData.id);
      dispatch(ActiveItemActions.activeItemRequest(activeData));
      event.stopPropagation();
      if (actionEnum === ActionsEnum.folder.key) {
        GlobalHistory.push(
          `/home/units-sales/unit-profile-edit?formType=${activeData.unitTypeId}&id=${activeData.id}&operationType=${activeData.operationType}`
        );
      } else if (actionEnum === ActionsEnum.matching.key) {
        GlobalHistory.push(
          `/home/units-sales/unit-profile-edit?formType=${activeData.unitTypeId
          }&id=${activeData.id}&operationType=${activeData.operationType
          }&matching=${true}`
        );
      }
    },
    [dispatch]
  );
  const filterByChanged = (value) => {
    setSelectedOrderBy((item) => ({ ...item, filterBy: value }));
  };
  const orderByChanged = (value) => {
    setSelectedOrderBy((item) => ({ ...item, orderBy: value }));
  };
  const orderBySubmitted = (event) => {
    event.preventDefault();
    if (!selectedOrderBy.filterBy || !selectedOrderBy.orderBy) {
      if (orderBy.filterBy || orderBy.orderBy) setOrderBy({});
      return;
    }
    dispatch(
      GlobalOrderFilterActions.globalOrderFilterRequest({
        ...orderFilter,
        unitsSalesFilter: {
          filterBy: selectedOrderBy.filterBy,
          orderBy: selectedOrderBy.orderBy,
        },
      })
    );
    setOrderBy({
      filterBy: selectedOrderBy.filterBy,
      orderBy: selectedOrderBy.orderBy,
    });
    setOrderByToggler(false);
  };
  const cardCheckboxClicked = useCallback((itemIndex, element) => {
    setCheckedDetailedCards((items) => {
      const index = items.findIndex((item) => item === element.id);
      if (index !== -1) items.splice(index, 1);
      else items.push(element.id);
      return [...items];
    });
    setCheckedCards((items) => {
      const index = items.findIndex((item) => item.id === element.id);
      if (index !== -1) items.splice(index, 1);
      else items.push(element);
      return [...items];
    });
    setUnitsIdUpdateArray((items) => {
      if (items.includes(element.id)) {
        return items.filter((item) => item !== element.id);
      } else {
        return [...items, element.id];
      }
    });
  }, []);
  useEffect(() => {
    localStorage.setItem("bulk-assign-ids", JSON.stringify(checkedCards));
    localStorage.setItem("units-ids-Array", JSON.stringify(unitsIdUpdateArray));
  }, [checkedCards, unitsIdUpdateArray]);
  const reloadData = useCallback(() => {
    setFilter((item) => ({ ...item, pageIndex: 0 }));
    setActiveCard(null);
    setCheckedDetailedCards([]);
    setCheckedCards([]);
    sideMenuComponentUpdate(null);
    sideMenuIsOpenUpdate(false);
  }, [getUnitsData]);

  const getUnitDetails = async (unitId) => {
    try {
      const res = await GetUnitDetails(unitId);
      setLeadOwnerDetails(res.leadOwner);
      return res.leadOwner;
    } catch (error) {
    }
  };

  const onCardClick = useCallback(
    (item, selectedIndex) => async (event) => {
      event.stopPropagation();
      event.preventDefault();
      const selectedleadOwnerDetails = await getUnitDetails(item.id);
      setActiveCard(item);
      if (getSideMenuStatus()) setAdvanceSearchBtn(true);
      dispatch(ActiveItemActions.activeItemRequest(item));
      const activeData = detailsUnitsList.result[selectedIndex];
      const ownerMobileNumber = {
        iconClasses: "mdi mdi-point-of-sale",
        title: "owner-mobile-number",
        value: !selectedleadOwnerDetails?.phone
          ? "N/A"
          : selectedleadOwnerDetails?.phone,
      };

      const details = [
        ...activeData.details.slice(0, 1),
        ownerMobileNumber,
        ...activeData.details.slice(1),
      ];

      const updatedActiveData = {
        ...activeData,
        details,
      };

      sideMenuComponentUpdate(
        <CardDetailsComponent
          activeData={updatedActiveData}
          cardDetailsActionClicked={detailedCardSideActionClicked}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          reloadData={reloadData}
          displyOpenFileButton={displayOpenFileButton}
          displyMatchingButton={displayMatchingButton}
          showQuickTransaction={![6, 13, 14].includes(activeData.status)}
          onClickActiveData={() => {
            onOpenDialogHandler("Seller Information");
          }}
        />
      );
      sideMenuIsOpenUpdate(true);
    },
    [
      detailedCardSideActionClicked,
      detailsUnitsList.result,
      reloadData,
      advanceSearchBtn,
    ]
  );

  const changePublishStatus = (value) => {
    dispatch(
      GlobalOrderFilterActions.globalOrderFilterRequest({
        ...orderFilter,
        UnitSalesPublishStatusFilter: value,
      })
    );
  };

  const checkBulkDisabled = (enums) => {
    if (
      enums === ActionsButtonsEnum[4].id ||
      enums === ActionsButtonsEnum[17].id ||
      enums === ActionsButtonsEnum[18].id ||
      enums === ActionsButtonsEnum[19].id ||
      enums === ActionsButtonsEnum[20].id ||
      enums === ActionsButtonsEnum[25].id
    )
      return !(checkedCards && checkedCards.length >= 2);

    return false;
  };

  const onSelectClicked = useCallback(
    (row, rowIndex) => {
      setCheckedDetailedCards((items) => {
        const index = items.findIndex((item) => item === rowIndex);
        if (index !== -1) items.splice(index, 1);
        else items.push(rowIndex);
        return [...items];
      });

      setUnitsIdUpdateArray((items) => {
        if (items.includes(row.id)) {
          return items.filter((item) => item !== row.id);
        } else {
          return [...items, row.id];
        }
      });

      setCheckedCards((items) => {
        const index = items.findIndex((item) => item.id === row.id);
        if (index !== -1) items.splice(index, 1);
        else items.push(row);
        return [...items];
      });
    },
    [checkedCards]
  );

  const getIsSelected = useCallback(
    (row) =>
      checkedCards &&
      checkedCards.findIndex((item) => item.id === row.id) !== -1,
    [checkedCards]
  );

  const focusedRowChanged = useCallback(
    async (rowIndex, item) => {
      if (rowIndex !== -1) {
        const activeData = detailsUnitsList.result[rowIndex];
        const selectedleadOwnerDetails = await getUnitDetails(item.id);

        sideMenuComponentUpdate(
          <CardDetailsComponent
            activeData={detailsUnitsList.result[rowIndex]}
            cardDetailsActionClicked={detailedCardSideActionClicked}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            reloadData={reloadData}
            getUnitsData={getUnitsData}
            displyOpenFileButton={displayOpenFileButton}
            displyMatchingButton={displayMatchingButton}
            showQuickTransaction={![6, 13, 14].includes(activeData.status)}
            onClickActiveData={() => {
              onOpenDialogHandler("Seller Information");
            }}
          />
        );
        sideMenuIsOpenUpdate(true);
      } else {
        sideMenuComponentUpdate(<></>);
        sideMenuIsOpenUpdate(false);
      }
    },
    [detailedCardSideActionClicked, detailsUnitsList.result, reloadData]
  );

  const onFilterValuesChanged = (newValue) => {
    setUnitsTableFilter(newValue);
  };

  useEffect(() => {
    getAllSearchableFormFieldsByFormId();
  }, [getAllSearchableFormFieldsByFormId]);
  useEffect(() => {
    if (
      returnPropsByPermissions(
        UnitsSalesPermissions.ViewAndSearchInSaleUnits.permissionsId
      ) && activeActionType !== ViewTypesEnum.map.key
    ) {
      const currentPageIndex = localStorage.getItem(
        "unitsSalesCurrentPageIndex"
      );
      const pageIndex = JSON.parse(currentPageIndex);
      bottomBoxComponentUpdate(
        <PaginationComponent
          pageIndex={pageIndex || filter.pageIndex}
          pageSize={filter.pageSize}
          totalCount={detailsUnitsList.totalCount}
          onPageIndexChanged={onPageIndexChanged}
          onPageSizeChanged={onPageSizeChanged}
        />
      );
    }
  });
  useEffect(
    () => () => {
      bottomBoxComponentUpdate(null);
      sideMenuComponentUpdate(null);
      sideMenuIsOpenUpdate(false);
    },
    []
  );
  const onFilterStatusChange = (value) => {
    if (value?.length > 1) {
      value = value.filter(item => !!item)
    } else if (value?.length === 0) {
      value = [0]
    }
    setUnitStatus(value);
    dispatch(
      GlobalOrderFilterActions.globalOrderFilterRequest({
        ...orderFilter,
        UnitsSalesStatus: value,
      })
    );
  };

  const changePublishPortal = (value) => {
    setUnitsPortal(value);
    dispatch(
      GlobalOrderFilterActions.globalOrderFilterRequest({
        ...orderFilter,
        PublishSalePortal: value,
      })
    );
  };
  const changePropertyName = (value) => {
    setSelected({ id: "propertyName", value: value });
    const newCriteria = value?.property?.property_name || null;
    setUnitsPropertyName(newCriteria);
  };
  const changeCommunity = (value) => {
    setSelected({ id: "community", value: value });
    const newCriteria = value?.lookupItemName || null;
    setUnitsCommunity(newCriteria);
  };
  const changeUnitType = (value) => {
    setSelected({ id: "unitType", value: value });
    const newCriteria = value?.lookupItemName || null;
    setUnitsUnitType(newCriteria);
  };
  const changeSaleType = (value) => {
    setSelected({ id: "saleType", value: value });
    const newCriteria = value?.lookupItemName || null;
    setUnitsSaleType(newCriteria);
  };
  useEffect(() => {
    const data = localStorage.getItem("UnitSaleFilter");
    if (data) {
      const saleSearchFilter = JSON.parse(data);
      setSearchData(saleSearchFilter);
      if (saleSearchFilter && saleSearchFilter.length)
        searchChangedClickedWithoutFilter(saleSearchFilter);
    } else getUnitsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isFirst1) {
      if (searchData && searchData.length === 0) {
        localStorage.removeItem("UnitSaleFilter");
        setSearchedItem("");
      } else localStorage.setItem("UnitSaleFilter", JSON.stringify(searchData));
    } else setFirst1(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchData]);

  useEffect(() => {
    if (!isFirst) setFirst(true);
    else {
      const data = localStorage.getItem("UnitSaleFilter");
      if (data) {
        const dataConvert = JSON.parse(data);
        // const unitSaleFilter = saleListingAgentRole() ? dataConvert.filter((x) => x.key !== 'listing_agent.name') : dataConvert;
        if (dataConvert && dataConvert.length)
          searchChangedClickedWithoutFilter(dataConvert);
      } else if (searchData && searchData.length === 0) getUnitsData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filterSearchDto,
    filter,
    unitStatus,
    unitsPortal,
    unitPublishStatus,
    orderBy,
    unitsTableFilter,
    searchData,
    map,
    selectedPropertyForMap,
    reloadTrigger
  ]);



  const getLookupItem = useCallback(async () => {
    setIsAllFormFieldsLoading(true);
    const res = await GetLookupItems(1, 25, "PortalName");
    if (!(res && res.data && res.data.ErrorId)) {
      setPortalData(res && res.result);
    } else setPortalData([]);
  }, []);

  useEffect(() => {
    getLookupItem();
    getActiveUserRoles();
    getAllSystemTemplateByCategoryId()
    getFieldsNeedingApproval();
  }, []);



  useEffect(() => {
    if (isFirst && isDatePickerChanged) {
      const searchDataFilter = searchData.reduce((total, item) => {
        if (total[item.key]) {
          total[item.key].push({
            searchType: TableFilterTypesEnum.textInput.defaultSelectedOperator,
            value: item.value,
          });
        } else {
          total[item.key] = [
            {
              searchType:
                TableFilterTypesEnum.textInput.defaultSelectedOperator,
              value: item.value,
            },
          ];
        }
        return total;
      }, {});
      getUnitsData(searchDataFilter);
    }
  }, [dateFilter, isDatePickerChanged, searchData]);

  useEffect(() => {
    getAllFormFieldsByFormId();
  }, [getAllFormFieldsByFormId]);

  useEffect(() => {
    GetSideMenuStatus();
  }, [GetSideMenuStatus]);

  useEffect(() => {
    setDisplayOpenFileButton(
      havePermissionToViewDetails(UnitsVerticalTabsData.sale)
    );
    setDisplayMatchingButton(
      havePermissionToEditInformation(
        UnitsSalesPermissions.ViewUnitMatching.permissionsId
      )
    );
  }, [isFirst]);

  const onCloseDialogHandler = () => {
    setOwnerDetailsDialog({
      ...ownerDetailsDialog,
      isOpen: false,
      titleText: "",
    });
  };

  const onUnitsBulkCloseDialogHandler = () => {
    setUnitsBulkStatusUpdateIsOpen(false);
  };

  const onOpenDialogHandler = (titleText) => {
    setOwnerDetailsDialog({
      ...ownerDetailsDialog,
      isOpen: true,
      titleText,
    });
  };

  const getCommaSeparatedIds = (IdsArray) => {
    let IdsString = IdsArray.reduce(
      (result, item) => result + item.id + ",",
      ""
    );
    return IdsString.slice(0, IdsString.length - 1);
  };

  const onFilterChanged = useCallback(
    (activeSelectedAction) => {
      if (
        activeSelectedAction === ActionsButtonsEnum[17].id &&
        ((unitStatus.length === 3 && unitStatus.includes("Available")) ||
          unitStatus.length !== 3)
      ) {
        onActionButtonChanged(ActionsButtonsEnum[1].id);
        setActionButtonsKey(generateUniqueKey());
      } else if (
        activeSelectedAction === ActionsButtonsEnum[18].id &&
        ((unitStatus.length === 3 && unitStatus.includes("Draft")) ||
          unitStatus.length !== 3)
      ) {
        onActionButtonChanged(ActionsButtonsEnum[1].id);
        setActionButtonsKey(generateUniqueKey());
      } else if (
        activeSelectedAction === ActionsButtonsEnum[19].id &&
        ((unitStatus.length === 1 && !unitStatus.includes("Available")) ||
          unitStatus.length !== 1 ||
          +unitPublishStatus !== 2)
      ) {
        onActionButtonChanged(ActionsButtonsEnum[1].id);
        setActionButtonsKey(generateUniqueKey());
      } else if (
        activeSelectedAction === ActionsButtonsEnum[25].id &&
        ((unitStatus.length === 1 && !unitStatus.includes("Available")) ||
          unitStatus.length !== 1 ||
          +unitPublishStatus !== 2)
      ) {
        onActionButtonChanged(ActionsButtonsEnum[1].id);
        setActionButtonsKey(generateUniqueKey());
      } else if (
        activeSelectedAction === ActionsButtonsEnum[20].id &&
        +unitPublishStatus !== 1
      ) {
        onActionButtonChanged(ActionsButtonsEnum[1].id);
        setActionButtonsKey(generateUniqueKey());
      }
    },
    [activeSelectedAction, unitStatus, unitPublishStatus]
  );

  useEffect(() => {
    onFilterChanged(activeSelectedAction);
  }, [activeSelectedAction, unitStatus, unitPublishStatus]);

  useEffect(() => {
    if (!isMapView && detailsUnitsList.result.length === 0) {
      reloadData();
    }
  }, [isMapView]);

  const onPinClick = ({ name }) => () => {
    setUnitCardsByProperty(true)
    setSelectedPropertyForMap(
      { key: 'property_name', title: 'Property Name *', value: name }
    );
    sideMenuIsOpenUpdate(true)

  }
  useEffect(() => {
    if (unitsCardsByProperty && getSideMenuStatus() && isMapView && advanceSearchBtn) {
      sideMenuComponentUpdate(
        <UnitsByProperty
          {...detailsUnitsList}
          isLoading={isLoadingAllUnits}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          pageIndex={filter.pageIndex}
          pageSize={filter.pageSize}
          onPageIndexChange={(index) =>
            setFilter((prevFilter) => ({ ...prevFilter, pageIndex: index }))
          }
          onPageSizeChange={(size) =>
            setFilter((prevFilter) => ({ ...prevFilter, pageSize: size }))
          }
          selectedCards={selectedUnitsCards}
          onSelectCard={(item) =>
            setSelectedUnitsCards((prevSelected) => prevSelected.concat(item))
          }
          onRemoveCard={(id) =>
            setSelectedUnitsCards((prevSelected) => prevSelected.filter(unit => unit.id !== id))
          }
          propertyName={selectedPropertyForMap?.value}
          isSendProposalDisabled={selectedUnitsCards.length === 0}
          openTemplateDialog={()=> setIsSelectTemplateOpen(true)}
        />
      );
    }
  }, [unitsCardsByProperty, isLoadingAllUnits, detailsUnitsList, selectedUnitsCards])

  useEffect(() => {
    setUnitCardsByProperty(getSideMenuStatus())
  }, [getSideMenuStatus()])


  useEffect(() => {
    if (activeActionType !== ViewTypesEnum.map.key) {
      setMap(null);
      setSelectedPropertyForMap(null);
    }
  }, [activeActionType])

  return (
    <>
      <div className="view-wrapper sale-view">
        {!isOpen && !isMapView && <Spinner isActive={isLoadingAllUnits} />}
        <div className="d-flex-column">
          <div className="header-section">
            <Spinner isActive={isMapView && isLoadingAllUnits} isAbsolute />
            <div className="filter-section">
              <div className="section">
                <ActionsButtonsComponent
                  key={actionButtonsKey}
                  permissionsList={Object.values(UnitsSalesPermissions)}
                  addPermissionsId={
                    UnitsSalesPermissions.AddNewUnit.permissionsId
                  }
                  selectPermissionsId={[
                    UnitsSalesPermissions.ImportUnits.permissionsId,
                    UnitsSalesPermissions.UnitBulkUpdate.permissionsId,
                    UnitsSalesPermissions.AddNewUnit.permissionsId,
                    UnitsSalesPermissions.AgentTransactionButton.permissionsId,
                    UnitsSalesPermissions.ShareUnits.permissionsId,
                  ]}
                  checkDisable={checkBulkDisabled}
                  onActionsButtonClicked={onActionsButtonClicked}
                  onActionButtonChanged={onActionButtonChanged}
                  enableBulk={getIsAllowedPermission(
                    Object.values(UnitsSalesPermissions),
                    loginResponse,
                    UnitsSalesPermissions.UnitBulkUpdate.permissionsId
                  )}
                  enableImport={getIsAllowedPermission(
                    Object.values(UnitsSalesPermissions),
                    loginResponse,
                    UnitsSalesPermissions.ImportUnits.permissionsId
                  )}
                  enableMakeTransaction={returnPropsByPermissions(
                    UnitsSalesPermissions.AgentTransactionButton.permissionsId
                  )}
                  enableMarkAsAvailable
                  enableMoveToDraft
                  enableAddToFavourite
                  enableShareUnit={getIsAllowedPermission(
                    Object.values(UnitsSalesPermissions),
                    loginResponse,
                    UnitsSalesPermissions.ShareUnits.permissionsId
                  )}
                  enablePublishToWebsite
                  enableUnpublish
                  separateMakeTransactionButton
                  wrapperClasses="wrap-flex"
                />
              </div>
              <div className="section autocomplete-section">
                <PermissionsComponent
                  permissionsList={Object.values(UnitsSalesPermissions)}
                  permissionsId={
                    UnitsSalesPermissions.ViewAndSearchInSaleUnits.permissionsId
                  }
                >
                  <div className="d-flex-column px-2 w-100 p-relative">
                    <div className="d-flex-column w-100">
                      <AutocompleteComponent
                        data={searchableFormFields.map((item) => ({
                          key: item.key,
                          title: item.title,
                        }))}
                        selectedValues={searchData}
                        parentTranslationPath={parentTranslationPath}
                        displayLabel={displayedLabel}
                        disabledOptions={disabledOptions}
                        onChange={filterOnChange}
                        searchClicked={searchClicked}
                        chipsLabel={chipsLabel}
                        getOptionSelected={(option) =>
                          searchData.findIndex(
                            (item) =>
                              item.key === option.key &&
                              item.value === searchInputValue
                          ) !== -1
                        }
                        tagValues={searchData}
                        inputValue={searchInputValue}
                        onInputChange={inputValueChanged}
                        inputLabel="filter"
                        inputPlaceholder="search-units"
                      />
                    </div>
                    <div className="d-inline-flex pl-5-reversed">
                      <div className="mt-2 mr-1-reversed">
                        <SelectComponet
                          data={Object.values(UnitsFilterStatusEnum.sale)}
                          emptyItem={{
                            value: 0,
                            text: "select-status",
                            isDisabled: true,
                            isHiddenOnOpen: true,
                          }}
                          isDisabled={isStatusFilterDisabled}
                          value={unitStatus}
                          valueInput="value"
                          textInput="title"
                          multiple
                          onSelectChanged={onFilterStatusChange}
                          wrapperClasses="w-auto"
                          themeClass="theme-default"
                          idRef="Unit_Status"
                          startAdornment={
                            (!isStatusFilterDisabled &&
                              unitStatus.findIndex((item) => item === 0) ===
                              -1 && (
                                <Button
                                  title="Remove Filter"
                                  className="mdi mdi-filter-off mr-4 c-warning"
                                  onClick={() => onFilterStatusChange([0])}
                                />
                              )) ||
                            ""
                          }
                          parentTranslationPath={parentTranslationPath}
                          translationPath={translationPath}
                          translationPathForData={translationPath}
                        />
                      </div>
                      <div className="mt-2 mr-1-reversed">
                        <SelectComponet
                          idRef="Unit_portal"
                          data={PortalData}
                          emptyItem={{
                            value: 0,
                            text: "publish-portal",
                            isDisabled: true,
                            isHiddenOnOpen: true,
                          }}
                          value={unitsPortal}
                          valueInput="lookupItemId"
                          textInput="lookupItemName"
                          multiple
                          onSelectChanged={changePublishPortal}
                          wrapperClasses="w-auto"
                          themeClass="theme-default"
                          startAdornment={
                            (unitsPortal.findIndex((item) => item === 0) ===
                              -1 && (
                                <Button
                                  title="Remove Filter"
                                  className="mdi mdi-filter-off mr-4 c-warning"
                                  onClick={() => changePublishPortal([0])}
                                />
                              )) ||
                            ""
                          }
                          parentTranslationPath={parentTranslationPath}
                          translationPath={translationPath}
                          translationPathForData={translationPath}
                        />
                      </div>
                      <div className="mt-2 mr-1-reversed">
                        <SelectComponet
                          data={[
                            { key: 1, name: "published" },
                            { key: 2, name: "unpublished" },
                          ]}
                          emptyItem={{
                            value: 0,
                            text: "select-unit-publish",
                            isDisabled: false,
                          }}
                          value={unitPublishStatus || 0}
                          valueInput="key"
                          textInput="name"
                          onSelectChanged={(value) => {
                            changePublishStatus(value);
                            setUnitPublishStatus(value);
                          }}
                          wrapperClasses="w-auto"
                          themeClass="theme-default"
                          idRef="Select_Unit_Publish"
                          parentTranslationPath={parentTranslationPath}
                          translationPath={translationPath}
                          translationPathForData={translationPath}
                        />
                      </div>
                      <div className="w-100 mt-2  ">
                        <Inputs
                          value={searchedItem}
                          onKeyUp={(e) => searchHandler(e.target.value)}
                          idRef="activitiesSearchRef"
                          labelClasses="mt-4"
                          onInputChanged={(e) =>
                            setSearchedItem(e.target.value)
                          }
                          inputPlaceholder={t(
                            `${translationPath}address-filter`
                          )}
                        />
                      </div>
                      <div className="w-100 customDatePicker">
                        <div className="date-type-select">
                          <SelectComponet
                            data={[
                              {
                                key: 1,
                                value: `${translationPath}created-date`,
                              },
                              {
                                key: 2,
                                value: `${translationPath}update-on-date`,
                              },
                              {
                                key: 4,
                                value: `${translationPath}listing-date`,
                              },
                              {
                                key: 5,
                                value: `${translationPath}publish-date`,
                              },
                              {
                                key: 6,
                                value: `${translationPath}expired-listing-date`,
                              },
                              //  { key: 7, value: `${translationPath}sale-transacted-date` },
                              //  { key: 8, value: `${translationPath}sale-reserved-transaction-date` }
                            ]}
                            defaultValue={{
                              key: 1,
                              value: `${translationPath}created-date`,
                            }}
                            value={dateFilter.selectedDateType || 1}
                            valueInput="key"
                            textInput="value"
                            isDisabled={isLoading}
                            onSelectChanged={(value) => {
                              setIsDatePickerChanged(true);
                              setDateFilter((f) => ({
                                ...f,
                                selectedDateType: value,
                              }));
                            }}
                            wrapperClasses="w-auto"
                            themeClass="theme-transparent"
                            idRef="Date_Select"
                            parentTranslationPath={parentTranslationPath}
                            translationPath={translationPath}
                            translationPathForData={translationPath}
                          />
                        </div>
                        <DateRangePickerComponent
                          onClearClicked={() => {
                            setDateFilter(dateRangeDefault);
                            setIsDatePickerChanged(true);
                          }}
                          onDialogClose={() => {
                            setIsDatePickerChanged(true);
                          }}
                          ranges={[dateFilter]}
                          parentTranslationPath={parentTranslationPath}
                          translationPath={translationPath}
                          onDateChanged={(selectedDate) =>
                            setDateFilter((item) => ({
                              ...item,
                              startDate:
                                selectedDate.selection &&
                                selectedDate.selection.startDate,
                              endDate: new Date(
                                moment(
                                  selectedDate.selection &&
                                  selectedDate.selection.endDate
                                ).endOf("day")
                              ),
                              key: "selection",
                            }))
                          }
                          isDisabled={isLoading}
                        />
                      </div>
                    </div>
                    <div className="d-flex pl-5-reversed w-100 ">
                      <div className="mt-2 mr-1-reversed w-50">
                        <AutocompleteComponent
                          inputPlaceholder="property-name"
                          data={data.propertyName}
                          parentTranslationPath={parentTranslationPath}
                          translationPath={translationPath}
                          withoutSearchButton
                          selectedValues={selected.propertyName}
                          onOpen={() => {
                            if (
                              data.propertyName &&
                              data.propertyName.length === 0
                            )
                              getAllProperties();
                          }}
                          onInputKeyUp={(event) => {
                            const { value } = event.target;
                            if (searchTimer.current)
                              clearTimeout(searchTimer.current);
                            searchTimer.current = setTimeout(() => {
                              getAllProperties(value);
                            }, 700);
                          }}
                          displayLabel={(option) =>
                            option.property.property_name || ""
                          }
                          onChange={(_, newValue) => {
                            changePropertyName(newValue);
                            setReloadTrigger(!reloadTrigger);
                          }}
                          chipsLabel={(option) =>
                            option.property.property_name || ""
                          }
                          multiple={false}
                        />
                      </div>

                      <div className="mt-2 mr-1-reversed w-50">
                        <AutocompleteComponent
                          inputPlaceholder="community"
                          data={data.community}
                          parentTranslationPath={parentTranslationPath}
                          translationPath={translationPath}
                          withoutSearchButton
                          selectedValues={selected.community}
                          onOpen={() => {
                            if (data.community && data.community.length === 0)
                              getCommunityType();
                          }}
                          chipsLabel={(option) => option.lookupItemName || ""}
                          multiple={false}
                          onChange={(_, newValue) => {
                            changeCommunity(newValue);
                            setReloadTrigger(!reloadTrigger);
                          }}
                          onInputKeyUp={(event) => {
                            const { value } = event.target;
                            if (searchTimer.current)
                              clearTimeout(searchTimer.current);
                            searchTimer.current = setTimeout(() => {
                              getCommunityType();
                            }, 700);
                          }}
                          displayLabel={(option) => option.lookupItemName || ""}
                        />
                      </div>
                      <div className="mt-2 mr-1-reversed w-50">
                        <AutocompleteComponent
                          inputPlaceholder="unit-type"
                          data={data.unitType}
                          parentTranslationPath={parentTranslationPath}
                          translationPath={translationPath}
                          withoutSearchButton
                          selectedValues={selected.unitType}
                          onOpen={() => {
                            if (data.unitType && data.unitType.length === 0)
                              getLookupItemsByLookupTypeName("unitType");
                          }}
                          displayLabel={(option) => option.lookupItemName || ""}
                          onInputKeyUp={(event) => {
                            const { value } = event.target;
                            if (searchTimer.current)
                              clearTimeout(searchTimer.current);
                            searchTimer.current = setTimeout(() => {
                              getLookupItemsByLookupTypeName(value);
                            }, 700);
                          }}
                          chipsLabel={(option) => option.lookupItemName || ""}
                          multiple={false}
                          onChange={(_, newValue) => {
                            changeUnitType(newValue);
                            setReloadTrigger(!reloadTrigger);
                          }}
                        />
                      </div>

                      <div className="mt-2 mr-1-reversed w-50">
                        <AutocompleteComponent
                          inputPlaceholder="sale-type"
                          data={data.saleType}
                          parentTranslationPath={parentTranslationPath}
                          translationPath={translationPath}
                          withoutSearchButton
                          selectedValues={selected.saleType}
                          onOpen={() => {
                            if (data.saleType && data.saleType.length === 0)
                              getSaleType(StaticLookupsIds.UnitSaleType);
                          }}
                          displayLabel={(option) => option.lookupItemName || ""}
                          onInputKeyUp={(event) => {
                            const { value } = event.target;
                            if (searchTimer.current)
                              clearTimeout(searchTimer.current);
                            searchTimer.current = setTimeout(() => {
                              getSaleType(value);
                            }, 700);
                          }}
                          chipsLabel={(option) => option.lookupItemName || ""}
                          multiple={false}
                          onChange={(_, newValue) => {
                            changeSaleType(newValue);
                            setReloadTrigger(!reloadTrigger);
                          }}
                        />
                      </div>



                    </div>
                    <div className="d-flex pl-5-reversed w-100">
                      <div className="mt-2 mr-1-reversed w-25">
                        <AutocompleteComponent
                          inputPlaceholder={t(`${translationPath}Unit-Reference-Number`)}
                          data={data.unitRef}
                          parentTranslationPath={parentTranslationPath}
                          translationPath={translationPath}
                          withoutSearchButton
                          isLoading={SearchedItemUnitReferenceNumber}
                          displayLabel={(option) => option?.unitRefNo || ""}
                          onInputKeyUp={(event) => {
                            const { value } = event.target;
                            if (searchTimer.current)
                              clearTimeout(searchTimer.current);
                            searchTimer.current = setTimeout(() => {
                              GetAllUnitsRef(value);
                            }, 700);
                          }}
                          chipsLabel={(option) => option?.unitRefNo || ""}
                          multiple={false}
                          onChange={(_, newValue) => {
                            if (newValue) {
                              SearchHandlerComponent("unit_ref_no", "Unit Ref No.", (newValue && newValue.unitRefNo) || "", 1300)
                              setSelected({id:'unitRefNo' ,value:newValue})
                            } else {
                              const index = searchData.findIndex(
                                (item) => item.key === "unit_ref_no"
                              );
                              if (index !== -1) searchData.splice(index, 1);
                              setFilterSearchDto(null);
                              localStorage.removeItem("UnitSaleFilter");
                              getUnitsData();
                            }
                          }}
                         selectedValues={selected.unitRefNo}
                        />
                      </div>
                    </div>
                  </div>
                </PermissionsComponent>
                <div className="view-search-wrapper">
                  <PermissionsComponent
                    permissionsList={Object.values(UnitsSalesPermissions)}
                    permissionsId={
                      UnitsSalesPermissions.ViewAndSearchInSaleUnits
                        .permissionsId
                    }
                  >
                    <ViewTypes
                      onTypeChanged={onTypeChanged}
                      initialActiveType={activeActionType}
                      className="mb-3"
                      withMap
                    />
                    <span className="advance-search">
                      <ButtonBase
                        onClick={onAdvanceSearchClick}
                        id="AdvanceSearch_bbt"
                        disabled={isAllFormFieldsLoading}
                        className={`btns theme-solid ${isAdvanceSearchActive &&
                          !isAllFormFieldsLoading &&
                          "is-active-filter"
                          }`}
                      >
                        <span
                          className={`${advanceSearchBtn
                              ? "mdi mdi-arrow-expand-left"
                              : "mdi mdi-arrow-expand-right"
                            } mr-1`}
                        />
                        {t(`${translationPath}advance-search`)}
                      </ButtonBase>
                    </span>
                    <ButtonBase
                      onClick={onClearedAllFiltersClick}
                      id="onClearedAllFiltersref"
                      disabled={isAllFormFieldsLoading}
                      className="btns theme-solid bg-danger clear-all-btn"
                    >
                      <span className="mdi mdi-filter-remove m-1" />
                      {t(`${translationPath}clear-filters`)}
                    </ButtonBase>
                  </PermissionsComponent>
                  <PermissionsComponent
                    permissionsList={Object.values(UnitsSalesPermissions)}
                    permissionsId={
                      UnitsSalesPermissions.UnitDuplications.permissionsId
                    }
                  >
                    <ButtonBase
                      onClick={checkDuplicatedUnitsButton}
                      id="checkDuplicatedUnitsButton"
                      disabled={isAllFormFieldsLoading}
                      className="btns theme-solid w-100 m-2"
                    >
                      {t(`${translationPath}duplicates`)}
                    </ButtonBase>
                  </PermissionsComponent>
                  <ButtonBase
                    onClick={() => {
                      setIsOpenFavoriteDialog(true);
                      setViewType("");
                    }}
                    id="cheitsButton"
                    className="btns theme-solid w-100 m-2"
                  >
                    <span className="mdi mdi-heart-box-outline ml-1"></span>
                    {t(`${translationPath}View-Favourite`)}
                  </ButtonBase>
                </div>
              </div>
            </div>
            <PermissionsComponent
              permissionsList={Object.values(UnitsSalesPermissions)}
              permissionsId={
                UnitsSalesPermissions.ViewAndSearchInSaleUnits.permissionsId
              }
            >
              {selectedUnitsCards.length !== 0 && (
                <div className=" p-3">
                  <div className=" d-flex">
                    <Button variant='contained' className="btns theme-solid" color='primary' onClick={() => setIsSelectedUnitDialogOpen(true)}>
                      <strong>({selectedUnitsCards.length}) selected units</strong>
                    </Button>
                    <SelectComponet
                      data={templatesProposal}
                      keyLoopBy="systemTemplateId"
                      valueInput="systemTemplateId"
                      textInput="systemTemplateName"
                      value={activeTemplateId}
                      emptyItem={{
                        value: null,
                        text: "select-template",
                        isDisabled: false,
                      }}
                      keyValue="actionsbuttons"
                      idRef="contactsActionsRef"
                      onSelectChanged={(value) => {
                        setActiveTemplateId(value);
                        if (value) {
                          setIsProposalDialogOpen(true)
                        }
                      }}
                      themeClass="theme-action-buttons"
                      translationPath={translationPath}
                      translationPathForData={translationPath}
                      wrapperClasses="bg-secondary c-white mx-2"
                      parentTranslationPath={parentTranslationPath}
                    />
                  </div>
                </div>
              )}
              <div className="d-flex px-2">
                <span className="mx-2 mt-1">
                  {t(`${translationPath}units`)}
                </span>
                <span className="separator-v s-primary s-reverse s-h-25px mt-1" />
                <span className="px-2 d-flex">
                  <span className="texts-large mt-1">
                    {t(`${translationPath}order-by`)}:
                  </span>
                  <div className="px-2">
                    <SelectComponet
                      idRef="filterByRef"
                      data={[
                        { id: "createdOn", filterBy: "created-on" },
                        { id: "updateOn", filterBy: "last-updated" },
                        {
                          id: "LastUpdatedOwner",
                          filterBy: "last-updated-owner",
                        },
                      ]}
                      value={selectedOrderBy.filterBy}
                      onSelectChanged={filterByChanged}
                      wrapperClasses="mb-3"
                      isRequired
                      valueInput="id"
                      textInput="filterBy"
                      emptyItem={{
                        value: null,
                        text: "select-filter-by",
                        isDisabled: false,
                      }}
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                      translationPathForData={translationPath}
                    />
                  </div>
                  <div className="px-2">
                    <SelectComponet
                      idRef="orderByRef"
                      data={[
                        { id: 1, orderBy: "ascending" },
                        { id: 2, orderBy: "descending" },
                      ]}
                      value={selectedOrderBy.orderBy}
                      onSelectChanged={orderByChanged}
                      wrapperClasses="mb-3"
                      isRequired
                      valueInput="id"
                      textInput="orderBy"
                      emptyItem={{
                        value: null,
                        text: "select-sort-by",
                        isDisabled: false,
                      }}
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                      translationPathForData={translationPath}
                    />
                  </div>
                  <div className="mt-1">
                    <ButtonBase
                      className="btns theme-solid"
                      onClick={orderBySubmitted}
                      id="action_apply"
                      disabled={
                        !selectedOrderBy.filterBy || !selectedOrderBy.orderBy
                      }
                    >
                      <span>{t(`${translationPath}apply`)}</span>
                    </ButtonBase>
                  </div>
                </span>
              </div>
            </PermissionsComponent>
          </div>
          {[ViewTypesEnum.cards.key, ViewTypesEnum.cardsExpanded.key].includes(activeActionType) && (
            <>
              <div className="body-section">
                <PermissionsComponent
                  permissionsList={Object.values(UnitsSalesPermissions)}
                  permissionsId={
                    UnitsSalesPermissions.ViewAndSearchInSaleUnits.permissionsId
                  }
                >
                  <UnitsCardsComponent
                    data={detailsUnitsList}
                    isExpanded={isExpanded}
                    onCardClicked={onCardClick}
                    onFooterActionsClicked={detailedCardSideActionClicked}
                    checkedDetailedCards={checkedDetailedCards}
                    parentTranslationPath={parentTranslationPath}
                    folderIdValue={folderIdValue}
                    handleRemoveFavorite={handleRemoveFavorite}
                    translationPath={translationPath}
                    onCardCheckboxClick={cardCheckboxClicked}
                    activeCard={activeCard}
                    unitsIdUpdateArray={unitsIdUpdateArray}
                    setUnitsIdUpdateArray={setUnitsIdUpdateArray}
                    withCheckbox={
                      activeSelectedAction === "bulk-update" ||
                      activeSelectedAction === "unit-status-bulk-update" ||
                      activeSelectedAction === "move-to-draft" ||
                      activeSelectedAction === "publish-to-website" ||
                      activeSelectedAction === "mark-as-available" ||
                      activeSelectedAction === "add-to-favourite" ||
                      activeSelectedAction === "unpublish" ||
                      activeSelectedAction === "add-to-favourite" ||
                      activeSelectedAction === "share-unit"
                    }
                    displyOpenFileButton={displayOpenFileButton}
                    displyMatchingButton={displayMatchingButton}
                  />
                </PermissionsComponent>
              </div>
            </>
          )}
          {activeActionType === ViewTypesEnum.tableView.key && (
            <PermissionsComponent
              permissionsList={Object.values(UnitsSalesPermissions)}
              permissionsId={
                UnitsSalesPermissions.ViewAndSearchInSaleUnits.permissionsId
              }
            >
              <UnitsSalesTableComponent
                detailsUnitsList={detailsUnitsList}
                tableActionClicked={tableActionClicked}
                onPageIndexChanged={onPageIndexChanged}
                onPageSizeChanged={onPageSizeChanged}
                unitsTableFilter={unitsTableFilter}
                onFilterValuesChanged={onFilterValuesChanged}
                filter={filter}
                parentTranslationPath={parentTranslationPath}
                focusedRowChanged={focusedRowChanged}
                onSelectClicked={onSelectClicked}
                setCheckedCards={setCheckedCards}
                checkedCardsIds={checkedCards}
                getIsSelected={getIsSelected}
                activeSelectedAction={activeSelectedAction}
                setOrderBy={setOrderBy}
                isClearFiltersClicked={isClearFiltersClicked}
                setIsClearFiltersClicked={setIsClearFiltersClicked}
                displyOpenFileButton={displayOpenFileButton}
                displyMatchingButton={displayMatchingButton}
                unitsIdUpdateArray={unitsIdUpdateArray}
                setUnitsIdUpdateArray={setUnitsIdUpdateArray}
                localStorageKey="UnitsSalesFilter"
              />
            </PermissionsComponent>
          )}
        </div>
        {activeActionType === ViewTypesEnum.map.key && (
          <UnitsMapView
            key={mapKey}
            setMap={map => {
              setIsMapChanged(true)
              setMap(map)
            }}
            totalCount={detailsPropertyByUnitsList.totalCount}
            totalUnitsCount={detailsPropertyByUnitsList.result?.[0]?.totalCountUnits}
            isLoading={isLoadingAllUnits}
            locations={detailsPropertyByUnitsList.result
              .map((property, index) => {
                return {
                  latitude: property?.latitude,
                  longitude: property?.longitude,
                  name: property?.propertyName,
                  id: property?.propertyId,
                  propertyTypeId: property?.propertyTypeId,
                  noOfUnits: property?.noOfUnits,
                  createdOn: property?.createdOn,
                  propertyStatus: property?.property_plan,
                  index,
                };
              })}
            onPinClick={onPinClick}
            stopChangeCapturing={stopChangeCapturing}
          />
        )}
        <UnitsImportDialog
          isOpen={isOpenImportDialog}
          isOpenChanged={() => setIsOpenImportDialog(false)}
        />
        {addActivity && (
          <ActivitiesManagementDialog
            open={addActivity}
            onSave={() => {
              setAddActivity(false);
            }}
            close={() => {
              setAddActivity(false);
            }}
            actionItemId={activeItem?.id}
            translationPath={""}
            parentTranslationPath={"ActivitiesView"}
            unitOperationId={activeItem?.operation_type?.lookupItemId}
            unitSaleTypeId={activeItem?.sale_type?.lookupItemId}
            unitTypeId={activeItem?.unit_type?.lookupItemId}
          />
        )}
      </div>

      {ownerDetailsDialog.isOpen && (
        <DialogComponent
          isOpen={ownerDetailsDialog.isOpen}
          onCloseClicked={onCloseDialogHandler}
          titleText={ownerDetailsDialog.titleText}
          maxWidth="lg"
          dialogContent={
            <OwnerDetailsDialog
              data={leadOwnerDetails}
              translationPath={parentTranslationPath}
              leadOwnerDetails={leadOwnerDetails}
              unitId={activeItem && activeItem.id}
            />
          }
        />
      )}
      {unitsBulkStatusUpdateIsOpen && (
        <DialogComponent
          isOpen={unitsBulkStatusUpdateIsOpen}
          titleText={t("unit-status-bulk-update")}
          maxWidth="lg"
          dialogContent={
            <>
              <UnitsBulkStatusUpdateDialog
                onUnitsBulkCloseDialogHandler={onUnitsBulkCloseDialogHandler}
                unitsIdUpdateArray={unitsIdUpdateArray}
                setUnitsIdUpdateArray={setUnitsIdUpdateArray}
                viewType={viewType}
                reloadData={reloadData}
                setViewType={setViewType}
                isPublishedUnits={isPublishedUnits}
                onClearedAllFiltersClick={onClearedAllFiltersClick}
                checkedCards={checkedCards}
                unitOperationSource={unitOperationSource.sale.key}
                isNeedApprovalMessageInMarketing={
                  isNeedApprovalMessageInMarketing
                }
                setNeedsApprovalDialog={setNeedsApprovalDialog}
                setTypeOfRequest={setTypeOfRequest}
                fieldsNeedsApproval={fieldsNeedsApproval}
                setFieldsNeedsApprovalDialog={setFieldsNeedsApprovalDialog}
              />
            </>
          }
        />
      )}

      {needsApprovalDialog && (
        <NeedsApprovalDialogBulkUnit
          setIsDialogOpen={setNeedsApprovalDialog}
          isDialogOpen={needsApprovalDialog}
          loginResponse={loginResponse}
          typeOfRequest={typeOfRequest}
          unitsIdUpdateArray={unitsIdUpdateArray}
          reloadData={reloadData}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          checkedCards={checkedCards}
          onUnitsBulkCloseDialogHandler={onUnitsBulkCloseDialogHandler}
        />
      )}
      {fieldsNeedsApprovalDialog && (
        <FieldsNeedsApprovalDialogBulkUnits
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          setIsDialogOpen={setFieldsNeedsApprovalDialog}
          isDialogOpen={fieldsNeedsApprovalDialog}
          checkedCards={checkedCards}
          reloadData={reloadData}
          onUnitsBulkCloseDialogHandler={onUnitsBulkCloseDialogHandler}
          unitsIdUpdateArray={unitsIdUpdateArray}
          isUpdatedState
        />
      )}
      {isSelectedUnitDialogOpen && <DialogComponent
        isOpen
        maxWidth={'lg'}
        titleText={'selected-units'}
        onCloseClicked={() => setIsSelectedUnitDialogOpen(false)}
        onCancelClicked={() => setIsSelectedUnitDialogOpen(false)}
        dialogContent={
          <div className=" d-flex fj-center flex-wrap gap-1">
            {Children.toArray(selectedUnitsCards.map((item) => (
              <UnitCard
                item={item}
                onRemoveCard={(id) =>
                  setSelectedUnitsCards((prev) =>
                    prev.filter((unit) => unit.id !== id)
                  )
                }
                selectedCards={selectedUnitsCards}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
              />
            )))}
          </div>
        }
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        onSaveClicked={e=> {
          e.preventDefault();
          if (!activeTemplateId) {
            setIsSelectTemplateOpen(true)
          } else {
            setIsProposalDialogOpen(true);
          }
        }}
        saveText={'send-proposal'}
      />}
      {isSelectTemplateOpen && (
        <DialogComponent
          titleText={'select-template'}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          isOpen={isSelectTemplateOpen}
          maxWidth={'sm'}
          onCloseClicked={() => setIsSelectTemplateOpen(false)}
          dialogContent={
            <SelectComponet
              data={templatesProposal}
              keyLoopBy='systemTemplateId'
              valueInput='systemTemplateId'
              textInput='systemTemplateName'
              wrapperClasses={'mb-2'}
              value={activeTemplateId}
              emptyItem={{
                value: null,
                text: 'select-template',
                isDisabled: false,
              }}
              keyValue='actionsbuttons'
              idRef='contactsActionsRef'
              onSelectChanged={(value) => {
                setActiveTemplateId(value);
                if (value) {
                  setIsProposalDialogOpen(true);
                }
              }}
              translationPath={translationPath}
              translationPathForData={translationPath}
              parentTranslationPath={parentTranslationPath}
            />
          }
        />
      )}      
      {isProposalDialogOpen && (
        <UnitTemplateDialogPreviewDialog
          activeItem={activeTemplateId}
          isOpen={isProposalDialogOpen}
          isOpenChanged={() =>
            setIsProposalDialogOpen(false)
          }
          unitIDsList={getCommaSeparatedIds(selectedUnitsCards)}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      )}
      {isOpenFavoriteDialog && (
        <FavoriteDFM
          isOpen={isOpenFavoriteDialog}
          closeHandler={() => { setIsOpenFavoriteDialog(false); setActiveItem(null); }}
          parentTranslationPath={'ShareUnit'}
          translationPath=''
          FavoriteType='UNIT'
          AddTOlistView={viewType === "add-to-favourite" || false}
          checkedDetailed={checkedCards && checkedCards}
          Rest={() => {
            setActiveItem(null);
            setCheckedCards([]);
            setCheckedDetailedCards([]);
            onActionButtonChanged(ActionsButtonsEnum[1].id);
            setActionButtonsKey(generateUniqueKey());
          }}
          checkDesabled={(checkedCards && checkedCards.length > 0)}
          setfolderIdValue={(item) => { setFolderIdValue(item) }}
          setFilterfolderId={(item) => { SearchHandlerComponent("FavoriteFolderId", `Favorite Folder  ${item.name || ''} ID  `, item.favoriteFolderId, 300) }}
          GetAllDataInSearch={() => { }}
          reloadData={reloadData}
        />
      )}
    </>
  );
};
