export const ContactFormTypeEnum = {
  INDIVIDUAL: {
    name: 'Individual',
    value: 1,
  },
  CORPORATE: {
    name: 'Corporate',
    value: 2,
  },
};
