import React from "react";
import { Avatar, Box, Typography } from "@material-ui/core";
import { CustomCard } from "../CustomCard";
import clsx from "clsx";
import { getDownloadableLink } from "../../../Helper";

// styles
import useStyles from "./styles";

const AssignedAgentsCrad = ({ agentItemdDetails }) => {
  const styles = useStyles();

  return (
    <CustomCard
      borderRadius="xl"
      borderColor="secondary"
      classes={styles.containerCard_Assign_Agent}
    >
    <Box
    className={styles.containerAvatar}
    >
    <Avatar
    className={clsx({
      [styles.AvatarStyle]: agentItemdDetails?.profileImg,
      [styles.defultAvatarStyle]: !agentItemdDetails?.profileImg,
    })}
    src={
      agentItemdDetails?.profileImg
        ? getDownloadableLink(agentItemdDetails?.profileImg)
        : "/images/contacts/MaleAvatar.svg"
    }
  ></Avatar>

    </Box>
      <Box className={styles.container_personalInfo}>
        <strong className={styles.name} variant="body1">
          {agentItemdDetails?.agentName}
        </strong>
        <Typography className={styles.job_Title} variant="body1">
          {agentItemdDetails?.branchName}
        </Typography>
      </Box>
    </CustomCard>
  );
};
export default AssignedAgentsCrad;
