import { makeStyles } from "@material-ui/core/styles";
import { useIsAr } from "../../../../../../Hooks";

export default makeStyles((theme) => {
  const { isAr } = useIsAr(theme.direction);

  return {
    timelineContentWrapper: {
      ...(isAr ? { paddingRight: "12px" } : { paddingLeft: "12px" }),
      marginBottom: "16px",
    },
    container_Contact_Activity_Item_Crad: {
      ...(isAr ? { padding: "12px 24px 12px 6px" } : { padding: "12px 6px 12px 24px" }),
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      position: "relative",
      gap: "12px",
      [theme.breakpoints.down("xs")]: {
        gap: "0px",
      },
    },
    container_Info_Activity: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      gap: "96px",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        gap: "6px",
      },
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
        gap: "6px",
      },
    },
    container_LeadInfo: {
      display: "flex",
      alignItems: "flex-start",
      flexDirection: "column",
      gap: "2px",
    },
    infoValue: {
      color: theme.palette.text.primary,
      fontSize: "14px",
      wordWrap: "break-word",
      whiteSpace: "normal",
      overflow: "hidden",
      fontWeight: "600",
      lineHeight: "20px",
    },
    title_lead_info: {
      color: theme.palette.text.primary,
      fontSize: "16px",
      fontWeight: "700px",
      lineHeight: "24px",
      fontStyle: "normal",
      [theme.breakpoints.down("xs")]: {
        width: "130px",
      },
      [theme.breakpoints.down('425px')]: {
        width: "100%",
      },
    },
    container_status: {
      display: "flex",
      flexDirection: "row",
      gap: "10px",
      alignItems: "center",
    },
    close_status: {
      color: theme.palette.text.error_primary,
      fontWeight: "500",
      lineHeight: "18px",
      fontSize: "14px",
    },
    open_status: {
      color: theme.palette.utility.success_700,
      fontWeight: "500",
      lineHeight: "18px",
      fontSize: "14px",
    },
    open_close_status: {
      fontWeight: "500",
      lineHeight: "20px",
      fontSize: "14px",
    },
    negative_Status: {
      fontWeight: "500",
      lineHeight: "18px",
      fontSize: "12px",
    },
    positive_Status: {
      fontWeight: "500",
      lineHeight: "18px",
      fontSize: "12px",
    },
    container_LeadScore: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      gap: "10px",
      width: "100%",
    },
    lead_Score_Info: {
      display: "flex",
      flexDirection: "column",
      gap: "6px",
    },
    divider: {
      backgroundColor: theme.palette.border.secondary,
    },
    infoItem: {
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "20px",
      gap: "6px",
      display: "flex",
      alignItems: "flex-start",
      fontSize: "14px",
      color: theme.palette.text.primary,
    },
    container_leadId: {
      display: "flex",
      alignItems: "center",
      gap: "6px",
    },
    container_lead_name: {
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
      width: "300px",
      gap: "6px",
      [theme.breakpoints.down(321)]: {
        width: "150px",
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        alignItems: "center",
      },
    },
    container_Created_Assign: {
      display: "flex",
      gap: "6px",
      alignItems: "center",
      flexDirection: "row",
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
        alignItems: "flex-start",
      },
    },
    container_arrow_assign_to: {
      display: "flex",
      alignItems: "center",
      gap: "10px",
    },
    Assign_to_createdBy_value: {
      wordWrap: "break-word",
      whiteSpace: "normal",
      overflow: "hidden",
      fontWeight: "600",
      lineHeight: "20px",
      fontSize: "14px",
    },
    container_lead_source: {
      display: "flex",
      flexDirection: "row",
      gap: "6px",
      justifyContent: "space-between",
      padding: "6px 0px 0px 0px",
      alignItems: "start",
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
        alignItems: "flex-start",
      },
    },
    lead_Source_Text: {
      whiteSpace: "pre-wrap",
      overflow: "hidden",
      color: theme.palette.text.text_quarterary,
      textOverflow: "ellipsis",
      fontSize: "14px",
      fontWeight: "500",
      lineHeight: "20px",
    },
    title_lead: {
      width: "fit-content",
      lineHeight: "20px",
      fontSize: "14px",
    },
    menuDefaultIconClass: {
      fill: theme.palette.foreground.quinary,
      cursor: "pointer",
      position: "absolute",
      ...(isAr ? { left: "6px" } : { right: "6px" }),
      top: "12px",
    },
    menuActiveIconClass: {
      fill: theme.palette.foreground.quarterary,
      cursor: "pointer",
      position: "absolute",
      ...(isAr ? { left: "6px" } : { right: "6px" }),
      top: "12px",
    },
    prefixContentWrapper: {
      width: "65px",
      marginInlineEnd: "20px",
      [theme.breakpoints.down("xs")]: {
        width: "53px",
      },
    },
    prefixItemMonth: {
      color: theme.palette.foreground.disabled_subtle,
      fontWeight: 500,
      fontSize: "18px",
      lineHeight: "28px",
      [theme.breakpoints.down("xs")]: {
        width: "50px",
      },
    },
    prefixItemYear: {
      color: theme.palette.foreground.disabled_subtle,
      fontWeight: 700,
      fontSize: "20px",
      lineHeight: "30px",
      [theme.breakpoints.down("xs")]: {
        width: "50px",
      },
    },
    prefixItemTime: {
      color: theme.palette.foreground.disabled_subtle,
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "20px",
      [theme.breakpoints.down("xs")]: {
        width: "50px",
      },
    }
  };
});
