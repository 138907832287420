import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { AgreementDetailsManagementComponents } from "../AgreementDetailsManagementComponents";

export const AgreementDetailsContentDialog = ({
  parentTranslationPath,
  translationPath,
  state,
  setState,
  isSubmitted,
  selected,
  setSelected,
  contactReletedWithDeveloperId
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [isLoading, setIsLoading] = useState({
    info: [],
  });

  const onChangeHandler = (key, values, selectedObj, selectedValues) => {
    if (!selectedObj) {
      setState({ id: [key], value: values });
    } else {
      setState({ id: [key], value: values });
      setSelected({ id: [selectedObj], value: selectedValues });
    }
  };

  return (
    <div className="dialog-content-wrapper">
      <AgreementDetailsManagementComponents
        translationPath={translationPath}
        parentTranslationPath={parentTranslationPath}
        state={state}
        setState={setState}
        selected={selected}
        onChangeHandler={onChangeHandler}
        className={"dialog-content-item"}
        editForm
        contactReletedWithDeveloperId={contactReletedWithDeveloperId}
      />
    </div>
  );
};

AgreementDetailsContentDialog.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  state: PropTypes.instanceOf(Object).isRequired,
  setState: PropTypes.func.isRequired,
};
