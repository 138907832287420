import React, { useState, useRef, useEffect } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { ContactClassTypesEnum } from "../../../../Enums";
import { getIsValidURL } from "../../../../Helper";
import {
  GetAllDevelopersContactsAPI,
  GetLookupItemsByLookupTypeName,
  GetAllBranches,
} from "../../../../Services";
import { ApplicationUserSearch } from "../../../../Services/userServices";
import {
  AutocompleteComponent,
  Inputs,
  SelectComponet,
  PhonesComponent,
} from "../../../../Components";
import { DateRangePickerComponent } from "../../../../Components/Controls/DateRangePickerComponent/DateRangePickerComponent";

export const DevelopersFiltersComponent = ({
  translationPath,
  parentTranslationPath,
  dateFilter,
  setDateFilter,
  setIsDatePickerChanged,
  dateRangeDefault,
  setIsAdvanceSearchActive,
  changeContactClass,
  developerContactClasses,
  developerContactClassesFilter,
  selectedValuesInFilters,
  setSelectedValuesInFilters,
  filterValues,
  setFilterValues,
  isAdvanceSearchCleared,
  setDeveloperContactClassesFilter , 
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [isLoading, setIsLoading] = useState({
    allDevelopers: false,
    agreementsTypes: false,
    signingBranch: false,
    autharaziedSignatories: false,
  });

  const [allItems, setAllItems] = useState({
    allDevelopers: [],
    agreementsTypes: [],
    signingBranch: [],
    autharaziedSignatories: [],
  });

  const [helperText, setHelperText] = useState({
    email: "",
    landline: "",
    website: "",
  });

  const searchTimer = useRef(null);

  const getIsContactClassChecked = (item) =>
    developerContactClassesFilter &&
    developerContactClassesFilter.findIndex(
      (element) => element === item.value
    ) !== -1;

  const GetAllDevelopers = async (search) => {
    setIsLoading((i) => ({ ...i, allDevelopers: true }));
    let list = [];

    const rs = await GetAllDevelopersContactsAPI({
      pageIndex: 1,
      pageSize: 50,
      search: search,
    });
    if (!(rs && rs.status && rs.status !== 200)) {
      rs.result.map((element) => {
        const developerContact =
          element?.contact && JSON.parse(element.contact);
        list.push({
          name: developerContact?.contact?.company_name,
          developerId: element.developerId,
        });
      });
    }

    setAllItems((g) => ({ ...g, allDevelopers: list || [] }));
    setIsLoading((i) => ({ ...i, allDevelopers: false }));
  };

  const getLookupItemsByName = async (lookUpName, key) => {
    setIsLoading((item) => ({ ...item, [key]: true }));
    const result = await GetLookupItemsByLookupTypeName({
      lookUpName,
      pageSize: 50,
      pageIndex: 1,
    });
    if (!(result && result.status && result.status !== 200)) {
      setAllItems((item) => ({
        ...item,
        [key]: (result && result.result) || [],
      }));
    } else setAllItems((item) => ({ ...item, [key]: [] }));
    setIsLoading((loading) => ({ ...loading, [key]: false }));
  };

  const getAllUsersAPI = async (search) => {
    let list = [];
    setIsLoading((item) => ({ ...item, autharaziedSignatories: true }));
    const result = await ApplicationUserSearch({
      pageIndex: 1,
      pageSize: 50,
      name: search,
      userStatusId: 2,
    });
    if (!(result && result.status && result.status !== 200)) {
      result.result.map((element) => {
        list.push({
          usersId: element.applicationUserId,
          fullName: element.fullName,
          branch: element.branch,
        });
      });
    }

    setIsLoading((item) => ({ ...item, autharaziedSignatories: false }));
    setAllItems((item) => ({
      ...item,
      autharaziedSignatories: (result && result.result) || [],
    }));
  };

  const getAllBranchesAPI = async (search) => {
    setIsLoading((item) => ({ ...item, signingBranch: true }));
    const result = await GetAllBranches({
      pageIndex: 1,
      pageSize: 50,
      branchName: search || "",
    });
    if (!(result && result.status && result.status !== 200))
      setAllItems((item) => ({
        ...item,
        signingBranch: result && result.result,
      }));
    else setAllItems((item) => ({ ...item, signingBranch: [] }));

    setIsLoading((item) => ({ ...item, signingBranch: false }));
  };

  useEffect(() => {
    if (isAdvanceSearchCleared) {
      setAllItems({
        allDevelopers: [],
        agreementsTypes: [],
        signingBranch: [],
        autharaziedSignatories: [],
      });
      setHelperText({
        email: "",
        website: "",
        landline: "",
      });
    }
  }, [isAdvanceSearchCleared]);

  return (
    <div className="d-flex-column px-2 w-100 p-relative">
      <div className="developers-filters-1">
        <div className="filter-part1">
          <AutocompleteComponent
            data={allItems?.allDevelopers || []}
            wrapperClasses="w-50"
            inputPlaceholder="developer-name"
            selectedValues={selectedValuesInFilters?.Ids}
            filterOptions={(options) => options}
            multiple={false}
            displayLabel={(option) => option?.name || ""}
            renderOption={(option) => option?.name || ""}
            onChange={(e, newValue) => {
              setSelectedValuesInFilters((item) => ({
                ...item,
                Ids: newValue,
              }));
              if(!newValue)
                GetAllDevelopers("");

            }}
            withoutSearchButton
            // onOpen={() => {
            //   if (allItems.allDevelopers && allItems.allDevelopers.length === 0)
            //     GetAllDevelopers("");
            // }}
            onInputKeyUp={(e) => {
              const value = e?.target?.value;
              if (value) {
                if (searchTimer.current) clearTimeout(searchTimer.current);
                searchTimer.current = setTimeout(() => {
                  GetAllDevelopers(value);
                }, 700);
              }
            }}
            getOptionSelected={(option) =>
              selectedValuesInFilters?.Ids === option?.developerId
            }
            filterSelectedOptions
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            isLoading={isLoading.allDevelopers}
            withLoader
          />
        </div>
        <div className="filter-part1">
          <PhonesComponent
            idRef={`landlineRef`}
            value={filterValues?.landline || ""}
            helperText={
              (helperText?.landline !== "" && t(helperText?.landline)) || ""
            }
            error={helperText?.landline !== ""}
            inputPlaceholder={"enter-landlines-number"}
            onInputChanged={(value) => {
              let newValue = value;
              newValue = newValue.replaceAll(" ", "");

              if (
                (newValue !== "" && newValue.length < 7) ||
                (newValue !== "" && newValue.length > 14)
              ) {
                setHelperText((item) => ({
                  ...item,
                  landline: t("please-enter-vaild-landline-number"),
                }));
                setFilterValues((item) => ({
                  ...item,
                  landline: newValue,
                }));
              } else {
                setHelperText((item) => ({ ...item, landline: "" }));
                setFilterValues((item) => ({
                  ...item,
                  landline: newValue.replaceAll(" ", ""),
                }));
              }
            }}
            onKeyUp={(e) => {
              let { value } = e.target;
              if (searchTimer.current) clearTimeout(searchTimer.current);
              searchTimer.current = setTimeout(() => {
                if (helperText.landline !== "") return;
                setSelectedValuesInFilters((item) => ({
                  ...item,
                  landline_number: value,
                }));
              }, 700);
            }}
            onKeyDown={() => {
              if (searchTimer) clearTimeout(searchTimer.current);
            }}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
          />
        </div>
        <div className="filter-part1">
          <Inputs
            wrapperClasses="w-100"
            inputPlaceholder={"email-address"}
            idRef={`email-adressRef`}
            value={filterValues?.email || ""}
            helperText={
              (helperText?.email !== "" && t(helperText?.email)) || ""
            }
            error={helperText?.email !== ""}
            onInputChanged={(e) => {
              let { value } = e?.target;
              const itemRegex = new RegExp(
                /^(?:[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+|\*{8})$/
              );
              if (
                typeof value === "string" &&
                value !== "" &&
                !itemRegex.test(value)
              ) {
                setHelperText((item) => ({
                  ...item,
                  email: t("please-fill-email-in-correct-way"),
                }));
              } else {
                setHelperText((item) => ({
                  ...item,
                  email: "",
                }));
              }
              setFilterValues((item) => ({
                ...item,
                email: value,
              }));
            }}
            onKeyUp={(e) => {
              let { value } = e.target;
              if (searchTimer.current) clearTimeout(searchTimer.current);
              searchTimer.current = setTimeout(() => {
                if (helperText.email !== "") return;
                setSelectedValuesInFilters((item) => ({
                  ...item,
                  email: value,
                }));
              }, 700);
            }}
            onKeyDown={() => {
              if (searchTimer) clearTimeout(searchTimer.current);
            }}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
          />
        </div>
        <div className="filter-part1">
          <Inputs
            idRef="websiteRef"
            wrapperClasses="w-100"
            inputPlaceholder={"website"}
            value={filterValues.website}
            onInputChanged={(e) => {
              let { value } = e?.target;

              // if (
              //   typeof value === "string" &&
              //   value !== "" &&
              //   !getIsValidURL(value)
              // ) {
              //   setHelperText((item) => ({
              //     ...item,
              //     website: t("please-fill-website-in-correct-way"),
              //   }));
              // } else {
              //   setHelperText((item) => ({
              //     ...item,
              //     website: "",
              //   }));
              // }
              setFilterValues((item) => ({
                ...item,
                website: value,
              }));
            }}
            onKeyUp={(e) => {
              let { value } = e.target;
              if (searchTimer.current) clearTimeout(searchTimer.current);
              searchTimer.current = setTimeout(() => {
                setSelectedValuesInFilters((item) => ({
                  ...item,
                  website: value,
                }));
              }, 700);
            }}
            onKeyDown={() => {
              if (searchTimer) clearTimeout(searchTimer.current);
            }}
            helperText={
              (helperText?.website !== "" && helperText?.website) || ""
            }
            error={helperText?.website !== ""}
            isWithError
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
          />
        </div>
      </div>
      <div className="developers-filters-1">
        <div className="filter-part1">
          <SelectComponet
                        getIsChecked={getIsContactClassChecked}
                        singleIndeterminate={
                          developerContactClasses &&
                          developerContactClasses.length > 0 &&
                          Object.values(ContactClassTypesEnum).length > 0 &&
                          Object.values(ContactClassTypesEnum).length >
                          developerContactClasses.length
                        }
                        renderValue={(value) =>
                          (developerContactClasses.length > 0 && (
                            <span>
                              {value.map((option, mapIndex) => {
                                let toReturn = '';
                                const optionIndex = Object.values(
                                  ContactClassTypesEnum
                                ).findIndex(
                                  (element) => element.value === option
                                );
                                if (optionIndex !== -1) {
                                  toReturn += Object.values(
                                    ContactClassTypesEnum
                                  )[optionIndex].value;
                                  if (mapIndex < value.length - 1)
                                    toReturn += ', ';
                                }
                                return toReturn;
                              })}
                            </span>
                          )) || (
                            <span>
                              {t(`${translationPath}select-developer-class`)}
                            </span>
                          )
                        }
                        singleChecked={
                          developerContactClasses &&
                          developerContactClasses.length > 0 &&
                          Object.values(ContactClassTypesEnum).length ===
                          developerContactClasses.length - 1
                        }
                        data={Object.values(ContactClassTypesEnum)}
                        value={developerContactClassesFilter}
                        isWithCheckAll
                        valueInput='value'
                        multiple={true}
                        keyLoopBy='value'
                        textInput='value'
                        onSelectChanged={changeContactClass}
                        wrapperClasses='w-auto'
                        themeClass='theme-default'
                        idRef='changeContactClassRef'
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        translationPathForData={translationPath}
                      />
        </div>
        <div className="filter-part1">
          <AutocompleteComponent
            key={`agreementType`}
            selectedValues={selectedValuesInFilters.agreementTypeId}
            idRef={`agreementTypeIdRef`}
            inputPlaceholder="select-agreement-type"
            multiple={false}
            data={allItems && allItems.agreementsTypes}
            wrapperClasses="w-50-unset mb-2"
            displayLabel={(option) => (option && option.lookupItemName) || ""}
            onChange={(e, newValue) => {
              setSelectedValuesInFilters((item) => ({
                ...item,
                agreementTypeId: newValue,
              }));
            }}
            isLoading={isLoading.agreementsTypes}
            withLoader
            withoutSearchButton
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
            onOpen={() => {
              if (allItems?.agreementsTypes?.length === 0)
                getLookupItemsByName("AgreementType", "agreementsTypes");
            }}
          />
        </div>
        <div className="filter-part1">
          <AutocompleteComponent
            data={allItems && allItems.signingBranch}
            multiple={false}
            selectedValues={selectedValuesInFilters.signingBranchId}
            chipsLabel={(option) => (option && option.branchName) || ""}
            displayLabel={(option) => (option && option.branchName) || ""}
            onChange={(e, newValue) => {
              setSelectedValuesInFilters((item) => ({
                ...item,
                signingBranchId: newValue,
              }));
              if(!newValue)
                getAllBranchesAPI();
            }}
            onInputKeyUp={(e) => {
              const { value } = e.target;
              if (searchTimer.current) clearTimeout(searchTimer.current);
              searchTimer.current = setTimeout(() => {
                getAllBranchesAPI(value);
              }, 700);
            }}
            filterSelectedOptions
            isLoading={isLoading.signingBranch}
            withLoader
            withoutSearchButton
            wrapperClasses="w-min-unset mb-2"
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            inputPlaceholder="signing-branch"
            onOpen={() => {
              if (allItems && allItems?.signingBranch?.length === 0)
                getAllBranchesAPI();
            }}
          />
        </div>
        <div className="mr-1 ml-1"></div>
        <div className="filter-part1">
          <AutocompleteComponent
            key={`users`}
            idRef={`usersRef`}
            multiple={false}
            wrapperClasses="w-min-unset mb-2"
            inputPlaceholder={"authorizedes-signatories"}
            data={allItems && allItems.autharaziedSignatories}
            selectedValues={selectedValuesInFilters.autharaziedSignatoryId}
            displayLabel={(option) => (option && option.fullName) || ""}
            onChange={(e, newValue) => {
              setSelectedValuesInFilters((item) => ({
                ...item,
                autharaziedSignatoryId: newValue,
              }));
              if(!newValue)
                getAllUsersAPI();
            }}
            filterSelectedOptions
            isLoading={isLoading.autharaziedSignatories}
            withLoader
            withoutSearchButton
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
            onOpen={() => {
              if (allItems && allItems.autharaziedSignatories?.length === 0)
                getAllUsersAPI();
            }}
            onInputKeyUp={(e) => {
              const { value } = e.target;
              if (searchTimer.current) clearTimeout(searchTimer.current);
              searchTimer.current = setTimeout(() => {
                getAllUsersAPI(value);
              }, 700);
            }}
          />
        </div>
      </div>
      <div className="developers-filters-1">
        <div className='filter-part1" customDatePicker'>
          <div className="date-type-select">
            <SelectComponet
              data={[
                { key: 1, value: `${translationPath}created-date` },
                {
                  key: 2,
                  value: `${translationPath}update-on-date`,
                },
              ]}
              defaultValue={{
                key: 1,
                value: `${translationPath}created-date`,
              }}
              value={dateFilter.selectedDateType || 1}
              valueInput="key"
              textInput="value"
              onSelectChanged={(value) => {
                setIsDatePickerChanged(true);
                setDateFilter((f) => ({
                  ...f,
                  selectedDateType: value,
                }));
              }}
              wrapperClasses="w-auto"
              themeClass="theme-transparent"
              idRef="Date_Select"
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              translationPathForData={translationPath}
            />
          </div>
          <DateRangePickerComponent
            onClearClicked={() => {
              setDateFilter(dateRangeDefault);
              setIsDatePickerChanged(true);
            }}
            onDialogClose={() => setIsDatePickerChanged(true)}
            ranges={[dateFilter]}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            onDateChanged={(selectedDate) => {
              setDateFilter((item) => ({
                ...item,
                startDate: selectedDate?.selection?.startDate,
                endDate: new Date(
                  moment(selectedDate?.selection?.endDate).endOf("day")
                ),
                key: "selection",
              }));
            }}
          />
        </div>
      </div>
    </div>
  );
};
