import React, { useCallback, useEffect, useReducer, useState } from "react";
import { Box, Typography, Divider } from "@material-ui/core";
import {
  CustomDialog,
  CustomInput,
  DropzoneComponent,
  CustomButton,
  CustomCard,
  CustomAutocomplete,
} from "../../../../../../Components";
import {
  lookupItemsGetId,
  CreateScopeDocument,
} from "../../../../../../Services";
import { ScopeDocumentEnum } from "../../../../../../Enums";
import { useVerticalNav } from "../../../../../../Contexts/VerticalNavContext";
import { DeleteIcon, PlusIcon } from "../../../../../../assets/icons";
import { useSelectedTheme } from "../../../../../../Hooks";
import { getIsValidDataURL, getIsValidURL } from "../../../../../../Helper";
import { GetFileTypeIcon } from "..";

//styles
import useStyles from "./styles";

function ContactUploadDocument({
  open,
  close,
  contactId,
  lookupTypeId,
  translate,
  setUpdatedGetAllScopeDocuments,
}) {
  const styles = useStyles();
  const { setAlertBoxContent } = useVerticalNav();
  const {
    theme: { palette },
  } = useSelectedTheme();
  function reducer(state, action) {
    return { ...state, [action.id]: action.value };
  }
  const [categories, setCategories] = useState({
    result: [],
    TotalCount: 0,
  });
  const [isOnSubmitLoading, setIsOnSubmitLoading] = useState(false);
  const [links, setLinks] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [state, setState] = useReducer(reducer, {
    categoryId: null,
    files: [
      {
        fileId: null,
        fileName: null,
        documentLink: null,
      },
    ],
  });
  const [selected, setSelected] = useReducer(reducer, {
    categoryItemName: null,
    files: [
      {
        fileId: null,
        fileName: null,
        documentLink: null,
      },
    ],
  });
  const [filesAccepted, setFilesAccepted] = useState([]);
  const [uploadedFileId, setUploadedFileId] = useState(null);
  const getAllLookups = useCallback(async (lookupTypeId) => {
    const res = await lookupItemsGetId({ lookupTypeId: lookupTypeId });
    if (!(res && res.status && res.status !== 200)) {
      setCategories({
        result: res,
      });
    } else {
      setCategories({
        result: [],
      });
    }
  }, []);

  const handleFilesAccepted = (files) => {
    setFilesAccepted(files);
  };

  const formatUploadedDocumentToMapp = () => {
    if (uploadedFileId) {
      setUploadedFiles((prevUploadedFiles) => [
        ...prevUploadedFiles,
        filesAccepted[0],
      ]);
      setUploadedFiles((prevUploadedFiles) => {
        const updatedFiles = [...prevUploadedFiles];
        if (updatedFiles?.length > 0) {
          updatedFiles[updatedFiles.length - 1] = {
            ...updatedFiles[updatedFiles.length - 1],
            fileId: uploadedFileId,
            fileSize: updatedFiles[updatedFiles?.length - 1]?.size,
            fileName: updatedFiles[updatedFiles?.length - 1]?.path,
            fileType: updatedFiles[updatedFiles?.length - 1]?.type,
          };
        }
        return updatedFiles;
      });
    }
  };

  const restFiled = () => {
    setFilesAccepted([]);
    setUploadedFileId(null);
    setSelected({ id: "categoryItemName", value: null });
    setState({
      id: "categoryId",
      value: null,
    });
    setState({
      id: "files",
      value: {
        fileId: null,
        fileName: null,
        documentLink: null,
      },
    });
    setSelected({
      id: "files",
      value: {
        fileId: null,
        fileName: null,
        documentLink: null,
      },
    });
  };
  const handleAddMoreLinks = () => {
    const linkToValidate = selected?.files[0]?.documentLink;
    const isLinkExistBefore =
      links.findIndex((link) => link === linkToValidate) !== -1;
    const isValidUrl =
      getIsValidURL(linkToValidate) || getIsValidDataURL(linkToValidate);

    if (isValidUrl && !isLinkExistBefore) {
      setLinks((prevLinks) => [...prevLinks, linkToValidate]);
      setState({
        id: "files",
        value: [
          {
            fileId: null,
            fileName: null,
            documentLink: "",
          },
        ],
      });
      setSelected({
        id: "files",
        value: [
          {
            fileId: null,
            fileName: null,
            documentLink: "",
          },
        ],
      });
    } else {
      setAlertBoxContent({
        display: true,
        variant: "error",
        title: translate("Enter-Valid-url"),
        onClose: () => {
          setAlertBoxContent(null);
        },
      });
      setState({
        id: "files",
        value: [
          {
            fileId: null,
            fileName: null,
            documentLink: "",
          },
        ],
      });

      setSelected({
        id: "files",
        value: [
          {
            fileId: null,
            fileName: null,
            documentLink: "",
          },
        ],
      });
      return;
    }
  };
  const handleDeleteLink = (index) => {
    setLinks((prevLinks) => prevLinks.filter((_, i) => i !== index));
  };
  const handleDeleteUploadedFile = (index) => {
    setUploadedFiles((prevUploadedFiles) =>
      prevUploadedFiles.filter((_, i) => i !== index)
    );
  };
  const handleUrlChange = (e) => {
    const documentLink = e.target.value;
    setState({
      id: "files",
      value: [
        {
          ...state.files[0],
          documentLink,
        },
      ],
    });
    setSelected({
      id: "files",
      value: [
        {
          ...selected.files[0],
          documentLink,
        },
      ],
    });
  };
  const handleSubmit = async () => {
    setIsOnSubmitLoading(true);
    try {
      const mapFiles = [];
      if (links.length > 0) {
        links.forEach((link) => {
          mapFiles.push({
            fileId: null,
            fileName: null,
            documentLink: link,
          });
        });
      }
      if (uploadedFiles.length > 0) {
        uploadedFiles.forEach((file) => {
          mapFiles.push({
            fileId: file?.fileId,
            fileName: file?.fileName,
            documentLink: null,
          });
        });
      }

      const body = {
        scopeId: +contactId,
        scopeTypeId: ScopeDocumentEnum.Contact.scopeTypeId,
        categoryFiles: [
          {
            categoryId: state?.categoryId,
            files: mapFiles,
          },
        ],
      };

      const res = await CreateScopeDocument(body);

      if (!(res && res.status && res.status !== 200)) {
        setAlertBoxContent({
          display: true,
          variant: "success",
          title: translate("SUBMISSION_SUCCESS_MESSAGE"),
          onClose: () => {
            setAlertBoxContent(null);
          },
        });
        restFiled();
        setUpdatedGetAllScopeDocuments(true);
        close();
        setLinks([]);
        setUploadedFiles([]);
      } else {
        setAlertBoxContent({
          display: true,
          variant: "errror",
          title: translate(
            "Category-Already-has-an-image-Please-update-the-existing-image-instead"
          ),
          onClose: () => {
            setAlertBoxContent(null);
          },
        });
        restFiled();
      }
    } catch (error) {
      console.error("An error occurred during submission:", error);
      setAlertBoxContent({
        display: true,
        variant: "error",
        title: translate("SUBMISSION_FAILED_MESSAGE"),
        onClose: () => {
          setAlertBoxContent(null);
        },
      });
    } finally {
      setIsOnSubmitLoading(false);
      restFiled();
    }
  };

  useEffect(() => {
    getAllLookups(lookupTypeId);
  }, []);

  useEffect(() => {
    formatUploadedDocumentToMapp();
  }, [uploadedFileId]);

  return (
    <CustomDialog
      onConfirm={handleSubmit}
      isDisabled={
        uploadedFiles.length === 0 ||
        !selected?.categoryItemName ||
        isOnSubmitLoading
      }
      open={open}
      onClose={() => {
        restFiled();
        setLinks([]);
        setUploadedFiles([]);
        close();
      }}
      width={"688px"}
      title={translate("upload_document")}
      subtitle={translate(
        "Add-new-documents-or-files-related-to-this-contact-for-secure-storage-and-easy-access"
      )}
      confirmText={translate("Upload")}
      content={
        <Box className={styles.containerContentDialog}>
          <Box className={styles.containerCategorySection}>
            <Typography className={styles.TitleItem}>
              {translate("Category")}
            </Typography>
            <CustomAutocomplete
              variant="default"
              options={categories?.result || []}
              selectedValues={selected.categoryItemName || null}
              getOptionLabel={(option) => option?.lookupItemName || ""}
              renderOption={(option) => option?.lookupItemName || ""}
              onChange={(e, newValue) => {
                setState({
                  id: "categoryId",
                  value: newValue?.lookupItemId || null,
                });
                setSelected({
                  id: "categoryItemName",
                  value: newValue || null,
                });
              }}
              placeholder="Select"
            />
          </Box>
          <Divider className={styles.divider} />
          <Box className={styles.LinkOverviewTable}>
            <Box className={styles.container_Link_Section}>
              <Typography className={styles.TitleItem}>
                {translate("Link")}
              </Typography>
              <Box className={styles.containerSectionFiledLink}>
                <Box className={styles.containerInput}>
                  <CustomInput
                    debounceTime={0}
                    hasSearchIcon={false}
                    inputContainerOverrideStyles={styles.inputFullWidth}
                    placeholder={translate("Enter-Url-Link")}
                    type="text"
                    value={selected?.files[0]?.documentLink || null}
                    onChange={(e) => {
                      handleUrlChange(e);
                    }}
                  />
                </Box>
                <CustomButton
                  size="sm"
                  variant="text"
                  color="tertiaryGray"
                  style={{ display: "flex", gap: "12px" }}
                  startIcon={
                    <PlusIcon
                      width="20"
                      height="20"
                      fill={palette.button.tertiaryGray_fg}
                    />
                  }
                  onClick={() => {
                    handleAddMoreLinks();
                  }}
                  disabled={
                    !state?.files?.length ||
                    !state?.files[0]?.documentLink?.trim() ||
                    links?.length < 0
                  }
                >
                  {translate("Add-another")}
                </CustomButton>
              </Box>
            </Box>
            {links?.length > 0 && (
              <CustomCard
                boxShadow="xs"
                borderRadius="xl"
                borderColor="secondary"
                classes={styles.MoreLinkesContainer}
              >
                <Box className={styles.HeaderTableSection}>
                  <Typography className={styles.TitleItemTable}>
                    {translate("Link")}
                  </Typography>
                  <Typography className={styles.TitleItemTable}>
                    {translate("Action")}
                  </Typography>
                </Box>
                <Divider />
                <Box className={styles.BodyTableSection}>
                  {links.map((link, index) => (
                    <Box key={index} className={styles.rowMoreLinkes}>
                      <Box className={styles.containerLink_Name}>
                        <Typography className={styles.LinkName}>
                          <a
                            href={link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={styles.HyperLinkItem}
                          >
                            {link}
                          </a>
                        </Typography>
                      </Box>
                      <Box className={styles.containerDelete_Icon}>
                        <DeleteIcon
                          width="20"
                          height="20"
                          fill={palette.button.tertiaryGray_fg}
                          style={{ cursor: "pointer" }}
                          onClick={() => handleDeleteLink(index)}
                        />
                      </Box>
                    </Box>
                  ))}
                </Box>
              </CustomCard>
            )}
          </Box>
          <Divider className={styles.divider} />
          <Box className={styles.containerAttatchmetSection}>
            <Typography className={styles.TitleItem}>
              {translate("Attatchmet")}
            </Typography>
            <Box className={styles.uploadedSection}>
              <DropzoneComponent
                accept="image/*,
                       video/*,
                       application/pdf,
                       application/msword,
                       application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                       application/vnd.ms-excel,
                       application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                       text/plain,
                       application/vnd.adobe.figma,
                       application/illustrator,
                       application/photoshop,
                       application/vnd.adobe.indesign,
                       application/aftereffects,
                       audio/mp3,
                       audio/wav,
                       video/mp4,
                       video/mpeg,
                       video/avi,
                       video/mkv,
                       text/html,
                       text/css,
                       application/rss+xml,
                       application/sql,
                       application/javascript,
                       application/json,
                       text/x-java-source,
                       application/xml,
                       application/x-msdownload,
                       application/x-dmg,
                       application/zip,
                       application/x-rar-compressed"
                title={translate("SVG-PNG-JPG-or-GIF-max-800x400px")}
                onFilesAccepted={handleFilesAccepted}
                setUploadedFileId={setUploadedFileId}
              />
              {uploadedFiles && uploadedFiles?.length > 0 && (
                <Box className={styles.containerUpladedFilesCrads}>
                  {uploadedFiles
                    .filter((file) => file?.fileType)
                    .map((uploadFile, index) => (
                      <CustomCard
                        boxShadow="xs"
                        borderRadius="xl"
                        borderColor="secondary"
                        classes={styles.CardUploadedFilesInfo}
                        key={index}
                      >
                        {GetFileTypeIcon(uploadFile?.fileType)}
                        <Box className={styles.ContainerFileUploadedInfo}>
                          <Box className={styles.containerTitleAndDeleteIcon}>
                            <Typography className={styles.TitleFileName}>
                              {uploadFile?.fileName}
                            </Typography>
                            <DeleteIcon
                              width="20"
                              height="20"
                              className={styles.DeleteIcon}
                              style={{ cursor: "pointer" }}
                              fill={palette.button.secondary_fg}
                              onClick={() => handleDeleteUploadedFile(index)}
                            />
                          </Box>
                          <Typography className={styles.FileSizeTitle}>
                            {uploadFile.fileSize
                              ? `${uploadFile?.fileSize} bytes`
                              : "Unknown Size"}
                          </Typography>
                        </Box>
                      </CustomCard>
                    ))}
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      }
    />
  );
}
export default ContactUploadDocument;
