import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Inputs } from "../../../../../../../../Components";
import { RepeatedItemDialog } from "../../../../../../FormBuilder/Dialogs/RepeatedItemDialog";

export const EmailComponent = ({
  parentTranslationPath,
  translationPath,
  emailAddresses,
  setEmail,
  isSubmitted,
  helperText,
  error,
  isReloadData,
  communicationId,
  isOpenEmails,
  setIsOpenEmails,
  id,
  setIsCanSave,
  isCanSave,
}) => {
  const { t } = useTranslation([parentTranslationPath, "Shared"]);
  const [repeatedItemDialogEmail, setRepeatedItemDialogEmail] = useState(false);
  const [timer, setTimer] = useState(null);
  const [HelperText, setHelperText] = useState("");
  const [Error, setError] = useState("");

  const emailItem = {
    data: {
      type: "string",
      title: "Email Address",
      description: "Main Information",
      lookupItem: 18037,
      CommunicationType: "Email",
      regExp: /^(?:[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+|\*{8})$/,
    },
    field: {
      id: "email_address",
      FieldType: "communication",
      Required: "true",
    },
  };

  const [emailState, setEmailState] = useState({
    email: "",
    typeId: communicationId,
  });

  useEffect(() => {
    if (emailAddresses && emailAddresses.length === 1)
      setEmailState({
        email: emailAddresses[0] && emailAddresses[0].communicationValue,
      });
    else if (emailAddresses && emailAddresses.length > 1) {
      let emails =
        emailAddresses &&
        emailAddresses.map((item) => {
          return item.communicationValue;
        });
      emails.splice(0, 1);
      setEmailState({
        email: emailAddresses[0]?.communicationValue,
        others: emails,
      });
    }
  }, [id, isOpenEmails]);

  useEffect(() => {
    let list = [];
    if (emailState.email !== "")
      list.push({
        communicationTypeId: communicationId,
        communicationValue: emailState.email,
      });
    if (emailState?.others && emailState.others.length > 0)
      emailState.others.map((item) =>
        list.push({
          communicationTypeId: communicationId,
          communicationValue: item,
        })
      );
    setEmail([...list]);
    const checkVaildEmail =
      emailState.email !== ""
        ? !new RegExp(
            /^(?:[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+|\*{8})$/
          ).test(emailState.email)
        : false;
    setIsCanSave((e) => ({ ...e, vaildEmail: checkVaildEmail }));
  }, [emailState, id]);

  useEffect(() => {
    if (isReloadData !== null && isReloadData !== undefined)
      setEmailState({ email: "", typeId: communicationId });
  }, [isReloadData]);

  return (
    <div className="w-100">
      <Inputs
        idRef="emailAddressRef"
        labelValue="emailAddress"
        isWithError
        value={emailState.email}
        buttonOptions={{
          className: "btns-icon theme-solid bg-blue-lighter",
          iconClasses: "mdi mdi-plus",
          isRequired: false,
          isDisabled:
            HelperText !== "" ||
            !emailAddresses?.length ||
            !(
              emailState.email !== "" &&
              new RegExp(
                /^(?:[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+|\*{8})$/
              ).test(emailState.email)
            ),
          onActionClicked: () => {
            if (
              emailState.email &&
              new RegExp(emailState.email.regExp).test(emailState.email)
            ) {
              setRepeatedItemDialogEmail(true);
              setIsOpenEmails(true);
            }
          },
        }}
        inputPlaceholder={t(`${translationPath}enter-email-address`)}
        onInputChanged={(e) => {
          let { value } = e?.target;
          if (value === "")
            setEmailState({ email: "", typeId: communicationId });
          else {
            setEmailState({
              ...emailState,
              email: value,
            });
          }
          if (
            value &&
            value !== "" &&
            !new RegExp(
              /^(?:[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+|\*{8})$/
            ).test(value)
          )
            setHelperText(
              t`${translationPath}please-fill-email-in-correct-way`
            );
          else setHelperText("");
        }}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        isSubmitted={isSubmitted}
        helperText={helperText || HelperText}
        error={error || Error || HelperText !== ""}
      />
      {repeatedItemDialogEmail && (
        <RepeatedItemDialog
          open={repeatedItemDialogEmail}
          item={emailItem}
          type="email"
          initialState={emailState}
          label={emailItem.data.title}
          onChange={(data) => {
            setEmailState({ ...data });
            setIsOpenEmails(false) ; 

          }}
          closeDialog={() => {
            setRepeatedItemDialogEmail(false);
            setIsOpenEmails(false) ; 
          }}
          setIsOpenEmails={setIsOpenEmails}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          isCanSave={isCanSave}
        />
      )}
    </div>
  );
};
