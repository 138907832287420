import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Inputs } from "../../../../../../Components";
import {
  EmailComponent,
  MobileNumberComponent,
} from "./Component/FieldsComponent";
import {
  showError,
  matchLinkedinUrl,
  getErrorByName,
} from "../../../../../../Helper";
import { ActivityFilesUploader } from "../../../../../../Components/UploaderActivitieFileComponent/ActivityFilesUploader";

export const DeveloperEmployeesManagementComponent = ({
  translationPath,
  parentTranslationPath,
  isReloadData,
  state,
  setState,
  onChangeHandler,
  editForm,
  className,
  selected,
  setSelected,
  key,
  schema,
  communicationsIdsValues,
  setIsCheckIsValidValues,
  setIsCanSave , 
  isCanSave
}) => {
  const { t } = useTranslation(parentTranslationPath, "Shared");

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isOpenEmails , setIsOpenEmails] = useState(false) ;
  const [isOpenPhone , setIsOpenPhone] = useState(false) ;

  const checkIsValidValues = (key) => {
    if ((key === "linkedInProfile" || key === "save") && state.linkedInProfile)
      return !matchLinkedinUrl(state.linkedInProfile || "");
    return false;
  };

  useEffect(() => {
    if (state && state.files)
      onChangeHandler(
        "employeePhoto",
        (state?.files && state.files.length > 0 && state.files[0].fileId) ||
          null,
        "employeePhoto",
        state.files
      );
  }, [state.files]);

  useEffect(() => {
    setIsCheckIsValidValues(checkIsValidValues("save"));
  }, [state]);

  return (
    <>
      {!editForm && (
        <div className="part1 mt-2 mb-2">
          <div className={`${className || "px-2 w-50 "}`}>
            <Inputs
              isAttachedInput
              idRef={`employeeName${1}`}
              labelValue={"employee-name"}
              value={state.employeeName || ""}
              onInputChanged={(e) => {
                const { value } = e.target;
                onChangeHandler("employeeName", value);
              }}
              inputPlaceholder={"enter-employee-name"}
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
              helperText={getErrorByName(schema, "employeeName").message}
              error={getErrorByName(schema, "employeeName").error}
              isWithError
              isSubmitted={isSubmitted}
            />
          </div>
          <div className={`${className || "px-2 w-50 "}`}>
            <MobileNumberComponent
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
              setNumber={(data) =>
                setSelected({ id: "mobileNumbers", value: data })
              }
              mobileNumbers={selected.mobileNumbers}
              isReloadData={isReloadData}
              communicationId={communicationsIdsValues.mobile}
              isOpenPhone={isOpenPhone}
              setIsOpenPhone={setIsOpenPhone}
              setIsCanSave={setIsCanSave}
              isCanSave={isCanSave}
            />
          </div>

          <div className={`${className || "px-2 w-50 "}`}>
            <EmailComponent
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
              setEmail={(data) =>
                setSelected({ id: "emailAddresses", value: data })
              }
              emailAddresses={selected.emailAddresses}
              isReloadData={!editForm ? isReloadData : null}
              communicationId={communicationsIdsValues.email}
              isOpenEmails={isOpenEmails}
              setIsOpenEmails={setIsOpenEmails}
              setIsCanSave={setIsCanSave}
              isCanSave={isCanSave}

            />
          </div>

          <div className={`${className || "px-2 w-50 "}`}>
            <Inputs
              isAttachedInput
              idRef={`jobTitle${1}`}
              labelValue={"job-title"}
              value={state.jobTitle || ""}
              onInputChanged={(e) => {
                onChangeHandler("jobTitle", e.target.value);
              }}
              inputPlaceholder={"enter-job-title"}
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
            />
          </div>

          <div className={`${className || "px-2 w-50 "}`}>
            <Inputs
              isAttachedInput
              idRef={"LinkedInLinkRef"}
              labelValue={"linkedIn-link"}
              type={"text"}
              value={state.linkedInProfile || ""}
              onInputChanged={(e) => {
                onChangeHandler(
                  "linkedInProfile",
                  e?.target?.value !== "" ? e.target.value : null
                );
              }}
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
              endAdornment={
                <>
                  <Button
                    disabled={
                      state.linkedInProfile
                        ? state.linkedInProfile !== ""
                          ? checkIsValidValues("linkedInProfile")
                          : true
                        : true
                    }
                    onClick={() => {
                      try {
                        window.open(state.linkedInProfile || "");
                      } catch (error) {
                        showError(
                          t(`${translationPath}this-link-is-not-valid`)
                        );
                      }
                    }}
                    className="googlemaps-bbt "
                  >
                    <span className="p-1 youtube-wraper">
                      <span className="mdi mdi-web" />
                    </span>
                  </Button>
                </>
              }
              helperText={t`${translationPath}please-enter-valid-linkedIn-url`}
              error={
                state?.linkedInProfile !== ""
                  ? checkIsValidValues("linkedInProfile")
                  : false
              }
              isWithError
              isSubmitted={isSubmitted}
            />
          </div>
        </div>
      )}
      {editForm && (
        <>
          <div className={`${className || "px-2 w-50 "}`}>
            <Inputs
              isAttachedInput
              idRef={`employeeName${1}`}
              labelValue={"employee-name"}
              value={state.employeeName || ""}
              onInputChanged={(e) => {
                const { value } = e.target;
                onChangeHandler("employeeName", value);
              }}
              inputPlaceholder={"enter-employee-name"}
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
              helperText={getErrorByName(schema, "employeeName").message}
              error={getErrorByName(schema, "employeeName").error}
              isWithError
              isSubmitted={isSubmitted}
            />
          </div>
          <div className={`${className || "px-2 w-50 "}`}>
            <MobileNumberComponent
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
              setNumber={(data) =>
                setSelected({ id: "mobileNumbers", value: data })
              }
              mobileNumbers={selected.mobileNumbers}
              isReloadData={editForm ? null : isReloadData}
              communicationId={communicationsIdsValues.mobile}
              id={state.employeeId}
            />
          </div>

          <div className={`${className || "px-2 w-50 "}`}>
            <EmailComponent
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
              setEmail={(data) =>
                setSelected({ id: "emailAddresses", value: data })
              }
              emailAddresses={selected.emailAddresses}
              isReloadData={editForm ? null : isReloadData}
              communicationId={communicationsIdsValues.email}
              id={state.employeeId}
              isOpenEmails={isOpenEmails}
              setIsOpenEmails={setIsOpenEmails}
              setIsCanSave={setIsCanSave}
              isCanSave={isCanSave}
              
            />
          </div>

          <div className={`${className || "px-2 w-50 "}`}>
            <Inputs
              isAttachedInput
              idRef={`jobTitle${1}`}
              labelValue={"job-title"}
              value={state.jobTitle || ""}
              onInputChanged={(e) => {
                onChangeHandler("jobTitle", e.target.value);
              }}
              inputPlaceholder={"enter-job-title"}
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
            />
          </div>

          <div className={`${className || "px-2 w-50 "}`}>
            <Inputs
              isAttachedInput
              idRef={"LinkedInLinkRef"}
              labelValue={"linkedIn-link"}
              type={"text"}
              value={state.linkedInProfile || ""}
              onInputChanged={(e) => {
                onChangeHandler(
                  "linkedInProfile",
                  e?.target?.value !== "" ? e.target.value : null
                );
              }}
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
              endAdornment={
                <>
                  <Button
                    disabled={
                      state.linkedInProfile
                        ? state.linkedInProfile !== ""
                          ? checkIsValidValues("linkedInProfile")
                          : true
                        : true
                    }
                    onClick={() => {
                      try {
                        window.open(state.linkedInProfile || "");
                      } catch (error) {
                        showError(
                          t(`${translationPath}this-link-is-not-valid`)
                        );
                      }
                    }}
                    className="googlemaps-bbt "
                  >
                    <span className="p-1 youtube-wraper">
                      <span className="mdi mdi-web" />
                    </span>
                  </Button>
                </>
              }
              helperText={t`${translationPath}please-enter-valid-linkedIn-url`}
              error={
                state?.linkedInProfile !== ""
                  ? checkIsValidValues("linkedInProfile")
                  : false
              }
              isWithError
              isSubmitted={isSubmitted}
            />
          </div>
        </>
      )}
      <div className="w-100">
        <ActivityFilesUploader
          state={state}
          setState={setState}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          multiple={false}
          accept={"/*"}
          canSelectOneDocument={true}
        />
      </div>
    </>
  );
};

DeveloperEmployeesManagementComponent.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  state: PropTypes.objectOf(PropTypes.any).isRequired,
  setState: PropTypes.func.isRequired,
  selected: PropTypes.objectOf(PropTypes.any).isRequired,
  setSelected: PropTypes.func.isRequired,
  onChangeHandler: PropTypes.func.isRequired,
  schema: PropTypes.objectOf(PropTypes.any).isRequired,
  communicationsIdsValues: PropTypes.objectOf(PropTypes.any).isRequired,
  setIsCheckIsValidValues: PropTypes.func.isRequired,
  key: PropTypes.string.isRequired,
  isReloadData: PropTypes.bool,
  className: PropTypes.string,
  editForm: PropTypes.bool,
};

DeveloperEmployeesManagementComponent.defaultProps = {
  editForm: false,
  isReloadData: false,
  className: "",
};
