import React, { useCallback, useEffect, useState } from 'react';
import {
  GetLookupItemsByLookupTypeName,
  lookupItemsGetId,
} from '../../../Services';
import { AutocompleteComponent } from '../AutocompleteComponent/AutocompleteComponent';

function LookupAutocomplete({
  lookupTypeId,
  lookupParentId,
  parentTranslationPath,
  translationPath,
  selectedValues,
  multiple = false,
  required,
  onChange,
  wrapperClasses,
  label,
  disabled,
  className = '',
  lookupName,
  filterSelectedOptions,
  isSubmitted,
  withError,
  error,
  helperText,
}) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const getLookupData = useCallback(async () => {
    setIsLoading(true);
    let res;
    if (lookupTypeId) {
      res = await lookupItemsGetId({ lookupTypeId, lookupParentId });
    }
    if (lookupName) {
      const result = await GetLookupItemsByLookupTypeName({
        lookUpName: lookupName,
        pageIndex: 1,
        pageSize: 100,
      });
      res = result.result;
    }
    setData(res);
    setIsLoading(false);
  }, [lookupTypeId, lookupParentId, lookupName]);

  useEffect(() => {
    if (!disabled) {
      getLookupData();
    }
  }, [getLookupData]);
  if (lookupName && lookupTypeId) {
    return (
      <h6 className='text-danger'>
        You can't provide both lookupName and lookupTypeId at the same time
      </h6>
    );
  }
  return (
    <div className={className}>
      <AutocompleteComponent
        data={data}
        filterSelectedOptions={filterSelectedOptions}
        filterOptions={
          filterSelectedOptions
            ? (options) => {
                const hasValue = (id) =>
                  selectedValues.findIndex((w) => w.lookupItemId === id) === -1;
                return options.filter((w) => hasValue(w.lookupItemId));
              }
            : undefined
        }
        isSubmitted={isSubmitted}
        isWithError={withError}
        selectedValues={selectedValues}
        labelClasses={required ? 'Requierd-Color' : ''}
        labelValue={label}
        multiple={multiple}
        error={error}
        helperText={helperText}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        displayLabel={(option) => option?.lookupItemName ?? ''}
        renderOption={(option) => option?.lookupItemName ?? ''}
        chipsLabel={(option) => option?.lookupItemName ?? ''}
        getOptionSelected={(option) => {
          if (multiple) {
            return !!selectedValues.find(
              (item) => option.lookupItemId === item.lookupItemId
            );
          } else {
            return selectedValues.lookupItemId === option.lookupItemId;
          }
        }}
        isLoading={isLoading}
        onChange={onChange}
        wrapperClasses={wrapperClasses}
        withoutSearchButton
        isDisabled={disabled}
      />
    </div>
  );
}

export default LookupAutocomplete;
