import React from "react";
import { Box } from "@material-ui/core";

import { KycFormsView } from "../../../../../../Views/Home/ContactsView/ContactProfileManagementView/Sections/KycViewComponent/Tabs/KycForms/KycFormsView";

function ContactKYC() {
  return (
    <Box>
      <KycFormsView />
    </Box>
  );
}

export default ContactKYC;
