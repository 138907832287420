import React, { memo, useEffect, useMemo, useState } from "react";
import LayoutStyles from "../../UnitOverviewComponent.module.scss";
import Styles from "./SideSection.module.scss";
import Button from "@material-ui/core/Button";
import { Avatar } from "@material-ui/core";
import { getDownloadableLink, getFirstLastNameLetters } from "../../../../../../../../../../Helper";
import { CopyToClipboardComponents } from "../../../../../../../../../../ReusableComponents/UtilityComponents";

const SideSection = memo(({ data }) => {
  const [showAll, setShowAll] = useState({
    views: false,
    fixtures: false,
  });

  const showAllHandler = (section) => {
    setShowAll((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  const viewsCapsulesMapper = () => {
    if (data.unitVeiw && data.unitVeiw.length > 0) {
      const viewsList = data.unitVeiw.split(",");
      return showAll.views ? viewsList : viewsList.slice(0, 4);
    }
  };

  const fixturesCapsulesMapper = () => {
    if (data.fixtures && data.fixtures.length > 0) {
      const fixturesList = data.fixtures;
      return showAll.fixtures ? fixturesList : fixturesList.slice(0, 4);
    }
  };

  const listingAgentMapper = () => {
    return data?.operationType === "Rent"
      ? data?.rentListingAgent
      : data?.listingAgentDto;
  };

  const views = useMemo(() => viewsCapsulesMapper(), [data, showAll]);
  const fixtures = useMemo(() => fixturesCapsulesMapper(), [data, showAll]);
  const listingAgent = useMemo(() => listingAgentMapper(), [data]);

  return (
    <>
      <div className={LayoutStyles.Content_Box}>
        <div>
          <p>View</p>
        </div>
        {views ? (
          <div className={LayoutStyles.Capsules_container}>
            {views.map((item) => (
              <div className={LayoutStyles.Outlined_capsule__gray}>
                <span>{item}</span>
              </div>
            ))}
          </div>
        ) : (
          "No views to preview ."
        )}

        {views && !showAll.views ? (
          <Button
            className="btns theme-propx outlined"
            id="btn-save"
            onClick={() => showAllHandler("views")}
          >
            {`Show all`}
          </Button>
        ) : null}
      </div>
      <div className={LayoutStyles.Content_Box}>
        <div>
          <p>What this place offers</p>
        </div>
        {fixtures ? (
          <div className={LayoutStyles.Capsules_container}>
            {fixtures.map((item) => (
              <div className={LayoutStyles.Outlined_capsule__gray}>
                <span>{item}</span>
              </div>
            ))}
          </div>
        ) : (
          "No fixtures to preview ."
        )}

        {fixtures && !showAll.fixtures ? (
          <Button
            className="btns theme-propx outlined"
            id="btn-save"
            onClick={() => showAllHandler("fixtures")}
          >
            {`Show all`}
          </Button>
        ) : null}
      </div>
      {listingAgent ? (
        <div className={LayoutStyles.Content_Box}>
          <div>
            <p>Listing agent details</p>
          </div>
          <div className="d-flex-v-center">
            <Avatar className={Styles.Avatar} src={
              listingAgent?.profileImage
                ? getDownloadableLink(listingAgent.profileImage)
                : ""
            }>
              {getFirstLastNameLetters(listingAgent.name || "")}
            </Avatar>
            <div className={Styles.Avatar_Text}>
              <span>{listingAgent.name || "-"}</span>
            </div>
          </div>
          <div className={Styles.Info_Pair}>
            <p>
              <span>Mobile</span>
            </p>
            <p>
              <span>
                {listingAgent.mobileNumber ? (
                  <CopyToClipboardComponents
                    data={listingAgent.mobileNumber}
                    childrenData={listingAgent.mobileNumber}
                  />
                ) : "-"}
              </span>
            </p>
          </div>
          <div className={Styles.Info_Pair}>
            <p>
              <span>Email</span>
            </p>
            <p>
              <span>
                {listingAgent.email ? (
                  <CopyToClipboardComponents
                    data={listingAgent.email}
                    childrenData={listingAgent.email}
                  />
                ) : "-"}
              </span>
            </p>
          </div>
          <div className={Styles.Info_Pair}>
            <p>
              <span>Whatsapp</span>
            </p>
            <p>
              <span>
                {listingAgent.whatsAppNumber ? (
                  <CopyToClipboardComponents
                    data={listingAgent.whatsAppNumber}
                    childrenData={listingAgent.whatsAppNumber}
                  />
                ) : "-"}
              </span>
            </p>
          </div>
        </div>
      ) : null}

      {/* <div className={LayoutStyles.Content_Box}>
        <div>
          <p>Developer details</p>
        </div>
        <div className="d-flex-v-center">
          <Avatar
            className={Styles.Avatar}
            src={
              ""
            }
          >
          </Avatar>
          <div className={Styles.Avatar_Text}>
            <span>{"EMAAR" || "-"}</span>
            <span>{"https://www.emaar.com/" || "-"}</span>
          </div>
        </div>

        <div className={Styles.Info_Pair}>
          <p>
            <span>Landline</span>
          </p>
          <p>
            <span>97143661688</span>
            <span></span>
          </p>
        </div>
        <div className={Styles.Info_Pair}>
          <p>
            <span>Email</span>
          </p>
          <p>
            <span>J.alali@propx.com</span>
            <span></span>
          </p>
        </div>
      </div> */}
    </>
  );
});

export default SideSection;
