import React, { useEffect, useRef, useState } from "react";
import {
  GetParams,
  showError,
  showSuccess,
} from "../../../../../../../../../../Helper";
import {
  ApproveOrRejectKycForm,
  ReadKycHtmlJQueryContant,
} from "../../../../../../../../../../Services";
import SignaturePad from "signature_pad";
import "./AgentForm.scss";
import { Spinner } from "../../../../../../../../../../Components";

function AgentForm() {
  const sharedKycDocumentId = GetParams("documentId");

  const [htmlContent, setHtmlContent] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const containerRef = useRef(null);
  const isFromAgent = true;

  const readSharedKycForm = async (documentId) => {
    setIsLoading(true);

    const res = await ReadKycHtmlJQueryContant(documentId);
    if (!(res && res.status && res.status !== 200)) {
      setHtmlContent(res.item1);
    } else {
      setHtmlContent("");
      showError("Error fetching KYC form:");
    }
    setIsLoading(false);
  };

  const handleFormAction = async (isSaveAction, agentStatus) => {
    const canvas = document.querySelector("#Declaration_agent_signatureRef");

    if (!canvas) return;

    const base64Image = canvas.toDataURL("image/png");
    const imgElement = document.createElement("img");
    imgElement.src = base64Image;
    imgElement.alt = "Signature";

    canvas.parentNode.replaceChild(imgElement, canvas);

    const container = containerRef.current;
    const AgentNameField = container.querySelector("#name_of_agentRef");
    if (AgentNameField) {
      AgentNameField.setAttribute("value", AgentNameField.value);
    }

    const updatedHtmlContent = container.innerHTML;

    const body = {
      sharedKycDocumentId,
      postStatus: 1,
      agentPostStatus: agentStatus,
      htmlContent: updatedHtmlContent,
    };

    const res = await ApproveOrRejectKycForm(isFromAgent, body);

    if (!(res && res.status && res.status !== 200)) {
      const successMessage = isSaveAction
        ? "Form approved successfully!"
        : "Form rejected successfully!";
      showSuccess(successMessage, "success");
    } else {
      showError(
        isSaveAction ? "Error approving KYC form!" : "Error rejecting KYC form!"
      );
    }
  };

  const saveHandler = async () => {
    setIsDisabled(true);
    await handleFormAction(true, 1);
  };

  const rejectHandler = async () => {
    setIsDisabled(true);
    await handleFormAction(false, 2);
  };

  const initializeSignaturePad = () => {
    const canvas = document.querySelector("#Declaration_agent_signatureRef");
    const signatureButton = document.querySelectorAll(".signature_button")[1];
    if (canvas) {
      const signaturePad = new SignaturePad(canvas);
      if (signatureButton) {
        signatureButton.addEventListener("click", () => {
          signaturePad.clear();
        });
      }
    }
  };

  const handleSignatureTextInput = () => {
    const canvas = document.querySelector("#Declaration_agent_signatureRef");
    const signatureTextInput = document.querySelector(
      ".signature_text.Declaration_agent_signature_id"
    );

    if (canvas && signatureTextInput) {
      signatureTextInput.addEventListener("input", () => {
        const context = canvas.getContext("2d");
        const typedName = signatureTextInput.value;

        context.clearRect(0, 0, canvas.width, canvas.height);

        context.font = "30px 'Monsieur La Doulaise', cursive";
        context.fillStyle = "black";

        const canvasCenterX = canvas.width / 2;
        context.textAlign = "center";
        document.fonts.ready.then(() => {
          context.fillText(typedName, canvasCenterX, 60);
        });
      });
    }
  };

  const enableRelevantInputs = () => {
    const inputs = document.querySelectorAll("input");
    const agentNameField = document.querySelector("#name_of_agentRef");
    const agentDateField = document.querySelector("#Agent-dateRef");
    const spanToRemove = document.querySelectorAll(".mdi-check");
    if (inputs.length > 0) {
      inputs.forEach((input) => input.setAttribute("disabled", true));
      const lastInput = inputs[inputs.length - 1];
      lastInput.removeAttribute("disabled");
    }
    if (agentNameField && agentDateField) {
      agentNameField.removeAttribute("disabled");
      agentDateField.removeAttribute("disabled");

      const handleInputChange = () => {
        agentNameField.setAttribute("value", agentNameField.value);
        agentDateField.setAttribute("value", agentDateField.value);
      };

      agentNameField.addEventListener("input", handleInputChange);
      agentDateField.addEventListener("input", handleInputChange);
    }
    if (spanToRemove.length > 0) {
      spanToRemove.forEach((span) => span.remove());
    }
  };

  useEffect(() => {
    if (sharedKycDocumentId) {
      readSharedKycForm(sharedKycDocumentId);
    }
  }, [sharedKycDocumentId]);

  useEffect(() => {
    initializeSignaturePad();
    enableRelevantInputs();
    handleSignatureTextInput();
  }, [htmlContent]);

  return (
    <div className="template-wrapper">
      <Spinner isActive={isLoading} isAbsolute />
      <div className="template-container">
        <link
          href="https://fonts.googleapis.com/css2?family=Monsieur+La+Doulaise&display=swap"
          rel="stylesheet"
        />
        <div
          ref={containerRef}
          dangerouslySetInnerHTML={{ __html: htmlContent }}
        />
      </div>
      <div className="form_actions">
        <button
          className="outlined"
          onClick={rejectHandler}
          disabled={isDisabled}
        >
          <span>Reject</span>
        </button>
        <button className="solid" onClick={saveHandler} disabled={isDisabled}>
          <span>Approve</span>
        </button>
      </div>
    </div>
  );
}

export default AgentForm;
