import { GetAllPropertyUnitModelsAPI } from '../Services';

let oldvalue = '';
let timer = null;

export const UnitModelRule = async (item, value, setRerender, itemList, setData, formType, values) => {
    if (!item.data.searchKey) return;
    if (item.data.searchKey !== 'Unit_Model_inquires') return;
    if (!value) return;
    const bedroomIndex = itemList.indexOf(itemList.find((f) => f.field.id === 'bedroom'));
    const areaSizeSqftIndex = itemList.indexOf(itemList.find((f) => f.field.id === 'area_sizesqft'));
    const primaryViewIndex = itemList.indexOf(itemList.find((f) => f.field.id === 'primary_view'));
    const secondaryViewIndex = itemList.indexOf(itemList.find((f) => f.field.id === 'secondary_view'));
    const clientBudgetFromIndex = itemList.indexOf(itemList.find((f) => f.field.id === 'client_budget-from' || f.field.id === 'client_budget_from'));
    const clientBudgetToIndex = itemList.indexOf(itemList.find((f) => f.field.id === 'client_budget-to' || f.field.id === 'client_budget_to'));
    const shortage_InlIndex = itemList.indexOf(itemList.find((f) => f.field.id === 'shortage_in'));
    const listingPriceFromIndex = itemList.indexOf(itemList.find((f) => f.field.id === 'listing_price-from' || f.field.id === 'listing_price_from'));
    const listingPriceToIndex = itemList.indexOf(itemList.find((f) => f.field.id === 'listing_price-to' || f.field.id === 'listing_price_to'));
    const areaSizeFrom = itemList.indexOf(itemList.find((f) => f.field.id === 'area_size-fromsqft' || f.field.id === 'area_size_fromsqft'));
    const areaSizeTo = itemList.indexOf(itemList.find((f) => f.field.id === 'area_size-tosqft' || f.field.id === 'area_size_tosqft'));

    if (formType === 1) {
        if (bedroomIndex !== -1 && value && value.bedroomNo && value.bedroomNo !== '')
            setData(itemList[bedroomIndex].field.id, (value && value.bedroomNo && [value.bedroomNo] || {}));

        if (areaSizeSqftIndex !== -1 && value && value.area && value.area !== '')
            setData(itemList[areaSizeSqftIndex].field.id, (value && value.area) || '  ');

        if (primaryViewIndex !== -1 && value && value.primaryView && value.primaryView.length)
            setData(itemList[primaryViewIndex].field.id, (value && value.primaryView) || {});

        if (secondaryViewIndex !== -1 && value && value.secondaryView && value.secondaryView.lookupItemId)
            setData(itemList[secondaryViewIndex].field.id, (value && value.secondaryView) || {});

        if (value && value.sellingPriceFrom && value.sellingPriceFrom !== '' && clientBudgetFromIndex !== -1 && itemList[clientBudgetFromIndex].data.specialKey === 'sale_inquiry') {
            setData(itemList[clientBudgetFromIndex].field.id, (value && value.sellingPriceFrom) || ' ');
            setData('client_budget_from', (value && value.sellingPriceFrom) || '  ');
        }

        if (value && value.sellingPriceTo && value.sellingPriceTo !== '' && clientBudgetToIndex !== -1 && itemList[clientBudgetToIndex].data.specialKey === 'sale_inquiry') {
            setData(itemList[clientBudgetToIndex].field.id, (value && value.sellingPriceTo) || '  ');
            setData('client_budget_to', (value && value.sellingPriceTo) || '  ');
        }

        if (value && value.rentPerYearFrom && value.rentPerYearFrom !== '' && clientBudgetFromIndex !== -1 && itemList[clientBudgetFromIndex].data.specialKey === 'lease_inquiry') {
            setData(itemList[clientBudgetFromIndex].field.id, (value && value.rentPerYearFrom) || '  ');
            setData('client_budget_from', (value && value.rentPerYearFrom) || '  ');
        }
        if (value && value.rentPerYearTo && value.rentPerYearTo !== '' && clientBudgetToIndex !== -1 && itemList[clientBudgetToIndex].data.specialKey && itemList[clientBudgetToIndex].data.specialKey === 'lease_inquiry') {
            setData(itemList[clientBudgetToIndex].field.id, (value && value.rentPerYearTo) || '  ');
            setData('client_budget_to', (value && value.rentPerYearTo) || '  ');
        }
        if (areaSizeFrom !== -1 && value && value.area && value.area !== '') {
            setData(itemList[areaSizeFrom].field.id, (value && value.area) || '  ');
            setData('area_size_fromsqft', (value && value.area) || '  ');
        }
        // if(areaSizeTo  !== -1 )
        // {
        //     setData(itemList[areaSizeTo].field.id,  null) ;
        //     setData('area_size_tosqft' ,  '  ') ;

        // }

        if (shortage_InlIndex !== -1 && values && values.shortage_in) {
            if (values && values.shortage_in && values.shortage_in === 'Sale') {
                if (listingPriceFromIndex !== -1 && listingPriceToIndex !== -1) {
                    if (value && value.sellingPriceFrom && value.sellingPriceFrom !== '') {
                        setData(itemList[listingPriceFromIndex].field.id, (value && value.sellingPriceFrom));
                        setData('listing_price_from', (value && value.sellingPriceFrom));
                    }
                    if (value && value.sellingPriceTo && value.sellingPriceTo !== '') {
                        setData(itemList[listingPriceToIndex].field.id, (value && value.sellingPriceTo));
                        setData('listing_price_to', (value && value.sellingPriceTo));
                    }
                }
            } else if (values && values.shortage_in && values.shortage_in === 'Leasing') {
                if (listingPriceFromIndex !== -1 && listingPriceToIndex !== -1) {
                    if (value && value.rentPerYearFrom && value.rentPerYearFrom !== '') {
                        setData(itemList[listingPriceFromIndex].field.id, (value && value.rentPerYearFrom));
                        setData('listing_price_from', (value && value.rentPerYearFrom));
                    }

                    if (value && value.rentPerYearFrom && value.rentPerYearFrom !== '') {
                        setData(itemList[listingPriceToIndex].field.id, (value && value.rentPerYearTo));
                        setData('listing_price_to', (value && value.rentPerYearTo));
                    }
                }
            }
        }
    }

    if (formType === 2) {
        if (bedroomIndex !== -1 && value && value.bedroomNo && value.bedroomNo !== '')
            setData((value && value.bedroomNo && [value.bedroomNo]), 0, itemList[bedroomIndex].field.id);

        if (areaSizeSqftIndex !== -1 && value && value.area && value.area !== '')
            setData((value && value.area), 0, itemList[areaSizeSqftIndex].field.id);

        if (areaSizeFrom !== -1 && value && value.area && value.area !== '')
            setData((value && value.area) || null, 0, 'area_size_fromsqft');

        // if(areaSizeTo !== -1 )
        //  setData(null , 0 ,'area_size_tosqft') ;

        if (primaryViewIndex !== -1 && value && value.primaryView && value.primaryView.length)
            setData((value && value.primaryView), 0, itemList[primaryViewIndex].field.id);

        if (secondaryViewIndex !== -1 && value && value.secondaryView && value.secondaryView.lookupItemId)
            setData((value && value.secondaryView), 0, itemList[secondaryViewIndex].field.id);

        if (value.sellingPriceFrom && value.sellingPriceFrom !== '' && clientBudgetFromIndex !== -1 && itemList[clientBudgetFromIndex].data.specialKey === 'sale_inquiry' && value.sellingPriceFrom)
            setData((value && value.sellingPriceFrom), 0, itemList[clientBudgetFromIndex].field.id);

        if (value && value.sellingPriceTo && value.sellingPriceTo !== '' && clientBudgetToIndex !== -1 && itemList[clientBudgetToIndex].data.specialKey === 'sale_inquiry' && value && value.sellingPriceTo)
            setData((value && value.sellingPriceTo), 0, itemList[clientBudgetToIndex].field.id);

        if (value && value.rentPerYearFrom && value.rentPerYearFrom !== '' && clientBudgetFromIndex !== -1 && itemList[clientBudgetFromIndex].data.specialKey === 'lease_inquiry')
            setData((value && value.rentPerYearFrom), 0, itemList[clientBudgetFromIndex].field.id);

        if (value && value.rentPerYearTo && value.rentPerYearTo !== '' && clientBudgetToIndex !== -1 && itemList[clientBudgetToIndex].data.specialKey === 'lease_inquiry')
            setData((value && value.rentPerYearTo), 0, itemList[clientBudgetToIndex].field.id);

        if (shortage_InlIndex !== -1 && values && values.shortage_in) {
            if (values && values.shortage_in && values.shortage_in === 'Sale') {
                if (listingPriceFromIndex !== -1 && listingPriceToIndex !== -1) {
                    if (value && value.sellingPriceFrom && value.sellingPriceFrom !== '')
                        setData((value && value.sellingPriceFrom), 0, itemList[listingPriceFromIndex].field.id);

                    if (value && value.sellingPriceTo && value.sellingPriceTo !== '')
                        setData((value && value.sellingPriceTo), 0, itemList[listingPriceToIndex].field.id);
                }
            } else if (values && values.shortage_in && values.shortage_in === 'Leasing') {
                if (listingPriceFromIndex !== -1 && listingPriceToIndex !== -1) {
                    if (value && value.rentPerYearFrom && value.rentPerYearFrom !== '')
                        setData((value && value.rentPerYearFrom), 0, itemList[listingPriceFromIndex].field.id);

                    if (value && value.rentPerYearTo && value.rentPerYearTo !== '')
                        setData((value && value.rentPerYearTo), 0, itemList[listingPriceToIndex].field.id);
                }
            }
        }
    }
};

export const onChangePropertyRule = async (item, value, setRerender, itemList, formType, setData) => {
    if (!item.data.searchKey) return;
    if (item.data.searchKey !== 'property') return;
    if (timer !== null) clearTimeout(timer);
    if (oldvalue === value) return;
    oldvalue = value;

    const unitModelIndex = itemList.indexOf(itemList.find((f) => f.field.id === 'inquiry_unit-model' || f.field.id === 'inquiry_unit_model'));

    if (unitModelIndex !== -1) {
        itemList[unitModelIndex].data.enum = [];
        if (formType === 1) {
            setData(itemList[unitModelIndex].field.id, null);
            setData('inquiry_unit_model', {});
        } else if (formType === 2)
            setData(null, 0, 'inquiry_unit_model');
    }
    if (value && value.id) {
        timer = setTimeout(async () => {
            const rs = await GetAllPropertyUnitModelsAPI({ propertyId: value && value.id, pageIndex: 1, pageSize: 99999 });
            if (!rs || !rs.result) return;

            rs.result.map((element) => {
                if(itemList[unitModelIndex]?.data?.enum)
                itemList[unitModelIndex].data.enum.push({
                    name: `${element.propertyUnitModelName}`,
                    area: (element.area) || null,
                    bedroomNo: (element.bedroomNo) || null,
                    bathroomNo: element.bathroomNo || null,
                    primaryView: (element.primaryData) || null,
                    rentPerYearFrom: (element.rentPerYearFrom) || null,
                    rentPerYearTo: (element.rentPerYearTo) || null,
                    secondaryView: (element.secondaryData) || null,
                    sellingPriceFrom: (element.sellingPriceFrom) || null,
                    sellingPriceTo: (element.sellingPriceTo) || null
                });
            });
            setRerender(Math.random());
        }, 500);
    }
};

export const UnitModelDefaultRule = async (item, setRerender, itemList, values) => {
    if (!item.data.searchKey) return;
    if (item.data.searchKey !== 'Unit_Model_inquires') return;

    const unitModelIndex = itemList.indexOf(itemList.find((f) => f.field.id === 'inquiry_unit-model' || f.field.id === 'inquiry_unit_model'));

    if (unitModelIndex !== -1) {
        itemList[unitModelIndex].data.enum = [];

        if (values && values.property && values.property.id) {
            timer = setTimeout(async () => {
                const rs = await GetAllPropertyUnitModelsAPI({ propertyId: (values && values.property && values.property.id), pageIndex: 1, pageSize: 99999 });
                if (!rs || !rs.result) return;

                rs.result.map((element) => {
                    if(itemList[unitModelIndex]?.data?.enum)
                    itemList[unitModelIndex].data.enum.push({
                        name: `${element.propertyUnitModelName}`,
                        area: (element.area) || null,
                        bedroomNo: (element.bedroomNo) || null,
                        bathroomNo: element.bathroomNo || null,
                        primaryView: (element.primaryData) || null,
                        rentPerYearFrom: (element.rentPerYearFrom) || null,
                        rentPerYearTo: (element.rentPerYearTo) || null,
                        secondaryView: (element.secondaryData) || null,
                        sellingPriceFrom: (element.sellingPriceFrom) || null,
                        sellingPriceTo: (element.sellingPriceTo) || null
                    });
                });
                setRerender(Math.random());
            }, 500);
        }
    }
};
