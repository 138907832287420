import React, { useCallback, useState } from "react";
import Styles from "./LeadInquiriesView.module.scss";
import { ActiveInquiryViewsEnum } from "./ActiveInquiryViewsEnum";
import LeadInquiriesComponent from "./LeadInquiriesComponent/LeadInquiriesComponent";
import MatchedUnitsComponent from "./MatchedUnitsComponent/MatchedUnitsComponent";
import MatchedUnitOverview from "./MatchedUnitOverview/MatchedUnitOverview";

const LeadInquiriesView = () => {

  const [activeView, setActiveView] = useState(ActiveInquiryViewsEnum.LeadInquiries);
  const [activeInquiryId, setActiveInquiryId] = useState(null);
  const [activeUnit, setActiveUnit] = useState(null);

  const viewChangeHandler = useCallback(({ view, inquiryId, unit }) => {

    setActiveView(view);
    if (inquiryId) setActiveInquiryId(inquiryId);
    setActiveUnit(unit || null)
  }, []);

  return (
    <div className={Styles.View_layout}>
      <div className="d-flex-v-center-h-between">
        <div className={Styles.Heading_box}>
          <div className={Styles.Heading}>
            <h2 onClick={() => viewChangeHandler({ view: ActiveInquiryViewsEnum.LeadInquiries })}>
              Lead Inquiries
            </h2>
            {(activeView == ActiveInquiryViewsEnum.MatchedUnit ||
              activeView == ActiveInquiryViewsEnum.UnitOverview) ? (
              <>
                <span className={`mdi mdi-page-last mt-1 mx-2`}></span>
                <h3 onClick={() => viewChangeHandler({ view: ActiveInquiryViewsEnum.MatchedUnit })}>Matched Units</h3>
              </>
            ) : null}
            {activeView == ActiveInquiryViewsEnum.UnitOverview ? (
              <>
                <span className={`mdi mdi-page-last mt-1 mx-2`}></span>
                <h4><span>{activeUnit?.propertyName || null}</span>
                  <span className="mx-2">{activeUnit?.unitRefNo ? `( ${activeUnit.unitRefNo} )` : "Overview"}</span>
                </h4>
              </>
            ) : null}
          </div>
          <div className="mt-2">
            {activeView == ActiveInquiryViewsEnum.LeadInquiries ? (<p> Browse leads's inquiries created by agent . </p>
            ) : null}
            {activeView == ActiveInquiryViewsEnum.MatchedUnit ? (<p> Browse the matching units based on the client's preferences.
              Use this page to <b>mark units as shortlisted</b> or <b>send proposals</b>.</p>
            ) : null}
          </div>
        </div>
      </div>
      {activeView === ActiveInquiryViewsEnum.LeadInquiries ? (
        <LeadInquiriesComponent viewChangeHandler={viewChangeHandler} />
      ) : null}
      {activeView === ActiveInquiryViewsEnum.MatchedUnit ? (
        <MatchedUnitsComponent inquiryId={activeInquiryId} viewChangeHandler={viewChangeHandler} />
      ) : null}
      {activeView === ActiveInquiryViewsEnum.UnitOverview ? (
        <MatchedUnitOverview
          actionButtons={{
            sendPropsal: true,
            markAsShortListed: true,
          }}
          inquiryId={activeInquiryId}
          unitId={activeUnit?.unitId}
        />
      ) : null}
    </div>
  );
};

export default LeadInquiriesView;
