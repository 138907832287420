import React, { useEffect, useState } from "react";
import GeneralStyles from "../LeadInquiriesView.module.scss";
import {
  GetInquiriesDetailsByLeadId,
  leadDetailsGet,
} from "../../../../../../../Services";
import { bottomBoxComponentUpdate, GetParams, GlobalHistory, sideMenuComponentUpdate, sideMenuIsOpenUpdate } from "../../../../../../../Helper";
import LeadInquiriesCard from "../Utilities/LeadInquiriesCard/LeadInquiriesCard";
import { PaginationComponent, Spinner } from "../../../../../../../Components";
import { ActiveInquiryViewsEnum } from "../ActiveInquiryViewsEnum";
import { ButtonBase } from '@material-ui/core';

const LeadInquiriesComponent = ({
  viewChangeHandler,
}) => {
  const leadId = GetParams('id');
  const leadClass = GetParams('leadClass');
  const leadStatus = GetParams('leadStatus');

  const [isLoading, setIsLoading] = useState(false);
  const [leadInquiries, setLeadInquiries] = useState({
    result: [],
    totalCount: 0,
  });
  const [filter, setFilter] = useState({
    pageSize: parseInt(localStorage.getItem('Pagination')) || 25,
    pageIndex: 0,
  });


  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
  };

  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
  };

  const getInquiriesDetailsByLeadId = async () => {
    setIsLoading(true);
    const res = await GetInquiriesDetailsByLeadId({
      leadId,
      pageIndex: filter.pageIndex + 1,
      pageSize: filter.pageSize,
    });
    if (!(res && res.status && res.status !== 200)) {
      setLeadInquiries(res || {});
    } else setLeadInquiries([]);
    setIsLoading(false);
  };

  const getLeadDetails = async () => {
    const result = await leadDetailsGet({ id: leadId });
    if (!(result && result.status && result.status !== 200)) {
      localStorage.setItem(
        'leadDetails',
        JSON.stringify(result && result.lead)
      );
    }
  };


  const isAddButtonDisplayed = () => (!isLoading &&
    leadStatus === 'Open' &&
    (leadClass === 'Buyer' || leadClass === 'Tenant'));
  
  useEffect(() => {
    bottomBoxComponentUpdate(
      <PaginationComponent
        pageIndex={filter.pageIndex}
        pageSize={filter.pageSize}
        totalCount={leadInquiries.totalCount}
        onPageIndexChanged={onPageIndexChanged}
        onPageSizeChanged={onPageSizeChanged}
      />
    );
  });

  useEffect(
    () => () => {
      bottomBoxComponentUpdate(null);
      sideMenuComponentUpdate(null);
      sideMenuIsOpenUpdate(false);
    },
    []
  );

  useEffect(() => {
    getInquiriesDetailsByLeadId();
  }, [filter]);


  useEffect(() => {
    getLeadDetails();
  }, []);

  return (
    <>
      <Spinner isActive={isLoading} />
      <div className={GeneralStyles.Button_add}>
        {isAddButtonDisplayed()? (
            <ButtonBase
              className='btns theme-solid'
              idRef={`AddNewZeroMatching`}
              onClick={() => {
                if (leadClass === 'Tenant')
                  GlobalHistory.push(`/home/zero-matching-lease/add?formType=2&leadId=${leadId}`);
                else if (leadClass === 'Buyer')
                  GlobalHistory.push(`/home/zero-matching-sale/add?formType=1&leadId=${leadId}`);
              }}
            >
              <span>{`Add new zero matching`}</span>
            </ButtonBase>
          ) : null}
      </div>
      <div className={GeneralStyles.Cards_Container}>
        {leadInquiries?.result
          ? leadInquiries.result.map((item) => item ? <div key={item.inquiryId} 
          onClick={() => viewChangeHandler({view: ActiveInquiryViewsEnum.MatchedUnit, inquiryId: item.inquiryId})}>
            <LeadInquiriesCard data={item} />
          </div> : null)
          : null}
      </div>
    </>
  );
};

export default LeadInquiriesComponent;
