export const fetchedDataModel = (data) => {
  let formattedResult = {};
  Object.entries(data).forEach(([key, value]) => {
    if (
      [
        'courseCommunities',
        'lmsOperationTypes',
        'lmsPropertyCategories',
      ].includes(key)
    ) {
      formattedResult[key] = value.map((item) => ({
        lookupItemId: item.id,
        lookupItemName: item.name,
      }));
    } else if (key === 'courseProjects') {
      formattedResult[key] = value.map((item) => ({
        propertyId: item.id,
        propertyName: item.name,
      }));
    } else if (key === 'courseDevelopers') {
      formattedResult[key] = value.map((item) => ({
        contactsId: item.id,
        name: item.name,
      }));
    } else {
      formattedResult[key] = value;
    }
  });
  return formattedResult;
};

export const createDtoModel = (data) => {
  let body = {};
  const keys = {
    courseCommunities: 'courseCommunityIds',
    courseDevelopers: 'courseDeveloperIds',
    lmsOperationTypes: 'lmsOperationTypesIds',
    lmsPropertyCategories: 'lmsPropertyCategoriesIds',
    courseProjects: 'courseProjectIds',
  };
  Object.entries(data).forEach(([key, value]) => {
    if (
      [
        'courseCommunities',
        'lmsOperationTypes',
        'lmsPropertyCategories',
      ].includes(key)
    ) {
      body[keys[key]] = value.map((item) => item.lookupItemId);
    } else if (key === 'courseProjects') {
      body[keys[key]] = value.map((item) => item.propertyId);
    } else if (key === 'courseDevelopers') {
      body[keys[key]] = value.map((item) => item.contactsId);
    } else {
      body[key] = value;
    }
  });
  return body;
};
