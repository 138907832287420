import React from "react";

function FIGFigmaIcon({ fill, ...restProps }) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.75 4C7.75 2.20508 9.20508 0.75 11 0.75H27C27.1212 0.75 27.2375 0.798159 27.3232 0.883885L38.1161 11.6768C38.2018 11.7625 38.25 11.8788 38.25 12V36C38.25 37.7949 36.7949 39.25 35 39.25H11C9.20507 39.25 7.75 37.7949 7.75 36V4Z"
        stroke="#D0D5DD"
        stroke-width="1.5"
      />
      <path
        d="M27 0.5V8C27 10.2091 28.7909 12 31 12H38.5"
        stroke="#D0D5DD"
        stroke-width="1.5"
      />
      <rect x="1" y="18" width="23" height="16" rx="2" fill="#7F6944" />
      <path
        d="M5.00222 30V22.7273H9.81756V23.995H6.53986V25.728H9.49796V26.9957H6.53986V30H5.00222ZM12.3895 22.7273V30H10.8518V22.7273H12.3895ZM18.477 25.0781C18.4273 24.9053 18.3575 24.7526 18.2675 24.62C18.1775 24.4851 18.0674 24.3714 17.9372 24.2791C17.8094 24.1844 17.6626 24.1122 17.4969 24.0625C17.3335 24.0128 17.1524 23.9879 16.9536 23.9879C16.5819 23.9879 16.2552 24.0803 15.9735 24.2649C15.6941 24.4496 15.4763 24.7183 15.32 25.071C15.1638 25.4214 15.0857 25.8499 15.0857 26.3565C15.0857 26.8632 15.1626 27.294 15.3165 27.6491C15.4704 28.0043 15.6882 28.2753 15.9699 28.4624C16.2516 28.647 16.5843 28.7393 16.9678 28.7393C17.3158 28.7393 17.6129 28.6778 17.8591 28.5547C18.1077 28.4292 18.2971 28.2528 18.4273 28.0256C18.5599 27.7983 18.6262 27.5296 18.6262 27.2195L18.9387 27.2656H17.0637V26.108H20.107V27.0241C20.107 27.6634 19.972 28.2126 19.7021 28.6719C19.4323 29.1288 19.0606 29.4815 18.5871 29.7301C18.1136 29.9763 17.5715 30.0994 16.9607 30.0994C16.2789 30.0994 15.6799 29.9491 15.1638 29.6484C14.6477 29.3454 14.2452 28.9157 13.9564 28.3594C13.67 27.8007 13.5267 27.1378 13.5267 26.3707C13.5267 25.7812 13.6119 25.2557 13.7824 24.794C13.9552 24.33 14.1967 23.937 14.5068 23.6151C14.817 23.2931 15.178 23.0481 15.5899 22.88C16.0019 22.7119 16.4481 22.6278 16.9287 22.6278C17.3406 22.6278 17.7242 22.6882 18.0793 22.8089C18.4344 22.9273 18.7493 23.0954 19.0239 23.3132C19.3009 23.531 19.527 23.7902 19.7021 24.0909C19.8773 24.3892 19.9898 24.7183 20.0395 25.0781H18.477Z"
        fill="white"
      />
    </svg>
  );
}

export default FIGFigmaIcon;
