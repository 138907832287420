import React, { useCallback, useEffect, useState } from "react";
import {
  AssignedAgentsCradSkeleton,
  NoDataFound,
} from "../../../../../../Components";
import { Box } from "@material-ui/core";
import { GetAgentsForContactLeads } from "../../../../../../Services";
import { AssignedAgentsCrad } from "../../../../../../Components/V2/AssignedAgentsCrad";
import { useTranslate } from "../../../../../../Hooks";

// Styles
import useStyles from "./styles";


function AssignedAgents({ contactId }) {
  const styles = useStyles();
  const { translate } = useTranslate("NewContactsView");
  const [assignedAgents, setAssignedAgents] = useState({
    result: [],
    totalCount: 0,
  });
  const [isloadingAssignedAgents, setIsloadingAssignedAgents] = useState(true);
  const getAgentsForContactLeads = useCallback(async () => {
    setIsloadingAssignedAgents(true);
    const res = await GetAgentsForContactLeads(contactId);
    if (!(res && res.status && res.status !== 200)) {
      setAssignedAgents({
        result: res?.result,
        totalCount: res?.totalCount,
      });
    } else {
      setAssignedAgents({
        result: [],
        totalCount: 0,
      });
    }
    setIsloadingAssignedAgents(false);
  });
  useEffect(() => {
    getAgentsForContactLeads();
  }, []);

  return (
    <>
      <Box className={styles.container_assignedAgentsWrapper}>
        {isloadingAssignedAgents ? (
          <AssignedAgentsCradSkeleton numberOfCardsSkeleton={8} />
        ) : assignedAgents?.result?.length > 0 ? (
          assignedAgents?.result?.map((agentItemdDetails, index) => (
            <AssignedAgentsCrad
              key={agentItemdDetails?.agentId || index}
              agentItemdDetails={agentItemdDetails}
            />
          ))
        ) : (
          <NoDataFound title={translate("Assigned_Agents")} />
        )}
      </Box>
    </>
  );
}

export default AssignedAgents;
