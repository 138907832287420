import React from "react";
import clsx from "clsx";
import { Typography, Divider, Box } from "@material-ui/core";
import { useSelectedTheme, useTranslate } from "../../../../../../../Hooks";
import { getDownloadableLink } from "../../../../../../../Helper";
import { CustomBadge, CustomCard } from "../../../../../../../Components";

// Styles
import { generalInformationStyles } from "./styles";


function GeneralInformation({ details }) {
  const styles = generalInformationStyles();
  
  const { translate } = useTranslate("NewContactsView");

  const {
    theme: { palette },
  } = useSelectedTheme();

  const spouseContactId = details?.spouse_contact?.contact_image?.["Image Upload"]?.[0]?.uuid;

  return (
    <CustomCard
      borderRadius="xl"
      boxShadow="xs"
      borderColor="secondary"
      classes={styles.container}
    >
      <Typography className={styles.heading} variant="h3">
      {translate('general-information')}
      </Typography>
      <Typography className={styles.subHeading} variant="h3">
      {translate('join-the-conversation')}:
      </Typography>
      <Divider className={styles.divider} />
      <Box className={styles.infoContainer}>
        <Box className={clsx(styles.dualPane, styles.mainSideWrapper)}>
          <Box className={styles.infoItem}>
            <Typography className={styles.infoLabel} variant="body1">
            {translate('qualification')}:
            </Typography>
            <CustomBadge
              Style={{
                padding: "2px 10px",
              }}
              label={details?.qualification?.lookupItemName ?? "N/A"}
              SizeVariant="large"
              BackgroundColor={palette.utility.gray_blue_50}
              BorderColor={palette.utility.gray_blue_200}
              Color={palette.text.secondary}
            />
          </Box>
          <Box className={styles.infoItem}>
            <Typography className={styles.infoLabel} variant="body1">
            {translate('job-title')}:
            </Typography>
            <Typography className={styles.infoValue} variant="body1">
                {details?.job_title ?? "N/A"}
            </Typography>
          </Box>
          <Box className={styles.infoItem}>
            <Typography className={styles.infoLabel} variant="body1">
            {translate('works-company-name')}:
            </Typography>
            <Typography className={styles.infoValue} variant="body1">
              {details?.works_company_name?.name ?? "N/A"}
            </Typography>
          </Box>
          <Box className={styles.infoItem}>
            <Typography className={styles.infoLabel} variant="body1">
            {translate('media-name')}:
            </Typography>
            <Typography className={styles.infoValue} variant="body1">
              {details?.media_name?.lookupItemName ?? "N/A"}
            </Typography>
          </Box>
          <Box className={styles.infoItem}>
            <Typography className={styles.infoLabel} variant="body1">
                {translate('Investor')}:
            </Typography>
            <Typography className={styles.infoValue} variant="body1">
              {details?.investor ?? "N/A"}
            </Typography>
          </Box>
        </Box>
        <Box className={styles.dualPane}>
          <Box className={styles.infoItem}>
            <Typography className={styles.infoLabel} variant="body1">
               {translate('representative-contact')}:
            </Typography>
            <Typography className={styles.infoValue} variant="body1">
              {details?.representative_contact?.name ?? "N/A"}
            </Typography>
          </Box>
          <Box className={styles.infoItem}>
            <Typography className={styles.infoLabel} variant="body1">
              {translate('spouse-contact')}:
            </Typography>
            <Box display="flex">
              <span className={styles.avatarWrapper}>
                <Box
                  className={styles.avatar}
                  style={{
                    backgroundImage: `url(${getDownloadableLink(spouseContactId)})`,
                  }}
                />
              </span>
              <Typography className={styles.infoValue} variant="body1">
                {details?.spouse_contact?.name ?? "N/A"}
              </Typography>
            </Box>
          </Box>
          <Box className={styles.infoItem}>
            <Typography className={styles.infoLabel} variant="body1">
              {translate('owner-in-multiple-areas')}:
            </Typography>
            <Typography className={styles.infoValue} variant="body1">
              {details?.owner_in_multiple_areas ?? "N/A"}
            </Typography>
          </Box>
          <Box className={styles.infoItem}>
            <Typography className={styles.infoLabel} variant="body1">
              {translate('high-net-worth')}:
            </Typography>
            <Typography className={styles.infoValue} variant="body1">
              {details?.high_net_worth ?? "N/A"}
            </Typography>
          </Box>
          <Box className={styles.infoItem}>
            <Typography className={styles.infoLabel} variant="body1">
              {translate('owner-for-one-unit')}:
            </Typography>
            <Typography className={styles.infoValue} variant="body1">
              {details?.owner_for_one_unit ?? "N/A"}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Divider className={clsx(styles.divider, styles.secondDivider)} />
      <Box className={styles.subInfoContainer}>
        <Box className={styles.infoItem}>
          <Typography className={styles.infoLabel} variant="body1">
          {translate('contact-profession')}:
          </Typography>
          <Typography className={styles.infoValue} variant="body1">
            {details?.contact_profession?.lookupItemName ?? "N/A"}
          </Typography>
        </Box>
        <Box className={styles.infoItem}>
          <Typography className={styles.infoLabel} variant="body1">
          {translate('representative-contact')}:
          </Typography>
          <Typography className={styles.infoValue} variant="body1">
            {details?.representative_contact?.name ?? "N/A"}
          </Typography>
        </Box>
      </Box>
    </CustomCard>
  );
}

export default GeneralInformation;
