import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Joi from 'joi';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  ButtonBase,
} from '@material-ui/core';
import { Spinner, Inputs } from '../../../../../../../../Components';
import { showError, getErrorByName } from '../../../../../../../../Helper';
import {
  GetLocationByAddress,
  propertyPost,
} from '../../../../../../../../Services';
import { propertyInitValue } from './PropertyQuickAddInitialValue';
import {
  PropertyTypeComponent,
  PropertyPlanComponent,
  CountryComponent,
  CityComponent,
  DistrictComponent,
  CommunityComponent,
  SubCommunityComponent,
} from './Components';
import { useSuccessDialog } from '../../../../../../../../SharedComponents';
import { usePropertyValidationContext } from '../../../../../../../../Contexts/PropertyValidationContext/PropertyValidationContext';

const translationPath = '';

const parentTranslationPath = 'PropertiesView';
export const PropertyQuickAddDialog = ({ open, onClose }) => {
  const {
    isValidProperty,
    resetStateHandler,
    data: {
      country,
      city,
      district,
      community,
      subCommunity,
      propertyType,
      propertyName,
      map,
    },
  } = usePropertyValidationContext();
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation(parentTranslationPath);
  const { showDialog } = useSuccessDialog();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [saveDisabled, setSaveDisabled] = useState(false);
  const [propertyDetails, setPropertyDetails] = useState(
    JSON.parse(JSON.stringify(propertyInitValue))
  );
  const [addressLoadings, setAddressLoadings] = useState({
    country: false,
    city: false,
    district: false,
    community: false,
    subCommunities: false,
  });
  const [addressList, setAddressList] = useState({
    countries: [],
    cities: [],
    districts: [],
    communities: [],
    subCommunities: [],
  });
  const history = useHistory();
  const schema = Joi.object({
    property_plan: Joi.object()
      .required()
      .messages({
        'object.base': t(`${translationPath}property_plan-is-required`),
      }),
    property_name: Joi.string()
      .required()
      .messages({
        'string.base': t(`${translationPath}property_name-is-required`),
        'string.empty': t(`${translationPath}property_name-is-required`),
      }),
    property_type: Joi.object()
      .required()
      .messages({
        'object.base': t(`${translationPath}property_type-is-required`),
      }),

    country: Joi.object()
      .required()
      .messages({
        'object.base': t(`${translationPath}country-is-required`),
      }),
    city: Joi.object()
      .required()
      .messages({
        'object.base': t(`${translationPath}city-is-required`),
      }),
    district: Joi.object()
      .required()
      .messages({
        'object.base': t(`${translationPath}district-is-required`),
      }),
    community: Joi.object()
      .required()
      .messages({
        'object.base': t(`${translationPath}community-is-required`),
      }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(propertyDetails.property);

  const saveHandler = async (isContinue) => {
    setSaveDisabled(true);
    setIsLoading(true);
    setIsSubmitted(true);
    if (schema.error) {
      showError(t('Shared:please-fix-all-errors'));
      setIsLoading(false);
      return;
    }
    propertyDetails.property.data_completed = 16;
    const postResponse = await propertyPost({
      propertyJson: propertyDetails,
    });
    setSaveDisabled(true);
    setIsLoading(true);

    if (!(postResponse && postResponse.status && postResponse.status !== 200)) {
      setPropertyDetails({
        property: {
          city: null,
          country: null,
          district: null,
          community: null,
          property_name: null,
          property_plan: null,
          property_type: null,
          property_owner: null,
          sub_community: null,
        },
      });
      // showSuccess(t(`${translationPath}property-Created-successfully`));
      showDialog(t('GlobalSuccessDialog:property-successfully'), {
        name: postResponse?.propertyName,
        id: postResponse?.propertyId,
      });

      if (isContinue)
        history.push(
          `/home/Properties-CRM/property-profile-edit?formType=1&id=${postResponse.propertyId}`
        );
      setSaveDisabled(false);
      setIsLoading(false);
      onClose();
      resetStateHandler()
    } else {
      setSaveDisabled(false);
      setIsLoading(false);
      const errorMsg =
        postResponse &&
        postResponse.data &&
        postResponse.data.Message &&
        postResponse.data.Message.split(':') &&
        postResponse.data.Message.split(':').pop();
      showError(`${t(
        `${translationPath}${errorMsg.substring(0, errorMsg.lastIndexOf(' '))}`
      )}
      ${errorMsg.substring(errorMsg.lastIndexOf(' '), errorMsg.length)}`);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    setPropertyDetails((item) => ({
      ...item,
      property: {
        ...item.property,
        country,
        city,
        district,
        community,
        sub_community: subCommunity,
        property_type_id: propertyType?.lookupItemId,
        property_name: propertyName,
        property_type: propertyType, 
        map,
      },
    }));
  }, [country, city, district, community, subCommunity, map]);

  useEffect(()=>{
    if (schema.error && isSubmitted) {
      setSaveDisabled(!!schema.error)
    }
  },[schema.error, propertyDetails.property])
  return (
    <div>
      <Dialog
        open={open}
        onClose={() => {
          onClose();
          resetStateHandler();
        }}
        className='activities-management-dialog-wrapper'
        disableBackdropClick
      >
        <form noValidate>
          <DialogTitle id='alert-dialog-slide-title'>
            {t(`${translationPath}add-new-property`)}
          </DialogTitle>
          <DialogContent>
            <div className='dialog-content-wrapper'>
              <Spinner isActive={isLoading} isAbsolute />
              <div className='dialog-content-item'>
                <PropertyPlanComponent
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  labelClasses='Requierd-Color'
                  isSubmitted={isSubmitted}
                  helperText={getErrorByName(schema, 'property_plan').message}
                  error={getErrorByName(schema, 'property_plan').error}
                  onUpdateValue={(returnValue) => {
                    const property = {
                      ...propertyDetails.property,
                      property_plan: returnValue,
                    };
                    setPropertyDetails((element) => ({ ...element, property }));
                  }}
                />
              </div>
              <div className='dialog-content-item'>
                <Inputs
                  idRef='propertyNameRef'
                  labelValue='property_name'
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  labelClasses='Requierd-Color'
                  isSubmitted={isSubmitted}
                  helperText={getErrorByName(schema, 'property_name').message}
                  error={getErrorByName(schema, 'property_name').error}
                  isWithError
                  value={propertyName}
                  isDisabled={isValidProperty}
                  onInputChanged={(e) => {
                    const property = {
                      ...propertyDetails.property,
                      property_name: e && e.target && e.target.value,
                    };
                    setPropertyDetails((element) => ({ ...element, property }));
                  }}
                />
              </div>
              <div className='dialog-content-item'>
                <PropertyTypeComponent
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  labelClasses='Requierd-Color'
                  isSubmitted={isSubmitted}
                  helperText={getErrorByName(schema, 'property_type').message}
                  error={getErrorByName(schema, 'property_type').error}
                  onUpdateValue={(returnValue) => {
                    const property = {
                      ...propertyDetails.property,
                      property_type: returnValue,
                    };
                    setPropertyDetails((element) => ({ ...element, property }));
                  }}
                  value={propertyType}
                  isDisabled={isValidProperty}
                />
              </div>
              <div className='dialog-content-item'>
                <CountryComponent
                  propertyValues={propertyDetails && propertyDetails.property}
                  addressLoadings={addressLoadings}
                  addressList={addressList}
                  setAddressLoadings={setAddressLoadings}
                  setAddressList={setAddressList}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  labelClasses='Requierd-Color'
                  helperText={getErrorByName(schema, 'country').message}
                  error={getErrorByName(schema, 'country').error}
                  isSubmitted={isSubmitted}
                  onUpdateValue={async (returnValue) => {
                    const location = await GetLocationByAddress(
                      returnValue.lookupItemName
                    );
                    const mapData = location.results?.[0]?.geometry?.location;
                    let map = propertyDetails.property.map;
                    if (mapData) {
                      const { lat, lng } = mapData;
                      const latitude = lat.toString();
                      const longitude = lng.toString();
                      map = { latitude, longitude };
                    }
                    const property = {
                      ...propertyDetails.property,
                      country: returnValue,
                      city: null,
                      district: null,
                      community: null,
                      sub_community: null,
                      map,
                    };
                    setPropertyDetails((element) => ({ ...element, property }));
                  }}
                  isDisabled={isValidProperty}
                />
              </div>
              <div className='dialog-content-item'>
                <CityComponent
                  propertyValues={propertyDetails && propertyDetails.property}
                  addressLoadings={addressLoadings}
                  addressList={addressList}
                  setAddressLoadings={setAddressLoadings}
                  setAddressList={setAddressList}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  labelClasses='Requierd-Color'
                  helperText={getErrorByName(schema, 'city').message}
                  error={getErrorByName(schema, 'city').error}
                  isSubmitted={isSubmitted}
                  onUpdateValue={async (returnValue) => {
                    const location = await GetLocationByAddress(
                      returnValue.lookupItemName
                    );
                    const mapData = location.results?.[0]?.geometry?.location;
                    let map = propertyDetails.property.map;
                    if (mapData) {
                      const { lat, lng } = mapData;
                      const latitude = lat.toString();
                      const longitude = lng.toString();
                      map = { latitude, longitude };
                    }
                    const property = {
                      ...propertyDetails.property,
                      city: returnValue,
                      district: null,
                      community: null,
                      sub_community: null,
                      map,
                    };
                    setPropertyDetails((element) => ({ ...element, property }));
                  }}
                  isDisabled={isValidProperty}
                />
              </div>
              <div className='dialog-content-item'>
                <DistrictComponent
                  propertyValues={propertyDetails && propertyDetails.property}
                  addressLoadings={addressLoadings}
                  addressList={addressList}
                  setAddressLoadings={setAddressLoadings}
                  setAddressList={setAddressList}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  labelClasses='Requierd-Color'
                  helperText={getErrorByName(schema, 'district').message}
                  error={getErrorByName(schema, 'district').error}
                  isSubmitted={isSubmitted}
                  onUpdateValue={async (returnValue) => {
                    const location = await GetLocationByAddress(
                      returnValue.lookupItemName
                    );
                    const mapData = location.results?.[0]?.geometry?.location;
                    let map = propertyDetails.property.map;
                    if (mapData) {
                      const { lat, lng } = mapData;
                      const latitude = lat.toString();
                      const longitude = lng.toString();
                      map = { latitude, longitude };
                    }
                    const property = {
                      ...propertyDetails.property,
                      district: returnValue,
                      community: null,
                      sub_community: null,
                      map,
                    };
                    setPropertyDetails((element) => ({ ...element, property }));
                  }}
                  isDisabled={isValidProperty}
                />
              </div>
              <div className='dialog-content-item'>
                <CommunityComponent
                  propertyValues={propertyDetails && propertyDetails.property}
                  addressLoadings={addressLoadings}
                  addressList={addressList}
                  setAddressLoadings={setAddressLoadings}
                  setAddressList={setAddressList}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  labelClasses='Requierd-Color'
                  helperText={getErrorByName(schema, 'community').message}
                  error={getErrorByName(schema, 'community').error}
                  isSubmitted={isSubmitted}
                  onUpdateValue={async (returnValue) => {
                    const location = await GetLocationByAddress(
                      returnValue.lookupItemName
                    );
                    const mapData = location.results?.[0]?.geometry?.location;
                    let map = propertyDetails.property.map;
                    if (mapData) {
                      const { lat, lng } = mapData;
                      const latitude = lat.toString();
                      const longitude = lng.toString();
                      map = { latitude, longitude };
                    }
                    const property = {
                      ...propertyDetails.property,
                      community: returnValue,
                      sub_community: null,
                      map,
                    };
                    setPropertyDetails((element) => ({ ...element, property }));
                  }}
                  isDisabled={isValidProperty}
                />
              </div>
              <div className='dialog-content-item'>
                <SubCommunityComponent
                  propertyValues={propertyDetails && propertyDetails.property}
                  addressLoadings={addressLoadings}
                  addressList={addressList}
                  setAddressLoadings={setAddressLoadings}
                  setAddressList={setAddressList}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onUpdateValue={async (returnValue) => {
                    const location = await GetLocationByAddress(
                      returnValue.lookupItemName
                    );
                    const mapData = location.results?.[0]?.geometry?.location;
                    let map = propertyDetails.property.map;
                    if (mapData) {
                      const { lat, lng } = mapData;
                      const latitude = lat.toString();
                      const longitude = lng.toString();
                      map = { latitude, longitude };
                    }
                    const property = {
                      ...propertyDetails.property,
                      sub_community: returnValue,
                      map,
                    };
                    setPropertyDetails((element) => ({ ...element, property }));
                  }}
                  isDisabled={isValidProperty}
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <div className='form-builder-wrapper'>
              <div className='form-builder-footer-wrapper is-dialog w-100 MuiGrid-align-items-xs-center MuiGrid-justify-xs-space-between'>
                <div className='MuiGrid-root-left'>
                  <ButtonBase
                    className='MuiButtonBase-root MuiButton-root MuiButton-text btns c-danger'
                    onClick={() => {onClose();resetStateHandler()}}
                  >
                    <span className='MuiButton-label'>
                      <span className='mx-2'>
                        {t(`${translationPath}cancel`)}
                      </span>
                    </span>
                    <span className='MuiTouchRipple-root' />
                  </ButtonBase>
                </div>
                <div className='MuiGrid-root-right'>
                  <ButtonBase
                    className='MuiButtonBase-root MuiButton-root MuiButton-text btns'
                    onClick={() => saveHandler(true)}
                  >
                    <span className='MuiButton-label'>
                      <span className='mx-2'>
                        {t(`${translationPath}Save and Continue`)}
                      </span>
                    </span>
                    <span className='MuiTouchRipple-root' />
                  </ButtonBase>
                  <ButtonBase
                    className='MuiButtonBase-root MuiButton-root MuiButton-text btns theme-solid bg-primary'
                    id='Submit-bbt'
                    onClick={() => saveHandler()}
                    disabled={saveDisabled}
                  >
                    <span className='MuiButton-label'>
                      <span className='mx-2'>
                        {t(`${translationPath}save`)}
                      </span>
                    </span>
                    <span className='MuiTouchRipple-root' />
                  </ButtonBase>
                </div>
              </div>
            </div>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};
