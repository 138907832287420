import React, { useEffect, useState } from 'react';
import { Button, ButtonBase } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  Spinner,
  Tables,
  PermissionsComponent,
} from '../../../../../../Components';
import { useTitle } from '../../../../../../Hooks';
import { TableActions } from '../../../../../../Enums';
import {
  GetParams,
  returnPropsByPermissions,
  showError,
  showSuccess,
} from '../../../../../../Helper';
import {
  DeletePropertyPlanInstallment,
  GetAllPaymentPlanByPropertyId,
  GetAllPropertyPlanInstallment,
} from '../../../../../../Services';
import { DeleteDialog } from './DeleteDialog';
import { PaymentPlanManangementDialog } from './PaymentPlanManangementDialog';
import { PropertiesPermissionsCRM } from '../../../../../../Permissions';
import { ArrowBackIos } from '@material-ui/icons';
import { InstallmentManagementDialog } from './InstallmentManagementDialog';

const parentTranslationPath = 'PropertiesProfileManagementView';
const translationPath = '';
export const InstallmentView = ({ handleChangeView, planId }) => {
  const { t } = useTranslation('PropertiesProfileManagementView');
  const [isLoading, setIsLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [installments, setInstallments] = useState({
    result: [],
    totalCount: 0,
  });
  const [filter, setFilter] = useState({
    pageIndex: 1,
    pageSize: 25,
    paymentPlanId: planId,
  });
  const addNewHandler = () => {
    setIsDialogOpen(true);
  };
  const getInstallments = async () => {
    setIsLoading(true);
    const result = await GetAllPropertyPlanInstallment(filter);
    if (!(result?.status && result.status !== 200)) {
      setInstallments(result);
    }
    setIsLoading(false);
  };

  const getActionTableWithPermissions = () => {
    const list = [];
    list.push({
      enum: TableActions.editText.key,
      isDisabled: false,
      externalComponent: null,
    });
    // if (
    //   returnPropsByPermissions(
    //     PropertiesPermissionsCRM.EditPropertyPaymentPlan.permissionsId
    //   )
    // )
    // if (
    //   returnPropsByPermissions(
    //     PropertiesPermissionsCRM.DeletePropertyPaymentPlan.permissionsId
    //   )
    // )
    list.push({
      enum: TableActions.deleteText.key,
      isDisabled: false,
      externalComponent: null,
    });
    return list;
  };

  const tableActionClicked = (actionEnum, item, focusedRow, event) => {
    event.stopPropagation();
    event.preventDefault();
    if (actionEnum === TableActions.deleteText.key) {
      setIsDeleteDialogOpen(true);
      setActiveItem(item);
    } else if (actionEnum === TableActions.editText.key) {
      setIsDialogOpen(true);
      setActiveItem(item);
    }
  };

  const deleteHandler = async () => {
    try {
      const res = await DeletePropertyPlanInstallment(
        activeItem.paymentPlanInstallmentId
      );
      if (!(res?.status && res.status !== 200)) {
        showSuccess(t(`${translationPath}delete-success`));
        setIsDeleteDialogOpen(false);
        getInstallments();
      } else {
        showError(t(`${translationPath}deleting-failed`));
      }
    } catch {
      showError(t(`${translationPath}deleting-failed`));
    }
  };

  const closeHandler = () => {
    setIsDialogOpen(false);
    setActiveItem(null);
  };

  useEffect(() => {
    getInstallments();
  }, [filter]);
  return (
    <div className='associated-Properties-wrapper childs-wrapper'>
      <Spinner isActive={isLoading} />
      <div className='title-section'>
        <ButtonBase
          onClick={handleChangeView}
          className='btns mx-2 theme-solid bg-cancel'
        >
          <ArrowBackIos fontSize='small' />
          {t('back')}
        </ButtonBase>
        <span>{t(`${translationPath}installments`)}</span>
      </div>
      <div className='d-flex-column'>
        <div className='header-section'>
          <div className='filter-section px-2'>
            <div className='section'>
              <ButtonBase
                className='btns theme-solid px-3'
                onClick={addNewHandler}
              >
                <span className='mdi mdi-plus' />
                {t(`${translationPath}add-new-installment`)}
              </ButtonBase>
            </div>

            <div className='section px-2' />
          </div>
        </div>
      </div>
      <div className='w-100 px-3'>
        <Tables
          data={installments.result}
          headerData={[
            {
              label: 'Payment Type',
              component: (item) => (
                <span>
                  {item?.paymentTypeLookupDto?.paymentTypeLookupName || 'N/A'}
                </span>
              ),
            },
            {
              label: 'Frequency',
              component: (item) => (
                <span>
                  {item?.frequencyLookupDto?.frequencyLookupName || 'N/A'}
                </span>
              ),
            },
            {
              label: 'Amount Nature',
              component: (item) => (
                <span>
                  {item?.amountNatureLookupDto?.amountNatureLookupName || 'N/A'}
                </span>
              ),
            },
            {
              label: 'contact-type',
              input: 'contactTypesLookupName',
            },
            {
              label: 'Description',
              input: 'description',
            },
          ]}
          defaultActions={getActionTableWithPermissions()}
          onPageIndexChanged={(index) =>
            setFilter((currentFilter) => ({
              ...currentFilter,
              pageIndex: index + 1,
            }))
          }
          onPageSizeChanged={(size) =>
            setFilter((currentFilter) => ({ ...currentFilter, pageSize: size }))
          }
          actionsOptions={{
            onActionClicked: tableActionClicked,
          }}
          itemsPerPage={filter.pageSize}
          activePage={filter.pageIndex}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          totalItems={installments.totalCount}
        />
      </div>

      {isDialogOpen && (
        <InstallmentManagementDialog
          isDialogOpen
          onClose={closeHandler}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          installments={installments.result}
          planId={planId}
          reload={getInstallments}
          activeItem={activeItem}
        />
      )}
      {isDeleteDialogOpen && (
        <DeleteDialog
          isDeleteDialogOpen={isDeleteDialogOpen}
          onCloseClicked={() => {
            setIsDeleteDialogOpen(false);
            setActiveItem(null);
          }}
          onCancelClicked={() => {
            setIsDeleteDialogOpen(false);
            setActiveItem(null);
          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onConfirmDelete={deleteHandler}
        />
      )}
    </div>
  );
};
