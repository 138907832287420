import React from 'react';
import { ContactFormTypeEnum } from '../../../Enums/ContactFormTypeEnum';
import moment from 'moment';
import {
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Typography,
} from '@material-ui/core';
import { CopyToClipboardComponents } from '../../../ReusableComponents/UtilityComponents';
import { useTranslation } from 'react-i18next';
import { ContactValidationContextLocalePath } from '../i18n/ContactValidationContextLocale';
import { LoadableImageComponant } from '../../../Components';
import { getDownloadableLink } from '../../../Helper';
import { DefaultImagesEnum, LoadableImageEnum } from '../../../Enums';

function ContactSummaryCard({
  contactId,
  createdAt,
  email,
  fullName,
  phoneNumber,
  contactImage,
  contactNationality,
  contactLanguage,
  contactsType,
}) {
  const { t } = useTranslation(ContactValidationContextLocalePath);
  return (
    <Card className='w-320px'>
      <CardHeader
        subheader={
          <h6>
            <strong>{t('contact_id')}: </strong>
            <CopyToClipboardComponents
              data={contactId}
              childrenData={contactId}
            />
          </h6>
        }
      />
      <CardHeader
        avatar={
          <LoadableImageComponant
            src={
              contactImage
                ? getDownloadableLink(contactImage)
                : DefaultImagesEnum.man.defaultImg
            }
            type={LoadableImageEnum.image.key}
            classes={'w-40px rounded-lg'}
          />
        }
        title={
          <h5>
            <strong>{t('full_name')}: </strong>
            {fullName}
          </h5>
        }
        className='py-1'
      />
      <CardContent>
        {contactsType === ContactFormTypeEnum.INDIVIDUAL.value && (
          <Typography className='my-2'>
            {contactNationality && `${contactNationality}  -`} {contactLanguage}
          </Typography>
        )}
        {!!email && (
          <h6>
            <strong>{t('email')}: </strong>
            {email}
          </h6>
        )}
        {!!phoneNumber && (
          <h6>
            <strong>{t('phone')}: </strong>
            {phoneNumber}
          </h6>
        )}
        <hr className='my-1' />
      </CardContent>
      <div className='flex fj-end px-3 pb-2'>
        <h6>
          <strong>{t('created_at')}: </strong>
          {moment(createdAt).format('YYYY-MM-DD')}
        </h6>
      </div>
    </Card>
  );
}

export default ContactSummaryCard;
