import React, { Children, useEffect, useRef, useState } from "react";
import {
  GetAllRoles,
  GetAllSystemTemplateByCategoryId,
} from "../../../../../../Services";
import {
  AutocompleteComponent,
  CheckboxesComponent,
} from "../../../../../../Components";
import { Checkbox, FormControlLabel, FormGroup } from "@material-ui/core";
import {
  RecipientTypeCoreEnum,
  ReminderTypeEnum,
  TemplateCategoryEnum,
} from "../../../../../../Enums";
import { useTranslation } from "react-i18next";
import { WithFieldset } from "./WithFieldset";

export const ActivityNotifications = ({
  parentTranslationPath,
  translationPath,
  state,
  setState,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const recipientTypes = Object.values(RecipientTypeCoreEnum);
  const [notifications, setNotifications] = useState(
    state.activityTypeTemplateRecipientTypes ?? [
      {
        templateId: null,
        recipientTypeId: null,
        activityTypeTemplateRecipientTypeSources: [],
      },
    ]
  );
  const [templates, setTemplates] = useState([]);
  const [emailReminderTemplates, setEmailReminderTemplates] = useState([]);
  const [loadings, setLoadings] = useState(false);
  const [isEmailNotify, setIsEmailNotify] = useState(false);
  const [selected, setSelected] = useState({
    templates: {},
    recipientType: [],
  });

  const getAllSystemTemplateByCategoryId = async (templateTypeIds) => {
    setLoadings(true);
    const res = await GetAllSystemTemplateByCategoryId(
      TemplateCategoryEnum.Activity.key,
      templateTypeIds
    );
    if (!(res?.status && res.status !== 200)) {
      const mappedTemplatesData = res.map((item) => ({
        templateName: item.systemTemplateName,
        templateId: item.systemTemplateId,
      }));
      if (templateTypeIds.length === 2) {
        setEmailReminderTemplates(mappedTemplatesData);
      } else setTemplates(mappedTemplatesData);
    }
    setLoadings(false);
  };

  useEffect(() => {
    getAllSystemTemplateByCategoryId([61826]);
  }, []);

  function EmailNotificationChecker(notification) {
    const notificationsArray =
      notification.activityTypeTemplateRecipientTypeSources || [];

    const hasEmail = notificationsArray.some(
      (item) =>
        item?.notifyByName === "Email" ||
        item?.notifyById === 1 ||
        item?.notifyBy === 1
    );

    if (hasEmail) {
      setIsEmailNotify(true);
      if (hasEmail && emailReminderTemplates.length < 1) {
        getAllSystemTemplateByCategoryId([61826, 61827]);
      }
    } else {
      setIsEmailNotify(false);
    }
  }

  const handleChange = (type, notification) => () => {
    EmailNotificationChecker(notification);

    if (
      notification.activityTypeTemplateRecipientTypeSources?.find(
        (source) => source.notifyBy === type || source.notifyById === type
      )
    ) {
      const updated =
        notification.activityTypeTemplateRecipientTypeSources.filter(
          (item) => ![item.notifyBy, item.notifyById].includes(type)
        );

      notification.activityTypeTemplateRecipientTypeSources = updated;
      const hasSMSReminder =
        notification.activityTypeTemplateRecipientTypeSources.some(
          (item) => item.notifyBy === 2 || item.notifyById === 2
        );
      const hasNotificationReminder =
        notification.activityTypeTemplateRecipientTypeSources.some(
          (item) => item.notifyBy === 3 || item.notifyById === 3
        );

      if (type === 1) {
        notification.emailSystemTemplateId = null;
      } else if (
        (type === 3 && !hasSMSReminder) ||
        (type === 2 && !hasNotificationReminder)
      ) {
        notification.templateName = null;
        notification.templateId = null;
      }
    } else {
      notification.activityTypeTemplateRecipientTypeSources =
        notification.activityTypeTemplateRecipientTypeSources.concat({
          notifyBy: type,
        });
    }
    setNotifications((prevNotifications) => {
      return prevNotifications.map((not) => {
        if (notification.recipientTypeId === not.recipientTypeId) {
          return notification;
        }
        return not;
      });
    });
  };

  useEffect(() => {
    setState({
      id: "activityTypeTemplateRecipientTypes",
      value: notifications,
    });
  }, [notifications]);

  useEffect(() => {
    if (notifications) {
      notifications.forEach((notification) =>
        EmailNotificationChecker(notification)
      );
    }
  }, [notifications]);

  useEffect(() => {
    if (state.activityTypeTemplateRecipientTypes?.length > 0) {
      setSelected((currentSelected) => ({
        ...currentSelected,
        recipientType: state.activityTypeTemplateRecipientTypes.map(
          (notification) =>
            recipientTypes.find(
              (recipientType) =>
                recipientType.key === notification.recipientTypeId
            )
        ),
      }));
    }
  }, []);

  return (
    <div className="my-3">
      <CheckboxesComponent
        idRef="IsImportantRef"
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        label="important-notification"
        singleChecked={state.isImportant}
        wrapperClasses={"mb-3"}
        themeClass="theme-secondary"
        onSelectedCheckboxClicked={() => {
          setState({
            id: "isImportant",
            value: !state.isImportant,
          });
        }}
      />
      <AutocompleteComponent
        idRef="activityTypeTemplateRecipientTypesRef"
        data={recipientTypes}
        displayLabel={(option) => option?.value ?? ""}
        chipsLabel={(option) => option?.value ?? ""}
        selectedValues={selected.recipientType ?? []}
        withoutSearchButton
        wrapperClasses="mb-3"
        labelValue="recipient-type"
        inputPlaceholder="recipient-type"
        onChange={(_event, newValue) => {
          setNotifications((currentNotifications) => {
            const newNotifications = newValue?.map((item) => {
              return (
                currentNotifications.find(
                  (value) => value.recipientTypeId === item.key
                ) ?? {
                  templateId: null,
                  recipientTypeId: item.key,
                  activityTypeTemplateRecipientTypeSources: [],
                }
              );
            });
            return newNotifications;
          });
          setSelected((current) => ({
            ...current,
            recipientType: newValue,
          }));
        }}
        getOptionSelected={(option) =>
          selected.recipientType.find(
            (recipient) => recipient.value === option.value
          )
        }
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
      />
      {notifications?.map((notification) =>
        Children.toArray(
          <WithFieldset
            legend={
              recipientTypes.find(
                (recipient) => recipient.key === notification.recipientTypeId
              )?.value
            }
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
          >
            {notification?.activityTypeTemplateRecipientTypeSources.some(
              (item) =>
                item?.notifyById !== 1 &&
                item?.notifyBy !== 1 &&
                (item?.notifyById || item?.notifyBy)
            ) && (
              <AutocompleteComponent
                idRef="Notification-template-Created-from-AdminRef"
                selectedValues={
                  templates.find(
                    (template) =>
                      template?.templateId === notification?.templateId
                  ) ?? {}
                }
                multiple={false}
                data={templates ?? []}
                displayLabel={(option) => t(`${option.templateName || ""}`)}
                getOptionSelected={(option) =>
                  option.templateId === notification.templateId
                }
                wrapperClasses="my-1"
                withoutSearchButton
                inputPlaceholder={t(`${translationPath}notification-template`)}
                labelValue={t(`${translationPath}notification-template`)}
                isLoading={loadings}
                isWithError
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onChange={(event, newValue) => {
                  setNotifications((currentNotifications) => {
                    const newNotifications = [...currentNotifications].map(
                      (item) => {
                        if (
                          notification?.recipientTypeId ===
                          item?.recipientTypeId
                        ) {
                          return {
                            ...item,
                            templateId: newValue?.templateId || null,
                          };
                        } else return item;
                      }
                    );
                    return newNotifications;
                  });
                }}
              />
            )}
            {notification?.activityTypeTemplateRecipientTypeSources.some(
              (item) =>
                item?.notifyByName === "Email" ||
                item?.notifyById === 1 ||
                item?.notifyBy === 1
            ) && (
              <AutocompleteComponent
                idRef="Email-Notification-template-Created-from-AdminRef"
                inputPlaceholder={t(
                  `${translationPath}email-notification-template`
                )}
                labelValue={t(`${translationPath}email-notification-template`)}
                wrapperClasses="my-1"
                data={emailReminderTemplates ?? []}
                selectedValues={
                  emailReminderTemplates.find(
                    (template) =>
                      template?.templateId ===
                      notification?.emailSystemTemplateId
                  ) ?? {}
                }
                displayLabel={(option) => t(`${option.templateName || ""}`)}
                getOptionSelected={(option) =>
                  option.templateId === notification.emailSystemTemplateId
                }
                isLoading={loadings}
                multiple={false}
                isWithError
                withoutSearchButton
                onChange={(event, newValue) => {
                  setNotifications((currentNotifications) => {
                    const newNotifications = [...currentNotifications].map(
                      (item) => {
                        if (
                          notification?.recipientTypeId ===
                          item?.recipientTypeId
                        ) {
                          return {
                            ...item,
                            emailSystemTemplateId: newValue?.templateId || null,
                          };
                        } else return item;
                      }
                    );
                    return newNotifications;
                  });
                }}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
            )}
            <FormGroup row className="my-2">
              <label className="label-wrapper">{t("created-from")}</label>
              {Children.toArray(
                Object.values(ReminderTypeEnum).map((item) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          notification.activityTypeTemplateRecipientTypeSources?.findIndex(
                            (source) =>
                              source.notifyBy === item.key ||
                              source.notifyById === item.key
                          ) !== -1
                        }
                        onChange={handleChange(item.key, notification)}
                        name="created-from"
                      />
                    }
                    label={item.value}
                  />
                ))
              )}
            </FormGroup>
          </WithFieldset>
        )
      )}
    </div>
  );
};
