import { GetAllDevelopersContactsAPI , formByIdGet  } from '../Services';

let oldvalue = '';
let timer = null;

export const DeveloperContactRule = async (item, value, setRerender) => {

  if (!item.data.searchKey) return;
  if (item.data.searchKey !== 'developers') return;
  if (item.value === '') return;
  if (value === '') return;
  if (timer !== null) clearTimeout(timer);

  if (oldvalue === value) return;
  oldvalue = value;
  
  timer = setTimeout(async () => {
    item.data.enum = [];
    let formId = null ; 
    const response = await formByIdGet({
      formname: "Developers",
    });
    if (!(response && response.status && response.status !== 200))
      formId= response && response.length && response[0] && response[0].form_id ; 
    item.data.formId = formId ; 
   
   const  rs = await GetAllDevelopersContactsAPI({
       pageIndex: 1, pageSize: 50, search: value });

    if (!rs || !rs.result) return;
    rs.result.map((element) => {
    const developerContact  = element?.contact && JSON.parse(element.contact) ; 
        item.data.enum.push({
           id: element.contactsId,
           name: developerContact?.contact?.company_name,
           mobile: (developerContact?.contact?.landline_number && developerContact?.contact?.landline_number?.phone) || null,
           email_address: (developerContact?.contact?.general_email && developerContact?.contact?.general_email?.email) || null,
           contact_image: developerContact?.contact?.company_logoimage ?
           (developerContact?.contact?.company_logoimage['Company Logo'] ?
           (developerContact?.contact?.company_logoimage['Company Logo'] && developerContact?.contact?.company_logoimage['Company Logo'].length && developerContact?.contact?.company_logoimage['Company Logo'][0]) : (developerContact?.contact?.company_logoimage['Company Images'] && developerContact?.contact?.company_logoimage['Company Images'].length && developerContact?.contact?.company_logoimage['Company Images'][0])) :
           null ,
           developerId : element.developerId, 

        });
    });
    setRerender(Math.random());
  }, 500);
};

