import {
     LeadInformationComponent as  LeadOwnerLeadInformationComponent , 
     LeadProfileUnitsComponent  as LeadOwnerLeadProfileUnitsComponent  , 
     LeadActivitiesComponent as LeadOwnerLeadActivitiesComponent , 
    LeadAssignAgentsTracking as LeadOwnerLeadAssignAgentsTracking  
} from '../../../../LeadOwnerLeadsView/LeadsProfileManagementView/Sections';

import {
  LeadInformationComponent,
  LeadProfileUnitsComponent,
  LeadActivitiesComponent,
  LeadAssignAgentsTracking
} from '../../../../LeadsSalesView/LeadsSalesProfileManagementView/Sections';

import {
  LeadInformationComponent as  MortgageLeadInformationComponent  , 
  LeadProfileUnitsComponent as  MortgageLeadProfileUnitsComponent  , 
  LeadActivitiesComponent as MortgageLeadActivitiesComponent  , 
  LeadAssignAgentsTracking as MortgageLeadAssignAgentsTracking  
} from '../../../../LeadsMortgageView/LeadsProfileManagementView/Sections' ; 

import { LeadsPermissions , LeadsLeasePermissions  , LeadsCAllCenterPermissions , LeadsSalesPermissions , LeadOwnerLeadsPermissions } from '../../../../../../Permissions';
import {ProjectTasksView} from '../../../../TaskConfiguration/ProjectTasks/Utilities';
import LeadInquiriesView from '../../../../LeadsSalesView/LeadsSalesProfileManagementView/Sections/LeadInquiriesView/LeadInquiriesView';

export const LeadsVerticalTabsData = {
   sale :[{
    label: 'lead-information',
    component: LeadInformationComponent,
    permissionsList:   Object.values(LeadsSalesPermissions),
    permissionsId:  LeadsSalesPermissions.ViewLeadDetails.permissionsId,
  },
  {
    label: 'activities',
    component: LeadActivitiesComponent,
    permissionsList: Object.values(LeadsSalesPermissions),
    permissionsId:  LeadsSalesPermissions.GetActivitiesForLead.permissionsId,

  },
  
  {
    label: 'matching',
    component: LeadProfileUnitsComponent,
    permissionsList: Object.values(LeadsSalesPermissions),
    permissionsId: LeadsSalesPermissions.ViewMatchingUnits.permissionsId,
      
  },
  {
    label: 'assign-agents-tracking',
    component:LeadAssignAgentsTracking, 
    permissionsList: Object.values(LeadsSalesPermissions),
    permissionsId: LeadsSalesPermissions.GetAgentLeadAssigmentHistory.permissionsId,
  },
  {
    label: 'tasks',
    component: ProjectTasksView,
    permissionsList: Object.values(LeadsSalesPermissions),
    permissionsId: LeadsSalesPermissions.ViewTasksForLead.permissionsId,
  },
  {
    label: 'Inquiries',
    component: LeadInquiriesView,
    permissionsList: [],
    permissionsId: null,
  },
] ,  
rent :[{
  label: 'lead-information',
  component: LeadInformationComponent,
  permissionsList:  Object.values(LeadsLeasePermissions),
  permissionsId:  LeadsLeasePermissions.ViewLeadDetails.permissionsId,
 
},
{
  label: 'activities',
  component: LeadActivitiesComponent,
  permissionsList:  Object.values(LeadsLeasePermissions),
  permissionsId:  LeadsLeasePermissions.GetActivitiesForLead.permissionsId
},

{
  label: 'matching',
  component: LeadProfileUnitsComponent,
  permissionsList: Object.values(LeadsLeasePermissions),
  permissionsId: LeadsLeasePermissions.ViewMatchingUnits.permissionsId,
 
},
{
  label: 'assign-agents-tracking',
  component:LeadAssignAgentsTracking, 
  permissionsList: Object.values(LeadsLeasePermissions),
  permissionsId: LeadsLeasePermissions.GetAgentLeadAssigmentHistory.permissionsId , 
},
{
  label: 'tasks',
  component: ProjectTasksView,
  permissionsList: Object.values(LeadsLeasePermissions),
  permissionsId: LeadsLeasePermissions.ViewTasksForLead.permissionsId,
},
{
  label: 'Inquiries',
  component: LeadInquiriesView,
  permissionsList: [],
  permissionsId: null,
}
] , 
callCenter :[{
  label: 'lead-information',
  component: LeadInformationComponent,
  permissionsList:  Object.values(LeadsCAllCenterPermissions),
  permissionsId:  LeadsCAllCenterPermissions.ViewLeadDetails.permissionsId,
 
},
{
  label: 'activities',
  component: LeadActivitiesComponent,
  permissionsList:  Object.values(LeadsCAllCenterPermissions),
  permissionsId:  LeadsCAllCenterPermissions.GetActivitiesForLead.permissionsId,
},

{
  label: 'matching',
  component: LeadProfileUnitsComponent,
  permissionsList: Object.values(LeadsCAllCenterPermissions),
  permissionsId: LeadsCAllCenterPermissions.ViewMatchingUnits.permissionsId,
 
},
{
  label: 'assign-agents-tracking',
  component:LeadAssignAgentsTracking, 
  permissionsList: Object.values(LeadsCAllCenterPermissions),
  permissionsId: LeadsCAllCenterPermissions.GetAgentLeadAssigmentHistory.permissionsId,
},
{
  label: 'tasks',
  component: ProjectTasksView,
  permissionsList: Object.values(LeadsCAllCenterPermissions),
  permissionsId: LeadsCAllCenterPermissions.ViewTasksForLead.permissionsId,
},
{
  label: 'Inquiries',
  component: LeadInquiriesView,
  permissionsList: [],
  permissionsId: null,
}
] , 
LeadsProprtyManagement :[{
  label: 'lead-information',
  component: LeadInformationComponent,
  permissionsList: Object.values(LeadsPermissions),
  permissionsId:  LeadsPermissions.ViewLeadDetails.permissionsId,
},
 
{
  label: 'activities',
  component: LeadActivitiesComponent,
},

{
  label: 'matching',
  component: LeadProfileUnitsComponent,
  permissionsList: 
   Object.values(LeadsPermissions),
  permissionsId: 
  LeadsPermissions.ViewMatchingUnits.permissionsId,
},
{
  label: 'assign-agents-tracking',
  component:LeadAssignAgentsTracking, 
},
{
  label: 'tasks',
  component: ProjectTasksView,
  permissionsList: [],
  permissionsId: '',
},
{
  label: 'Inquiries',
  component: LeadInquiriesView,
  permissionsList: [],
  permissionsId: null,
}
] , 

LeadsMortgage :[{
  label: 'lead-information',
  component:  MortgageLeadInformationComponent ,
},
{
  label: 'activities',
  component: MortgageLeadActivitiesComponent,
},
{
  label: 'matching',
  component: LeadProfileUnitsComponent,
},
{
  label: 'assign-agents-tracking',
  component:MortgageLeadAssignAgentsTracking, 
},
{
  label: 'tasks',
  component: ProjectTasksView,
  permissionsList: [],
  permissionsId: '',
},
{
  label: 'Inquiries',
  component: LeadInquiriesView,
  permissionsList: [],
  permissionsId: null,
}
] ,
leadFromContact : [
  {
    label: 'lead-information',
    component: LeadInformationComponent,
    
  },
  {
    label: 'activities',
    component: LeadActivitiesComponent,
    permissionsList:  Object.values(LeadsCAllCenterPermissions),
    permissionsId:  LeadsCAllCenterPermissions.GetActivitiesForLead.permissionsId,
  },
  
  {
    label: 'matching',
    component: LeadProfileUnitsComponent,
     permissionsList: Object.values(LeadsCAllCenterPermissions),
     permissionsId: LeadsCAllCenterPermissions.ViewMatchingUnits.permissionsId,
   
  },
  {
    label: 'assign-agents-tracking',
    component:LeadAssignAgentsTracking, 
    permissionsList: Object.values(LeadsCAllCenterPermissions),
    permissionsId: LeadsCAllCenterPermissions.GetAgentLeadAssigmentHistory.permissionsId,
  },
  {
    label: 'tasks',
    component: ProjectTasksView,
    permissionsList: [],
    permissionsId: '',
  },
  {
    label: 'Inquiries',
    component: LeadInquiriesView,
    permissionsList: [],
    permissionsId: null,
  }
 ],
 leadOwnerLeads :[
    {
     label: 'lead-information',
     component: LeadOwnerLeadInformationComponent,
     permissionsList:  Object.values(LeadOwnerLeadsPermissions),
     permissionsId:  LeadOwnerLeadsPermissions.ViewLeadDetails.permissionsId,
 
   },
   {
     label: 'activities',
     component: LeadActivitiesComponent,
     permissionsList:  Object.values(LeadOwnerLeadsPermissions),
     permissionsId:  LeadOwnerLeadsPermissions.GetActivitiesForLead.permissionsId,
   },
   {
    label: 'matching',
    component: LeadProfileUnitsComponent,
    permissionsList: Object.values(LeadOwnerLeadsPermissions),
    permissionsId: LeadOwnerLeadsPermissions.ViewMatchingUnits.permissionsId, 
   },
   {
     label: 'assign-agents-tracking',
     component:LeadAssignAgentsTracking, 
     permissionsList: Object.values(LeadOwnerLeadsPermissions),
     permissionsId: LeadOwnerLeadsPermissions.GetAgentLeadAssigmentHistory.permissionsId,
    },
    {
      label: 'tasks',
      component: ProjectTasksView,
      permissionsList: [],
      permissionsId: '',
    },
    {
      label: 'Inquiries',
      component: LeadInquiriesView,
      permissionsList: [],
      permissionsId: null,
    }
 ]
};


