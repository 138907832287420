import React from "react";
import { Box } from "@material-ui/core";

import { getDownloadableLink } from "../../../Helper";
import { useTranslate } from "../../../Hooks";
import { CustomCopyToClipboard } from "../../../Components";
import { getMimeTypeHandler } from "../../../Utils";
import GoogleDocIframe from "../../../Views/Home/TemplatesView/Dialogs/TemplatesPreviewDialog/Utilities/GoogleDocIframe";

// Styles
import useStyles from "./styles";

const PreviewEvidenceModal = ({ activePreviewItem }) => {
  const styles = useStyles();
  const { translate } = useTranslate("NewContactsView");

  const { previewData } = activePreviewItem || {};

  const renderFilePreview = () => {
    if (!previewData?.fileId || !previewData?.fileName) return null;

    const mimeHandler = getMimeTypeHandler(previewData.fileName);

    if (mimeHandler.isImage) {
      return (
        <img
          draggable="false"
          className={styles.fullWidth}
          src={getDownloadableLink(previewData.fileId)}
          alt={translate("UPLOADED_FILE_ALT")}
        />
      );
    }

    if (mimeHandler.isPdf) {
      return <GoogleDocIframe url={getDownloadableLink(previewData.fileId)} />;
    }

    // Handle other file types
    return (
      <></>
      // <a
      //   href={getDownloadableLink(previewData.fileId)}
      //   target="_blank"
      //   rel="noopener noreferrer"
      // >
      //   Download File
      // </a>
    );
  };

  return (
    <Box>
      <Box className={styles.fieldContainer}>
        <label>{translate("EVIDENCE_LINK_LABEL")}</label>
        {previewData?.evidenceLink && (
          <CustomCopyToClipboard
            customText="Link Copied!"
            data={previewData.evidenceLink}
            childrenData={previewData.evidenceLink}
          />
        )}
      </Box>

      <Box className={styles.fieldContainer}>
        <label>{translate("WRITTEN_EVIDENCE_LABEL")}</label>
        {previewData?.writtenEvidence && <p>{previewData.writtenEvidence}</p>}
      </Box>

      <Box className={styles.fieldContainer}>
        <label>{translate("UPLOADED_FILES_LABEL")}</label>
        <Box className={styles.fullWidth}>{renderFilePreview()}</Box>
      </Box>
    </Box>
  );
};

export default PreviewEvidenceModal;
