import { Button, FormControlLabel, IconButton } from '@material-ui/core';
import {
  AutocompleteComponent,
  Inputs,
  SelectComponet,
  Spinner,
} from '../../../../Components';
import React, { useEffect, useRef, useState } from 'react';
import './InquirySchemeManagement.scss';
import { StaticLookupsIds } from '../../../../assets/json/StaticLookupsIds';
import {
  CreateInquiryRotationScheme,
  GetAllBranches,
  GetAllRoles,
  GetInquiryRotationSchemeById,
  UpdateInquiryRotationScheme,
} from '../../../../Services';
import { ApplicationUserSearch } from '../../../../Services/userServices';
import {
  GlobalHistory,
  getErrorByName,
  showError,
  showSuccess,
  RemoveDuplicateOptions,
} from '../../../../Helper';
import InquiryTypes from './components/InquiryTypes';
import IOSSwitch from './components/IOSSwitch';
import SelectedChip from './components/SelectedChip';
import LabeledField from './components/LabeledField';
import Joi from 'joi';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

const operationTypes = [
  { id: StaticLookupsIds.Sales, name: 'sale' },
  { id: StaticLookupsIds.Rent, name: 'Lease' },
];
export const ReminderTimeOptions = {
  Minutes: {
    id: 1,
    value: 'Minutes',
    text: 'Minutes',
  },
  Hours: {
    id: 2,
    value: 'Hours',
    text: 'Hours',
  },
  Days: {
    id: 3,
    value: 'Days',
    text: 'Days',
  },
};

const parentTranslationPath = 'InquiryRotation';

const translationPath = '';

export const InquirySchemeManagement = () => {
  const searchTimer = useRef(null);
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(!!id);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { t } = useTranslation(parentTranslationPath);
  const inquiryRotationSchema = Joi.object({
    name: Joi.string()
      .required()
      .messages({
        'string.empty': t('is-required', { field: t('inquiry_rotation_name') }),
        'any.required': t('is-required', { field: t('inquiry_rotation_name') }),
      }),
    isSLAApplied: Joi.boolean().required(),
    slaExpirationPeriod: Joi.number()
      .integer()
      .when('isSLAApplied', {
        is: true,
        then: Joi.required(),
        otherwise: Joi.allow(null),
      }),
    slaExpirationPeriodFreqency: Joi.number()
      .integer()
      .when('isSLAApplied', {
        is: true,
        then: Joi.required(),
        otherwise: Joi.allow(null),
      })
      .messages({
        'number.base': t('is-required', { field: t('sla_expiration_period') }),
        'any.required': t('is-required', { field: t('sla_expiration_period') }),
      }),
    inquiryRotationInquiryTypes: Joi.array()
      .items(Joi.any())
      .min(1)
      .required()
      .messages({
        'any.required': t('is-required', { field: t('inquiry_type') }),
        'array.min': t('is-required', { field: t('inquiry_type') }),
      }),
    inquiryRotationOperationTypes: Joi.array()
      .items(Joi.any())
      .min(1)
      .required()
      .messages({
        'any.required': t('is-required', { field: t('operation_type') }),
        'array.min': t('is-required', { field: t('operation_type') }),
      }),
    inquiryRotationBranches: Joi.array()
      .items(
        Joi.object({
          branchId: Joi.number().integer().required(),
          isRequesterBranch: Joi.boolean().required(),
        })
      )
      .required(),
    inquiryRotationRoles: Joi.array().items(Joi.object()).required(),
    inquiryRotationUsers: Joi.array()
      .items(
        Joi.object({
          userId: Joi.number().integer().required(),
          isSelectedUser: Joi.boolean().required(),
        })
      )
      .required(),
  }).options({ abortEarly: false, allowUnknown: true });
  const [state, setState] = useState({
    name: '',
    isSLAApplied: false,
    slaExpirationPeriod: null,
    slaExpirationPeriodFreqency: 1,
    inquiryRotationInquiryTypes: [],
    inquiryRotationOperationTypes: [],
    inquiryRotationBranches: [],
    inquiryRotationRoles: [],
    inquiryRotationUsers: [],
    isActive: false,
  });
  const [selected, setSelected] = useState({
    inquiryTypes: [],
    operationTypes: [],
    branches: [],
    requesterBranches: [],
    roles: [],
    users: [],
    reminderUsers: [],
  });
  const [data, setData] = useState({
    branches: [],
    roles: [],
    users: [],
  });
  const [activeItem, setActiveItem] = useState(null);
  const [schema, setSchema] = useState({});

  const setDataHandler = (field, value) => {
    setData((data) => ({ ...data, [field]: value }));
  };
  const getBranches = async (branchName) => {
    const res = await GetAllBranches({
      pageSize: 25,
      pageIndex: 1,
      branchName,
    });

    if (!(res?.status && res.status !== 200)) {
      setDataHandler('branches', res.result);
    }
  };
  const getRoles = async (value) => {
    const response = await GetAllRoles(1, 25, value);
    setDataHandler('roles', response.result);
  };
  const getAllUsers = async (searchValue) => {
    const res = await ApplicationUserSearch({
      pageSize: 25,
      pageIndex: 1,
      rolesIds: [],
      name: searchValue || '',
      userStatusId: 2,
    });
    if (!(res?.status && res.status !== 200)) {
      const localValue = res.result;
      if (localValue.length > 0) {
        setDataHandler('users', localValue);
      }
    }
  };

  const searchHandler = (e) => {
    const { value } = e.target;
    if (searchTimer.current) clearTimeout(searchTimer.current);
    searchTimer.current = setTimeout(() => {
      getBranches(value);
    }, 700);
  };
  const rolesSearchHandler = (e) => {
    const { value } = e.target;
    if (searchTimer.current) clearTimeout(searchTimer.current);
    searchTimer.current = setTimeout(() => {
      getRoles(value);
    }, 700);
  };
  useEffect(() => {
    getBranches();
    getRoles();
    getAllUsers();
  }, []);

  const submitHandler = async () => {
    setIsSubmitted(true);
    const body = { ...state };
    body.inquiryRotationUsers = [...selected.users, ...selected.reminderUsers];
    body.inquiryRotationBranches = [
      ...selected.branches,
      ...selected.requesterBranches,
    ];
    body.inquiryRotationRoles = [...selected.roles];
    body.inquiryRotationOperationTypes = [
      ...selected.operationTypes.map((operationType) => ({ operationType })),
    ];
    body.inquiryRotationInquiryTypes = [
      ...state.inquiryRotationInquiryTypes.map((inquiryTypeId) => ({
        inquiryTypeId,
      })),
    ];
    const validation = inquiryRotationSchema.validate(body);
    if (validation.error) {
      setSchema(validation);
      showError(t('fill_all_required_fields'));
      return;
    }
    try {
      const res = await CreateInquiryRotationScheme(body);
      if (!isNaN(res)) {
        showSuccess(t('rotation_inquiry_created'));
        GlobalHistory.goBack();
      } else {
        throw res;
      }
    } catch (error) {
      showError(t('something_went_wrong'));
    }
  };

  const submitUpdateHandler = async () => {
    setIsSubmitted(true);
    const body = { ...state, id };
    body.inquiryRotationUsers = [...selected.users, ...selected.reminderUsers];
    body.inquiryRotationBranches = [
      ...selected.branches,
      ...selected.requesterBranches,
    ];
    body.inquiryRotationRoles = [...selected.roles];
    body.inquiryRotationOperationTypes = [
      ...selected.operationTypes.map((operationType) => ({ operationType })),
    ];
    body.inquiryRotationInquiryTypes = [
      ...state.inquiryRotationInquiryTypes.map((inquiryTypeId) => ({
        inquiryTypeId,
      })),
    ];
    const validation = inquiryRotationSchema.validate(body);
    if (validation.error) {
      setSchema(validation);
      showError(t('fill_all_required_fields'));
      return;
    }
    try {
      const res = await UpdateInquiryRotationScheme(body);
      if (!isNaN(res)) {
        showSuccess(t('update-successfully'));
        GlobalHistory.goBack();
      } else {
        throw res;
      }
    } catch (error) {
      showError(t('something_went_wrong'));
    }
  };
  const getInquiryRotationSchemeById = async (id) => {
    try {
      const res = await GetInquiryRotationSchemeById(id);
      if (!(res?.status && res.status !== 200)) {
        setActiveItem(res);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      getInquiryRotationSchemeById(id);
    }
  }, [id]);

  useEffect(() => {
    if (activeItem) {
      setSelected((prev) => ({
        ...prev,
        operationTypes:
          activeItem.rotationOperationTypes?.map(
            (item) => item.operationTypeId
          ) ?? [],
        roles: activeItem.rotationRoles.map((item) => ({
          ...item,
          rolesName: item.role,
        })),
        requesterBranches: activeItem.rotationBranches
          .filter((item) => item.isRequesterBranch)
          .map((item) => ({ ...item, branchName: item.branch })),
        branches: activeItem.rotationBranches
          .filter((item) => !item.isRequesterBranch)
          .map((item) => ({ ...item, branchName: item.branch })),
        reminderUsers: activeItem.rotationUsers
          .filter((item) => item.isSelectedUser)
          .map((item) => ({ ...item, fullName: item.user })),
        users: activeItem.rotationUsers
          .filter((item) => !item.isSelectedUser)
          .map((item) => ({ ...item, fullName: item.user })),
        inquiryTypes: activeItem.rotationInquiryTypes.map(
          (item) => item.inquiryTypeId
        ),
      }));
      const _state = { ...activeItem };
      delete _state.rotationOperationTypes;
      delete _state.rotationRoles;
      delete _state.rotationBranches;
      delete _state.rotationUsers;
      delete _state.rotationInquiryTypes;
      setState({
        ..._state,
        slaExpirationPeriodFreqency: _state.slaExpirationPeriodFreqencyId,
      });
    }
  }, [activeItem]);
  return (
    <div className='p-3 inquiry-management-container'>
      <Spinner isActive={isLoading} isAbsolute />
      <div className='p-3 d-flex'>
        <div className='py-2'>
          <IconButton color='secondary' onClick={() => GlobalHistory.goBack()}>
            <span class='mdi mdi-arrow-left-thick'></span>
          </IconButton>
        </div>
        <div className='w-100'>
          <h2>{t('new_rotation')}</h2>
          <p>{t('define_rotation_criteria')}</p>
          <div className=' d-flex-v-center fj-between '>
            <h5 className='my-4'>{t('rotation_criteria')}</h5>
            <div>
              <Button
                className='discard-button p-2 action-btn'
                onClick={() => GlobalHistory.goBack()}
              >
                {t('discard')}
              </Button>
              {!id ? (
                <Button
                  className=' mx-2 add-button p-2 action-btn'
                  onClick={() => submitHandler()}
                >
                  {t('add')}
                </Button>
              ) : (
                <Button
                  className=' mx-2 add-button p-2 action-btn'
                  onClick={() => submitUpdateHandler()}
                >
                  {t('update')}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className='create-form p-2 w-100'>
        <h4 className='form-subtitle'>{t('info_inquiry')}</h4>
        <LabeledField
          label={t('inquiry_rotation_name')}
          component={
            <Inputs
              wrapperClasses={'w-25'}
              value={state.name}
              onInputChanged={(e) => {
                e.persist();
                setState((prev) => ({ ...prev, name: e.target?.value }));
              }}
              isWithError
              helperText={getErrorByName(schema, 'name').message}
              error={getErrorByName(schema, 'name').error}
              isSubmitted={isSubmitted}
            />
          }
        />
        <LabeledField
          label={t('operation_type')}
          component={
            <SelectComponet
              wrapperClasses={'w-25 p-1'}
              data={operationTypes}
              textInput='name'
              valueInput='id'
              value={selected.operationTypes}
              multiple
              onSelectChanged={(e) =>
                setSelected((prev) => ({ ...prev, operationTypes: e }))
              }
              renderValue={(value) => (
                <div className='flex chips-container p-1'>
                  {operationTypes
                    .filter((type) => value.includes(type.id))
                    .map((item) => (
                      <SelectedChip>{item.name}</SelectedChip>
                    ))}
                </div>
              )}
              isWithError
              helperText={
                getErrorByName(schema, 'inquiryRotationOperationTypes').message
              }
              error={
                getErrorByName(schema, 'inquiryRotationOperationTypes').error
              }
              isSubmitted={isSubmitted}
            />
          }
        />
        <LabeledField
          label={t('isActive')}
          component={
            <IOSSwitch
              checked={state.isActive}
              onChange={(e) => {
                e.persist();
                setState((prev) => ({ ...prev, isActive: e.target?.checked }));
              }}
            />
          }
        />
        <LabeledField
          label={t('inquiry_type')}
          component={
            <InquiryTypes
              isLease={selected.operationTypes.includes(StaticLookupsIds.Rent)}
              isSale={selected.operationTypes.includes(StaticLookupsIds.Sales)}
              onChange={(data) =>
                setState((prev) => ({
                  ...prev,
                  inquiryRotationInquiryTypes: data,
                }))
              }
              parentTranslationPath={parentTranslationPath}
              isZeroMatchingSelected={
                !!activeItem?.rotationInquiryTypes?.find((item) =>
                  [1, 2].includes(item.inquiryTypeId)
                )
              }
              isListingShortageSelected={
                !!activeItem?.rotationInquiryTypes?.find((item) =>
                  [3, 4].includes(item.inquiryTypeId)
                )
              }
            />
          }
        />
        <LabeledField
          label={t('requester_branch')}
          component={
            <AutocompleteComponent
              wrapperClasses={'w-50'}
              idRef='branch2Ref'
              selectedValues={selected.requesterBranches}
              inputPlaceholder={t('select_multiple')}
              data={data?.branches}
              chipsLabel={(option) => option?.branchName ?? ''}
              displayLabel={(option) => option?.branchName ?? ''}
              multiple
              withoutSearchButton
              onInputKeyUp={(e) => searchHandler(e)}
              onChange={(_event, newValue) => {
                const branches = newValue?.map((x) => ({
                  branchId: x.branchId,
                  branchName: x.branchName,
                  isRequesterBranch: true,
                }));

                const uniqueBranches = RemoveDuplicateOptions(
                  branches,
                  'branchId'
                );
                setSelected((prev) => ({
                  ...prev,
                  requesterBranches: uniqueBranches,
                }));
              }}
              renderTags={(e, getTagProps) => (
                <div className='flex chips-container p-1'>
                  {e.map((option, index) => (
                    <SelectedChip {...getTagProps({ index })}>
                      {option.branchName}
                    </SelectedChip>
                  ))}
                </div>
              )}
            />
          }
        />
        <h4 className='form-subtitle mt-5'>{t('rotation_for')}</h4>
        <LabeledField
          label={t('branch')}
          component={
            <AutocompleteComponent
              wrapperClasses={'w-50'}
              idRef='branch2Ref'
              selectedValues={selected.branches}
              inputPlaceholder={t('select_multiple')}
              data={data?.branches}
              chipsLabel={(option) => option?.branchName ?? ''}
              displayLabel={(option) => option?.branchName ?? ''}
              multiple
              withoutSearchButton
              onInputKeyUp={(e) => searchHandler(e)}
              onChange={(_event, newValue) => {
                const branches = newValue?.map((x) => ({
                  branchId: x.branchId,
                  branchName: x.branchName,
                  isRequesterBranch: false,
                }));

                const uniqueBranches = RemoveDuplicateOptions(
                  branches,
                  'branchId'
                );
                setSelected((prev) => ({ ...prev, branches: uniqueBranches }));
              }}
              renderTags={(e, getTagProps) => (
                <div className='flex chips-container p-1'>
                  {e.map((option, index) => (
                    <SelectedChip {...getTagProps({ index })}>
                      {option.branchName}
                    </SelectedChip>
                  ))}
                </div>
              )}
            />
          }
        />
        <LabeledField
          label={t('roles')}
          component={
            <AutocompleteComponent
              wrapperClasses={'w-50'}
              idRef='Assigned-ToRef'
              labelClasses='Requierd-Color'
              selectedValues={selected.roles || []}
              multiple
              data={data.roles}
              chipsLabel={(option) => option?.rolesName || ''}
              displayLabel={(option) => `${option?.rolesName || ''}`}
              withoutSearchButton
              inputPlaceholder={t('select_multiple')}
              // isLoading={loadings}
              // helperText={helperText}
              // error={!!helperText}
              // isSubmitted={isSubmitted}
              onInputKeyUp={(e) => rolesSearchHandler(e)}
              isWithError
              // parentTranslationPath={parentTranslationPath}
              // translationPath={translationPath}
              onChange={(event, newValue) => {
                const roles = newValue?.map((x) => ({
                  rolesId: x.rolesId,
                  rolesName: x.rolesName,
                }));
                const uniqueRoles = RemoveDuplicateOptions(roles, 'rolesId');
                setSelected((prev) => ({ ...prev, roles: uniqueRoles }));
              }}
              renderTags={(e, getTagProps) => (
                <div className='flex chips-container p-1'>
                  {e.map((option, index) => (
                    <SelectedChip {...getTagProps({ index })}>
                      {option.rolesName}
                    </SelectedChip>
                  ))}
                </div>
              )}
            />
          }
        />
        <LabeledField
          label={t('users')}
          component={
            <AutocompleteComponent
              wrapperClasses={'w-50'}
              idRef='UsersRef'
              inputPlaceholder={t('select_multiple')}
              selectedValues={selected.users || []}
              multiple
              data={data.users || []}
              renderTags={(e, getTagProps) => (
                <div className='flex chips-container p-1'>
                  {e.map((option, index) => (
                    <SelectedChip {...getTagProps({ index })}>
                      {option.fullName || option.usersName || ''}
                    </SelectedChip>
                  ))}
                </div>
              )}
              displayLabel={(option) =>
                option.fullName || option.usersName || ''
              }
              renderOption={(option) =>
                (option.fullName
                  ? `${option.fullName}`
                  : `${option.usersName}`) || ''
              }
              withoutSearchButton
              onInputKeyUp={(event) => {
                const { value } = event.target;
                if (searchTimer.current) clearTimeout(searchTimer.current);
                searchTimer.current = setTimeout(() => {
                  getAllUsers(value);
                }, 700);
              }}
              // isLoading={isLoading.users}
              isWithError
              // parentTranslationPath={parentTranslationPath}
              // translationPath={translationPath}
              onChange={(event, newValue) => {
                const users = newValue?.map((item) => ({
                  userId: item.applicationUserId || item.userId,
                  fullName: item.fullName,
                  isSelectedUser: false,
                }));

                const uniqueUsers = RemoveDuplicateOptions(users, 'userId');

                setSelected((prev) => ({
                  ...prev,
                  users: uniqueUsers,
                }));
              }}
            />
          }
        />
        <h4 className='form-subtitle mt-5'>{t('sla_configuration')}</h4>
        <LabeledField
          label={t('SLA')}
          component={
            <FormControlLabel
              control={
                <IOSSwitch
                  checked={state.isSLAApplied}
                  onChange={(e) => {
                    e.persist();
                    setState((prev) => ({
                      ...prev,
                      isSLAApplied: e.target?.checked,
                    }));
                  }}
                />
              }
              label={
                <span className='label-style'>
                  SLA for Inquiry Reassignment
                </span>
              }
              onChange={(e) => e.stopPropagation()}
            />
          }
        />
        {state.isSLAApplied && (
          <>
            <LabeledField
              label={t('sla_for_inquiry_reassignment')}
              component={
                <Inputs
                  wrapperClasses={'w-50'}
                  value={state.slaExpirationPeriod}
                  onInputChanged={(e) => {
                    e.persist();
                    setState((prev) => ({
                      ...prev,
                      slaExpirationPeriod: e.target?.valueAsNumber,
                    }));
                  }}
                  startAdornment={
                    <SelectComponet
                      textInput={'text'}
                      valueInput={'id'}
                      wrapperClasses={'w-25'}
                      data={Object.values(ReminderTimeOptions)}
                      onSelectChanged={(e) =>
                        setState((prev) => ({
                          ...prev,
                          slaExpirationPeriodFreqency: e,
                        }))
                      }
                      value={state.slaExpirationPeriodFreqency}
                    />
                  }
                  type={'number'}
                  min={0}
                  isWithError
                  helperText={
                    getErrorByName(schema, 'slaExpirationPeriod').message
                  }
                  error={getErrorByName(schema, 'slaExpirationPeriod').error}
                  isSubmitted={isSubmitted}
                />
              }
            />
            <LabeledField
              label={t('users')}
              component={
                <AutocompleteComponent
                  wrapperClasses={'w-50'}
                  idRef='UsersRef'
                  inputPlaceholder={t('select_multiple')}
                  selectedValues={selected.reminderUsers || []}
                  multiple
                  data={data.users || []}
                  renderTags={(e, getTagProps) => (
                    <div className='flex chips-container p-1'>
                      {e.map((option, index) => (
                        <SelectedChip {...getTagProps({ index })}>
                          {option.fullName || option.usersName || ''}
                        </SelectedChip>
                      ))}
                    </div>
                  )}
                  displayLabel={(option) =>
                    option.fullName || option.usersName || ''
                  }
                  renderOption={(option) =>
                    (option.fullName
                      ? `${option.fullName}`
                      : `${option.usersName}`) || ''
                  }
                  withoutSearchButton
                  onInputKeyUp={(event) => {
                    const { value } = event.target;
                    if (searchTimer.current) clearTimeout(searchTimer.current);
                    searchTimer.current = setTimeout(() => {
                      getAllUsers(value);
                    }, 700);
                  }}
                  isWithError
                  onChange={(event, newValue) => {
                    const users = newValue?.map((item) => ({
                      userId: item.applicationUserId || item.userId,
                      fullName: item.fullName,
                      isSelectedUser: true,
                    }));
                    const uniqueUsers = RemoveDuplicateOptions(users, 'userId');
                    setSelected((prev) => ({
                      ...prev,
                      reminderUsers: uniqueUsers,
                    }));
                  }}
                />
              }
            />
          </>
        )}
      </div>
    </div>
  );
};

export default InquirySchemeManagement;
