import React from "react";

function AEPAfterEffectsIcon({ fill, ...restProps }) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.75 4C7.75 2.20508 9.20508 0.75 11 0.75H27C27.1212 0.75 27.2375 0.798159 27.3232 0.883885L38.1161 11.6768C38.2018 11.7625 38.25 11.8788 38.25 12V36C38.25 37.7949 36.7949 39.25 35 39.25H11C9.20507 39.25 7.75 37.7949 7.75 36V4Z"
        stroke="#D0D5DD"
        stroke-width="1.5"
      />
      <path
        d="M27 0.5V8C27 10.2091 28.7909 12 31 12H38.5"
        stroke="#D0D5DD"
        stroke-width="1.5"
      />
      <rect x="1" y="18" width="27" height="16" rx="2" fill="#6938EF" />
      <path
        d="M6.34659 30H4.69886L7.20952 22.7273H9.19105L11.6982 30H10.0504L8.22869 24.3892H8.17188L6.34659 30ZM6.24361 27.1413H10.1357V28.3416H6.24361V27.1413ZM12.5735 30V22.7273H17.4741V23.995H14.1112V25.728H17.2219V26.9957H14.1112V28.7322H17.4883V30H12.5735ZM18.6966 30V22.7273H21.5659C22.1175 22.7273 22.5874 22.8326 22.9757 23.0433C23.3639 23.2517 23.6599 23.5417 23.8635 23.9134C24.0694 24.2827 24.1724 24.7088 24.1724 25.1918C24.1724 25.6747 24.0682 26.1009 23.8599 26.4702C23.6516 26.8395 23.3497 27.1271 22.9544 27.3331C22.5614 27.5391 22.0855 27.642 21.5268 27.642H19.698V26.4098H21.2782C21.5742 26.4098 21.818 26.3589 22.0098 26.2571C22.2039 26.1529 22.3483 26.0097 22.443 25.8274C22.5401 25.6428 22.5886 25.4309 22.5886 25.1918C22.5886 24.9503 22.5401 24.7396 22.443 24.5597C22.3483 24.3774 22.2039 24.2365 22.0098 24.1371C21.8156 24.0353 21.5694 23.9844 21.2711 23.9844H20.2342V30H18.6966Z"
        fill="white"
      />
    </svg>
  );
}

export default AEPAfterEffectsIcon;
