import React, { useEffect, useState } from "react";
import MatchedUnitCard from "../Utilities/MatchedUnitCard/MatchedUnitCard";
import CardStyles from "../Utilities/MatchedUnitCard/MatchedUnitCard.module.scss"
import { GetMatchingUnitsByInquiryId } from "../../../../../../../Services";
import { ActiveInquiryViewsEnum } from "../ActiveInquiryViewsEnum";
import Button from "@material-ui/core/Button";

const MatchedUnitsComponent = ({ inquiryId, viewChangeHandler }) => {

  const [matchedUnits, setMatchedUnits] = useState({
    shortListed: [],
    other: [],
  });

  const sortMatchedUnits = (result) => {
    let shortListed = [];
    let other = [];

    result.forEach((item) => {
      if (item.isShortListed) shortListed.push(item);
      else other.push(item);
    });

    return {
      shortListed,
      other,
    };
  };

  const getMatchingUnitsByInquiryId = async () => {
    const res = await GetMatchingUnitsByInquiryId({
      inquiryId,
      pageIndex: 1,
      pageSize: 150,
    });
    if (!(res && res.status && res.status !== 200)) {
      let sortedUnits = res?.result
        ? sortMatchedUnits(res.result)
        : {
          shortListed: [],
          other: [],
        };

      setMatchedUnits(sortedUnits);
    } else {
      setMatchedUnits({
        shortListed: [],
        other: [],
      });
    }
  };

  const checkQuickTransactionVisability = (unit) => (unit?.operationType === "Sale" ||
    unit?.operationType === "SaleAndRent") &&
    ![6, 13, 14].includes(unit.saleStatus);



  useEffect(() => {
    getMatchingUnitsByInquiryId();
  }, []);

  return (
    <div>
      <div className="p-4">
        <h4 className="fz-20px fw-simi-bold">Shortlisted Units</h4>
        <div className="d-flex p-3 flex-wrap gap-16">
          {matchedUnits?.shortListed && matchedUnits?.shortListed.length > 0 ? (
            matchedUnits.shortListed.map((item) => (
                <MatchedUnitCard
                  inquiryId={inquiryId}
                  data={item}
                  actionButtons={{
                    sendPropsal: true,
                    quickTransaction: checkQuickTransactionVisability(item),
                    unmarkAsShortListed: true,
                    viewUnit: true,
                  }}
                  viewUnitHandler={()=> viewChangeHandler({ view: ActiveInquiryViewsEnum.UnitOverview, unit: item })}
                  reload={getMatchingUnitsByInquiryId}
                // translationPath={translationPath}
                // parentTranslationPath={parentTranslationPath}
                />
            ))
          ) : (
            <p className="fz-16px c-gray mx-3">No units to preview .</p>
          )}
        </div>
      </div>
      <div className="p-4">
        <h4 className="fz-20px fw-simi-bold">Other Matching Units</h4>
        <div className="d-flex p-3 flex-wrap gap-16">
          {matchedUnits?.other && matchedUnits?.other.length > 0 ? (
            matchedUnits.other.map((item) => (
                <MatchedUnitCard
                  inquiryId={inquiryId}
                  data={item}
                  actionButtons={{
                    markAsShortListed: true,
                    sendPropsal: true,
                    viewUnit: true,
                  }}
                  viewUnitHandler={()=> viewChangeHandler({ view: ActiveInquiryViewsEnum.UnitOverview, unit: item })}
                  reload={getMatchingUnitsByInquiryId}
                // translationPath={translationPath}
                // parentTranslationPath={parentTranslationPath}
                />
            ))
          ) : (
            <p className="fz-16px c-gray mx-3">No units to preview .</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default MatchedUnitsComponent;
