export const PropertyValidationContextLocale = {
  en: {
    add_new_property: 'Add New Property',
    submit: 'Submit',
    created_at: 'Created At',
    single_matching_property: '1 matching property found',
    multiple_matching_properties: '{{count}} matching properties found',
    property_name: 'Property Name',
    property_type: 'Property Type',
    country: 'Country',
    city: 'City',
    district: 'District',
    community: 'Community',
    sub_community: 'Sub-Community',
    property_id: 'Property ID',
    multiple_fuzzy_matching_properties:
      '{{count}} fuzzy matching properties found',
    single_fuzzy_matching_property: '1 fuzzy matching property found',
    similar_properties: 'Similar Properties',
    skip_and_continue: 'Skip and Continue',
    invalid_property_name:
      'Property name cannot contain only special characters.',
  },
  ar: {
    add_new_property: 'إضافة عقار جديد',
    submit: 'إرسال',
    created_at: 'تاريخ الإنشاء',
    single_matching_property: 'تم العثور على عقار واحد مطابق',
    multiple_matching_properties: 'تم العثور على {{count}} عقارات مطابقة',
    property_name: 'اسم العقار',
    property_type: 'نوع العقار',
    country: 'الدولة',
    city: 'المدينة',
    district: 'الحي',
    community: 'المجتمع',
    sub_community: 'المجتمع الفرعي',
    property_id: 'معرف العقار',
    multiple_fuzzy_matching_properties:
      'تم العثور على {{count}} عقارات متطابقة بشكل تقريبي',
    single_fuzzy_matching_property:
      'تم العثور على عقار واحد متطابق بشكل تقريبي',
    similar_properties: 'عقارات مشابهة',
    skip_and_continue: 'تخطي ومتابعة',
    invalid_property_name: 'اسم العقار لا يمكن أن يحتوي على رموز خاصة فقط.',
  },
  ru: {
    add_new_property: 'Добавить новую недвижимость',
    submit: 'Отправить',
    created_at: 'Создано',
    single_matching_property: 'Найден 1 совпадающий объект',
    multiple_matching_properties: 'Найдено {{count}} совпадающих объектов',
    property_name: 'Название объекта',
    property_type: 'Тип объекта',
    country: 'Страна',
    city: 'Город',
    district: 'Район',
    community: 'Сообщество',
    sub_community: 'Подсообщество',
    property_id: 'ID объекта',
    multiple_fuzzy_matching_properties:
      'Найдено {{count}} объектов с приблизительным совпадением',
    single_fuzzy_matching_property:
      'Найден 1 объект с приблизительным совпадением',
    similar_properties: 'Похожие объекты',
    skip_and_continue: 'Пропустить и продолжить',
    invalid_property_name:
      'Название объекта не может состоять только из специальных символов.',
  },
  cn: {
    add_new_property: '添加新物业',
    submit: '提交',
    created_at: '创建于',
    single_matching_property: '找到 1 个匹配的物业',
    multiple_matching_properties: '找到 {{count}} 个匹配的物业',
    property_name: '物业名称',
    property_type: '物业类型',
    country: '国家',
    city: '城市',
    district: '区',
    community: '社区',
    sub_community: '子社区',
    property_id: '物业 ID',
    multiple_fuzzy_matching_properties: '找到 {{count}} 个模糊匹配的物业',
    single_fuzzy_matching_property: '找到 1 个模糊匹配的物业',
    similar_properties: '类似的物业',
    skip_and_continue: '跳过并继续',
    invalid_property_name: '物业名称不能仅包含特殊字符。',
  },
};

export const PropertyValidationContextLocalePath = 'PropertyValidationContext';
