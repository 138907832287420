import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import "./ContactSourceDetailsCard.scss";

function ContactSourceDetailsCard({
  data,
  translationPath,
  parentTranslationPath,
}) {
  const { t } = useTranslation(parentTranslationPath);
  return (
    <React.Fragment>
      <div className="contact-source-card-container">
        <div>
          <div className="contact-source-card-upper-section">
            <span className="contact-source-card-title">
              {t("contact-source-details")}
            </span>
            <div className="contact-source-card-upper-section-item-container">
              {data && data.contactSourceType && (
                <span className="contact-source-card-upper-section-item">
                  {data.contactSourceType === 1
                    ? t("contact")
                    : data.contactSourceType === 2
                    ? t("lead")
                    : null}
                </span>
              )}
              {data && (data.sourceFileYear || data.sourceFileDate) && (
                <span className="contact-source-card-upper-section-item">
                  {data && data.sourceFileYear
                    ? data.sourceFileYear
                    : data && data.sourceFileDate
                    ? moment(data.sourceFileDate).format("YYYY")
                    : null}
                </span>
              )}
              {data && (data?.mediaName || data?.sourceFileName)  && (
                <span className="contact-source-card-upper-section-item">
                  {t(data.mediaName || data?.sourceFileName)}
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="d-flex-column">
          <span>{t("contact-by")}:</span>
          <span>{data?.createdby || ""}</span>
        </div>
        <div className="d-flex-column">
          <span>{t("community-contact-file")}:</span>
          <span>{data?.communitySourceFile || ""}</span>
        </div>
        <div className="d-flex-column">
          <span>{t("property-contact-file")}:</span>
          <span>{data?.propertySourceFile || ""}</span>
        </div>
        <div className="contact-source-card-bottom-section">
          <div className="d-flex-column ">
            <span>{t("contact-file-date")}:</span>
            <span>
              {data && data.sourceFileDate
                ? moment(data.sourceFileDate).format("DD/MM/YYYY")
                : ""}
            </span>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ContactSourceDetailsCard;
