export const AgentCourseQualificationsLocale = {
  en: {
    name: 'Name',
    branch: 'Branch',
    courses: 'Courses',
    team: 'Team',
    staff_id: 'Staff ID',
    email: 'Email',
    branches: 'Branches',
    teams: 'Teams',
    select_branch: 'Select Branch',
    select_team: 'Select Team',
    select_course: 'Select Course',
    badge: 'Badge',
    select_badge: 'Select Badge',
    agent_name: 'Agent Name',
    matches_with: 'Matches With',
    no_agent_found: 'No qualified agents found.',
    no_courses_available: 'No Courses Available',
    no_matching_agents_found: 'No matching agents found',
  },
  ar: {
    name: 'الاسم',
    branch: 'الفرع',
    courses: 'الدورات',
    team: 'الفريق',
    staff_id: 'معرف الموظف',
    email: 'البريد الإلكتروني',
    branches: 'الفروع',
    teams: 'الفرق',
    select_branch: 'اختر فرع',
    select_team: 'اختر فريق',
    select_course: 'اختر دورة',
    badge: 'شارة',
    select_badge: 'اختر شارة',
    agent_name: 'اسم الوكيل',
    matches_with: 'يتطابق مع',
    no_agent_found: 'لم يتم العثور على وكلاء مؤهلين.',
    no_courses_available: 'لا توجد دورات متاحة',
    no_matching_agents_found: 'لم يتم العثور على وكلاء مطابقين',
  },
  ru: {
    name: 'Имя',
    branch: 'Филиал',
    courses: 'Курсы',
    team: 'Команда',
    staff_id: 'ID сотрудника',
    email: 'Электронная почта',
    branches: 'Филиалы',
    teams: 'Команды',
    select_branch: 'Выберите филиал',
    select_team: 'Выберите команду',
    select_course: 'Выберите курс',
    badge: 'Значок',
    select_badge: 'Выберите значок',
    agent_name: 'Имя агента',
    matches_with: 'Совпадает с',
    no_agent_found: 'Квалифицированные агенты не найдены.',
    no_courses_available: 'Курсы недоступны',
    no_matching_agents_found: 'Соответствующие агенты не найдены',
  },
  cn: {
    name: '姓名',
    branch: '分行',
    courses: '课程',
    team: '团队',
    staff_id: '员工ID',
    email: '电子邮件',
    branches: '分支机构',
    teams: '团队',
    select_branch: '选择分支',
    select_team: '选择团队',
    select_course: '选择课程',
    badge: '徽章',
    select_badge: '选择徽章',
    agent_name: '代理名称',
    matches_with: '匹配',
    no_agent_found: '未找到合格的代理。',
    no_courses_available: '没有可用的课程',
    no_matching_agents_found: '未找到匹配的代理',
  },
};

export const AgentCourseQualificationsLocalePath = 'AgentCourseQualifications';
