import React from "react";

function JAVAIcon({ fill, ...restProps }) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.75 4C7.75 2.20508 9.20508 0.75 11 0.75H27C27.1212 0.75 27.2375 0.798159 27.3232 0.883885L38.1161 11.6768C38.2018 11.7625 38.25 11.8788 38.25 12V36C38.25 37.7949 36.7949 39.25 35 39.25H11C9.20507 39.25 7.75 37.7949 7.75 36V4Z"
        stroke="#D0D5DD"
        stroke-width="1.5"
      />
      <path
        d="M27 0.5V8C27 10.2091 28.7909 12 31 12H38.5"
        stroke="#D0D5DD"
        stroke-width="1.5"
      />
      <rect x="1" y="18" width="33" height="16" rx="2" fill="#444CE7" />
      <path
        d="M7.90927 22.7273H9.42915V27.7983C9.42915 28.267 9.3238 28.6742 9.1131 29.0199C8.90477 29.3655 8.61476 29.6319 8.24308 29.8189C7.87139 30.0059 7.43933 30.0994 6.94691 30.0994C6.50894 30.0994 6.11121 30.0225 5.75373 29.8686C5.39862 29.7124 5.11689 29.4756 4.90856 29.1584C4.70022 28.8388 4.59724 28.4375 4.59961 27.9545H6.13015C6.13488 28.1463 6.17395 28.3108 6.24734 28.4482C6.32309 28.5831 6.42608 28.6873 6.55629 28.7607C6.68886 28.8317 6.84511 28.8672 7.02504 28.8672C7.21443 28.8672 7.37423 28.8269 7.50444 28.7464C7.63701 28.6636 7.73763 28.5429 7.80629 28.3842C7.87494 28.2256 7.90927 28.0303 7.90927 27.7983V22.7273ZM11.954 30H10.3063L12.8169 22.7273H14.7985L17.3056 30H15.6578L13.8361 24.3892H13.7793L11.954 30ZM11.851 27.1413H15.7431V28.3416H11.851V27.1413ZM18.5866 22.7273L20.3445 28.2528H20.4119L22.1733 22.7273H23.8778L21.3707 30H19.3892L16.8786 22.7273H18.5866ZM25.04 30H23.3922L25.9029 22.7273H27.8844L30.3915 30H28.7438L26.9221 24.3892H26.8652L25.04 30ZM24.937 27.1413H28.829V28.3416H24.937V27.1413Z"
        fill="white"
      />
    </svg>
  );
}

export default JAVAIcon;
